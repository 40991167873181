import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles({
    root: {
        width: 500,
    },
    input: {
        width: 100,
        color: "black",
        borderColor: "black",
    },
});

export default function RangeSlider(props) {
    const classes = useStyles();
    var [value, setValue] = React.useState([props.min, props.max]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.handleSlider(props.name, newValue);
    };

    const handleInputChange = (event, i) => {
        const input_value = Number(event.target.value);
        var newValue = value.map((e, index) => {
            if (index === i) {
                console.log(index);
                return input_value;
            }
            return e;
        });
        setValue(newValue);
    };

    const handleBlur = (e) => {
        const index = e.target.index;
        var newValue = value;
        if (value[index] < props.min) {
            newValue[index] = props.min;
            setValue(newValue);
        } else if (value[index] > props.max) {
            newValue[index] = props.max;
            setValue(newValue);
        }
    };

    return (
        <div className={classes.root}>
            {props.unit &&
            <Typography
                id="input-slider"
                style={{ marginBottom: "50px" }}
                gutterBottom
            >
                Specify in {props.unit}
            </Typography>}
            <Grid container spacing={5} alignItems="center">
                <Grid item>
                    <Input
                        index={0}
                        className={classes.input}
                        value={value[0]}
                        margin="dense"
                        style={{"color":"black"}}
                        onChange={(e) => handleInputChange(e, 0)}
                        onBlur={handleBlur}
                        inputProps={{
                            step: props.step,
                            min: props.min,
                            max: props.max,
                            type: "number",
                            "aria-labelledby": "input-slider",
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <Slider
                        value={value}
                        onChange={handleChange}
                        aria-labelledby="input-slider"
                        style={{"color":"black"}}
                        min={props.min}
                        max={props.max}
                        step={props.step}
                        valueLabelDisplay="on"
                    />
                </Grid>
                <Grid item>
                    <Input
                        index={1}
                        className={classes.input}
                        value={value[1]}
                        margin="dense"
                        style={{"color":"black"}}
                        onChange={(e) => handleInputChange(e, 1)}
                        onBlur={handleBlur}
                        inputProps={{
                            step: props.step,
                            min: props.min,
                            max: props.max,
                            type: "number",
                            "aria-labelledby": "input-slider",
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
}
