const initEstimation = {
    canvas:{},
    author:{},
    comments:[],
    email:{},
    id:"", 
    estimatedTime: '', 
    estimatedCost: 0, 
    notes:'', 
    estimatedCostDetails:[], 
    loading:false, 
    success: false, 
    error: false, 
    successMsg:'', 
    errorMsg:'', 
    uploads:[],
    type: '',
    estimatedTimeDetails: {
        sketching: 0,
        modeling: 0,
        rendering: 0,
        drawing: 0
    },
    designStart: {
        canYouStartNow: false,
        whenCanYouStart: ''
    }
};

let DEBUG = false;

const estimationReducer = (state = initEstimation, action) => {
    let newState;

    if (DEBUG) console.log("ESTIMATION REDUCER:");
    if (DEBUG) console.log("ACTION:");
    if (DEBUG) console.log(action);

    if (DEBUG) console.log("CURRENT STATE:");
    if (DEBUG) console.log(state);

    switch (action.type) {
        case "SET_ESTIMATION":
            newState = {
                ...state,
                ...action.estimation,
                id: action.id,
                loading: action.loading,
                error: action.error
            };
            break;
        case "SET_ESTIMATION_LOADING":newState = {
            ...state,
            ...action.estimation,
            loading: action.loading
        };
        break;
        case "SET_ERROR":
            newState = {
                ...state,
                error: action.error, errorMsg:action.msg
            };
            break;
        case "SET_SUCCESS":
            newState = {
                ...state,
                success: action.success, successMsg:action.msg
            };
            break;
        default:
            newState = state;
    }

    if (DEBUG) console.log("NEW STATE:");
    if (DEBUG) console.log(newState);

    return newState;
};

export default estimationReducer;
