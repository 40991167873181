import React, { Component, useCallback } from "react";

import { Container, Row, Image, Col } from "react-bootstrap";

import Dropzone, { useDropzone } from "react-dropzone";
import "../../stylesheets/mediadrop.css";
import axios from "axios";

import ImageCard from "./ImageCard";

import { API_ENDPOINT } from "../../secrets";

const style = {
    container: {
        cursor: "pointer",
        border: "2px dashed black",
        borderRadius: "5px",
        // backgroundColor: "black",
        padding: "50px",
    },
};

function CustomDropzone(props) {
    const onDrop = useCallback(props.callback, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: true,
    });

    return (
        <Container {...getRootProps()} style={style.container}>
            <Row className="justify-content-md-center">
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p style={{ color: "white" }}>Drop the files here ...</p>
                ) : (
                    <p style={{ color: "white" }}>
                        Drag 'n' drop some files here, or click to select files
                    </p>
                )}
            </Row>
        </Container>
    );
}

class MediaDrop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        };
    }

    uploadFile = (fileData) => {
        const formData = new FormData();
        const file = fileData.file;
        formData.append("file", file, file.name);

        axios
            .post(`${API_ENDPOINT}/api/upload`, formData, { crossDomain: true })
            .then((response) => {
                this.props.addFile(this.props.name, response.data.link);
                const newFiles = this.state.files.map((fileData) => {
                    if (fileData.file === file) {
                        return { file: file, uploaded: true };
                    }
                    return fileData;
                });
                this.setState({
                    files: newFiles,
                });
            })
            .catch((err) => {
                alert("Error uploading image!");
                const newFiles = [];
                this.state.files.forEach((fileData) => {
                    if (fileData.file !== file) newFiles.push(fileData);
                });
                this.setState({
                    files: newFiles,
                });
                console.log(err);
            });
    };

    handleFilesAdd = (files) => {
        files.forEach((file) => {
            var files = this.state.files;
            const fileData = { file: file, uploaded: false };
            files.push(fileData);
            this.setState({
                files: files,
            });
            this.uploadFile(fileData);
        });
    };

    renderPreviews = () => {
        let renderPreviews = [];
        this.state.files.forEach((file) => {
            renderPreviews.push(
                <ImageCard
                    src={URL.createObjectURL(file.file)}
                    loaded={file.uploaded}
                ></ImageCard>
            );
        });
        return renderPreviews;
    };

    render() {
        const renderPreviews = this.renderPreviews();
        return (
            <Dropzone onDrop={this.handleFilesAdd}>
                {({ getRootProps, getInputProps }) => (
                    <Container style={style.container} {...getRootProps()}>
                        <Row className="justify-content-md-center">
                            {renderPreviews}
                        </Row>
                        <Row className="justify-content-md-center">
                            <input {...getInputProps()} />
                            <p>
                                Drag 'n' drop some files here, or click to
                                select files
                            </p>
                        </Row>
                    </Container>
                )}
            </Dropzone>
        );
    }
}

export default MediaDrop;
