import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import axios from "axios";

import { ADMIN_BACKEND } from "../../secrets";

import "../../css/estimateInput.css";
import close from "../../images/icons/cancel.svg";

class EstimateRequest extends Component {
    constructor(props) {
        super(props);
        const id = props.match.params.id;
        this.state = {
            requestId: id,
            emailInput: false,
            formData: {},
            componentName: "",
            componentPrice: "",
        };
    }

    getRequest = () => {
        axios
            .get(
                `${ADMIN_BACKEND}/estimates/request?id=${this.state.requestId}&email=${this.state.email}`
            )
            .then((response) => {
                console.log(response.data);
                const request = response.data.estimateRequest;
                console.log(request);
                if (!request) {
                    this.setState({
                        invalid: true,
                    });
                } else {
                    this.setState({
                        request,
                        canvas_id: request.canvas_id,
                        emailInput: true,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    sendEstimate = () => {
        const data = {
            estimate: this.state.formData,
            canvas_id: this.state.canvas_id,
            email: this.state.email,
            request_id: this.state.requestId,
        };
        axios
            .post(`${ADMIN_BACKEND}/estimates/submit`, data)
            .then((response) => {
                console.log(response.data);
                this.setState({
                    done: true,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    emailInput = (e) => {
        const value = e.target.value;
        this.setState({
            email: value,
        });
    };

    textInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    numberInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (value && value.length > 0) {
            for (var c in value) {
                if (value[c] > "9" || value[c] < "0") {
                    if (value[c] !== ".") return;
                }
            }
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };

    renderEntry = (entry, index) => {
        return (
            <tr key={index}>
                <td>{entry.name}</td>
                <td>${entry.price}</td>
                <td>
                    <button
                        onClick={() => {
                            this.removeComponent(entry.name);
                        }}
                    >
                        <img src={close} alt='remove' />
                    </button>
                </td>
            </tr>
        );
    };

    renderEntries = () => {
        const entries = this.state.formData.breakdown;
        if (!entries || !entries.length) {
            return null;
        }
        var rows = entries.map(this.renderEntry);
        return [
            <h3>Cost Breakdown</h3>,
            <table className='breakdown-table'>
                <thead>
                    <tr>
                        <th style={{ width: "80%" }}>NAME</th>
                        <th>PRICE</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>,
        ];
    };

    componentNameInput = (e) => {
        this.setState({
            componentName: e.target.value,
        });
    };

    componentPriceInput = (e) => {
        const value = e.target.value;
        if (value && value.length > 0) {
            for (var c in value) {
                if (value[c] > "9" || value[c] < "0") {
                    if (value[c] !== ".") return;
                }
            }
        }
        this.setState({
            componentPrice: value,
        });
    };

    addComponent = () => {
        var breakdown = this.state.formData.breakdown;
        if (!breakdown || !breakdown.length) breakdown = [];
        breakdown.push({
            name: this.state.componentName,
            price: this.state.componentPrice,
        });
        var sum = 0;
        breakdown.forEach((c) => {
            sum += Number(c.price);
        });
        this.setState({
            formData: {
                ...this.state.formData,
                estimate: sum,
                breakdown,
            },
            componentName: "",
            componentPrice: 0,
        });
    };

    removeComponent = (name) => {
        var breakdown = this.state.formData.breakdown;
        if (!breakdown || !breakdown.length) breakdown = [];
        var newBreakdown = [];
        breakdown.forEach((item) => {
            if (item.name !== name) newBreakdown.push(item);
        });
        breakdown = newBreakdown;
        var sum = 0;
        breakdown.forEach((c) => {
            sum += Number(c.price);
        });
        this.setState({
            formData: {
                ...this.state.formData,
                estimate: sum,
                breakdown,
            },
            componentName: "",
            componentPrice: 0,
        });
    };

    renderEntryInput = (entry) => {
        return (
            <div
                style={{
                    background: "#ddd",
                    margin: "5px 0px",
                    padding: "10px 10px",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Row style={{ margin: 0 }}>
                    <h3>Add Items to list (for cost breakdown)</h3>
                </Row>
                <Row>
                    <Col>
                        <input
                            className='estimate-input-field'
                            type='text'
                            placeholder='Enter name of item'
                            onChange={this.componentNameInput}
                            value={this.state.componentName}
                        />
                    </Col>
                    <Col>
                        <input
                            className='estimate-input-field'
                            type='text'
                            placeholder='price'
                            onChange={this.componentPriceInput}
                            value={this.state.componentPrice}
                        />
                    </Col>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            flexGrow: 0,
                            minWidth: "100px",
                        }}
                    >
                        <button
                            className='add-button'
                            onClick={this.addComponent}
                        >
                            Add
                        </button>
                    </div>
                </Row>
            </div>
        );
    };

    render() {
        let render;
        if (this.state.done) {
            render = (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <h1>
                        Thank you for your input! Your request has been recorder
                    </h1>
                </div>
            );
        } else if (this.state.invalid) {
            render = (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <h1>Oops! The email or link is incorrect</h1>
                </div>
            );
        } else if (!this.state.emailInput) {
            render = (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <h1>Enter your email to continue</h1>
                    <input
                        className='estimate-input-field'
                        type='text'
                        name='email'
                        onChange={this.emailInput}
                    />
                    <Button onClick={this.getRequest}>Submit</Button>
                </div>
            );
        } else {
            let renderEntries = this.renderEntries();
            let renderEntryInput = this.renderEntryInput();
            render = (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <h1>Estimate Entry</h1>
                    <label>Name</label>
                    <input
                        className='estimate-input-field'
                        type='text'
                        value={this.state.formData.name}
                        name='name'
                        onChange={this.textInput}
                    />
                    {renderEntries}
                    {renderEntryInput}
                    <label>Estimate Amount (in $)</label>
                    <input
                        className='estimate-input-field'
                        type='text'
                        value={this.state.formData.estimate}
                        name='estimate'
                        onChange={this.numberInput}
                    />
                    <label>Lead Time (number of weeks)</label>
                    <input
                        className='estimate-input-field'
                        type='text'
                        value={this.state.formData.leadTime}
                        name='leadTime'
                        onChange={this.numberInput}
                    />
                    <label>Notes</label>
                    <input
                        className='estimate-input-field'
                        type='text'
                        value={this.state.formData.notes}
                        name='notes'
                        onChange={this.textInput}
                    />
                    <Button onClick={this.sendEstimate}>Submit</Button>
                </div>
            );
        }
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "30px 25%",
                    justifyContent: "center",
                    minHeight: "100vh",
                }}
            >
                {render}
            </div>
        );
    }
}

export default EstimateRequest;
