import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Image } from "react-bootstrap";
import { getCanvas, updateCanvas } from "../../actions/canvasActions";
import { withRouter } from "react-router-dom";
import logo from "../../images/naya-logo-white.png";
import { DEBUG } from "../../secrets";

var Chance = require("chance");
var chance = new Chance();

class CanvasWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editCanvasNameMode: false,
            canvasName: chance.animal({ type: "ocean" }).split(" ")[0],
        };
    }

    componentDidMount() {
        console.log("CANVAS WIDGET:", this.props.canvasId)
        this.props.getCanvas({ id: this.props.canvasId });
    }

    handleClick = (e) => {
        e.preventDefault();
        localStorage.setItem("nayaCanvasID", this.props.canvasId);
        localStorage.setItem("nayaCanvasId", this.props.canvasId);
        // this.props.history.push("canvas");
        window.open(`https://app.naya.studio/canvas/${this.props.canvasId}`, "_blank", "");
        // let uri = `${USER_APP_ENDPOINT}/canvas/${this.props.canvasId}`
        // window.location = uri
        // if(DEBUG) console.log(uri)
    };

    timeDifference(current, previous) {
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;

        var elapsed = current - previous;

        if (elapsed < msPerMinute) {
            return Math.round(elapsed / 1000) + " seconds ago";
        } else if (elapsed < msPerHour) {
            return Math.round(elapsed / msPerMinute) + " minutes ago";
        } else if (elapsed < msPerDay) {
            return Math.round(elapsed / msPerHour) + " hours ago";
        } else if (elapsed < msPerMonth) {
            return Math.round(elapsed / msPerDay) + " days ago";
        } else if (elapsed < msPerYear) {
            return Math.round(elapsed / msPerMonth) + " months ago";
        } else {
            return Math.round(elapsed / msPerYear) + " years ago";
        }
    }

    getImages(canvas) {
        let allImages = [];
        if (canvas.uploads) {
            if (DEBUG) console.log(canvas.uploads);
            allImages = [
                ...allImages,
                ...canvas.uploads.map((upload) => upload && upload.link),
            ];
        }

        let visualMoodboard;
        if (
            canvas.moodboards &&
            canvas.moodboards.filter((moodboard) => !moodboard.isText).length >
                0
        ) {
            visualMoodboard = canvas.moodboards.filter(
                (moodboard) => !moodboard.isText
            )[0].options;
            allImages = [...allImages, ...visualMoodboard];
        }
        allImages = [...allImages, logo];
        return allImages;
    }

    handleNameChange = (e) => {
        e.preventDefault();
        this.setState({ canvasName: e.target.value });
    };

    handleSubmitChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let data = {
            id: this.props.canvas._id,
            name: this.state.canvasName,
        };
        this.props.updateCanvas(data);
        this.setState({ editCanvasNameMode: false });
    };

    renderCanvasThumb = () => {
        if (this.props.canvas !== undefined) {
            let date = new Date(this.props.canvas.updatedAt);
            let now = new Date();
            let diff = this.timeDifference(now, date);

            let allImages = this.getImages(this.props.canvas);

            return (
                <Col
                    xs={12}
                    md={4}
                    lg={{ span: 3 }}
                    className="canvas-widget"
                    onClick={this.handleClick}
                >
                    <Col xs={12}>
                        <Row>
                            <Col
                                xs={12}
                                style={{
                                    paddingLeft: "0",
                                    paddingRight: "0",
                                    height: "250px",
                                    overflow: "hidden",
                                }}
                            >
                                <Image
                                    src={
                                        allImages[
                                            Math.floor(
                                                Math.random() * allImages.length
                                            )
                                        ]
                                    }
                                    style={{ height: "100%" }}
                                    fluid
                                ></Image>
                            </Col>
                            <Col xs={12} className="widget-name">
                                <Row>
                                    <Col xs={12}>
                                        {!this.state.editCanvasNameMode && (
                                            <h5>
                                                {" "}
                                                {this.props.canvas.name !==
                                                undefined
                                                    ? this.props.canvas.name
                                                    : this.state.canvasName}
                                            </h5>
                                        )}
                                        {this.state.editCanvasNameMode && (
                                            <span>
                                                <Form.Control
                                                    type="text"
                                                    onClick={(e) =>
                                                        e.stopPropagation()
                                                    }
                                                    onChange={
                                                        this.handleNameChange
                                                    }
                                                    placeholder={
                                                        this.state.canvasName
                                                    }
                                                    value={
                                                        this.state.canvasName
                                                    }
                                                />
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={12}>
                                <p>Last updated</p>
                            </Col>
                            <Col xs={12}>
                                <p>{diff}</p>
                            </Col>
                        </Row>
                    </Col>
                </Col>
            );
        } else {
            return (
                <Col xs={12} lg={{ span: 4 }} className="canvas-widget">
                    <Col xs={12}>
                        <Row>Loading Information...</Row>
                    </Col>
                </Col>
            );
        }
    };
    render() {
        return this.renderCanvasThumb();
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        canvas: state.canvas[ownProps.canvasId],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCanvas: (id) => dispatch(getCanvas(id)),
        updateCanvas: (data) => dispatch(updateCanvas(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CanvasWidget));
