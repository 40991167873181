import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Modal, Image, Col, Row, Form} from 'react-bootstrap';
import closeIcon from '../../images/icons/cancel_icon.png';
import addIcon from '../../images/icons/add_circle.png';
import Slider from '@material-ui/core/Slider';
import {addAnswer, updateUser} from '../../actions/accountActions';

class Options extends React.Component{
  state = {
    selectedOptions:[],
    moreOptions:[], 
    priceRange:[1200, 6000],
    productionScale:[1, 1000],
    productionCapacity:20,
    moreOptionText:"", 
    workExperience:1,
    dimensions:[0,0,0],
    additionalQuestion:'',
    edit:false,
    additionalInfo:''
  }

  componentDidMount(){
    let answer = this.checkForAnswer(this.props.questionData);
    if(answer!==null){
      // if the answer is already answered by the user, getting the previous answer
      switch(this.props.questionData.type){
        case "mcq":
          this.setState({
            edit:true,
            selectedOptions:answer[0].selected_options,
            moreOptions:answer[0].selected_options.filter(x => !this.props.questionData.options.includes(x))
          })
          break;
        case "production-capacity":
          this.setState({
            edit:true,
            productionCapacity:answer[0].production_capacity,
          })
          break;
        case "dimensions":
          this.setState({
            edit:true,
            dimensions:answer[0].dimensions,
          })
          break;
        case "price-range":
          this.setState({
            edit:true,
            priceRange:answer[0].price_range,
          })
          break;
        case "production-scale":
          this.setState({
            edit:true,
            productionScale:answer[0].production_scale,
          })
          break;
        case "work-experience":
          this.setState({
            edit:true,
            workExperience:answer[0].work_experience,
          })
          break;
        case "additional-info":
          this.setState({
            edit:true,
            additionalInfo:answer[0].additional_info,
          })
          break;
        default:
          break;
      }
      
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps!==this.props){
    let answer = this.checkForAnswer(this.props.questionData);
    if(answer!==null){
      // if the answer is already answered by the user, getting the previous answer
      switch(this.props.questionData.type){
        case "mcq":
          this.setState({
            edit:true,
            selectedOptions:answer[0].selected_options,
            moreOptions:answer[0].selected_options.filter(x => !this.props.questionData.options.includes(x))
          })
          break;
        case "production-capacity":
          this.setState({
            edit:true,
            productionCapacity:answer[0].production_capacity,
          })
          break;
        case "dimensions":
          this.setState({
            edit:true,
            dimensions:answer[0].dimensions,
          })
          break;
        case "price-range":
          this.setState({
            edit:true,
            priceRange:answer[0].price_range,
          })
          break;
        case "production-scale":
          this.setState({
            edit:true,
            productionScale:answer[0].production_scale,
          })
          break;
        case "work-experience":
          this.setState({
            edit:true,
            workExperience:answer[0].work_experience,
          })
          break;
        case "additional-info":
          this.setState({
            edit:true,
            additionalInfo:answer[0].additional_info,
          })
          break;
        default:
          break;
      }
      
    }
  }
  }

  // function to display question statement and the user selected options in the question modal
  getQuestionData = () => {
    return(
      <Col>
        <h5 style={{ textAlign:'center', marginBottom:5}}>{this.props.questionData.question}</h5>
        <h6 style={{ textAlign:'center', fontSize:14, fontWeight:400, color:'#999999', marginBottom:'1rem'}}>{this.props.questionData.instructions}</h6>
        {this.getOptions(this.props.questionData)}
      </Col>
    )
  }

  addMoreOptions = (option) => {
    if(!this.state.moreOptions.includes(option)){
      this.setState({moreOptions:[...this.state.moreOptions, option], selectedOptions:[...this.state.selectedOptions, option], moreOptionText:''})
    }
  }

  // function to handle select and unsleect of an option
  addRemoveOption = (option) => {
    if(this.state.selectedOptions.includes(option)){
      let tempArr = this.state.selectedOptions.filter(i=>i!==option)
      this.setState({selectedOptions:tempArr})
    }
    else{
      this.setState({selectedOptions:[...this.state.selectedOptions, option]})
    }
  }

  handlePriceRange = (event, newValue) => {
    this.setState({priceRange:newValue})
  }

  handleProductionScale = (event, newValue) => {
    this.setState({productionScale:newValue})
  }

  handleProductionCapacity = (event, newValue) => {
    this.setState({productionCapacity:newValue})
  }

  // function to save the answer to the database
  saveAnswer = (type) => {
    if(type!=='skip'){
      switch(this.props.questionData.type){
        case "mcq":
          // if(this.state.selectedOptions.length>0){
            let questionData = {
              updateType:'PROFILE_QUESTION',
              id:this.props.user._id,
              data:{
                type:this.props.questionData.type,
                id:this.props.questionData.id,
                name: this.props.questionData.name,
                selected_options:this.state.selectedOptions,
                additional_question:this.state.additionalQuestion
              }
            }
            if(this.state.edit){
              this.props.updateUser(questionData)
            }
            else{
              this.props.addAnswer(questionData);
            }
          // }
          break;
        case "production-capacity":
          let productionCapacityData = {
            updateType:'PROFILE_QUESTION',
            id:this.props.user._id,
            name: this.props.questionData.name,
            data:{
              type:this.props.questionData.type,
              id:this.props.questionData.id,
              production_capacity:this.state.productionCapacity
            }
          }
          if(this.state.edit){
            this.props.updateUser(productionCapacityData)
          }
          else{
            this.props.addAnswer(productionCapacityData);
          }
          break;
        case "dimensions":
          let dimensionsData = {
            updateType:'PROFILE_QUESTION',
            id:this.props.user._id,
            name: this.props.questionData.name,
            data:{
              type:this.props.questionData.type,
              id:this.props.questionData.id,
              dimensions:this.state.dimensions
            }
          }
          if(this.state.edit){
            this.props.updateUser(dimensionsData)
          }
          else{
            this.props.addAnswer(dimensionsData);
          }
          break;
        case "price-range":
          let priceRangeData = {
            updateType:'PROFILE_QUESTION',
            id:this.props.user._id,
            name: this.props.questionData.name,
            data:{
              type:this.props.questionData.type,
              id:this.props.questionData.id,
              price_range:this.state.priceRange
            }
          }
          if(this.state.edit){
            this.props.updateUser(priceRangeData)
          }
          else{
            this.props.addAnswer(priceRangeData);
          }
          break;
        case "production-scale":
          let productionScaleData = {
            updateType:'PROFILE_QUESTION',
            id:this.props.user._id,
            name: this.props.questionData.name,
            data:{
              type:this.props.questionData.type,
              id:this.props.questionData.id,
              production_scale:this.state.productionScale
            }
          }
          if(this.state.edit){
            this.props.updateUser(productionScaleData)
          }
          else{
            this.props.addAnswer(productionScaleData);
          }
          break;
        case "work-experience":
          let workExperienceData = {
            updateType:'PROFILE_QUESTION',
            id:this.props.user._id,
            name: this.props.questionData.name,
            data:{
              type:this.props.questionData.type,
              id:this.props.questionData.id,
              work_experience:this.state.workExperience
            }
          }
          if(this.state.edit){
            this.props.updateUser(workExperienceData)
          }
          else{
            this.props.addAnswer(workExperienceData);
          }
          break;
        case "additional-info":
          // if(this.state.additionalInfo.trim().length!==0){
            let additionalInfoData = {
              updateType:'PROFILE_QUESTION',
              id:this.props.user._id,
              name: this.props.questionData.name,
              data:{
                type:this.props.questionData.type,
                id:this.props.questionData.id,
                additional_info:this.state.additionalInfo
              }
            }
            if(this.state.edit){
              this.props.updateUser(additionalInfoData)
            }
            else{
              this.props.addAnswer(additionalInfoData);
            }
          // }
          break;
        default:
          break;
      }
    }
    this.setState({additionalQuestion:''})
    // if(this.state.edit){
    //   this.props.toggleQuestionModal();
    // }
    // else{
      if(type==="continue" || type==="skip"){
          this.setState({selectedOptions:[], moreOptions:[], edit:false})
          this.props.handleContinue();
      }
      else{
        // this.props.toggleQuestionModal();
        
        this.setState({edit:false},this.props.toggleQuestionModal());
      }
    // }
    
  }

  // function to check if the answer exists
  checkForAnswer = (question) => {
    console.log(question)
    let answer = null;
    answer=this.props.questions.filter(x=>x.id===question.id);
    if(answer===null || answer.length===0){
      return null;
    }
    else{
      return answer;
    }
  }

  // function to display options as per the question type
  getOptions = (question) => {
    console.log(question)
    console.log(this.props.questions)
    const {selectedOptions} = this.state;
    

    switch(question.type){
      case "mcq":
        return (
          <>
          <Row className="options-container">
            <Row style={{justifyContent:'center'}}>
            {
              question.options.map((option, index)=>(
                <div 
                  onClick={()=>{this.addRemoveOption(option)}}
                  key={index} 
                  style={{cursor:'pointer'}}
                  className={selectedOptions.includes(option) ? 'selected-option' : 'unselected-option'}
                  dangerouslySetInnerHTML={{__html:option}}>
                </div>
              ))
            }
            {
              this.state.moreOptions.map((option, index)=>(
                <div 
                  onClick={()=>{this.addRemoveOption(option)}}
                  key={index} 
                  style={{cursor:'pointer'}}
                  className={selectedOptions.includes(option) ? 'selected-option' : 'unselected-option'}
                  dangerouslySetInnerHTML={{__html:option}}>
                </div>
              ))
            }
            </Row>
            <>
            {
              question.add_more && (
                <Row style={{width:250, marginTop:20}}>
                  <Image src={addIcon} style={{width:15, height:15}}/>
                  <p style={{fontSize:10, marginLeft:5, marginRight:10, color:'#666666',  fontWeight:400}}>add more</p>
                  <Form.Control 
                    type="plaintext" 
                    placeholder="as many as you like" 
                    value={this.state.moreOptionText}
                    onChange={(e)=>{this.setState({moreOptionText:e.target.value})}}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        if(event.target.value.trim().length!==0){
                          this.addMoreOptions(event.target.value)
                        }
                      }
                    }}
                    style={{padding:5, fontSize:10, flex:1, height:20, borderRadius:5}}/>
                </Row>
              )
            }
            </>
          </Row>
          <Row style={{justifyContent:'center', alignItems:'center', paddingLeft:'1rem', paddingRight:'1rem'}}>
            {
              question.additional_question.length!==0 && (
                <>
                <p style={{fontSize:14, marginLeft:5, marginRight:10, color:'#666666',  fontWeight:400, marginBottom:0}}>{question.additional_question}</p>
                <Form.Control 
                  type="plaintext" 
                  placeholder="Art Deco, Mid-century Modern, Shaker, etc…" 
                  value={this.state.additionalQuestion}
                  onChange={(e)=>{this.setState({additionalQuestion:e.target.value})}}
                  style={{padding:5, fontSize:12, flex:1, height:35, borderRadius:5, marginBottom:0}}/>
                </>
              )
            }
          </Row>
          </>
        )
      case "production-capacity":
        return (
          <Col>
            <Row style={{paddingLeft:'1rem', paddingRight:'1rem', paddingTop:'3rem', paddingBottom:'3rem'}}>
              <div>
                <div style={{fontSize:12, fontWeight:400,  color:'#999999', textAlign:'center'}}>Artisanal</div>
                <div style={{fontSize:10, fontWeight:400,  color:'#999999', textAlign:'center'}}>qty 1</div>
              </div>
              <div style={{flex:1, marginLeft:'1rem', marginRight:'1rem'}}>
                <Slider
                  defaultValue={20}
                  min={1}
                  max={150}
                  aria-labelledby="discrete-slider-always"
                  step={1}
                  value={this.state.productionCapacity}
                  onChange={this.handleProductionCapacity}
                  valueLabelDisplay="on"
                />
              </div>
              <div>
                <div style={{fontSize:12, fontWeight:400,  color:'#999999', textAlign:'center'}}>Production</div>
                <div style={{fontSize:10, fontWeight:400,  color:'#999999', textAlign:'center'}}>qty 150+</div>
              </div>
            </Row>
            <Row style={{justifyContent:'center'}}>
              <div>
                <Form.Control 
                type="number" 
                min={1}
                max={150}
                value={this.state.productionCapacity}
                onChange={(e)=>{this.setState({productionCapacity: e.target.value})}}
                style={{ borderTop:'none', borderLeft:0, borderRight:0, padding:0, marginBottom:0, width:100, textAlign:'center'}}/>
                <div style={{ textAlign:'center', color:'#999999', fontWeight:400, fontSize:14}}>units per order</div>
              </div>
            </Row>
          </Col>
        )
      case "dimensions":
        return (
          <Row style={{paddingTop:"2rem", display:'flex', justifyContent:'center', alignItems:'center'}}>
            <div>
              <div style={{display:'flex', alignItems:'center'}}>
                <Form.Control 
                className="dimensions-input"
                type="number" 
                min={0}
                value={this.state.dimensions[0]}
                onChange={(e)=>{this.setState({dimensions:[e.target.value, this.state.dimensions[1], this.state.dimensions[2]]})}}
                style={{marginLeft:'1rem',  borderTop:'none', borderLeft:0, borderRight:0, padding:0, marginBottom:0}}/>
                <div style={{marginRight:10, color:'#a3a3a3'}}>ft</div>
              </div>
              <div style={{ textAlign:'center', color:'#999999', fontWeight:400, fontSize:14}}>length</div>
            </div>
            <div style={{ textAlign:'center', color:'#999999', fontWeight:100, fontSize:35}}>&#xd7;</div>
            <div>
              <div style={{display:'flex', alignItems:'center'}}>
                <Form.Control 
                  className="dimensions-input"
                  type="number" 
                  min={0}
                  value={this.state.dimensions[1]}
                  onChange={(e)=>{this.setState({dimensions:[this.state.dimensions[0], e.target.value, this.state.dimensions[2]]})}}
                  style={{marginLeft:10, borderTop:'none', borderLeft:0, borderRight:0, padding:0, marginBottom:0}}/>
                  <div style={{marginRight:10, color:'#a3a3a3'}}>ft</div>
                </div>
                <div style={{ textAlign:'center', color:'#999999', fontWeight:400, fontSize:14}}>width</div>
            </div>
            <div style={{ textAlign:'center', color:'#999999', fontWeight:100, fontSize:35}}>&#xd7;</div>
            <div>
              <div style={{display:'flex', alignItems:'center'}}>
                <Form.Control 
                  type="number" 
                  className="dimensions-input"
                  min={0}
                  value={this.state.dimensions[2]}
                  onChange={(e)=>{this.setState({dimensions:[this.state.dimensions[0], this.state.dimensions[1], e.target.value]})}}
                  style={{marginLeft:10, marginRight:'1rem', borderTop:'none', borderLeft:0, borderRight:0, padding:0, marginBottom:0}}/>
                  <div style={{color:'#a3a3a3'}}>ft</div>
                </div>
                <div style={{ textAlign:'center', color:'#999999', fontWeight:400, fontSize:14}}>height</div>
            </div>
          </Row>
        )
      case "price-range":
        return (
          <Col>
            <Row style={{justifyContent:'center'}}>
              <Slider
                min={1}
                max={20000}
                value={this.state.priceRange}
                onChange={this.handlePriceRange}
                aria-labelledby="range-slider"
                step={10}
                valueLabelDisplay="on"
                style={{width:'50%', marginTop:60}}
                // getAriaValueText={valuetext}
              />
              </Row>
              <Row style={{paddingTop:"2rem", display:'flex', justifyContent:'center', alignItems:'center'}}>
                <div style={{ textAlign:'center', color:'#999999', fontWeight:100, fontSize:22, marginRight:10}}>$</div>
                <div>
                  <Form.Control 
                  className="dimensions-input"
                  type="number" 
                  min={0}
                  value={this.state.priceRange[0]}
                  max={this.state.priceRange[1]-1}
                  onChange={(e)=>{this.setState({priceRange:[e.target.value, this.state.priceRange[1]]})}}
                  style={{borderTop:'none', borderLeft:0, borderRight:0, padding:0, marginBottom:0, textAlign:'center'}}/>
                </div>
                <div style={{ textAlign:'center', color:'#999999', fontWeight:100, fontSize:14, marginLeft:20, marginRight:20}}>to</div>
                <div style={{ textAlign:'center', color:'#999999', fontWeight:100, fontSize:22, marginRight:10}}>$</div>
                <div>
                  <Form.Control 
                    className="dimensions-input"
                    type="number"
                    max={20000}
                    value={this.state.priceRange[1]}
                    min={this.state.priceRange[0]}
                    onChange={(e)=>{this.setState({priceRange:[this.state.priceRange[0], e.target.value]})}}
                    style={{borderTop:'none', borderLeft:0, borderRight:0, padding:0, marginBottom:0, textAlign:'center'}}/>
                </div>
              </Row>
          </Col>
        )
      case "production-scale":
        return (
          <Col>
            <Row style={{paddingLeft:'1rem', paddingRight:'1rem', paddingTop:'3rem', paddingBottom:'3rem'}}>
              <div>
                <div style={{fontSize:12, fontWeight:400,  color:'#999999', textAlign:'center'}}>Artisanal</div>
                <div style={{fontSize:10, fontWeight:400,  color:'#999999', textAlign:'center'}}>qty 1</div>
              </div>
              <div style={{flex:1, marginLeft:'1rem', marginRight:'1rem'}}>
                <Slider
                  min={1}
                  max={1000}
                  value={this.state.productionScale}
                  onChange={this.handleProductionScale}
                  aria-labelledby="range-slider"
                  step={10}
                  valueLabelDisplay="on"
                  // style={{width:'50%', marginTop:60}}
                  // getAriaValueText={valuetext}
                />
              </div>
              <div>
                <div style={{fontSize:12, fontWeight:400,  color:'#999999', textAlign:'center'}}>Production</div>
                <div style={{fontSize:10, fontWeight:400,  color:'#999999', textAlign:'center'}}>qty 1000+</div>
              </div>
              </Row>
              <Row style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <div>
                  <Form.Control 
                  className="dimensions-input"
                  type="number" 
                  min={0}
                  value={this.state.productionScale[0]}
                  max={this.state.productionScale[1]-1}
                  onChange={(e)=>{this.setState({productionScale:[e.target.value, this.state.productionScale[1]]})}}
                  style={{borderTop:'none', borderLeft:0, borderRight:0, padding:0, marginBottom:0, textAlign:'center'}}/>
                  <div style={{ textAlign:'center', color:'#999999', fontWeight:400, fontSize:10}}>units per order</div>
                </div>
                <div style={{ textAlign:'center', color:'#999999', fontWeight:100, fontSize:14, marginLeft:20, marginRight:20}}>to</div>
                <div>
                  <Form.Control 
                    className="dimensions-input"
                    type="number"
                    max={1000}
                    value={this.state.productionScale[1]}
                    min={this.state.productionScale[0]}
                    onChange={(e)=>{this.setState({productionScale:[this.state.productionScale[0], e.target.value]})}}
                    style={{borderTop:'none', borderLeft:0, borderRight:0, padding:0, marginBottom:0, textAlign:'center'}}/>
                    <div style={{ textAlign:'center', color:'#999999', fontWeight:400, fontSize:10}}>units per order</div>
                </div>
              </Row>
          </Col>
        )
      case "work-experience":
        return(
          <Row style={{paddingTop:"2rem", display:'flex', justifyContent:'center', alignItems:'center'}}>
            <div>
              <Form.Control 
              className="dimensions-input"
              type="number" 
              min={1}
              value={this.state.workExperience}
              onChange= {(e)=>{this.setState({workExperience:e.target.value})}}
              style={{borderTop:'none', borderLeft:0, borderRight:0, padding:0, marginBottom:0, textAlign:'center', fontSize:20}}/>
              <div style={{ textAlign:'center', color:'#999999', fontWeight:400, fontSize:14}}>years</div>
            </div>
          </Row>
        )
      case "additional-info":
        return(
          <Row style={{paddingTop:"2rem", paddingBottom:'2rem', paddingLeft:'1rem', paddingRight:'1rem', display:'flex', justifyContent:'flex-end'}}>
            <Form.Control as="textarea" maxLength={150} 
            style={{fontSize:11,  height:80}}
            defaultValue={this.state.additionalInfo}
            onChange={(e)=>{this.setState({additionalInfo:e.target.value})}}
            placeholder="For example: I once built a raft from palm fronds and drift wood to escape from a desert island… If that was true, we’d definitely want to know about it."/>
            <p style={{margin:0, fontSize:10, textAlign:'right', color:'#a3a3a3', }}>150 characters</p>
          </Row>
        )
        break;
      default:
        break;
    }
  }

  render(){
    return(
      <Modal
        show={this.props.isVisible}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={()=>this.saveAnswer("close")}
        id="question-modal"
        size="lg"
        // backdrop="static"
      >
        <Modal.Header style={{dispaly:'flex', flexFlow:'row-reverse', paddingTop:5, paddingBottom:5, paddingRight:5}}>
          <Image src={closeIcon} style={{width:20, cursor:'pointer'}} onClick={()=>this.saveAnswer("close")}/>
        </Modal.Header>
        <Modal.Body style={{paddingLeft:'1rem', paddingRight:'1rem'}}>
          {
            this.getQuestionData()
          }
        </Modal.Body>
        <Modal.Footer style={{paddingBottom:5, paddingRight:10}}>
          {/* CONTINUE, BACK & SKIP BUTTONS */}
          {
            // this.state.edit ? (<div style={{ cursor:'pointer'}} onClick={()=>this.saveAnswer("continue")}>Submit</div>) : 
            // (
              <div style={{display:'flex', justifyContent:'space-between', flexDirection:'row', width:'100%'}}>
                <div>
                  <div style={{ cursor:'pointer'}} onClick={this.props.handleBack}>Back</div>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                  <div style={{ cursor:'pointer', color:'#a3a3a3', marginRight:'1rem'}} onClick={()=>this.saveAnswer("skip")}>Skip</div>
                  <div style={{ cursor:'pointer'}} onClick={()=>this.saveAnswer("continue")}>Continue</div>
                </div>
              </div>
            // )
          }
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      // user: state.user,
      // questions:state.user.questions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // addAnswer: (data) => dispatch(addAnswer(data)),
    // updateUser: (data) => dispatch(updateUser(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Options));