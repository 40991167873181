import firebase from "firebase/app";
import "firebase/auth";

const {
  REACT_APP_API_KEY: apiKey,
  REACT_APP_AUTH_DOMAIN: authDomain,
  REACT_APP_PROJECT_ID: projectId,
  REACT_APP_STORAGE_BUCKET: storageBucket,
  REACT_APP_MESSAGIN_SENDER_ID: messagingSenderId,
  REACT_APP_APP_ID: appId,
} = process.env

const firebaseConfig = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId
};

firebase.initializeApp(firebaseConfig);
export default firebase;