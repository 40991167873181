import React, { Component } from "react";

import "../../stylesheets/mediadrop.css";
import { Container } from "react-bootstrap";
import loadingIcon from "../../images/rectLoading.svg";
import { css } from "@emotion/core";

import SyncLoader from "react-spinners/SyncLoader";

const spinnerStyle = css`
    margin-left: 50%;
    transform: translate(-50%, 0);
`;
class ImageCard extends Component {
    render() {
        return (
            <div>
                {this.props.loaded ? (
                    <img className="image" src={this.props.src} alt="" />
                ) : (
                    <SyncLoader
                                        css={spinnerStyle}
                                        color={"#000"}
                                        sizeUnit={"px"}
                                        size={"10"}
                                    ></SyncLoader>
                )}
            </div>
        );
    }
}

export default ImageCard;
