import React, { Component } from 'react'
import { connect } from 'react-redux'   
import {Container, Row} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import CanvasWidget from './CanvasWidget'
// import { DEBUG } from  '../../secrets'

class CanvasManager extends Component{
    
    renderCanvases = () =>
    {
        let CanvasRenders = <div>You have no {this.props.type} projects assigned yet</div>
        if(this.props.projects!==undefined){
            if(this.props.projects.length>0)
            {
                CanvasRenders = this.props.projects.map(canvasId => <CanvasWidget editStatus={this.props.editStatus} key={canvasId} canvasId={canvasId}></CanvasWidget>)
            }
        }
        return CanvasRenders
    }

    render()
    {
        return (
            // this.props.projects.length>0? 
            <Container id="canvas-manager">
                <Row>
                    <h2 className="col-12" style={{"fontSize":"1.5rem", "fontWeight":"700", "marginBottom":"3rem", "marginTop":"3rem"}}>{this.props.type} projects</h2>
                    {this.renderCanvases()}               
                </Row>
            </Container>
            // : null
        )
    }
}



const mapStateToProps = (state) => {
    return { 
        user: state.user,
    }
}

export default connect(mapStateToProps)(withRouter(CanvasManager))