import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Row, Image, Modal, Button} from 'react-bootstrap';
import {updateUser} from '../../actions/accountActions';
// import rotateIcon from '../../images/icons/rotateIcon.svg';
import maximize from '../../images/icons/maximize.svg';
import deleteIcon from '../../images/icons/delete.png';
import documentIcon from '../../images/icons/document.svg';
import audio from '../../images/icons/audio.svg';
import video from '../../images/icons/video.svg';

class ImageTile extends React.Component{
  imgBox = React.createRef();
  state = {
    rotation:0, 
    showLightbox:false,
    deleteModal:false,
  }

  checkFileType = () => {
    let type=""
    let imgExtensions = ["jpg","jpeg","gif","png","svg","raw","webp","tiff","psd","bmp","heif"];
    let audioExtensions = ['mp3','wav','wma','aac','m4a', 'flac']
    let videoExtensions = ['webm','mpg','mp2','mpeg','mpe','mpv','ogg','mp4','m4p','m4v','avi','wmv']
    for(let i=0;i<imgExtensions.length;i++){
      if(this.props.image.includes(imgExtensions[i])){
        type="image";
        break;
      }
    }
    for(let i=0;i<audioExtensions.length;i++){
      if(this.props.image.includes(audioExtensions[i])){
        type="audio";
        break;
      }
    }
    for(let i=0;i<videoExtensions.length;i++){
      if(this.props.image.includes(videoExtensions[i])){
        type="video";
        break;
      }
    }
    if(type.length===0){
      type="document";
    }
    return type;
  }

  render(){
    const {rotation} = this.state;
    return(
      <Row className="image-tile">
        {
          this.checkFileType()==="image" ? (
            <Image src={this.props.image} fluid style={{transform: `rotate(${rotation}deg)`, width:'100%'}}/>
          ) : (
            <div className="document">
              <a downlad href={this.props.image}>
                <Image src={this.checkFileType()==="audio" ? audio : this.checkFileType()==="video" ? video : documentIcon}/>
              </a>
            </div>
          )
        }
        {
          !this.props.disabled && (
            <div className="icons-wrapper">
              <Image src={deleteIcon} fluid style={{width:'2rem', marginRight:'1rem', cursor:'pointer', marginBottom:'1rem'}} onClick={()=>{this.setState({deleteModal:true})}}/>
              {/* <Image src={rotateIcon} fluid style={{width:'2rem', marginRight:'1rem', cursor:'pointer'}} onClick={this.rotateImage}/> */}
              <Image src={maximize} fluid style={{width:'2rem', marginRight:'1rem', cursor:'pointer', marginBottom:'1rem'}} onClick={() => this.setState({showLightbox: true})}/>
            </div>
          )
        }

        {/* Expand image modal */}
        <Modal
          size="xl"
          show={this.state.showLightbox}
          onHide={() => this.setState({showLightbox: false})}
          aria-labelledby="example-modal-sizes-title-xl"
          id="instructions-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body style={{overflowY:'auto'}}>
            <Row 
              className="justify-content-center" 
              // style={{height:imageBoxWidth}}
              >
                {
                  this.checkFileType()==="image" ? (
                    <img 
                      // ref={this.imgBox}
                      src={this.props.image}
                      style={{width:'auto'}} 
                      // onLoad={this.lightBoxImageDisplay}
                      // style={{"maxWidth":"100%", transform: `rotate(${rotation}deg)`}} alt="lightbox"
                      ></img>
                  ) : (
                    <img src={this.checkFileType()==="audio" ? audio : this.checkFileType()==="video" ? video : documentIcon}></img>
                  )
                }
            </Row>
          </Modal.Body>
      </Modal>

      {/* delete image warning modal */}
      <Modal
          show={this.state.deleteModal}
          onHide={() => this.setState({deleteModal: false})}
          aria-labelledby="example-modal-sizes-title-xl"
          id="instructions-modal"
          centered
        >
          <Modal.Header closeButton style={{border:'none'}}></Modal.Header>
          <Modal.Body style={{overflowY:'auto'}}>
            <h4 style={{textAlign:"center", fontWeight:'bold'}}>Are you sure you want to delete this image?</h4>
            <div style={{display:'flex', justifyContent:'space-evenly', marginTop:'2rem'}}>
              <btn onClick={()=>{this.props.deleteImage(this.props.image, this.props.type); this.setState({deleteModal:false})}} style={{color:'red', cursor:'pointer'}}>Delete</btn>
              <btn onClick={()=>{this.setState({deleteModal:false})}} style={{color:'grey', cursor:'pointer'}}>Cancel</btn>
            </div>
          </Modal.Body>
      </Modal>
      </Row>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      user: state.user,
      questions: state.user.questions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(updateUser(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ImageTile));