import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container } from 'react-bootstrap';
import Navbar from '../utilities/navbar/Navbar'
import ActionsButtons from './ActionButtons';
import ProjectDetails from './ProjectDetails';
import Estimate from './Estimate';
import Comments from './Comments';
import { motion, AnimatePresence } from "framer-motion";
import {getEstimation} from '../../actions/estimationActions';
import Firebase from '../../services/firebase'
import {signOut} from '../../actions/userActions';
import '../../stylesheets/manual-estimation.css';
import user from '../../images/userIcon.png';
import { USER_APP_ENDPOINT } from "../../secrets";

class ManualEstimation extends React.Component{
  state = {
    showProjectDetails:true,
    showEstimate: false,
    showComments: false
  }

  componentDidMount(){
    Firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        localStorage.removeItem("nayaEcosystemRedirect");
        this.props.getEstimation(this.props.id)
      } else {
        return localStorage.setItem("nayaEcosystemRedirect", `estimation/${this.props.id}`);
      }
    })
  }

  // Checking if the device is a mobile phone or not to render data accordingly
  isMobile = () => {
    if(window.innerWidth <500){
        return true 
    }
    else{
        return false
    }
  }

  // toggle estimate for phone view
  toggleEstimate = () => {
    this.setState({showEstimate:!this.state.showEstimate, showProjectDetails: this.state.showEstimate, showComments: false})
  }

  // toggle comments for phone view
  toggleComments = () => {
    this.setState({showEstimate:false, showProjectDetails: this.state.showComments, showComments: !this.state.showComments})
  }

  renderDetailsContent = () => {
    const {showEstimate, showProjectDetails, showComments} = this.state;
    return(
      <div>
        {
          !this.isMobile() ? <ProjectDetails></ProjectDetails> : (
            <React.Fragment>
              {
                showProjectDetails && (
                  <AnimatePresence>
                    <motion.div 
                      initial={{ x: 1000 }} animate={{ x: 0 }}
                      transition={{ ease: "easeOut", duration: 0.5 }}
                    >
                      <ProjectDetails></ProjectDetails>
                    </motion.div>
                  </AnimatePresence>
                )
              }
              {
                showEstimate && (
                  <AnimatePresence>
                    <motion.div 
                      initial={{ opacity: 0, scale: 0.75 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0 }}
                    >
                      <Estimate toggleEstimate={this.toggleEstimate}></Estimate>
                    </motion.div>
                  </AnimatePresence>
                )
              }
              {
                showComments && (
                  <AnimatePresence>
                    <motion.div 
                      initial={{ y: 1000 }} animate={{ y: 0 }}
                      transition={{ ease: "easeOut", duration: 0.5 }}
                    >
                      <Comments toggleComments={this.toggleComments}></Comments>
                    </motion.div>
                  </AnimatePresence>
                )
              }
            </React.Fragment>
          )
        }
      </div>
    )
  }

  renderActionsContent = () => {
    return(
      <div>
        {
          this.state.showComments && (
            <AnimatePresence>
              <motion.div 
                initial={{ x: 1000 }} animate={{ x: 0 }}
                transition={{ ease: "easeOut", duration: 0.5 }}
              >
                <Comments toggleComments={this.toggleComments}></Comments>
              </motion.div>
            </AnimatePresence>
          ) 
        }
        {
          !this.state.showComments && (
            <AnimatePresence>
              <motion.div 
                initial={{ opacity: 0, scale: 0.75 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
              >
                <Estimate toggleEstimate={this.toggleEstimate} toggleComments={this.toggleComments}></Estimate>
              </motion.div>
            </AnimatePresence>
          )
        }
      </div>
    )
  }

  render(){
    return(
      <>
      <div className="header-space">
        <Navbar />
      </div>
      <div id="manual-estimation">
        <div className="details" style={{backgroundColor:this.isMobile() && this.state.showComments && '#FFF'}}>
          <Container className="details-container" style={{backgroundColor:this.isMobile() && this.state.showComments && '#FFF'}}>
            {
              this.renderDetailsContent()
            }
          </Container>
        </div>
        <div className="actions">
          <Container className="actions-container">
            {
              // !this.isMobile() && (<div className="header-space"></div>)
            }
            {
              this.isMobile() && !this.state.showComments && (
                <ActionsButtons
                  toggleComments={this.toggleComments}
                  toggleEstimate={this.toggleEstimate}
                  showEstimate={this.state.showEstimate}
                ></ActionsButtons>
              )
            }
            {
              !this.isMobile() && (this.renderActionsContent())
            }
          </Container>
        </div>
      </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      user: state.user, 
      estimation: state.estimation
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEstimation: (data) => dispatch(getEstimation(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManualEstimation));
