import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import './admin.css'
import { Button } from 'react-bootstrap'
import { ADMIN_PAGE_AUTH } from '../../secrets'

export const AdminAuth = () => {
    const [ password, setPassword ] = useState('')
    const [ warning, setWarning ] = useState('')
    const history = useHistory()

    const handleSubmit = (e) => {
        if (ADMIN_PAGE_AUTH === password) {
            handleBadPassword('Logging in...')
            localStorage.setItem('nayaEcoAdminAuth', true)
            history.push('/admin-dashboard')
        }
        handleBadPassword('Wrong password, please try again')
    }

    const handleBadPassword = (message) => {
        setWarning(message)
    }

    return (
        <div className='admin-auth'>
            <div className='admin-auth__container'>
                <input type='password' value={password} onChange={e => setPassword(e.target.value)} placeholder='Password...' />
                <Button type='button' onClick={handleSubmit} > submit </Button>
            </div>
            <div className='admin-auth__warning'>
                {warning}
            </div>
        </div>
    )
}