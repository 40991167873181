
import React, { Component } from "react";
import { Navbar, NavDropdown, Image } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import userIcon from '../../images/userIcon'
// import { DEBUG } from "../../secrets";
import userIcon from '../../images/icons/userIcon.png'

import "../../stylesheets/navbar.css";

class NavBar extends Component {
    handleSignOut = (e) => {
        localStorage.setItem("nayaJwtToken", "");
        this.props.signOut();
        this.props.history.push("login");
    };


    handleAccount = (e) => {
        this.props.history.push("profile");
    };
    renderUserProfile = () => {
        if (this.props.user && this.props.user.id !== "") {
            return (
                <Navbar.Text>
                    <span>
                            {/* <Image src={userIcon} style={{"height":"2rem"}}></Image> */}
                            {this.props.user.profile_pic? 
                                <Image src={this.props.user.profile_pic} style={{"height":"3rem", "width":"3rem", "borderRadius":"5rem", "overflow":"hidden"}} fluid />: 
                                <Image src={userIcon} style={{"height":"3rem", "width":"3rem"}} fluid />}

                            {/* <ProfilePic></ProfilePic> */}
                        </span>
                    <NavDropdown
                        style={{"color":"black", display: "inline-block"}}
                        title={this.props.user.email.split("@")[0]}
                        id="collasible-nav-dropdown"
                        bsPrefix="dropdown-title"
                    >
                        <NavDropdown.Item href="" onClick={this.handleAccount}>
                            My Account
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={this.handleSignOut}>
                            Sign Out
                        </NavDropdown.Item>
                    </NavDropdown>
                    {/* Signed in as: <a href="/account">{this.props.user.email.split("@")[0]}</a> */}
                </Navbar.Text>
            );
        } else {
            return (
                <Navbar.Text>
                    <a href="/login">Sign In</a>
                </Navbar.Text>
            );
        }
    };
    render() {
        return (
            <Navbar id="naya-navbar">
                <Navbar.Brand href="https://www.naya.studio/" style={{"color":"black"}}>naya</Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    {this.renderUserProfile()}
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch({ type: "SIGN_OUT" }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar));
