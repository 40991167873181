import UserIcon from "../images/userIcon.png";

const DEBUG = false;
const initUser = {
    id: "",
    email: "",
    user_type: "NOT_SET",
    user_canvas: [],
    design_canvas: [],
    fabrication_canvas: [],
    profile_pic: UserIcon,
    profile_name: "",
    // Newly added
    name:"",
    last_name:"",
    studio_name:"",
    description:"",
    personal_website:"",
    location:"",
    questions:[],
    paymentDetails:null,
    detailed_location:{
        city:'',
        state:'', 
        display_address:'',
        lat:0,
        lng:0,
        country:''
    },
    acceptedTermsOfService: {
        designer: false,
        maker: false,
        client: false
    },
    paymentDetails:null
};

const userReducer = (state = initUser, action) => {
    let newState;

    if (DEBUG) console.debug("USER REDUCER:");
    if (DEBUG) console.debug("ACTION:");
    if (DEBUG) console.debug(action);
    if (DEBUG) console.debug("CURRENT STATE:");
    if (DEBUG) console.debug(state);
    let user;
    switch (action.type) {
        // case "LOGIN_LOCAL":
        //     localStorage.setItem("nayaJwtToken", action.data.token);
        //     user = action.data.user;
        //     newState = {
        //         ...state,
        //         id                  : user._id,
        //         email               : user.email,
        //         user_type           : user.user_type === undefined ? "USER" : user.user_type,
        //         user_canvas         : user.user_canvas,
        //         design_canvas       : user.design_canvas,
        //         fabrication_canvas  : user.fabrication_canvas,
        //         consulting_canvas   : user.consulting_canvas,
        //         profile_name        : user.name,
        //         name                : user.name,
        //         profile_pic         : user.profile_pic,
        //         last_name           : user.last_name,
        //         studio_name         : user.studio_name,
        //         description         : user.description,
        //         personal_website    : user.personal_website,
        //         location            : user.location,
        //         questions           : user.questions,
        //         paymentDetails: user.paymentDetails
        //     };
        //     break;
        // case "LOGIN_TOKEN":
        //     user = action.data;
        //     newState = {
        //         ...state,
        //         id: user._id,
        //         email: user.email,
        //         user_type:
        //             user.user_type === undefined ? "USER" : user.user_type,
        //         user_canvas: user.user_canvas,
        //         design_canvas: user.design_canvas,
        //         fabrication_canvas: user.fabrication_canvas,
        //         consulting_canvas: user.consulting_canvas,
        //         profile_name: user.name,
        //         name: user.name,
        //         profile_pic: user.profile_pic,
        //         // 
        //         last_name:user.last_name,
        //         studio_name:user.studio_name,
        //         description:user.description,
        //         personal_website:user.personal_website,
        //         location:user.location,
        //         questions:user.questions,
        //         paymentDetails: user.paymentDetails
        //     };
        //     break;
        case 'LOGIN_COOKIE':
            user = action.data;
            newState = {
                ...state,
                id: user._id,
                email: user.email,
                user_type:
                    user.user_type === undefined ? "USER" : user.user_type,
                user_canvas: user.user_canvas,
                design_canvas: user.design_canvas,
                fabrication_canvas: user.fabrication_canvas,
                consulting_canvas: user.consulting_canvas,
                profile_name: user.name,
                name: user.name,
                profile_pic: user.profile_pic,
                last_name: user.last_name,
                studio_name: user.studio_name,
                description: user.description,
                personal_website: user.personal_website,
                location: user.location,
                questions: user.questions,
                paymentDetails: user.paymentDetails,
                detailed_location:user.detailed_location,
                acceptedTermsOfService: user.acceptedTermsOfService
            };
            break;
        case "SIGN_OUT":
            newState = initUser;
            break;
        case 'SET_PROFILE_PIC':
            if(action.link != undefined)
            {
                newState = {
                            ...state,
                            profile_pic: action.link
                }

            }
            break

    case 'SET_PROFILE_NAME':
            if(action.name !== undefined)
            {
                newState = {
                            ...state,
                            profile_name: action.name
                }

            }
            break

    case 'SET_PAYMENT_DETAILS':
        console.log("in set payment:", action)
         newState = {
                    ...state,
                    paymentDetails: action.paymentDetails
            }

         console.log("new state:", newState)
            break
    case 'SIGN_UP_LOCAL':
        localStorage.setItem('nayaJwtToken', action.data.token)
        newState = {...state, ...action.data.user, id: action.data.user._id}
        break
    case 'LEAD':
        console.log(action)
        newState = {email: action.data.email}
        break
    case 'REFRESH_USER':
        user = action.user
        newState = {
                    ...state, 
                    id                  : user._id,
                    email               : user.email, 
                    user_type           : user.user_type == undefined? "USER": user.user_type,
                    acceptedTermsOfService: user.acceptedTermsOfService,
                    ...user
                }
        break
        default:
            newState = state;
    }

    if (DEBUG) console.debug("NEW STATE:");
    if (DEBUG) console.debug(newState);
    return newState;
};

export default userReducer;
