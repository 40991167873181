import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Container} from 'react-bootstrap';
import {getNotifications, readNotification} from '../../actions/notificationsActions';
import Prompt from '../prompts/Prompt';
import Navbar from '../utilities/navbar/Navbar';
import '../../stylesheets/notifications.css';

class Notifications extends React.Component{
  state = {
    showNotification: false,
    currentNotification: undefined,
    notifications:this.props.notifications,
    unread:0,
  }

  componentDidMount(){
    // this.props.getNotifications({user_id:this.props.user.id})
    // // fetching the notifications after every 10 seconds
    // setInterval(() => this.props.getNotifications({user_id:this.props.user.id}), 10000)
    
}

static getDerivedStateFromProps(nextProps, prevState){
  if(nextProps.notifications!==undefined){
    if(nextProps.notifications!==prevState.notifications){
        return {notifications:nextProps.notifications}
    }
  }
   return null;
 }

  // function to calculate the time difference for the notifications
  timeDifference = (current, previous) => {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
         return Math.round(elapsed/1000) + ' seconds ago';   
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' mins ago';   
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' h ago';   
    }

    else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago';   
    }

    else if (elapsed < msPerYear) {
        return  Math.round(elapsed/msPerMonth) + ' months ago';   
    }

    else {
        return Math.round(elapsed/msPerYear ) + ' y ago';   
    }
  }

  toggleNotification = () => {
    this.setState({showNotification:!this.state.showNotification})
  }

  // passing data to the notification prompt
  passDataToPrompt = (data) => {
      this.setState({currentNotification:data},this.toggleNotification())
  }

  render(){
    return(
      <div>
        <Navbar/>
        <Container id="notifications">
        {
          this.state.notifications.length!==0 ? (
            <>
            {
              this.state.notifications.reverse().map((notification, index)=>(
              <div key={index}>
                <Container onClick={()=>{
                  // if(!notification.readStatus){
                    this.props.readNotification(notification._id, this.props.user.id)
                  // }
                  this.passDataToPrompt(notification);
                }} className={notification.readStatus===true ? 'read-notification' : 'unread-notification'}>
                  <div style={{flex:1}}>
                  <h6>{notification.content.title}</h6>
                  <p style={{margin:0}}>{this.timeDifference(new Date(), new Date(notification.date))}</p>
                  </div>
                  {
                    !notification.readStatus && (<div style={{width:10, height:10, borderRadius:5, backgroundColor:'blue'}}></div>)
                  }
                  {/* <div style={{height:1, backgroundColor:'#a3a3a3'}}></div> */}
                </Container>
                {/* {
                  index!==this.state.notifications.length-1 && <div style={{height:1, backgroundColor:'#a3a3a3'}}></div>
                } */}
              </div>
              ))
            }
            </>
          )  : (
            <h6>No notifications found</h6>
          )
        }
        {
          this.state.showNotification && (<Prompt data={this.state.currentNotification} toggleNotification={this.toggleNotification}/>)
        }
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
      user: state.user,
      notifications: state.notifications.notifications
  }
}

const mapDispatchToProps = (dispatch) => {

  return { 
      getNotifications: (data) => dispatch(getNotifications(data)),
      readNotification: (notifId,userId) => dispatch(readNotification(notifId,userId)),
  }
}
export default  connect(mapStateToProps, mapDispatchToProps)(withRouter(Notifications));