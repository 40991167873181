import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Image, Modal, Button } from "react-bootstrap";
import CanvasDraw from "react-canvas-draw";
import axios from "axios";
import { API_ENDPOINT } from "../../secrets";
import userIcon from "../../images/icons/userIcon.png";
import ReactHtmlParser from "react-html-parser";
import maximize from "../../images/icons/maximize.svg";
import download from "../../images/icons/download.svg";
import rotateIcon from "../../images/icons/rotateIcon.svg";
import ImageBox from "./ImageBox";
import {declineComment, approveComment} from '../../actions/approvalCommentsActions';

class CommentTile extends Component {
    constructor(props) {
        super(props);

        let name = "Anonymous";
        let profile_pic = userIcon;
        if (this.props.data.user && this.props.data.user.email) {
            name = this.props.data.user.email.split("@")[0];
        }
        if (this.props.data.user && this.props.data.user.name) {
            name = this.props.data.user.name;
        }
        if (this.props.data.user && this.props.data.user.profile_pic) {
            profile_pic = this.props.data.user.profile_pic;
        }
        this.state = {
            declineWarning:false,
            cols: 1,
            showTextInput: false,
            nImgs: 0,
            imgs: [],
            inputText: "",
            profile_pic: profile_pic,
            imgLoaded: false,
            name: name,
            showLightbox: false,
            lightboxImage: null,
            rotation: 0,
            imageBoxWidth: "",
            imageBoxHeight: "auto",
        };

        this.commentImage = React.createRef();
        this.imgContainer = React.createRef();
    }

    componentDidMount() {
        const GET_PROFILE_PIC_URL = `${API_ENDPOINT}/api/userprefs`;
        const GET_PROFILE_NAME_URL = `${API_ENDPOINT}/api/userprefs`;

        if (this.props.data.user) {
            let data = {
                user_id: this.props.data.user._id,
                key: "PROFILE_PIC",
            };
            axios
                .get(
                    GET_PROFILE_PIC_URL,
                    { params: data },
                    { crossDomain: true }
                )
                .then((response) => {
                    if (response.data.status) {
                        this.setState({
                            profile_pic: response.data.data.options[0].value,
                        });
                    }
                })
                .catch(
                    (e) => {}
                    // console.log(e)
                );
            data = {
                user_id: this.props.data.user._id,
                key: "PROFILE_NAME",
            };
            axios
                .get(
                    GET_PROFILE_NAME_URL,
                    { params: data },
                    { crossDomain: true }
                )
                .then((response) => {
                    if (response.data.status) {
                        this.setState({
                            name: response.data.data.options[0].value,
                        });
                    }
                })
                .catch(
                    (e) => {}
                    // console.log(e)
                );
        }
        // if(this.divElement)
        // {
        //     this.setState({clientWidth: this.divElement.clientWidth})
        // }
    }

    timeDifference(current, previous) {
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;

        var elapsed = current - previous;

        if (elapsed < msPerMinute) {
            return Math.round(elapsed / 1000) + " seconds ago";
        } else if (elapsed < msPerHour) {
            return Math.round(elapsed / msPerMinute) + " mins ago";
        } else if (elapsed < msPerDay) {
            return Math.round(elapsed / msPerHour) + " h ago";
        } else if (elapsed < msPerMonth) {
            return Math.round(elapsed / msPerDay) + " days ago";
        } else if (elapsed < msPerYear) {
            return Math.round(elapsed / msPerMonth) + " months ago";
        } else {
            return Math.round(elapsed / msPerYear) + " y ago";
        }
    }

    renderUserProfile = () => {
        let render;
        try {
            let name = this.state.name;
            let profile_pic = this.state.profile_pic;
            let date = new Date(this.props.data.updatedAt);
            let now = new Date();
            let diff = this.timeDifference(now, date);

            render = (
                <Col xs={12} className="user-profile">
                    <Row>
                        <Image src={profile_pic}></Image>
                        <Col xs={10}>
                            <p className="name">{name}</p>
                            <p className="time">{diff}</p>
                        </Col>
                    </Row>
                </Col>
            );
        } catch (error) {
            console.log(error);
            render = <p>{this.props.data.user.email}</p>;
        }
        return render;
    };

    getCols = () => {
        let cols = this.getTextCols() + this.getImageCols();

        if (cols < 4) {
            console.log("Total Cols " + cols);
            return cols;
        } else {
            console.log("Total Cols " + 4);
            return 4;
        }
    };

    getTextCols = () => {
        let cols = 0;
        if (
            this.props.data.comment_text !== undefined &&
            this.props.data.comment_text !== ""
        ) {
            cols = cols + 1;
            cols += Math.round(this.props.data.comment_text.length / 200);
        }

        if (cols > 4) {
            return 4;
        }
        return cols;
    };

    getImageCols = () => {
        let cols = 0;
        if (
            this.props.data.comment_image_uploads !== undefined &&
            this.props.data.comment_image_uploads.length > 0
        ) {
            cols = cols + this.props.data.comment_image_uploads.length;
        }

        if (this.props.data.data_linked !== undefined) {
            cols = cols + 1;
        }

        if (this.props.data.annotation_linked !== undefined) {
            cols = cols + 1;
        }
        return cols;
    };

    rotateImage = () => {
        let newRotation = this.state.rotation + 90;
        if (newRotation == 360) {
            newRotation = 0;
        }
        this.setState({
            rotation: newRotation,
            imageBoxHeight: "auto",
            imageBoxWidth: "",
        });
    };

    lightBoxImageDisplay = () => {
        if (this.state.rotation === 90 || this.state.rotation === 270)
            this.setState({
                imageBoxWidth: `${this.imgBox.current.clientWidth}px`,
                imageBoxHeight: `${this.imgBox.current.clientHeight}px`,
            });
    };

    renderImageBox = (upload) => {
        const { rotation, imageBoxWidth, imageBoxHeight } = this.state;
        return (
            <Col
                xs={12}
                md={this.getCols() < 2 ? 12 / this.getCols() : 6}
                lg={this.getCols() < 4 ? 12 / this.getCols() : 4}
                xl={12 / this.getCols()}
                style={{ minHeight: "300px", padding: "0" }}
                className="img-wrapper"
            >
                <Image
                    src={upload.link}
                    style={{ transform: `rotate(${rotation}deg)` }}
                ></Image>
                <Image
                    src={maximize}
                    className="maximizeButton"
                    style={{
                        width: "2rem",
                        height: "2rem",
                        bottom: "1rem",
                        right: "1rem",
                        top: "unset",
                        left: "unset",
                        cursor: "pointer",
                    }}
                    onClick={() =>
                        this.setState({
                            showLightbox: true,
                            lightboxImage: upload.link,
                        })
                    }
                ></Image>
                <a href={upload.link}>
                    <Image
                        src={download}
                        className="maximizeButton"
                        style={{
                            width: "2rem",
                            height: "2rem",
                            bottom: "1rem",
                            right: "3.5rem",
                            top: "unset",
                            left: "unset",
                            cursor: "pointer",
                        }}
                    ></Image>
                </a>
                <Image
                    src={rotateIcon}
                    className="maximizeButton"
                    style={{
                        width: "2rem",
                        height: "2rem",
                        bottom: "1rem",
                        right: "6rem",
                        top: "unset",
                        left: "unset",
                        cursor: "pointer",
                    }}
                    onClick={this.rotateImage}
                ></Image>

                <Modal
                    size="xl"
                    show={this.state.showLightbox && this.state.lightboxImage}
                    onHide={() =>
                        this.setState({
                            showLightbox: false,
                            lightboxImage: null,
                        })
                    }
                    aria-labelledby="example-modal-sizes-title-xl"
                    id="instructions-modal"
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body style={{ overflowY: "auto" }}>
                        <Row
                            className="justify-content-center"
                            style={{ height: imageBoxWidth }}
                        >
                            <img
                                ref={this.imgBox}
                                src={this.state.lightboxImage}
                                onLoad={this.lightBoxImageDisplay}
                                style={{
                                    maxWidth: "100%",
                                    height: imageBoxHeight,
                                    transform: `rotate(${rotation}deg)`,
                                }}
                                alt="lightbox"
                            ></img>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Col>
        );
    };

    renderReplyTo = () => {
        let cols = this.getCols();

        let render = <div></div>;
        try {
            let replyTo = this.props.data.data_linked;
            if (
                this.props.data.annotation_linked &&
                this.props.data.annotation_linked._id
            ) {
                replyTo = { type: "ANNOTATION" };
            }

            console.log(replyTo);
            switch (replyTo.type) {
                case "SKETCH":
                    render = (
                        <Col
                            xs={12}
                            md={this.getCols() < 2 ? 12 / this.getCols() : 6}
                            lg={cols < 4 ? 12 / cols : 4}
                            xl={12 / cols}
                            className={"reply-to"}
                        >
                            <h6>Replying to:</h6>
                            <Col xs={12} className="canvas-tile">
                                <Row>
                                    <Col
                                        xs={12}
                                        ref={(divElement) =>
                                            (this.divElement = divElement)
                                        }
                                        style={{ minHeight: "100%" }}
                                    >
                                        <CanvasDraw
                                            className="canvas-sketch"
                                            disabled
                                            canvasWidth={this.state.clientWidth}
                                            canvasHeight={
                                                this.state.clientWidth
                                            }
                                            ref={(canvasDraw) =>
                                                (this.loadableCanvas = canvasDraw)
                                            }
                                            saveData={replyTo.image}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    );
                    break;
                case "IMAGE":
                    render = (
                        <Col
                            xs={12}
                            md={this.getCols() < 2 ? 12 / this.getCols() : 6}
                            lg={cols < 4 ? 12 / cols : 4}
                            xl={12 / cols}
                            className={"reply-to"}
                        >
                            <h6>Replying to:</h6>
                            <Col xs={12} className="canvas-tile">
                                <Row>
                                    <Image src={replyTo.image}></Image>
                                </Row>
                            </Col>
                        </Col>
                    );
                    break;
                case "TEXT_IMG":
                    render = (
                        <Col
                            xs={12}
                            md={this.getCols() < 2 ? 12 / this.getCols() : 6}
                            lg={cols < 4 ? 12 / cols : 4}
                            xl={12 / cols}
                            className={"reply-to"}
                        >
                            <h6>Replying to:</h6>
                            <Col xs={12} className="canvas-tile">
                                <Row>
                                    <Col xs={12}>
                                        <h5 style={{ textAlign: "center" }}>
                                            {replyTo.text}
                                        </h5>
                                        <Image
                                            src={replyTo.image}
                                            style={{ filter: "invert(1)" }}
                                        ></Image>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    );
                    break;
                case "TEXT":
                    render = (
                        <Col
                            xs={12}
                            md={this.getCols() < 2 ? 12 / this.getCols() : 6}
                            lg={cols < 4 ? 12 / cols : 4}
                            xl={12 / cols}
                            className={"reply-to"}
                        >
                            <h6>Replying to:</h6>
                            <Col xs={12} className="canvas-tile">
                                <Row>
                                    <Col xs={12}>
                                        <h5 style={{ textAlign: "center" }}>
                                            {replyTo.text}
                                        </h5>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    );
                    break;
                case "ANNOTATION":
                    render = (
                        <Col
                            xs={12}
                            md={this.getCols() < 2 ? 12 / this.getCols() : 6}
                            lg={cols < 4 ? 12 / cols : 4}
                            xl={12 / cols}
                            className={"reply-to"}
                        >
                            <h6>Replying to:</h6>
                            <Col
                                xs={12}
                                className="canvas-tile"
                                style={{
                                    height: this.state.clientHeight,
                                    minHeight: "0%",
                                    marginBottom: "1rem",
                                }}
                            >
                                <Row
                                    style={{ height: this.state.clientHeight }}
                                >
                                    <Col
                                        xs={12}
                                        ref={(divElement) =>
                                            (this.divElement = divElement)
                                        }
                                        style={{
                                            height: this.state.clientHeight,
                                        }}
                                    >
                                        <img
                                            src={
                                                this.props.data
                                                    .annotation_linked
                                                    .annotationSrc
                                            }
                                            ref={this.imgContainer}
                                            onLoad={this.handleImageLoaded}
                                            style={{ maxWidth: "100%" }}
                                            alt="annotation"
                                        ></img>

                                        {this.state.imgLoaded &&
                                            this.props.data.annotation_linked
                                                .annotationSrc && (
                                                <CanvasDraw
                                                    className="canvas-sketch"
                                                    // imgSrc={this.props.data.annotation_linked.annotationSrc}
                                                    disabled={true}
                                                    immediateLoading={true}
                                                    canvasWidth={
                                                        this.state.clientWidth
                                                    }
                                                    canvasHeight={
                                                        this.state.clientHeight
                                                    }
                                                    ref={(canvasDraw) =>
                                                        (this.loadableCanvas = canvasDraw)
                                                    }
                                                    backgroundColor={
                                                        "transparent"
                                                    }
                                                    hideGrid={true}
                                                    saveData={
                                                        this.props.data
                                                            .annotation_linked
                                                            .annotationData
                                                    }
                                                />
                                            )}
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    );
                    break;
                default:
                    render = <div></div>;
            }
        } catch (error) {
            console.log(error);
            render = <div></div>;
        }

        return render;
    };

    handleImageLoaded = (e) => {
        let height =
            (this.imgContainer.current.clientHeight *
                this.divElement.clientWidth) /
            this.imgContainer.current.clientWidth;
        this.setState({
            imgLoaded: true,
            clientWidth: this.divElement.clientWidth,
            clientHeight: height,
        });
    };

    decline = () => {
        this.props.declineComment({id:this.props.data._id});
    }

    approve = () => {
        console.log("click approved")
        this.props.approveComment({
            id:this.props.data._id,
            approved:true,
            canvas_id:this.props.canvas.id
        })
    }

    render() {
        let cols = this.getCols();
        if (cols > 0) {
            let replyTo = this.props.data.data_linked;
            if (
                this.props.data.data_linked === undefined &&
                this.props.data.annotation_linked === undefined
            ) {
                replyTo = <div></div>;
            } else {
                replyTo = this.renderReplyTo();
            }
            return (
                <Col
                    xs={12}
                    md={cols * 6}
                    lg={cols * 3}
                    xl={cols * 3}
                    data={cols}
                    style={{ padding: "0.5rem" }}
                >
                    <Col xs={12}>{this.renderUserProfile()}</Col>
                    <div className={this.props.type==='APPROVAL_COMMENT' ?  "normal-comment-wrapper" : "admin-comment-wrapper"}>
                        <Col xs={12} className="comment-tile comment-tile-render" style={{zIndex:this.props.type==='APPROVAL_COMMENT' && -1}}>
                            <Row>
                                {replyTo}
                                {this.props.data.comment_text !== undefined &&
                                    this.props.data.comment_text.length > 3 && (
                                        <Col
                                            xs={12}
                                            md={this.getTextColsDisplay()}
                                            lg={this.getTextColsDisplay()}
                                            xl={this.getTextColsDisplay()}
                                            data={this.getTextColsDisplay()}
                                            style={{
                                                minHeight: "300px",
                                                padding: "1rem",
                                            }}
                                        >
                                            <div>
                                                {ReactHtmlParser(
                                                    this.props.data.comment_text
                                                )}
                                            </div>
                                        </Col>
                                    )}
                                {this.props.data.comment_image_uploads !==
                                    undefined &&
                                    this.props.data.comment_image_uploads.length >
                                        0 &&
                                    this.props.data.comment_image_uploads.map(
                                        (img) => (
                                            <ImageBox
                                                upload={img}
                                                getCols={this.getCols}
                                            />
                                        )
                                    )}
                            </Row>
                        </Col>
                    </div>
                    {/* For appproval comments */}
                    {
                        this.props.type==='APPROVAL_COMMENT' && (
                            <Col>
                                <Row>
                                    <div>Comment Approval Pending!</div>
                                    {
                                        this.props.user.user_type==='ADMIN' && (
                                            <div style={{display:'flex'}}>
                                                <button style={{marginRight:'1rem', color:'green', cursor:'pointer', backgroundColor:'#fff', border:'none'}} onClick={this.approve}>accept</button>
                                                <button style={{color:'red', cursor:'pointer', backgroundColor:'#fff', border:'none'}} onClick={()=>{this.setState({declineWarning:true})}}>decline</button>
                                            </div>
                                        )
                                    }
                                </Row>
                            </Col>
                        )
                    }
                    <Modal show={this.state.declineWarning} onHide={()=>{this.setState({declineWarning:false})}} centered>
                        <Modal.Header closeButton style={{border:'none'}}></Modal.Header>
                        <Modal.Body style={{ fontWeight:700, textAlign:'center'}}>
                            Are you sure you want to decline this comment? 
                        </Modal.Body>
                        <Modal.Footer style={{border:'none'}}>
                        <button style={{color:'red', backgroundColor:'#FFFFFF', fontWeight:700, border:'none', flex:1, margin:0, textTransform:'lowercase'}} onClick={this.decline}>
                            Yes
                        </button>
                        <button style={{color:'#999999', backgroundColor:'#FFFFFF', fontWeight:700, border:'none', flex:1, margin:0, textTransform:'lowercase'}} onClick={()=>{this.setState({declineWarning:false})}}>
                            No
                        </button>
                        </Modal.Footer>
                    </Modal>
                </Col>
            );
        } else {
            return null;
        }
    }

    getTextColsDisplay = () => {
        let totalCols = this.getCols();
        let textCols = this.getTextCols();
        if (totalCols === textCols) {
            return 12;
        }

        // 2, 1 = 6
        // 3, 2 = 8
        // 4, 3 = 9
        if (totalCols - textCols === 1) {
            if (textCols === 1) {
                return 6;
            }
            if (textCols === 2) {
                return 8;
            }
            if (textCols === 3) {
                return 9;
            }
        }

        // 3, 1 = 4
        // 4, 2 = 6
        if (totalCols - textCols === 2) {
            if (textCols === 1) {
                return 4;
            }
            if (textCols === 2) {
                return 6;
            }
        }

        // 4, 1 = 3
        if (totalCols - textCols === 3) {
            if (textCols === 1) {
                return 3;
            }
        }
    };

    getTextColsLG = () => {
        let totalCols = this.getCols();
        let textCols = this.getTextCols();
        if (totalCols === textCols) {
            return 12;
        }

        // 2, 1 = 6
        // 3, 2 = 8
        // 4, 3 = 9
        if (totalCols - textCols === 1) {
            if (textCols === 1) {
                return 6;
            }
            if (textCols === 2) {
                return 8;
            }
            if (textCols === 3) {
                return 9;
            }
        }

        // 3, 1 = 4
        // 4, 2 = 6
        if (totalCols - textCols === 1) {
            if (textCols === 1) {
                return 4;
            }
            if (textCols === 2) {
                return 6;
            }
        }

        // 4, 1 = 3
        if (totalCols - textCols === 1) {
            if (textCols === 1) {
                return 3;
            }
        }
    };

    componentDidUpdate() {
        // console.log(this.imgContainer)
        // if(this.imgContainer && !this.state.imgLoaded && this.divElement)
        // {
        //     console.log(this.divElement.clientWidth)
        //     console.log(this.imgContainer.current.clientHeight)
        //     console.log(this.imgContainer.current.clientWidth)
        //     let height = this.imgContainer.current.clientHeight * this.divElement.clientWidth/this.imgContainer.current.clientWidth
        //     console.log(height)
        //     this.setState({imgLoaded: true, clientWidth: this.divElement.clientWidth, clientHeight: height})
        // }
        // if(!this.state.imgLoaded && this.props.annotation && this.imgContainer)
        // {
        //     this.getMeta(this.props.annotation.annotationSrc, (url, width, height) =>
        //     {
        //         let wrapperWidth = this.imgContainer.current.clientWidth
        //         console.log("callback reached")
        //         console.log(wrapperWidth)
        //         this.setState({height: height * wrapperWidth/width, width: wrapperWidth, imgLoaded: true})
        //     })
        // }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        canvas: state.canvas[localStorage.getItem('nayaCanvasId')]
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        declineComment: (data) => dispatch(declineComment(data)),
        approveComment: (data) => dispatch(approveComment(data))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CommentTile));
