import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CommentMedia from './CommentMedia';
import DisplayPDF from './DisplayPDF';

class ProjectDetails extends React.Component{

  render(){
    return(
      <div id="project-details">
        <h6>quote requested for</h6>
        <h3 style={{marginBottom:'3rem', fontFamily:'rand medium'}}>{this.props.estimation_canvas.name}</h3>

        <h6>description:</h6>
        <p dangerouslySetInnerHTML={{__html:this.props.email && this.props.email.body}}></p>
        {
          this.props.email && this.props.email.attachments && (
            <>
            <h6 style={{marginTop:'3rem'}}>files:</h6>
            <div className="comment-media-wrapper">
              {
                this.props.email.attachments.map((attachment, index) => ( 
                  <>
                  {/* {
                    attachment.ext!=="pdf" &&  */}
                    <CommentMedia src={attachment.link} ext={attachment.ext} key={index}></CommentMedia>
                  {/* } */}
                  </>
                ))
              }
            </div>
            {/* <div className="comment-media-wrapper">
            {
              this.props.email.attachments.map((attachment, index) => ( 
                <>
                {
                  attachment.ext==="pdf" && <DisplayPDF src={attachment.link} ext={attachment.ext} key={index}></DisplayPDF>
                }
                </>
              ))
            }
            </div> */}
            </>
          )
        }
        
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      estimation_canvas: state.estimation.canvas,
      email: state.estimation.email,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectDetails));
