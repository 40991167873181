import { API_ENDPOINT } from  '../secrets';
import axios from 'axios'
import { DEBUG } from  '../secrets'
import { config, getEmailAndId } from '../services/cookieHelpers'


const API_ANNOTATION_ENDPOINT = `${API_ENDPOINT}/api/annotation`


export const createAnnotation = (data) => {
    if(DEBUG) console.log(data)
    return (dispatch) => 
    {
        const credentials = getEmailAndId()

        axios.post(API_ANNOTATION_ENDPOINT, {...data, ...credentials}, config).then(response => {
            response = response.data
            if(response.status)
            {
                dispatch({type: 'CREATE_ANNOTATION_SUCCESS', payload: response.payload})
            }
            else
            {
                dispatch({type: 'CREATE_ANNOTATION_ERROR'})
            }
        })
        dispatch({type: 'CREATE_ANNOTATION_INIT'})
    }
}

export const editAnnotation = (data) => {
    return (dispatch) => 
    {
        const credentials = getEmailAndId()

        axios.put(API_ANNOTATION_ENDPOINT, {...data, ...credentials}, config).then(response => {
            response = response.data
            if(response.status)
            {
                dispatch({type: 'EDIT_ANNOTATION_SUCCESS', payload: response.payload})
            }
            else
            {
                dispatch({type: 'EDIT_ANNOTATION_ERROR'})
            }
        })
        dispatch({type: 'EDIT_ANNOTATION_INIT'})
    }
}


export const getAnnotation = (data) => {
    return (dispatch) => 
    {
        const credentials = getEmailAndId()

        axios.get(API_ANNOTATION_ENDPOINT, {params : { ...data, ...credentials } }, config).then(response => {
            response = response.data
            if(response.status)
            {
                dispatch({type: 'GET_ANNOTATION_SUCCESS', payload: response.payload})
            }
            else
            {
                dispatch({type: 'GET_ANNOTATION_ERROR'})
            }
        })
        dispatch({type: 'GET_ANNOTATION_INIT'})
    }
}


export const deleteAnnotation = (data) => {
    return (dispatch) => 
    {
        const credentials = getEmailAndId()

        axios.delete(API_ANNOTATION_ENDPOINT, {params : { ...data, ...credentials }}, config).then(response => {
            response = response.data
            if(response.status)
            {
                dispatch({type: 'DELETE_ANNOTATION_SUCCESS', payload: response.payload})
            }
            else
            {
                dispatch({type: 'DELETE_ANNOTATION_ERROR'})
            }
        })
        dispatch({type: 'DELETE_ANNOTATION_INIT'})
    }
}