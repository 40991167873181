import { API_ENDPOINT } from "../secrets";
import axios from "axios";
import { DEBUG } from "../secrets";
import {getCanvas} from './canvasActions';
import {createComment} from './commentActions';
import { config, getEmailAndId, getConfigEmail } from '../services/cookieHelpers'

export const postComment = (data) => {
    return (dispatch) => {
        //Create Answer
        const CREATE_COMMENT_DATA_URI = `${API_ENDPOINT}/api/approval-comments`;
        const credentials = getEmailAndId()

        axios
            .post(CREATE_COMMENT_DATA_URI, {...data, ...credentials}, config)
            .then((response) => {
                console.log("Comment added:", response.data)
                if (DEBUG) console.log(response);
                if (response.data.status) {
                    dispatch({
                        type: "SET_APPROVAL_COMMENTS",
                        id: response.data.canvas._id,
                        approval_comments: response.data.canvas.approval_comments,
                    });
                } else {
                    dispatch({
                        type: "ERROR_POST_COMMENT",
                        message: response.data.message,
                    });
                }
            })
            .catch((e) => {
                if (DEBUG) console.log(e);
                dispatch({
                    type: "ERROR_POST_COMMENT",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const declineComment = (data) => {
    return (dispatch) => {
        //Create Answer
        const DELETE_COMMENT = `${API_ENDPOINT}/api/approval-comments`;
        const credentials = getEmailAndId()


        axios
            .delete(DELETE_COMMENT, {params:{ ...data, ...credentials }}, config)
            .then((response) => {
                console.log("Comment added:", response.data)
                if (DEBUG) console.log(response);
                if (response.data.status) {
                    console.log("Comment approval declined!")
                    dispatch(getCanvas());
                } 
            })
            .catch((e) => {
                if (DEBUG) console.log(e);
                dispatch({
                    type: "ERROR_POST_COMMENT",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const approveComment = (data) => {
    return (dispatch) => {
        //Create Answer
        const APPROVE_COMMENT = `${API_ENDPOINT}/api/approval-comments`;
        const credentials = getEmailAndId()


        axios
            .put(APPROVE_COMMENT, { ...data, ...credentials }, config)
            .then((response) => {
                console.log("Comment approved:", response.data)
                if (DEBUG) console.log(response);
                if (response.data.status) {
                    console.log("Comment approval accepted!", response.data.comment)
                    let commentData = response.data.comment;
                    commentData["canvas_id"]=data.canvas_id;
                    console.log("comment data:", commentData)
                    dispatch(createComment(commentData));
                    dispatch(declineComment({id:data.id}))
                    // dispatch(getCanvas());
                } 
            })
            .catch((e) => {
                if (DEBUG) console.log(e);
                dispatch({
                    type: "ERROR_POST_COMMENT",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

