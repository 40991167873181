import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from 'lodash'
import {Container, Row, Col, Image, Form, Modal} from 'react-bootstrap';
import ProgressBar from './ProgressBar'
import Navbar from '../utilities/navbar/Navbar';
import location from '../../images/icons/location.png';
import {updateUser} from '../../actions/accountActions';
import '../../stylesheets/profile.css';
import Projects from './Projects';
import Questions from './Questions';
import Switch from "react-switch";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import ProfilePic from './ProfilePic';

const ADDRESS_KEYS = {
  'city':'administrative_area_level_2',
  'country':'country',
  'state':'administrative_area_level_1',
  'country_code':'country',
  'lat':0,
  'lng':0
}

class Profile extends React.Component{
  constructor(props){
    super(props);
    this.fileInput = React.createRef();
  }
  state = {
    designer:this.props.user.user_type==="DESIGNER" || this.props.user.user_type==="DESIGNER_MAKER" ? true : false,
    maker:this.props.user.user_type==="MAKER" || this.props.user.user_type==="DESIGNER_MAKER" ? true : false,
    editName:false,
    editCompany:false,
    editLocation:false,
    editDescription:false,
    name:this.props.user.name,
    last_name:this.props.user.last_name,
    profile_pic:this.props.user.profile_pic,
    studio_name:this.props.user.studio_name,
    location:this.props.user.detailed_location ? this.props.user.detailed_location.display_address : '',
    description:this.props.user.description,
    user_type:this.props.user.user_type,
    nameError:false,
    lastNameError:false,
    address: '',
    detailed_location:this.props.user.detailed_location
    
  }

  componentDidUpdate(prevProps) {
    const {
      user: {
        acceptedTermsOfService: {
          designer: prevDesigner, //boolean
          maker: prevMaker //boolean
        },
        user_type: prevUserType,
        email: prevEmail
      }
    } = prevProps

    const { user: {
      acceptedTermsOfService: {
        designer, //boolean
        maker, //boolean
      },
      user_type,
      email
    } } = this.props

    if (
        prevEmail !== email 
        || prevDesigner !== designer
        || prevMaker !== maker
        || prevUserType !== user_type
      )
      this.setState({
        designer: this.props.user.user_type === "DESIGNER" || this.props.user.user_type === "DESIGNER_MAKER" ? true : false,
        maker: this.props.user.user_type === "MAKER" || this.props.user.user_type === "DESIGNER_MAKER" ? true : false,
        name: this.props.user.name,
        last_name: this.props.user.last_name,
        profile_pic: this.props.user.profile_pic,
        studio_name: this.props.user.studio_name,
        location: this.props.user.location,
        description: this.props.user.description,
        user_type: this.props.user.user_type,
      })
  }

  // toggles designer switch
  handleChangeDesigner = () => {
    if(this.props.user.user_type !== "ADMIN"){
      this.setState({ designer: !this.state.designer },()=>{
        let type="";
        if(this.state.designer && this.state.maker){
          type="DESIGNER_MAKER";
        }
        else if(this.state.designer){
          type="DESIGNER";
        }
        else if(this.state.maker){
          type="MAKER";
        }
        else{
          type="USER";
        }
        let data = {
          id:this.props.user.id,
          user_type:type,
          updateType:'PROFILE_TYPE'
        }
        this.props.updateUser(data);
        const { acceptedTermsOfService: {
          designer: tosDesigner,
          maker: tosMaker
        } } = this.props.user
        if (type.includes('DESIGNER') && !tosDesigner)
          this.props.setAuth({
            checkingAuthStatus: true,
            authenticated: false
          })
        if (type.includes('MAKER') && !tosMaker)
          this.props.setAuth({
            checkingAuthStatus: true,
            authenticated: false
          })
      });
    }
  };

  // toggles maker switch
  handleChangeMaker = () => {
    if(this.props.user.user_type !== "ADMIN"){
      this.setState({ maker: !this.state.maker },()=>{
        let type="";
        if(this.state.designer && this.state.maker){
          type="DESIGNER_MAKER";
        }
        else if(this.state.designer){
          type="DESIGNER";
        }
        else if(this.state.maker){
          type="MAKER";
        }
        else{
          type="USER";
        }
        let data = {
          id:this.props.user.id,
          user_type:type,
          updateType:'PROFILE_TYPE'
        }
        this.props.updateUser(data);
        const { acceptedTermsOfService: {
          designer: tosDesigner,
          maker: tosMaker
        }} = this.props.user
        if (type.includes('DESIGNER') && !tosDesigner)
          this.props.setAuth({
            checkingAuthStatus: true,
            authenticated: false
          })
        if (type.includes('MAKER') && !tosMaker)
          this.props.setAuth({
            checkingAuthStatus: true,
            authenticated: false
          })
      });
    }
  };

  handleChange = (e) =>{
    this.setState({[e.target.name]:e.target.value})
  }

  // edit profile data
  editData = (e, type) => {
    switch(type){
      case "PROFILE_LOCATION":
        let locationData = {
          id:this.props.user.id,
          updateType:type,
          detailed_location:this.state.detailed_location
        }
        this.props.updateUser(locationData);
        this.setState({editLocation:false})
        break;
      case "PROFILE_NAME":
        if(this.state.name.trim().length===0 && this.state.last_name.trim().length===0){
          this.setState({nameError:true, lastNameError:true})
        }
        else if(this.state.name.trim().length===0){
          this.setState({nameError:true})
        }
        else if(this.state.last_name.trim().length===0){
          this.setState({lastNameError:true})
        }
        else{
          let nameData = {
            id:this.props.user.id,
            updateType:type,
            name:this.state.name,
            last_name:this.state.last_name,
          }
          this.props.updateUser(nameData);
          this.setState({editName:false, nameError:false, lastNameError:false})
        }
        break;
      case "PROFILE_STUDIO":
        let studioData = {
          id:this.props.user.id,
          updateType:type,
          studio_name:this.state.studio_name,
        }
        this.props.updateUser(studioData);
        this.setState({editCompany:false})
        break;
      case "PROFILE_DESCRIPTION":
        let descriptionData = {
          id:this.props.user.id,
          updateType:type,
          description:this.state.description,
        }
        this.props.updateUser(descriptionData);
        this.setState({editDescription:false})
        break;
      default:
        break;
    }
    
  }
 
  handleLocationChange = address => {
    this.setState({ address });
  }

  extractAddress = (address, type) => {
    let data = "";
    for(let i = 0; i<address.length; i++){
      if(address[i].types.includes(ADDRESS_KEYS[type])){
        if(type==='country_code'){
          data = address[i].short_name;
        }
        else{
          data = address[i].long_name;
        }
      }
    }
    return data;
  }
 
  handleSelect = async(address) => {
    this.setState({address})
    geocodeByAddress(address)
      .then(async(results) => {
        const coords =await getLatLng(results[0]);
        const detailed_address = {
          "city":this.extractAddress(results[0].address_components, 'city'),
          "state": this.extractAddress(results[0].address_components, 'state'),
          "country": this.extractAddress(results[0].address_components, 'country'),
          "country_code": this.extractAddress(results[0].address_components, 'country_code'),
          'lat': coords.lat,
          'lng':coords.lng,
          "display_address":this.state.address
        }
        this.setState({detailed_location: detailed_address});
      })
      .catch(error => console.error('Error', error));
  }

  renderAutoComplete = () => (
    <PlacesAutocomplete
      value={this.state.address}
      onChange={this.handleLocationChange}
      onSelect={this.handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="auto-complete">
          <input
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'location-search-input',
            })}
          />
          <div className={(loading || suggestions.length>0) && "autocomplete-dropdown-container"}>
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              const className = 'suggestion-item';
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )

  renderLocationModal = () => {
    return(
      <Modal show={this.state.editLocation} onHide={()=>{this.setState({editLocation:false})}} className="search-location-modal">
        <Modal.Header closeButton style={{borderBottom:0}}>
          <Modal.Title style={{fontSize:'1.2rem'}}>Search location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderAutoComplete()}
        </Modal.Body>
        <Modal.Footer style={{borderTop:0, display:'flex', justifyContent:'center'}}>
          <button className="main-btn" onClick={(e)=>{this.editData(e, "PROFILE_LOCATION")}}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    )
  }

  render(){
    return(
      <div>
        <Navbar/>
        {/* {this.renderAutoComplete()} */}
        <Container id="profile">
        {this.renderLocationModal()}
          <Row className="user-details-container">
            {/* PROFILE PICTURE */}
            <ProfilePic ></ProfilePic>
            {/* USER DETAILS */}
            <div style={{flex:1,
              //  minWidth:100
               }} className="name-container">
              {/* NAME */}
              <Col className="name" style={{paddingight:0}}>
                <Row style={{flexFlow:'row', width:'inherit'}} className="name-wrapper">
                  {
                    this.state.editName ? (
                      <>
                      <div style={{marginTop:'1rem', marginRight:5}}>
                        <Form.Control 
                          plaintext={!this.state.editName} 
                          readOnly={!this.state.editName} 
                          defaultValue={this.props.user.name} 
                          name="name" 
                          placeholder="First Name"
                          onChange={this.handleChange}
                          style={{padding:5, margin:0,  borderRadius:5}} />
                          {
                            this.state.nameError && (<p style={{color:'red', fontSize:10, margin:0}}>*first name cannot be empty</p>)
                          }
                      </div>
                      <div style={{marginTop:'1rem'}}>
                        <Form.Control 
                          plaintext={!this.state.editName} 
                          readOnly={!this.state.editName} 
                          defaultValue={this.props.user.last_name} 
                          name="last_name" 
                          placeholder="Last Name"
                          onChange={this.handleChange}
                          style={{padding:5, margin:0,  borderRadius:5}} />
                          {
                            this.state.lastNameError && (<p style={{color:'red', fontSize:10, margin:0}}>*last name cannot be empty</p>)
                          }
                        
                      </div>
                      </>
                    ) : (
                      <h3 style={{}} onClick={()=>{this.setState({editName:true})}}>{this.props.user.name+" "+this.props.user.last_name}</h3>
                    )
                  }
                  {
                    !this.state.editName && (
                      <Row className="edit-name" style={{marginLeft:'1rem', alignItems:'center'}}>
                        <div style={{cursor:'pointer',  fontWeight:400}} onClick={()=>{this.setState({editName:true})}}>edit</div>
                      </Row>
                    )
                  }
                </Row>
                {
                  this.state.editName && (
                    <Row>
                      <div style={{cursor:'pointer', marginRight:10,  fontWeight:400}} name='submit' onClick={(e)=>{this.editData(e, "PROFILE_NAME")}}>submit</div>
                      <div style={{cursor:'pointer', color:'#a3a3a3',  fontWeight:400}} onClick={()=>{this.setState({editName:false})}}>cancel</div>
                    </Row>
                  )
                }
              </Col>
              {/* COMPANY */}
              <Col className="company" style={{marginBottom:5, paddingight:0}}>
                <Row style={{width:'inherit'}} className="name-wrapper">
                {
                    this.state.editCompany ? (
                      <>
                      <Form.Control 
                        plaintext={!this.state.editCompany} 
                        readOnly={!this.state.editCompany} 
                        defaultValue={this.props.user.studio_name} 
                        name="studio_name"
                        placeholder="Studio/Company name"
                        onChange={this.handleChange}
                        style={{padding:5, margin:0,  fontWeight:400, color:'#666666', borderRadius:5,marginBottom:0}}/>
                      </>
                    ) : (
                      <h4 onClick={()=>{this.setState({editCompany:true})}} style={{padding:!this.state.editCompany && 0,  fontWeight:400, color:'#a3a3a3', borderRadius:5, marginTop:0}} >{this.props.user.studio_name ? this.props.user.studio_name : "Your studio/company name"}</h4>
                    )
                  }
                  {
                  !this.state.editCompany && (
                    <Row className="edit-company" style={{marginLeft:'1rem', alignItems:'center'}}>
                      <div style={{cursor:'pointer',  fontWeight:400}} onClick={()=>{this.setState({editCompany:true})}}>edit</div>
                    </Row>
                  )
                }
                </Row>
                {
                  this.state.editCompany && (
                    <Row>
                      <div style={{ cursor: 'pointer', marginRight: 10, fontWeight: 400 }} name='submit' onClick={(e)=>{this.editData(e, "PROFILE_STUDIO")}}>submit</div>
                      <div style={{cursor:'pointer', color:'#a3a3a3',  fontWeight:400}} onClick={()=>{this.setState({editCompany:false})}}>cancel</div>
                    </Row>
                  )
                }
              </Col>
              {/* location */}
              <Col className="location" style={{paddingLeft:0, paddingight:0}}>
                <div style={{display:'flex',marginBottom:!this.state.editLocation && '0.75rem', alignItems:'center'}} className="name-wrapper">
                  <Image src={location} style={{width:20, height:20, marginRight:5}}/>
                  <h5 onClick={()=>{this.setState({editLocation:true})}} style={{padding:!this.state.editLocation && 0, margin:0, color:'#a3a3a3',  borderRadius:5,}}>{(this.props.user.detailed_location && this.props.user.detailed_location.display_address) ? this.props.user.detailed_location.display_address  : "Enter your location"}</h5>
                  {
                    !this.state.editLocation && (
                      <Row className="edit-location" style={{margin:0, alignItems:'center'}}>
                        <div style={{cursor:'pointer',  fontWeight:400}} onClick={()=>{this.setState({editLocation:true})}}>edit</div>
                      </Row>
                    )
                  }
                </div>
              </Col>
            </div>
            {/* USER TYPE-> MAKER/DESIGNER */}
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} className="switch-container">
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <Switch
                  checked={this.state.designer}
                  onChange={this.handleChangeDesigner}
                  onColor="#FFFFFF"
                  onHandleColor="#000000"
                  handleDiameter={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={35}
                  className="react-switch"
                  id="material-switch"
                />
                <button onClick={this.handleChangeDesigner} className="switch-button" style={{ backgroundColor: this.state.designer ? '#000000' : '#666666' }}>Designer</button>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <Switch
                  checked={this.state.maker}
                  onChange={this.handleChangeMaker}
                  onColor="#FFFFFF"
                  onHandleColor="#000000"
                  handleDiameter={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={35}
                  className="react-switch"
                  id="material-switch"
                />
                <button onClick={this.handleChangeMaker} style={{ margin: 0, backgroundColor: this.state.maker ? '#000000' : '#666666', color: '#FFFFFF', padding: '0.5rem', fontSize: 12, textTransform: 'capitalize', border: 'none', width: 100, borderRadius: 5, marginLeft: 10 }}>Fabricator</button>
              </div>
            </div>
          </Row>
          {/* DESCRIPTION */}
          <Form.Group as={Row} className="description-container">
            <div style={{color:'#666666', fontWeight:400,  fontSize:18, width:108, alignSelf:this.state.editDescription && 'flex-start'}}>
              Description:
            </div>
            <div style={{padding:0, flex:1, minWidth:300}} className="description">
              {
                !this.props.user.description || this.state.editDescription ? (
                  <>
                  <Form.Control 
                    as="textarea" 
                    maxLength={150}
                    name="description"
                    onChange={this.handleChange}
                    onClick={()=>{this.setState({editDescription:true})}}
                    defaultValue={this.props.user.description}
                    readOnly={!this.state.editDescription}
                    placeholder="Share a bit about the kind of work you do, the experience you have, or any expertise you want to highlight."/>
                  <p style={{margin:0, fontSize:11, textAlign:'right', color:'#a3a3a3', }}>150 characters</p>
                  {
                      !this.state.editDescription && (
                        // <Row className="edit-description" style={{marginLeft:'1rem'}}>
                          <div className="edit-description" style={{cursor:'pointer',  fontWeight:400, width:'100%'}} onClick={()=>{this.setState({editDescription:true})}}>edit</div>
                        // {/* </Row> */}
                      )
                    }
                  </>
                ) : (
                  <div style={{display:'flex', alignItems:'baseline'}}>
                  <h6 style={{wordBreak:'break-all'}} onClick={()=>{this.setState({editDescription:true})}}>{this.props.user.description}</h6>
                  {
                      !this.state.editDescription && (
                        <Row className="edit-description" style={{marginLeft:'1rem'}}>
                          <div style={{cursor:'pointer',  fontWeight:400, width:'100%'}} onClick={()=>{this.setState({editDescription:true})}}>edit</div>
                        </Row>
                      )
                    }
                  </div>
                )
              }
                {
                  this.state.editDescription && (
                    <Row style={{width:'100%', textAlign:'right',margin:0, justifyContent:'flex-end'}}>
                    <div style={{ cursor: 'pointer', marginRight: 10, fontWeight: 400 }} name='submit' onClick={(e)=>{this.editData(e, "PROFILE_DESCRIPTION")}}>submit</div>
                      <div style={{cursor:'pointer', color:'#a3a3a3',  fontWeight:400}} onClick={()=>{this.setState({editDescription:false})}}>cancel</div>
                    </Row>
                  )
                }
            </div>
          </Form.Group>
          <ProgressBar />
          {/* UPLOAD */}
          {
            // If both (maker and designer) is selected
              (this.state.designer || this.state.maker) ? (
              <>
              <Projects disabled={false}/>
              </>
            ) : 
            // No option is selected
            (
              <Projects disabled={true}/>
            )
          }
          {/* QUESTIONS */}
          <Questions/>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(updateUser(data))
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile));