import React, { Component } from "react";
import { Row } from "react-bootstrap";
import "../../stylesheets/utilities.css";

class ProgressBar extends Component {
    render() {
        return (
            <Row style={{ borderBottom: "1px solid #222" }}>
                <div
                    style={{
                        width: this.props.width + "%",
                        backgroundColor: "black",
                        height: "0.6rem",
                    }}
                ></div>
            </Row>
        );
    }
}

export default ProgressBar;
