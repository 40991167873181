/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown, Button, Col } from 'react-bootstrap';
import { Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import ShouldRender from '../utilities/ShouldRender';
import {
  ECOSYSTEM_LOGIN_PAGE,
  ECOSYSTEM_SIGNUP_PAGE,
  ECOSYSTEM_PROFILE_PAGE,
} from '../../secrets';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { apiResponse: 'NOT_CONNECTED', scrollPos: 0, 'loginModal': this.props.show, 'token': this.props.token };
  }


  // eslint-disable-next-line require-jsdoc
  render() {

    return (
      <footer className="col-12">
        <Col xs={{ span: '12', offset: '0' }} lg={{ span: '10', offset: '1' }}>
          <Row className="justify-content-md-center">
            {/* <Col xs='12' md='3' lg='3' xl='2' className='links'>
              <ul>
                <li><h1>naya</h1></li> 
                <li><i className="fas fa-phone"></i> 617-218-7273</li> 
                <li><i className="fas fa-envelope"></i> info@naya.studio</li>
              </ul>
            </Col> */}

            <Col xs='12' md='3' lg='3' xl='2' className='links'>
              <ul>
                <li>Account</li>
                <ShouldRender
                  condition={this.props.isLoggedIn}
                >
                  <li><a href={ECOSYSTEM_PROFILE_PAGE}>My Profile</a></li>
                </ShouldRender>
                <ShouldRender
                  condition={!this.props.isLoggedIn}
                >
                  <li><a href={ECOSYSTEM_LOGIN_PAGE}>Login</a></li>
                  <li><a href={ECOSYSTEM_SIGNUP_PAGE}>Sign Up</a></li>
                </ShouldRender>
              </ul>
            </Col>

            <Col xs='12' md='3' lg='3' xl='2' className='links'>
              <ul>
                <li>Company</li>
                <li><a href="https://www.freeprivacypolicy.com/privacy/view/a27fd87f24aa63dc2e7e8a4809d48d06">Privacy Policy</a></li>
                <li><a href="https://docs.google.com/document/u/2/d/e/2PACX-1vTov12Yo4MCqPhMYsXgHgngMOTRAyeqNHCfwnEfMVCvZywTrMROtX9wFF9kBaS7XTPR0x6wSQ8Dj-vV/pub">Terms and Conditions</a></li>
              </ul>
            </Col>

            <Col xs='12' md='3' lg='3' xl='2' className='social links'>
              <ul>
                <li>Connect </li>
                <li><a href="https://www.facebook.com/pages/category/Furniture/Naya-Studio-538392739983745/"><i className="fab fa-facebook"></i> Facebook </a></li>
                <li><a href="https://www.instagram.com/naya__studio/"><i className="fab fa-instagram"></i> Instagram </a></li>
                <li><a href="https://www.linkedin.com/company/studio-naya/"><i className="fab fa-linkedin"></i> LinkedIn </a></li>
                <li style={{ display: 'flex', alignItems: 'center' }}><i className="fas fa-envelope" style={{ marginRight: 5 }}></i> info@naya.studio</li>
              </ul>
              {/* <p>Terms and Conditions</p> */}
            </Col>
          </Row>
        </Col>
      </footer>

    );
  }
}


export default connect(
  status => {
    const { user } = status;
    const isLoggedIn = user && !!user.id;
    return ({ isLoggedIn })
  }
)(Footer);
