import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Button} from 'react-bootstrap';
import photo_placeholder from '../../images/photo-placeholder.png';
import attach from '../../images/attach.svg';
import send from '../../images/send.svg';
import chevron_down from '../../images/chevron-down.svg';
import cancel from '../../images/close.png';
import CommentTile from './CommentTile';
import { motion } from "framer-motion";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import {UPLOAD_ENDPOINT} from '../../secrets';
import {createComment} from '../../actions/estimationActions';
import axios from 'axios';
import video from '../../images/video.svg';
import audio from '../../images/audio.svg';
import document from '../../images/document.svg';

class Comments extends React.Component{
  state = {
    dummy_comments: [
      {
        user:"5ed4c56158526fd335ce170b", comment_text:"This is a dummy comment 1.", type:"self", comment_image_uploads:["https://via.placeholder.com/250x100", "https://via.placeholder.com/150", "https://via.placeholder.com/200x300", "https://via.placeholder.com/100x150"]
      },
      {
        user:"5ed4c56158526fd3s35ce170b", comment_text:"This is a dummy comment 2.", type:"other", comment_image_uploads:[]
      },
      {
        user:"5ed4c56158526fd335ce170b", comment_text:"This is a dummy comment 3.", type:"self", comment_image_uploads:[] 
      },
      {
        user:"5ed4c56158526fd33ded5ce170b", comment_text:"This is a dummy comment 4.", type:"other", comment_image_uploads:[]
      },
    ],
    comment_text:'',
    uploads:[]
  }
  // Checking if the device is a mobile phone or not to render data accordingly
  isMobile = () => {
    if(window.innerWidth <500){
        return true 
    }
    else{
        return false
    }
  }

  onCommentTextChange = (e) => {
    this.setState({comment_text: e})
  }

  handleUpload = (fileData) => {
    const formData = new FormData();
    formData.append("file", fileData, fileData.name);
    const UPLOAD_FILE_DATA_URI = UPLOAD_ENDPOINT
    axios
      .post(UPLOAD_FILE_DATA_URI, formData, { crossDomain: true })
      .then((response) => {
        this.setState({uploading:false});
        this.setState({uploads:[...this.state.uploads, response.data]})
      })
      .catch((e) => {
          console.log(e);
          this.setState({uploading:false})
      });
    };

  // handling files upload
  handleFilesAdd = (e) => {
    for(let i=0;i<e.target.files.length;i++){
      this.handleUpload(e.target.files[i]);
    }
  };

  removeImage = (link) => {
    let images = this.state.uploads.filter(x=>x!==link);
    this.setState({uploads:images});

  }

  addComment = () => {
    let data = {
      comment: {
        user: this.props.userId,
        comment_text: this.state.comment_text,
        comment_image_uploads: this.state.uploads.map((upload) => upload.id),
      },
      estimation_id: this.props.estimation.id,
    };
    console.log(this.props)
    this.props.createComment(data);
    this.setState({uploads:[], comment_text:''})
  }

  getAttachmentType= (ext) => {
    if(["mp3","wav","wma","aac","m4a","flac",].includes(ext)){
      return "audio";
    }
    else if(["webm","mpg","mp2","mpeg","mpe","mpv","ogg","mp4","m4p","m4v","avi","wmv",].includes(ext)){
      return "video";
    }
    else if(["jpg","jpeg","gif","png","svg","raw","webp","tiff","psd","bmp","heif",].includes(ext)){
      return "image";
    }
    else{
      return "document";
    }
  }

  getAttachmentLink = (upload) => {
    switch(this.getAttachmentType(upload.ext)){
      case 'audio':
        return audio;
      case 'video':
        return video;
      case 'image':
        return upload.link;
      case 'document':
        return document;
      default:
        break;
    }
  }

  renderCommentsInput = () => {
    return(
      <div className="comments-input-container">
        <Button variant="outline-secondary" className="input-group-btn" disabled>
          <img src={photo_placeholder} alt="placeholder" style={{width:'1.5rem', marginBottom:'0.5rem', marginTop:'0.5rem'}}></img>
        </Button>
          <div className="text-image-container">
            <div className="images-container">
              {
                this.state.uploads.map((upload, index)=> (
                  <div className="img-close-icon-container" key={index}>
                    <img src={this.getAttachmentLink(upload)} alt="placeholder" className="image" fluid></img>
                    <motion.img src={cancel} alt="cancel" className="cancel" onClick={()=>{this.removeImage(upload)}}
                      whileHover={{
                        scale: 1.1,
                        transition: { duration: 0.2 },
                      }}
                      whileTap={{ scale: 0.9 }}/>
                  </div>
              ))
              }
              
            </div>
            <ReactQuill
              value={this.state.comment_text}
              placeholder="Add a new comment"
              style={{
                  height: "auto",
                  fontSize: "1rem",
                  border: "none",
                  outline: "none",
              }}
              onChange={this.onCommentTextChange}
            />
        </div>
        <label htmlFor="fileinput" style={{display:'flex', margin:0, cursor:'pointer'}} className="input-group-btn">
          <motion.img src={attach} alt="attach" style={{width:'1.5rem', marginBottom:'0.5rem', marginTop:'0.5rem'}}
            whileHover={{
              scale: 1.05,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.9 }}></motion.img>
          <input  id = "fileinput" 
          multiple
            className   = "file-input" 
            style={{display:'none'}}
            onChange={this.handleFilesAdd}
            type = "file">
          </input>
      </label>
      <Button variant="outline-secondary" className="input-group-btn">
        <motion.img src={send} alt="send" style={{width:'1.5rem', marginBottom:'0.5rem', marginTop:'0.5rem'}}
            whileHover={{
              scale: 1.2,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.9 }} onClick={this.addComment}></motion.img>
      </Button>
      </div>
    )
  }

  renderCommentTiles = () => {
    const comments = this.props.estimation.comments;
    let comments_to_display = [];
    for(let i=0;i<comments.length;i++){
      if(comments[i].user?._id === this.props.userId){
        comments_to_display.push(<CommentTile type={'self'} comment_data={comments[i]}></CommentTile>)
      }
      else{
        comments_to_display.push(<CommentTile type={'other'} comment_data={comments[i]}></CommentTile>)
      }
    }
    return comments_to_display;
  }

  render(){
    return(
      <div id="estimate-comments">
        {
          this.isMobile() ? (
            <div style={{width:'100%', display:'flex', justifyContent:'center', marginBottom:'2rem', marginTop:'1rem'}}>
              <img src={chevron_down} alt="chevron-down" style={{width:'2rem', cursor:'pointer'}} fluid onClick={this.props.toggleComments}></img>
            </div>
          ) : (
            <p className="toggle-comments" onClick={this.props.toggleComments} style={{marginBottom:'2rem', textAlign:'left', fontFamily:'rand medium'}}>{"< Back to Estimates"}</p>
          )
        }
        <h3 style={{marginBottom:'3rem', fontFamily:'rand medium'}}>Comments</h3>
        {this.renderCommentTiles()}
        {this.renderCommentsInput()}
        
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      userId: state.user.id, 
      estimation: state.estimation
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createComment: (data) => dispatch(createComment(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Comments));
