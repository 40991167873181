import canvasReducer from "./canvasReducer";
import userReducer from "./userReducer";
import errorsReducer from "./errorsReducer";
import loadingReducer from "./loadingReducer";
import commentsReducer from "./commentsReducer";
import approvalCommentsReducer from "./approvalCommentsReducer";
import { combineReducers } from "redux";
import annotationsReducer from "./annotationsReducer";
import UIReducer from "./UIReducer";
import transactionsReducer from "./transactionsReducer";
import notificationsReducer from "./notificationsReducer";
import estimationReducer from "./estimationReducer";

const rootReducer = combineReducers({
    canvas: canvasReducer,
    user: userReducer,
    comments: commentsReducer,
    approval_comments: approvalCommentsReducer,
    errors: errorsReducer,
    loading: loadingReducer,
    annotations: annotationsReducer,
    UI: UIReducer,
    transactions: transactionsReducer,
    notifications: notificationsReducer,
    estimation: estimationReducer
});

export default rootReducer;
