import React, { Component } from "react";
import { Container, Row, Col, Button, Image, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { GOOGLE_MAPS_API_KEY } from "../../secrets";

class GoogleMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coordinates: {
                lat: 0,
                lng: 0,
            },
            mapLoaded: false,
            map: null,
            marker: null,
        };
        this.mapRef = React.createRef();
        this.handleGoogleMapLoad = this.handleGoogleMapLoad.bind(this);
        this.searchBoxRef = React.createRef();
    }

    loadGoogleMapScript = (callback) => {
        if (
            typeof window.google === "object" &&
            typeof window.google.maps === "object"
        ) {
            callback();
        } else {
            const googleMapScript = document.createElement("script");
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
            window.document.body.appendChild(googleMapScript);
            googleMapScript.addEventListener("load", callback);
        }
    };

    componentDidMount() {
        this.loadGoogleMapScript(this.handleGoogleMapLoad);
    }

    handleGoogleMapLoad = () => {
        console.log(this.setState);
        const map = new window.google.maps.Map(this.mapRef.current, {
            center: {
                lat: this.state.coordinates.lat,
                lng: this.state.coordinates.lng,
            },
            zoom: 8,
            panControl: true,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            overviewMapControl: true,
            rotateControl: true,
        });
        var position = this.state.coordinates;

        const marker = new window.google.maps.Marker({
            position: position,
            map: map,
        });

        const searchBox = new window.google.maps.places.SearchBox(
            this.searchBoxRef.current
        );

        searchBox.addListener(
            "places_changed",
            function () {
                var places = searchBox.getPlaces();

                if (places.length === 0) {
                    return;
                }

                // For each place, get the icon, name and location.
                var bounds = new window.google.maps.LatLngBounds();
                const place = places[0];
                if (!place.geometry) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                // Create a marker for each place
                this.placeMarkerAndPanTo(place.geometry.location, map);

                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
                map.fitBounds(bounds);
            }.bind(this)
        );

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                console.log(position);
                this.placeMarkerAndPanTo(pos, map);
            });
        } else {
            console.log("No geolocation provider!");
        }

        map.controls[window.google.maps.ControlPosition.TOP].push(
            this.searchBoxRef.current
        );

        map.addListener("click", (e) => {
            this.placeMarkerAndPanTo(e.latLng, map);
        });

        this.setMapData(map, marker);
    };

    placeMarkerAndPanTo = (latLng, map) => {
        this.state.marker.setPosition(latLng);
        console.log(latLng)
        const pos = {
            lat: latLng.lat,
            lng: latLng.lng,
        };
        this.setState({
            coordinates: pos,
        });
        console.log(pos);
        this.props.updateLocation(this.props.name, pos);
        map.panTo(latLng);
    };

    setMapData = (map, marker) => {
        console.log("Loaded!");
        const newState = {
            map: map,
            marker: marker,
            mapLoaded: true,
        };
        this.setState(newState);
    };

    renderMap = () => {
        return;
    };

    render() {
        return (
            <Container>
                <Col>
                    <input  style={{borderColor:"1px solid black"}} type="text" ref={this.searchBoxRef} />
                </Col>
                <Col
                    ref={this.mapRef}
                    style={{ width: "100%", height: "50vh" }}
                >
                    {this.state.mapLoaded && <p>Loading...</p>}
                </Col>
            </Container>
        );
    }
}

export default GoogleMap;
