import React, { useEffect, useRef } from 'react'
import Navbar from '../utilities/navbar/Navbar';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {Container, Table, Col} from 'react-bootstrap';
import '../../stylesheets/estimates.css'
import { usePopulate } from './util/util'
import moment from 'moment-timezone'


const TableInfo = ({
    canvasName = '',
    requestedOn = '',
    receivedAt = '',
    estimatedCost = '',
    estimatedTime = '',
    status = '',
    estimationID = '',
    type = ''
  }) => {

  const statusStyle = () => {
    switch (status) {
      case "Active":
        return 'orange'
      case "Approved":
        return 'green'
      case "Rejected":
        return 'red'
      case "Expired":
      default:
        return 'grey'
    }
  }

  const estimatedStyle = () => {
    switch (status) {
      case "Approved":
        return "green"
      case "Rejected": 
        return "red"
      default:
        return 'black'
    }
  }
  

  return (
    <tr>
      <td name='project-title'>{canvasName}</td>
      <td name='requested-on'>{requestedOn}</td>
      <td name='estimated-on'>{receivedAt}</td>
      <td name='estimated-cost'>
        <span style={{ color: `${estimatedStyle()}`}}>
          {estimatedCost}{(status === 'Active' && !estimatedCost) ? '-' : ''}
          </span>
      </td>
      <td name='estimated-time'>
        <span style={{ color: `${estimatedStyle()}` }}>
          {estimatedTime}{(status === 'Active' && !estimatedTime) ? '-' : ''}
          </span> 
      </td>
      <td name='status'>
        <span style={{ color: `${statusStyle()}`, textShadow: '0px 0px 1px #a6a6a6'}}>{status}</span>
      </td>
      <td name='type'>
        <span>{type}</span>
      </td>
      <td className='edit-button' name={estimationID}> 
        <Link to={`../estimation/${estimationID}`} > Edit </Link> 
      </td>
    </tr>
  )
}

const Estimates = (props) => {
  const { 
    user, 
    designCanvases, 
    fabricationCanvases, 
    consultingCanvases, 
  } = props

  //fetch canvas and estimations info
  const [ data, loading ] = usePopulate()
  const numberToCurrency = useRef(null)

  useEffect(() => {
      numberToCurrency.current = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: "2"
      })

  }, [])

  //find a canvas's name
  const findName = (canvasID) => {
    return data.canvas.find((canvas) => canvas._id === canvasID).name
  }

  const parseDate = (isoString) => {
    if (!isoString)
      return ''

    return moment(isoString).format("MM/DD/YYYY")
  }

  //changes number to USD string
  const parseCurrency = (number) => {
    if (!number)
      return ''
    
    number = number * 0.01

    return numberToCurrency.current.format(number)
  }


  return (
    <div>
      <Navbar />
      <Container id='my-estimates' as='main'>
        <Col xs={12} >
          <h3> My Estimates </h3>
          <Table borderless>
            <thead>
              <tr>
                <th>PROJECT TITLE</th>
                <th>REQUESTED ON</th>
                <th>ESTIMATED ON</th>
                <th>ESTIMATED COST</th>
                <th>ESTIMATED TIME</th>
                <th>STATUS</th>
                <th>TYPE</th>
                <th> - </th>
              </tr>
            </thead>
            <tbody>
              {loading && data.canvas.length > 0 && data.estimates.map((estimate) => {
                return <TableInfo 
                  canvasName={findName(estimate.canvas)}
                  requestedOn={parseDate(estimate.requestedOn)}
                  receivedAt={parseDate(estimate.receivedAt)}
                  estimatedCost={parseCurrency(estimate.estimatedCost)}
                  estimatedTime={estimate.estimatedTime}
                  status={estimate.status}
                  type={estimate.type}
                  estimationID={estimate._id}
                />
              })}
            </tbody>
          </Table>
        </Col>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    designCanvases: state.user.design_canvas,
    fabricationCanvases: state.user.fabrication_canvas,
    consultingCanvases: state.user.consulting_canvas,
  };
};


export default connect(mapStateToProps)(withRouter(Estimates));