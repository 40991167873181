import { API_ENDPOINT } from "../secrets";
import axios from "axios";
import { config, getEmailAndId, getConfigEmail } from '../services/cookieHelpers'

export const getEstimation = (estimation_id) => {
    const configEmail = getConfigEmail()
    return (dispatch) => {
        dispatch({
            type: "SET_ESTIMATION_LOADING",
            loading: true
        });
        dispatch({
            type: "SET_SUCCESS",
            success: false,
            msg: ''
        });
        const GET_ESTIMATION = `${API_ENDPOINT}/api/estimations/${estimation_id}`;

        axios
            .get(GET_ESTIMATION, configEmail)
            .then((response) => {
                dispatch({
                    type: "SET_ESTIMATION",
                    estimation: response.data,
                    id: response.data._id,
                    loading: false, error: false,
                });
            })
            .catch((e) => {
                console.log("Error getting canvas");
                console.log(e);
                dispatch({
                    type: "SET_ERROR",
                    success: true,
                    msg: 'Error in getting estimation'
                });
            });
    };
};

export const updateEstimation = (data) => {
    const credentials = getEmailAndId()
    return (dispatch) => {
        dispatch({
            type: "SET_ESTIMATION_LOADING",
            loading: true
        });
        dispatch({
            type: "SET_SUCCESS",
            success: false,
            msg: ''
        });
        const UPDATE_ESTIMATION = `${API_ENDPOINT}/api/estimations/${data.estimation_id}`;

        axios
            .put(UPDATE_ESTIMATION, { ...data, ...credentials }, config)
            .then((response) => {
                dispatch(getEstimation(data.estimation_id));
                dispatch({
                    type: "SET_SUCCESS",
                    success: true,
                    msg: 'Estimation updated successfully'
                });
            })
            .catch((e) => {
                console.log("Error updating estimation");
                console.log(e);
                dispatch({
                    type: "SET_ERROR",
                    success: true,
                    msg: 'Error in updating estimation'
                });
            });
    };
};

export const createComment = (data) => {
    const credentials = getEmailAndId()
    console.log("config:", config)
    return (dispatch) => {
        const CREATE_COMMENT_DATA_URI = `${API_ENDPOINT}/api/estimations/${data.estimation_id}/comments`;

        axios
            .post(CREATE_COMMENT_DATA_URI, { ...data.comment, ...credentials }, config)
            .then((response) => {
                dispatch(getEstimation(data.estimation_id));
            })
            .catch((e) => {
                console.log("error:", e);
            });
    };
};