import React, { Component } from 'react'
import {Image} from 'react-bootstrap'
import cancel from '../../images/icons/selected.svg'

class UploadOption extends Component{
    componentDidMount(){
    }
    handleSelect = (e) => {
        e.preventDefault()
        this.props.onClick(this.props.photo.src)
    }

    handleDeselect = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.props.onRemove(this.props.photo.src)
    }

    render()
    {
        let cont = {
            backgroundColor: "#eee",
            cursor: "pointer",
            overflow: "hidden",
            position: "relative",
          };
        cont.position = "absolute";
        cont.left = this.props.left;
        cont.top = this.props.top;
        let style = { margin: this.props.margin, height: this.props.photo.height, width: this.props.photo.width, ...cont }
        return (
            <div className='search-upload-option option' onClick={this.handleSelect} style={style}>
                <Image src={this.props.photo.src} style={{ height: this.props.photo.height, width: this.props.photo.width}} fluid></Image>
                {this.props.selected && <img onClick={this.handleDeselect} src={cancel} style={{"height": "20px", "position":"absolute", "top":"0.5rem", "right": "0.5rem", "left":"unset", "bottom":"unset"}}></img>}
            </div>
        )
    }
}

export default UploadOption