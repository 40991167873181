import { API_ENDPOINT } from "../secrets";
import axios from "axios";
import { config, getEmailAndId } from '../services/cookieHelpers'


export const initiatePayment = (payload) => {
    const credentials = getEmailAndId()

    return (dispatch) => {
        const PAY_FEES = `${API_ENDPOINT}/payments/client`;
        dispatch({
            type: "SET_LOADING",
            key: "transactionProcessing",
            value: true,
        });

        axios
            .post(PAY_FEES, { ...payload, ...credentials}, config)
            .then((response) => {
                response = response.data;
                if (response.status) {
                    dispatch(
                        getTransactionsForCanvas(
                            response.transaction.canvas._id
                        )
                    );
                    dispatch(
                        getTransactionsForUser(response.transaction.user._id)
                    );
                } else {
                    dispatch({
                        type: "TRANSACTION_ERROR",
                        message: response.message,
                    });
                }

                dispatch({
                    type: "SET_LOADING",
                    key: "transactionProcessing",
                    value: false,
                });
            })
            .catch((e) => {
                dispatch({
                    type: "TRANSACTION_ERROR",
                    message:
                        "Something went wrong. Check your internet connection.",
                });
                dispatch({
                    type: "SET_LOADING",
                    key: "transactionProcessing",
                    value: false,
                });
            });
    };
};

export const getTransactionsForCanvas = (id) => {
    const credentials = getEmailAndId()

    return (dispatch) => {
        const GET_CANVAS_TRANSACTIONS = `${API_ENDPOINT}/payments/canvas`;
        axios
            .get(GET_CANVAS_TRANSACTIONS, { params: { id: id, ...credentials } }, config)
            .then((response) => {
                console.log(response);
                response = response.data;
                if (response.status) {
                    dispatch({
                        type: "ADD_TRANSACTIONS",
                        id: id,
                        transactions: response.transactions,
                    });
                } else {
                    console.log(response.message);
                }
            })
            .catch((e) => {
                // console.log(e)
            });
    };
};

export const getTransactionsForUser = (id) => {
    const credentials = getEmailAndId()

    return (dispatch) => {
        const GET_CANVAS_TRANSACTIONS = `${API_ENDPOINT}/payments/user`;
        axios
            .get(GET_CANVAS_TRANSACTIONS, { params: { id: id }, ...credentials }, config)
            .then((response) => {
                response = response.data;
                if (response.status) {
                    dispatch({
                        type: "ADD_TRANSACTIONS",
                        id: id,
                        transactions: response.transactions,
                    });
                } else {
                    // console.log(response.message)
                }
            })
            .catch((e) => {
                // console.log(e)
            });
    };
};
