import React from 'react';
import {Modal} from 'react-bootstrap';
import moment from 'moment-timezone';
import '../../stylesheets/prompts.css';

class Prompt extends React.Component{

  state = {
    zone : Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  render(){
    return(
        <Modal
          size="md"
          show={true}
          onHide={()=>this.props.toggleNotification()}
          aria-labelledby="example-modal-sizes-title-xl"
          id="instructions-modal"
          className="prompts"
          style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}
          centered
        >
        <Modal.Header closeButton style={{padding:'1rem', border:'none'}}>
          <Modal.Title style={{width:'100%', textAlign:'center', fontFamily:'bigmoore, serif', fontSize:'2.5rem', fontWeight:'normal'}}>naya</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{textAlign:'center', marginBottom:20,fontWeight:'bold', fontSize:36}}>{this.props.data.content.title}</h3>
          <div dangerouslySetInnerHTML={{__html: this.props.data.content.body.replace('{{time}}',moment(new Date(this.props.data.date)).tz(this.state.zone).format('h:mm a[,] Do MMM'))}}/>
        </Modal.Body>
      </Modal>
    )
  }
}

export default Prompt;