import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Container, Row, Col, Button} from 'react-bootstrap';
import cloudUpload from "../../images/icons/cloud_upload_icon.png";
import cloudUploadDisabled from "../../images/icons/cloud_upload_icon_disabled.png";

import SyncLoader from "react-spinners/SyncLoader";

import Dropzone from "react-dropzone";
import { css } from "@emotion/core";
import axios from "axios";
import { API_ENDPOINT } from "../../secrets";
import Gallery from "react-photo-gallery";
import UploadOption from './UploadOption';
import {updateUser, addAnswer} from '../../actions/accountActions';
import add from '../../images/icons/add.png';

import ImageTile from './ImageTile.js';

import {UPLOAD_ENDPOINT} from '../../secrets';

import '../../stylesheets/upload.css';

// importing icons
import documentIcon from '../../images/icons/document.svg';
import audio from '../../images/icons/audio.svg';
import video from '../../images/icons/video.svg';
import close from '../../images/icons/close.svg';

const spinnerStyle = css`
  margin-left: 50%;
  transform: translate(-50%, 0);
`;

class ProjectsBuilt extends React.Component{
  state = {
    images:[],
    gallery:[],
    otherDocuments:[],
    selected_options:new Set([]),
    uploading:false,
    projects:[],
    edit:false,
  }

  componentDidMount(){
    // this.getProjectsBuilt();
  }

  getProjects = (type) => {
    let temp = [];
    temp=this.props.questions.filter(x=>x.id===type);
    
    if(temp.length!==0){
      if(temp[0].images.length!==0){
        this.setState({projects:temp[0].images, edit:true})
        return temp[0].images;
      }
      else{
        this.setState({edit:false})
        return [];
      }
    }
    else{
      this.setState({edit:false})
      return [];
    }
  }

  handleUpload = (fileData) => {
    const formData = new FormData();
    const file = fileData.file;
    formData.append("file", file, file.name);
    const UPLOAD_FILE_DATA_URI = UPLOAD_ENDPOINT
    axios
      .post(UPLOAD_FILE_DATA_URI, formData, { crossDomain: true })
      .then((response) => {
        this.setState({uploading:false})
          this.setState({images:[...this.state.images, response.data.link]})
          // Checking if the uploaded file is image or not
          // if it is an image, adding it to the gallery
          if (
              [
                  "jpg",
                  "jpeg",
                  "gif",
                  "png",
                  "svg",
                  "raw",
                  "webp",
                  "tiff",
                  "psd",
                  "bmp",
                  "heif",
              ].includes(response.data.ext)
          ) {
              this.getMeta(response.data.link, this.addImageUpload);
          } else {
              this.setState({
                  otherDocuments: [
                      ...this.state.otherDocuments,
                      {
                          id: response.data.id,
                          link: response.data.link,
                          name: response.data.name,
                          ext: response.data.ext,
                      },
                  ],
              });
          }
      })
      .catch((e) => {
          console.log(e);
          this.setState({uploading:false})
      });
  };

  // handling files upload
  handleFilesAdd = (files) => {
    files.forEach((file) => {
      this.setState({uploading:true})
        const fileData = { file: file, uploaded: false };
        this.handleUpload(fileData);
    });
};

  // Adding the uploaded images to the gallery
  addImageUpload = (url, width, height) => {
    if (!this.state.selected_options.has(url)) {
        let selected_options = this.state.selected_options;
        selected_options.add(url);
        this.setState({
            gallery: [
                ...this.state.gallery,
                { src: url, height: height, width: width },
            ],
            selected_options: selected_options,
        });
    }
  };

  // remove a particular image
  removeImage = (url, width, height) => {
    if (this.state.selected_options.has(url)) {
        let selected_options = this.state.selected_options;
        selected_options.delete(url);
        let temp = this.state.images.filter((upload) => upload.link !== url);
        let gallery = this.state.gallery.filter((x) => x.src !== url);
        
        this.setState({
            gallery: gallery,
            selected_options: selected_options,
            images:temp
        });
    }
  };

  getMeta = (url, callBack) => {
    var img = new Image();

    img.onload = function () {
        callBack(url, this.width, this.height);
    };
    img.src = url;
  };

  addToGallery = (url) => {
      this.getMeta(url, this.addImage);
  };

  removeFromGallery = (url) => {
      this.getMeta(url, this.removeImage);
  };

  // Adding a searches image to the gallery
  addImage = (url, width, height) => {
    if (!this.state.selected_options.has(url)) {
        let selected_options = this.state.selected_options;
        selected_options.add(url);
        
        this.setState({
            gallery: [
                ...this.state.gallery,
                { src: url, height: height, width: width },
            ],
            selected_options: selected_options
        });
    }
  };

  imageRenderer = ({ index, left, top, key, photo }) => {
    return (
      <UploadOption
        onClick={this.addToGallery}
        onRemove={this.removeFromGallery}
        value={photo.src}
        selected={this.state.selected_options.has(photo.src)}
        margin={"2px"}
        left={left}
        top={top}
        photo={photo}
        key={key}
      ></UploadOption>
    );
  };

  handleSubmit = () => {
    let type=this.props.user.user_type==="DESIGNER_MAKER" ?  "projects" : this.props.user.user_type==="DESIGNER" ? "projects_designed" : "projects_built";
    let imagesData = {
      updateType:'PROFILE_QUESTION',
      id:this.props.user.id,
      data:{
        type:'projects',
        name: 'uploads',
        id:type,
        images:[...this.state.images, ...this.getProjects(type)]
      }
    }
    let answer = null;
    answer=this.props.questions.filter(x=>x.id===type);
    if(answer===null || answer.length===0){
      this.props.addAnswer(imagesData);
    }
    else{
      this.props.updateUser(imagesData)
    }
    this.setState({edit:true})
    this.setState({images:[], selected_options:new Set([]), gallery:[], otherDocuments:[]})
  }

  deleteImage = (link, type) => {
    let temp = this.props.questions.filter(x=>x.id===type)[0].images.filter(x => x !== link);
    let imagesData = {
      updateType:'PROFILE_QUESTION',
      id:this.props.user.id,
      data:{
        type:'projects',
        name: 'uploads',
        id:type,
        images:temp
      }
    }
    this.props.updateUser(imagesData)
    if(temp.length===0){
      this.setState({edit:false})
    }
  }
  
  renderProjects = () => {
    
    return(
      <Row id="image-tile-wrapper">
        {/* Displaying deisgner images */}
        {
          this.props.questions.filter(x=>x.id==="projects_designed").length!==0 ? (
            <>
            {
              this.props.questions.filter(x=>x.id==='projects_designed')[0].images.map((image, index) => (
                <ImageTile image={image} key={index} deleteImage={this.deleteImage} type="projects_designed" disabled={this.props.disabled}/>
              ))
            }
            </>
          ) : false 
        }
        {/* Displaying fabricator images */}
        {
          this.props.questions.filter(x=>x.id==="projects_built").length!==0 ? (
            <>
            {
              this.props.questions.filter(x=>x.id==='projects_built')[0].images.map((image, index) => (
                <ImageTile image={image} key={index} deleteImage={this.deleteImage} type="projects_built" disabled={this.props.disabled}/>
              ))
            }
            </>
          ) : false 
        }
        {/* Displaying designer-maker images */}
        {
          this.props.questions.filter(x=>x.id==="projects").length!==0 ? (
            <>
            {
              this.props.questions.filter(x=>x.id==='projects')[0].images.map((image, index) => (
                <ImageTile image={image} key={index} deleteImage={this.deleteImage} type="projects" disabled={this.props.disabled}/>
              ))
            }
            </>
          ) : false 
        }
        </Row>
    )
  }

  // check if the images exist 
  ImagesExist = () => {
    let type=this.props.user.user_type==="DESIGNER_MAKER" ?  "projects" : this.props.user.user_type==="DESIGNER" ? "projects_designed" : "projects_built";
    if(this.props.questions.filter(x=>x.id===type).length!==0){
      if(this.props.questions.filter(x=>x.id===type)[0].images.length>0){
        return true;
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
  }

  render(){
    return(
      <Col style={{marginBottom:20}}>
        {/* title */}
        <Row>
          <h6 style={{width:'100%', textAlign:'center', fontWeight:'bold', marginBottom:20, marginTop:20, fontSize:20, color:this.props.disabled ? "#a3a3a3" : "#000000"}}>
            {this.props.user.user_type==="DESIGNER_MAKER" ?  "Show us some of your work" :
            this.props.user.user_type==="DESIGNER" ? "Show us some design work you’ve done" : 
            "Show us some projects you’ve built"
            }
          </h6>
        </Row>
        {/* rener images */}
        {this.renderProjects()}
        {/* images upload- drag and drop */}
        <Row className='media-drop-wrapper' style={{ padding: "0rem", backgroundColor:this.props.disabled ? "#f5f5f5" : '#EBEBEB'}}>
          <Dropzone onDrop={this.handleFilesAdd} disabled={this.props.disabled}>
              {({ getRootProps, getInputProps }) => (
                <Container style={{
                  cursor: "pointer",
                  border: this.props.disabled ? "1px dashed #a3a3a3" : "1px dashed #666666",
                  borderRadius: "5px",
                  paddingRight: "50px",
                  paddingLeft:"50px",
                  paddingTop: "10px",
                  paddingBottom:"10px",
                  textAlign: "center",
                }} {...getRootProps()} fluid>
                  {this.state.gallery.length > 0 && (
                    <Gallery
                      photos={this.state.gallery}
                      direction={"column"}
                      columns={2}
                      renderImage={this.imageRenderer}
                    ></Gallery>
                  )}
                  <div style={{display:'flex', flexWrap:'wrap'}}>
                  {
                      this.state.otherDocuments.length!==0 && this.state.otherDocuments.map((doc, index)=> (
                          <div className="other-documents-item">
                              <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                                  <img 
                                      src={close}
                                      alt="close"
                                      style={{width:12, height:20, alignSelf:'flex-end'}}
                                      onClick={()=>{
                                          let temp = this.state.otherDocuments.filter(x => x.link!=doc.link)
                                          let tempImages = this.state.images.filter(x => x!=doc.link)
                                          this.setState({otherDocuments:temp, images:tempImages})
                                      }}/>
                                  <img src={
                                      ['mp3','wav','wma','aac','m4a', 'flac'].includes(doc.ext) ? audio : 
                                      ['webm','mpg','mp2','mpeg','mpe','mpv','ogg','mp4','m4p','m4v','avi','wmv'].includes(doc.ext) ? video : documentIcon
                                  } style={{width:40, height:40}} alt="document format icon"/>
                              </div>
                              <div style={{
                                      width: 80,
                                      height: 20,
                                      whiteSpace: 'break-spaces',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      textAlign: 'center',
                              }}>{doc.name}</div>
                          </div>
                      ))
                  }
                  </div>
                  <Row className='justify-content-md-center'>
                    <input {...getInputProps()} />
                    {this.state.uploading ? (
                    <SyncLoader css={spinnerStyle} color={"#000000"} sizeUnit={"em"} size={"1"} style={{ margin: "auto" }}></SyncLoader>
                    ) : (
                      <>
                      {
                        this.ImagesExist() ? (
                          <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <img src={add} style={{width:40, height:40}}  alt="add-icon"/>
                            <p style={{fontSize: "1rem",fontWeight: "400", marginLeft:'1rem', marginBottom:0 ,color:this.props.disabled ? '#666666' : '#000000'}}>add more</p>
                          </div>

                        ) : (
                          <Col>
                            <Col xs={{span: 4,offset: 4,}}>
                              <img src={this.props.disabled ? cloudUploadDisabled : cloudUpload} className={"img-fluid"} alt="project-built"/>
                            </Col>
                            <p  style={{fontSize: "1rem",fontWeight: "400",marginBottom:"2rem",color:this.props.disabled ? '#666666' : '#000000'}}>
                              Drag and drop upto 5 files
                              here<br/> or
                            </p>
                            {/* <p style={{"borderBottom":"1px solid grey", "lineHeight":"0.1rem"}}><span style={{"padding":"0.1rem 1rem 0.1rem 1rem", "backgroundColor":"rgb(245, 245, 245)"}}>Or</span></p> */}
                            <Button disabled={this.props.disabled} style={{marginTop: "0rem",textTransform:"none", backgroundColor:'#666666'}}>Select Files</Button>
                          </Col>
                        )
                      }
                      </>
                    )}
                  </Row>
                </Container>
              )}
          </Dropzone>
        </Row>
        {/* if images are selected, submit and cancel buttons */}
        {
          this.state.images.length>0 && (
            <Row style={{justifyContent:'flex-end'}}>
              <div style={{ cursor:'pointer', marginRight:10}} onClick={this.handleSubmit}>Submit</div>
              <div style={{ cursor:'pointer', color:'#a3a3a3'}} onClick={()=>{this.setState({images:[], selected_options:new Set([]), gallery:[]})}}>Cancel</div>
            </Row>
          )
        }
      </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      user: state.user,
      questions: state.user.questions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(updateUser(data)),
    addAnswer: (data) => dispatch(addAnswer(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectsBuilt));