import React from 'react';
import download from '../../images/download.svg';
import maximize from '../../images/maximize.svg';
import { motion } from "framer-motion";
import {Modal} from 'react-bootstrap';
import video from '../../images/video.svg';
import audio from '../../images/audio.svg';
import document from '../../images/document.svg';

class CommentMedia extends React.Component{
  state = {
    showModal: false
  }

  toggleImageModal = () => {
    this.setState({showModal: !this.state.showModal})
  }

  getAttachmentType= () => {
    if(["mp3","wav","wma","aac","m4a","flac",].includes(this.props.ext)){
      return "audio";
    }
    else if(["webm","mpg","mp2","mpeg","mpe","mpv","ogg","mp4","m4p","m4v","avi","wmv",].includes(this.props.ext)){
      return "video";
    }
    else if(["jpg","jpeg","gif","png","svg","raw","webp","tiff","psd","bmp","heif",].includes(this.props.ext)){
      return "image";
    }
    else{
      return "document";
    }
  }

  getAttachmentLink = () => {
    switch(this.getAttachmentType()){
      case 'audio':
        return audio;
      case 'video':
        return video;
      case 'image':
        return this.props.src;
      case 'document':
        return document;
      default:
        break;
    }
  }

  render(){
    return(
      <>
      <div className="comment-media-tile">
        <img src={this.getAttachmentLink()} alt="comment upload" className="media" fluid></img>
        <a href={this.props.src} download>
          <motion.img src={download} alt="downoad" className="image-actions" style={{marginLeft:'0.2rem'}}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.9 }}/>
        </a>
        {
          this.getAttachmentType() === 'image' && (
            <motion.img src={maximize} alt="maximize" className="image-actions" style={{marginLeft:'2rem'}} onClick={this.toggleImageModal}
              whileHover={{
                scale: 1.1,
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 0.9 }}/>
          )
        }
        
      </div>
      <Modal
        show={this.state.showModal}
        onHide={this.toggleImageModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{borderBottom:'none'}}>
        </Modal.Header>
        <Modal.Body  style={{overflowY:'auto', display:'flex', justifyContent:'center'}}>
          <img src={this.props.src} style={{width:'100%'}} alt="modal-content"></img>
        </Modal.Body>
      </Modal>
      </>
    )
  }
}

export default CommentMedia;