// images for how it works
import communicate from '../../images/1.svg';
import collaborate from '../../images/2.svg';
import make from '../../images/3.svg';

// images for vallue prop
import NoCompromise from '../../images/compromise.svg'
import Signature from '../../images/signature.svg'
import Access from '../../images/access.svg'

import calling_creators from '../../images/Maker_1920.jpg';

let PAGE_CONFIG = 
{
    data: [
        {
            type: "HERO",
            title: "Designers and Makers",
            subtitle: "Join our ecosystem of talented professionals.",
            cta: "Join Now",
            redirect_path: "/login",
            css_slug:"ecosystem"
        },
        {
            type: "TESTIMONIALS",
            elements_data: [
                {text: "The ability to have a Google Doc version of 3D design software that is a hybrid of Pinterest, collage, drawing, and also a client, designer, and maker 'chat'."},
                {text: "...can connect makers with clients that others would not be able to find a local designer/maker."},
                {text: "It is rare that you get a fair price on Etsy, unless you are cranking out a lot of easy to make, repetitive objects. Thats not the case with Naya."},
                {text: "...freedom of customization and a bridge between local make shop, designers and customers. And this is adaptable to different design styles."}
            ]
        },
        {
            type:"TEXT_ICON",
            title: "How it works",
            css_slug: "hiw",
            fluid: true,
            xs:{span:10, offset:1},
            lg:{span:8, offset:2},
            elements_data: [
                {
                    title: "Match Project",
                    image_src: communicate,
                    description: "Let us know what you like to design and/or fabricate. You will be matched with a project based on your preferences."
                },
                {
                    title: "Collaborate & Estimate",
                    image_src: collaborate,
                    description: "As a designer, you will co:design with the client and as a maker, you will work on build details and price estimate."
                },
                {
                    title: "Make it!",
                    image_src: make,
                    description: "Build the piece and send progress photos to the client and a video of the final piece to us. Once shipped, we will release the deposit."
                }
            ]
        },

        {
            type: "FULL_WIDTH_IMAGE",
            title: "Calling all creators!",
            css_slug: "calling-all-creators",
            description: "Naya Studio partners with skilled local designers, makers, artists and fabricators. Working with Naya can be a great opportunity to bring your artistic expression to unique projects while making some extra bucks. Naya is a truly collaborative design experience.",
            img_src:calling_creators,
        },
        {
            type:"TEXT_ICON",
            title: "Our Value Proposition",
            css_slug: "why-naya",
            fluid: true,
            xs:{span:8, offset:2},
            lg:{span:6, offset:3},
            elements_data: [
                {
                    title: "Access to Projects",
                    image_src: Access,
                    description: "A continuous flow of unique and exciting projects to fill capacity with fair pricing. Also, get paid for design work."
                },
                {
                    title: "No compromises",
                    image_src: NoCompromise,
                    description: "Guaranteed deposits at start and end of the project. We keep track of client requests so that expectations are aligned."
                },
                {
                    title: "True personalization",
                    image_src: Signature,
                    description: "Get unique and creative project that keep things interesting. Matched with projects that fit your skills and style."
                }
            ]
        },
        {
            type: "FULL_WIDTH_IMAGE_WITH_BTN",
            title: "",
            css_slug: "ecosystem",
            cta: "Join Us",
            redirect_path: "/login",
            // redirect_type: "INTERNAL",
        }
    ]
}

export default PAGE_CONFIG