import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Form, Col, Row, Button, Image, Modal } from 'react-bootstrap';
import userIcon from '../../images/icons/profile_pic.png';
import { updateUser } from '../../actions/accountActions';
import ReactCrop from 'react-image-crop';
import axios from 'axios'
import { API_ENDPOINT } from '../../secrets'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import '../../stylesheets/basicInfo.css';

class BasicInfo extends React.Component {

  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    const contact_email = this.props.user && this.props.user.email;
    this.state = {
      showError: false,
      name: "",
      last_name: "",
      studio_name: "",
      contact_email,
      personal_website: "",
      profile_pic: "",
      location: "",
      phone_number: "",
      showImageCropModal: false,
      isImageUploading: false,
      uploadedImageLink: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 1 / 1,
      },
      croppedImageUrl: null,
      phoneError: false,
    }

  }
  componentDidMount() {
    this.checkForName();
    if (this.props.user.id === "") {
      this.props.history.push("login");
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, showError: false })
  }

  onSelectFile = () => {
    if (this.fileInput.current.files.length > 0 && this.fileInput.current.files[0]) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({
          uploadedImageLink: reader.result,
          showImageCropModal: true,
          isImageUploading: false
        })
      );
      reader.readAsDataURL(this.fileInput.current.files[0]);
      this.setState({ isImageUploading: true })
    }
  }

  handleUpload = (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append("file", this.state.croppedImageBlob)
    const UPLOAD_FILE_DATA_URI = `${API_ENDPOINT}/api/upload`
    axios.post(UPLOAD_FILE_DATA_URI, formData, { crossDomain: true })
      .then(response => {
        console.log("Image uploaded:", response.data)
        // let data = {
        //     id: this.props.user.id,
        //     updateType: "PROFILE_PIC",
        //     profile_pic: response.data.link
        // }
        // this.props.updateUser(data)
        this.setState({ profile_pic: response.data.link, showImageCropModal: false }, () => { console.log("Changed profile pic:", this.state.profile_pic) })

      }).catch(e => {
        console.log(e)
        this.setState({ showImageCropModal: false })

      })
  }
  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  handleContinue = (e) => {
    e.preventDefault();
    if (
      this.state.name.trim().length === 0 ||
      this.state.last_name.trim().length === 0 ||
      this.state.location.trim().length === 0 ||
      !this.validateEmail(this.state.contact_email)
      ) {
      this.setState({ showError: true });
    }
    // if (this.state.personal_website.trim().length === 0) {
    //   this.setState({ showError: true });
    // }
    // if (this.state.phone_number.trim().length >= 0) {
    //   var phoneRegex = /^(\+[0-9]{1,3})[a-zA-Z0-9\-().\s]{10,15}$/;
    //   if (!this.state.phone_number.match(phoneRegex)) {
    //     this.setState({ phoneError: true });
    //   } else {
    //     this.setState({ phoneError: false });
    //   }
    // }
    else {
      const data = {
        id: this.props.user.id,
        name: this.state.name,
        last_name: this.state.last_name,
        studio_name: this.state.studio_name,
        personal_website: this.state.personal_website,
        location: this.state.location,
        profile_pic: this.state.profile_pic,
        updateType: "PROFILE_DETAILS",
        phone_number: this.state.phone_number,
        contact_email: this.state.contact_email,
      }
      console.log("DATA:", data)
      this.props.updateUser(data);
      this.props.history.push('profile')
    }
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const [croppedImageBlob, croppedImageUrl] = await this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg');
      this.setState({ croppedImageUrl, croppedImageBlob });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }

        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve([blob, this.fileUrl]);
      }, 'image/jpeg');
    });
  }

  onContactChange = (value) => {
    this.setState({ phone_number: value })
  }

  render() {
    const { contact_email } = this.state;
    return (
      <Container id="basic-info-container">
        <a href="/" style={{ color: '#000000' }}>&#x2190; Back to home page</a>
        <h2>Tell us about your work</h2>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '1.5rem' }}>
          <h3>Basic Info</h3>
          <h6 style={{ color: '#a3a3a3', fontSize: 12 }}><span style={{ color: 'red' }}>*</span> required field</h6>
        </div>
        <Form>
          {/* PROFILE PICTURE */}
          <Form.Group as={Row} style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Label column sm="3">
              Profile Picture
            </Form.Label>
            <Col sm="9">
              <div class="image-upload">
                <label htmlFor="file-input" style={{ display: 'flex' }}>
                  <div style={{ border: '1px solid #a3a3a3', borderRadius: '50%', cursor: 'pointer' }}>
                    <Image src={this.state.profile_pic.length !== 0 ? this.state.profile_pic : userIcon} fluid style={{ borderRadius: '50%' }} />
                  </div>
                  <div style={{ backgroundColor: '#666666', borderRadius: '50%', marginTop: 'auto', width: 30, height: 30, marginLeft: -25, cursor: 'pointer' }}>
                    <div style={{ width: '100%', height: '100%', textAlign: 'center', fontSize: 40, color: '#FFFFFF', marginTop: -21, fontWeight: 400 }}>+</div>
                  </div>
                </label>

                <input id="file-input" type="file" onChange={this.onSelectFile} ref={this.fileInput} />
              </div>
            </Col>
          </Form.Group>
          {/* NAME */}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Your Name<span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <Col sm="9" style={{ display: 'flex' }}>
              <Form.Control placeholder="first name" style={{ marginRight: 10 }} name="name" onChange={this.handleChange} />
              <Form.Control placeholder="last name" name="last_name" onChange={this.handleChange} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} >
            <Form.Label column sm="3">
              Contact email <span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <Col sm="9">
              <Form.Control value={contact_email} name="contact_email" onChange={this.handleChange} />
            </Col>
          </Form.Group>
          {/* Phone Number */}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Phone Number
            </Form.Label>
            <Col sm="9" style={{ display: 'flex' }}>
              <PhoneInput
                placeholder="Enter phone number"
                value={this.state.phone_number}
                onChange={this.onContactChange} />
            </Col>
          </Form.Group>
          {/* STUDIO/SHOP NAME */}
          <Form.Group as={Row} controlId="formPlaintextPassword">
            <Form.Label column sm="3">
              Shop/Studio Name
            </Form.Label>
            <Col sm="9">
              <Form.Control placeholder="Ex: New England Furniture Co." name="studio_name" onChange={this.handleChange} />
            </Col>
          </Form.Group>
          {/* PERSONAL WEBSITE */}
          <Form.Group as={Row} controlId="formPlaintextPassword">
            <Form.Label column sm="3">
              Website/Portfolio
            </Form.Label>
            <Col sm="9">
              <Form.Control placeholder="www.newenglandfurniture.co" name="personal_website" onChange={this.handleChange} />
            </Col>
          </Form.Group>
          {/* Location */}
          <Form.Group as={Row} controlId="formPlaintext">
            <Form.Label column sm="3">
              Location <span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <Col sm="9">
              <Form.Control placeholder="Boston, MA, USA" name="location" onChange={this.handleChange} />
            </Col>
          </Form.Group>
          {
            this.state.showError && (
              <p style={{ width: '100%', textAlign: 'center', color: 'red' }}>please fill all the required fields</p>
            )
          }
          {
            this.state.phoneError && (
              <p style={{ width: '100%', textAlign: 'center', color: 'red' }}>please enter a valid phone number</p>
            )
          }
          <div className="button-container">
            <Button type="submit" style={{ margin: 0, marginBottom: 20, backgroundColor: '#000000', textTransform: 'capitalize' }} onClick={this.handleContinue}>
              Continue
            </Button>
          </div>
        </Form>

        <Modal
          size="sm"
          show={this.state.showImageCropModal}
          onHide={() => this.setState({ showImageCropModal: false })}
          aria-labelledby="example-modal-sizes-title-sm"
          id="instructions-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Crop Profile Image
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center">
              <ReactCrop
                src={this.state.uploadedImageLink}
                crop={this.state.crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col xs="12">
              <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <Row className="justify-content-center">
                  <Button variant="dark" onClick={this.handleUpload}>Done</Button>
                </Row>
              </Col>
            </Col>
          </Modal.Footer>
        </Modal>

      </Container>
    )
  }

  checkForName = () => {
    console.log("BASIC INFO PROPS:", this.props.user)
    if (this.props.user.last_name !== undefined && this.props.user.name !== undefined && this.props.user.last_name !== null && this.props.user.name !== null) {
      if (this.props.user.last_name.length !== 0 && this.props.user.name.length !== 0) {
        this.props.history.push('profile')
      }
    }
  }

  componentDidUpdate() {
    this.checkForName()
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(updateUser(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BasicInfo));