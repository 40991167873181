import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Button } from 'react-bootstrap'
import moment from 'moment-timezone'
import axios from 'axios'
import { API_ENDPOINT } from '../../secrets'
import './admin.css'
import { config, getEmailAndId, getConfigEmail } from '../../services/cookieHelpers'

const credentials = getEmailAndId()
const configEmail = getConfigEmail()



export const Admin = () => {
    const [ search, setSearch ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ message, setMessage ] = useState('')
    const [ messageStyle, setMessageStyle ] = useState('green')
    const [ loading, setLoading ] = useState(false)
    const [ users, setUsers ] = useState([])
    const [ displayUsers, setDisplayUsers ] = useState([])
    const timeoutRef = useRef(null)
    const messageTimeoutRef = useRef(null)
    const history = useHistory()

    //used to sort users by join date (ascending aka oldest account first)
    const sortUsers = useCallback((firstUser, secondUser) => {
        return (firstUser.createdAt < secondUser.createdAt) ? -1 : ((firstUser.createdAt > secondUser.createdAt) ? 1: 0)
    }, [])

    //format the date
    const formatDate = useCallback((isoDate) => {
        return moment(isoDate).format('MMM DD, YYYY')
    }, [])

    //checks if user is authenticated and fetches data on mount
    useEffect(() => {
        if (!localStorage.getItem('nayaEcoAdminAuth')) 
            history.push('/admin')

        const fetchUsers = async () => {
            setLoading(true)
            let response = await axios.get(API_ENDPOINT + '/users/')
            response = [...response.data.designers, ...response.data.fabricators]
            response = response.sort(sortUsers)
            response = response.map((user) => ({...user, createdAt: formatDate(user.createdAt)}))
            setUsers(response)
            setLoading(false)
        }

        fetchUsers()
    }, [history, sortUsers, formatDate])


    //sets display users after users have loaded
    useEffect(() => {
        setLoading(true)
        setDisplayUsers([...users])
        setLoading(false)
    }, [users])

    
    //used to filter through our user lists
    const filterUsers = useCallback(() => {
        if (!search)
            return users

        const regExp = new RegExp(`${search}`, 'gi')

        const filteredUsers = users.filter((user) => {

            //defines which parameters to filter through
            let stringifyUser = {
                email: user.email, 
                name: user.name , 
                user_type: user.user_type ,
                id: user._id
            }
            stringifyUser = JSON.stringify(stringifyUser)

            if (stringifyUser.match(regExp))
                return true
            else
                return false
        })

        return filteredUsers
    }, [search, users])


    //causes timeout on searching before engaging search
    useEffect(() => {
        if (timeoutRef.current !== null)
            clearTimeout(timeoutRef.current)

        if (search !== '') {
            timeoutRef.current = setTimeout(() => {
                timeoutRef.current = null
                setDisplayUsers(filterUsers())
            }, 500)
        } else {
            setDisplayUsers(users)
        }

        return () => {
            clearTimeout(timeoutRef.current)
        }

    }, [search, filterUsers, users])


    //timeout reset for message (after adding user)
    useEffect(() => {
        if (messageTimeoutRef.current !== null)
            clearTimeout(messageTimeoutRef.current)

        if (message !== '') {
            messageTimeoutRef.current = setTimeout(() => {
                messageTimeoutRef.current = null
                setMessage('')
                setMessageStyle('green')
            }, 5000)
        } 

        return () => {
            clearTimeout(messageTimeoutRef.current)
        }

    }, [message])


    /**
     * 
     * @param {object} user takes in user object as parameter
     * @returns {string} returns email name if no name is provided
     */
    const formatName = (user) => {
        if (!user)
            return
        const { email, name = '', last_name = '' } = user
        if (name === '') {
            return email.split('@')[0]
        }
        return name + ' ' + last_name
    }


    //used to add new emails to the user list
    const addNewUserHandler = async () => {
        try {
            const response = await axios.post(`${API_ENDPOINT}/auth/add-user`, { email, ...credentials }, config )
            console.log(response)
            handleAddUserStatus(response.data)
        } catch (e) {
            console.error(e)
            setMessage('Error. Contact info@naya.studio for more information.')
            setMessageStyle('red')
        }
    }

    //sets user message when using the add user button
    const handleAddUserStatus = (data) => {
        if (!data.status) {
            setMessage(data.message)
            setMessageStyle('red')
        }
        setMessage(data.message + '. Refresh the page.')
    }

    return (
        <div className='admin-page__container'>
            <div className='admin-page__topContainer'>
                <input 
                    className='admin-page__input' 
                    type='text' 
                    name='search' 
                    placeholder='Search to filter results...' 
                    value={search} 
                    onChange={e => setSearch(e.target.value)}  
                />
                <div className='admin-page__emailContainer'>
                    <input
                        className='admin-page__email'
                        type='text'
                        name='email'
                        placeholder='Enter Email Address'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <Button type='button' className='admin-page__button__addUser' onClick={addNewUserHandler}> Add User </Button>
                </div>
            </div>

            <div className='admin-page__notifications' style={{color: messageStyle}}>
                {message}
            </div>

            { loading ? 'Loading...' : <div className='table-container'>
                <Table hover>
                    <thead>
                        <tr>
                            <th> # </th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>User Type</th>
                            <th>Joined On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            displayUsers.map((user, index) => {
                            return (
                                <tr 
                                    className='admin-page__tr'
                                    onClick={() => {
                                        window.open(`https://ecosystem.naya.studio/user/${user._id}`, '_blank', 'noopener,noreferrer')
                                }}>
                                    <td> {index} </td>
                                    <td> {formatName(user)} </td>
                                    <td> {user.email} </td>
                                    <td> {user.user_type} </td>
                                    <td> {user.createdAt} </td>
                                </tr>
                            )})
                        }
                    </tbody>
                </Table>
            </div> }
        </div>
    )
}
