import { stringify } from 'query-string'
import questionCheatSheet from './questionCheatSheet'

const ecosystemTypes = [
  'DESIGNER',
  'MAKER',
  'DESIGNER_MAKER'
]

/**
 * Please refer to './questionCheatSheet.json' to find question id
 * @param {object[]} questions - i.e. user.questions, only ecosystem users will have questions property
 * @param {string} queryId, i.e. 'mcq5', 'mcq8', 'projects', 'production-scale', 'work-experience'
 * @returns {string | string[] | number} returns the answer the user has selected or provided
 * @returns {null} - returns null if queryId does not exist in questions
 */
const getAnswers = (questions, queryId) => {
  if (!questions || !queryId || questions.length < 1)
    return null

  //finds specific question in array
  let question

  if (queryId.match(/^projects/)) {

    //check for uploads question
    const projects = questions.filter(q => q.type === queryId.trim())
    if (projects.length > 0) {

      //check for images in uploads question
      const images = projects.map(question => question.images)?.flat()
      if (images?.length > 0) {
        //required for switch statement
        question= {
          images,
          type: 'projects'
        }
      }
    }

  } else {
    question = questions.find(q => q.id === queryId.trim())
  }

  if (!question) {
    return null
  }

  //returns answer
  let answer
  switch (question.type) {
    case 'projects':
      answer = question.images
      break
    case 'production-scale':
      answer = question.production_scale
      break
    case 'work-experience':
      answer = question.work_experience
      break
    case 'additional-info':
      answer = question.additional_info
      break
    case 'production-capacity':
      answer = question.production_capacity
      break
    case 'dimensions':
      answer = question.dimensions
      break
    case 'price-range':
      answer = question.price_range
      break
    case 'mcq':
      answer = question.selected_options
      break
    default:
      break
  }

  return answer
}

/**
 * Checks if ecosystem user's profile is 'Complete', 'Basic', 'Incomplete'
 * @param {object} user - ecosystem user object
 * @returns {string} 'Complete' || 'Basic' || 'Incomplete'
 */
const checkProfileStatus = (user) => {

  if (!user)
    return 'No user provided'

  if (ecosystemTypes.findIndex(type => type === user.user_type) < 0) {
    return `User is not of an ecosystem user_type`
  }

  //pre-filter for checking 'complete' profile
  if (user.email && user.questions.length > 2) {
    const uploadsList = user.questions.filter(question => question.type === 'projects')?.map(question => question.images)
    const images = uploadsList.flat()

    //checks for 2 images in uploads
    if ((images?.length > 0)) {
      const extensions = images.map(image => {
        const split = image.split('.')
        return split[split.length - 1].toLowerCase()
      })

      const properUploads = extensions.filter(ext =>
        [
          "jpg",
          "jpeg",
          "gif",
          "png",
          "svg",
          "raw",
          "webp",
          "tiff",
          "psd",
          "bmp",
          "heif",
          'pdf',
          'zip'
        ].includes(ext)
      )

      const pdfPresent = extensions.find(ext => ext.includes('pdf'))
      const zipPresent = extensions.find(ext => ext.includes('zip'))

      //at least uploaded 2 images of work
      if (properUploads.length > 1 || pdfPresent || zipPresent) {
        const { questions, user_type } = user

        // console.log(questions)

        //designers criteria
        if (
          user_type === 'DESIGNER' &&
          questions.find(question => question.id === 'mcq11')?.selected_options?.length > 0 //what design programs can you use?
          && questions.find(question => question.id === 'mcq12')?.selected_options?.length > 0 //what fabrication / manufacturing processes can you design for?
        )
          return 'Complete'

        //makers criteria
        if (
          user_type === 'MAKER' &&
          questions.find(question => question.id === 'mcq1')?.selected_options?.length > 0 //what materials can you work in your shop?
          && questions.find(question => question.id === 'mcq14')?.selected_options?.length > 0 //what's your shop's average production capacity?
          && questions.find(question => question.id === 'mcq2')?.selected_options?.length > 0 //Where can you deliver your furniture?
        )
          return 'Complete'

        //designer/maker criteria
        if (
          user_type === 'DESIGNER_MAKER' &&

          //maker questions
          questions.find(question => question.id === 'mcq1')?.selected_options?.length > 0 //what materials can you work in your shop?
          && questions.find(question => question.id === 'mcq14')?.selected_options?.length > 0 //what's your shop's average production capacity?
          && questions.find(question => question.id === 'mcq2')?.selected_options?.length > 0 //Where can you deliver your furniture?

          //designer questions
          && questions.find(question => question.id === 'mcq11')?.selected_options?.length > 0 //what design programs can you use?
          && questions.find(question => question.id === 'mcq12')?.selected_options?.length > 0 //what fabrication / manufacturing processes can you design for?
        )
          return 'Complete'
      }
    }
  }

  if (
    user.email
    && user.questions.length > 0
  ) {
    return 'Basic'
  }

  return 'Incomplete'
}

/**
 * 
 * @param {int} ratio - number between 0 and 1
 * @returns {string} hexcolor string
 */
const calculateColorInGradient = (ratio) => {
  const color1 = 'eee7f6'
  const color2 = '7842bc'
  const hex = (color) => {
    const colorString = color.toString(16);
    return colorString.length === 1 ? `0${colorString}` : colorString;
  };

  const r = Math.round(
    parseInt(color2.substring(0, 2), 16) * ratio
    + parseInt(color1.substring(0, 2), 16) * (1 - ratio),
  );
  const g = Math.round(
    parseInt(color2.substring(2, 4), 16) * ratio
    + parseInt(color1.substring(2, 4), 16) * (1 - ratio),
  );
  const b = Math.round(
    parseInt(color2.substring(4, 6), 16) * ratio
    + parseInt(color1.substring(4, 6), 16) * (1 - ratio),
  );

  return hex(r) + hex(g) + hex(b);
};

//specific questions for designer and makers used in profile status check
const statusQuestions = {
  'MAKER': function() { return ['mcq1', 'mcq14', 'mcq2'] },
  'DESIGNER': function() { return ['mcq11', 'mcq12'] },
  'DESIGNER_MAKER': function() { return [...this.MAKER(), ...this.DESIGNER()] }
}

/**
 * 
 * @param {object} user - userObject
 * @returns {[{
 *  type: string,
 *  message: string
 * }]} Array of missing question objects 
 */
const findMissingQuestions = (user) => {
  if (!ecosystemTypes.includes(user.user_type))
    return []

  const { user_type, questions } = user
  const questionList = statusQuestions[user_type]()
  const missingQuestions = []

  const images = getAnswers(questions, 'projects')

  //checks uploads
  if (images?.length > 0) {
    
    const extensions = images.map(image => {
      const split = image.split('.')
      return split[split.length - 1].toLowerCase()
    })

    //checks for image uploads
    const properUploads = extensions.filter(ext =>
      [
        "jpg",
        "jpeg",
        "gif",
        "png",
        "svg",
        "raw",
        "webp",
        "tiff",
        "psd",
        "bmp",
        "heif",
        'pdf',
        "zip"
      ].includes(ext)
    )

    const pdfPresent = extensions.find(ext => ext.includes('pdf'))
    const zipPresent = extensions.find(ext => ext.includes('zip'))

    
    //user does not have at least 2 images and pdf is found
    if (properUploads.length < 2) {
      if (!pdfPresent || !zipPresent)
        missingQuestions.push({
          type: 'projects',
          message: 'Provide at least two images of your work'
        })
    } 

  } else {
    missingQuestions.push({
      type: 'projects',
      message: 'Provide at least two images of your work'
    })
  }

  //find missing questions from question list
  questionList.map((q, i) => {
    const response = getAnswers(questions, q)
    let answer = response
    if (Array.isArray(response)) {
      answer = response.length
    }

    if (!answer) {
      missingQuestions.push({
        type: 'mcq',
        id: q,
        message: questionCheatSheet[q]
      })
    }
  })
  
  return missingQuestions
}


export {
  getAnswers,
  checkProfileStatus,
  calculateColorInGradient,
  findMissingQuestions,
  statusQuestions,
  ecosystemTypes
}

