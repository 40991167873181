let AUTH_REGISTER_API_URL;
let AUTH_USER_PREFS_API_URL;
let API_ENDPOINT;
let UPLOAD_ENDPOINT;
let USER_APP_ENDPOINT;
let DEBUG;
let PROJECT_BACKEND_ENDPOINT;
let ADMIN_BACKEND;
let ECOSYSTEM_APP_ENDPOINT;
let HOME_PAGE_ENDPOINT;
let AUTH_BACKEND_ENDPOINT
let AUTH_FRONTEND_ENDPOINT

if (process.env.REACT_APP_STAGING) {
    AUTH_REGISTER_API_URL = "https://stg-api.naya.studio/auth/register";
    AUTH_USER_PREFS_API_URL = "https://stg-api.naya.studio/api/userprefs";
    API_ENDPOINT = "https://stg-api.naya.studio";
    USER_APP_ENDPOINT = "https://canary.naya.studio";
    UPLOAD_ENDPOINT =
        "https://naya-uploads-staging-ziylmcndpq-ue.a.run.app/upload";
    PROJECT_BACKEND_ENDPOINT =
        "https://naya-project-backend-staging-ziylmcndpq-ue.a.run.app";
    ADMIN_BACKEND = "https://admin-panel-backend-staging-ziylmcndpq-ue.a.run.app";
    ECOSYSTEM_APP_ENDPOINT = "https://stg-eco.naya.studio";
    HOME_PAGE_ENDPOINT = "https://staging.naya.studio";
    AUTH_BACKEND_ENDPOINT = 'https://stg-auth.backend.naya.studio'
    AUTH_FRONTEND_ENDPOINT = 'https://stg-auth.naya.studio'
    DEBUG = true;
} else if (process.env.NODE_ENV === "production") {
    AUTH_REGISTER_API_URL = "https://api.naya.studio/auth/register";
    AUTH_USER_PREFS_API_URL = "https://api.naya.studio/api/userprefs";
    API_ENDPOINT = "https://api.naya.studio";
    USER_APP_ENDPOINT = "http://app.naya.studio";
    UPLOAD_ENDPOINT =
        "https://naya-uploads-production-ziylmcndpq-ue.a.run.app/upload";
    PROJECT_BACKEND_ENDPOINT =
        "https://naya-project-backend-production-ziylmcndpq-ue.a.run.app";
    DEBUG = false;
    ADMIN_BACKEND =
        "https://admin-panel-backend-staging-gp6vcaswya-ue.a.run.app";
    ECOSYSTEM_APP_ENDPOINT ='https://ecosystem.naya.studio';
    HOME_PAGE_ENDPOINT = "https://naya.studio";
    AUTH_BACKEND_ENDPOINT = 'https://auth.backend.naya.studio'
    AUTH_FRONTEND_ENDPOINT = 'https://auth.naya.studio'
} else {
    AUTH_REGISTER_API_URL = "http://localhost:3005/auth/register";
    AUTH_USER_PREFS_API_URL = "http://localhost:3005/api/userprefs";
    API_ENDPOINT = "http://localhost:3005";
    USER_APP_ENDPOINT = "http://localhost:3001";
    UPLOAD_ENDPOINT =
        "https://naya-uploads-staging-ziylmcndpq-ue.a.run.app/upload";
    DEBUG = true;
    PROJECT_BACKEND_ENDPOINT =
        "https://naya-project-backend-development-ziylmcndpq-ue.a.run.app";
    ADMIN_BACKEND = "http://localhost:3006";
    ECOSYSTEM_APP_ENDPOINT = "http://localhost:3003";
    HOME_PAGE_ENDPOINT = "http://localhost:3008";
    AUTH_BACKEND_ENDPOINT = "http://localhost:3015"
    AUTH_FRONTEND_ENDPOINT = "http://localhost:3013"
}

const GOOGLE_MAPS_API_KEY = "AIzaSyBE5iN0SLeAwuVgY5-kwd9l7ByQFWVT8ys";
const ECOSYSTEM_LOGIN_PAGE = `${USER_APP_ENDPOINT}/login-ecosystem`;
const ECOSYSTEM_PROFILE_PAGE = `${ECOSYSTEM_APP_ENDPOINT}/profile`;
const ECOSYSTEM_LOGOUT_PAGE = `${ECOSYSTEM_APP_ENDPOINT}/logout`;
const ECOSYSTEM_SIGNUP_PAGE = `${ECOSYSTEM_APP_ENDPOINT}/signup`;
const ADMIN_PAGE_AUTH = 'SaVi11advek'

export {
    AUTH_USER_PREFS_API_URL,
    AUTH_REGISTER_API_URL,
    API_ENDPOINT,
    USER_APP_ENDPOINT,
    UPLOAD_ENDPOINT,
    DEBUG,
    GOOGLE_MAPS_API_KEY,
    PROJECT_BACKEND_ENDPOINT,
    ADMIN_BACKEND,
    ECOSYSTEM_LOGIN_PAGE,
    ECOSYSTEM_PROFILE_PAGE,
    ECOSYSTEM_LOGOUT_PAGE,
    ECOSYSTEM_SIGNUP_PAGE,
    ADMIN_PAGE_AUTH,
    HOME_PAGE_ENDPOINT,
    AUTH_BACKEND_ENDPOINT,
    AUTH_FRONTEND_ENDPOINT,
    ECOSYSTEM_APP_ENDPOINT
};
