import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import CanvasDraw from "react-canvas-draw";
import qMark from "../../images/qmark.svg";
import { createAnnotation } from "../../actions/annotationActions";

class CanvasTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            className: "canvas-tile-wrapper",
            clientWidth: 100,
            clientHeight: undefined,
        };

        this.replyToData = {
            text: undefined,
            image: undefined,
        };
        this.imgContainer = React.createRef();
    }

    componentDidMount() {
        let classNameAddition = " ";
        switch (this.props.contentType) {
            case "VISUAL_MOODBOARD":
                classNameAddition = classNameAddition + " image-tile";
                break;
            case "UPLOAD":
                classNameAddition = classNameAddition + " image-tile upload";
                break;
            case "SKETCH":
                classNameAddition = classNameAddition + " image-tile sketch";
                break;
            default:
                break;
        }

        if (this.divElement) {
            this.setState({
                className: this.state.className + classNameAddition,
                clientWidth: this.divElement.clientWidth,
            });
        } else {
            this.setState({
                className: this.state.className + classNameAddition,
            });
        }
    }
    renderContent = () => {
        let render;
        switch (this.props.contentType) {
            case "TEXT":
                render = <p>{this.props.text}</p>;
                break;
            case "IMAGE":
                render = <Image src={this.props.image}></Image>;
                break;
            case "ANSWER":
                render = (
                    <Col xs={12}>
                        <h5>
                            {this.props.data.question != null &&
                                this.props.data.question.main}
                        </h5>
                        <Row className="justify-content-md-center">
                            {this.props.data.options.map((option) => (
                                <Col
                                    key={option.text + option.link}
                                    xs={6}
                                    lg={6}
                                >
                                    <Image
                                        src={option.link}
                                        // style={{ filter: "invert(1)" }}
                                        fluid
                                    ></Image>
                                    <p className="option-text">{option.text}</p>
                                </Col>
                            ))}
                            {this.props.data.isOther && (
                                <Col key={this.props.data.other} xs={6} lg={6}>
                                    <Image src={qMark} fluid></Image>
                                    <p className="option-text">
                                        {this.props.data.other}
                                    </p>
                                </Col>
                            )}
                        </Row>
                    </Col>
                );
                this.replyToData["text"] =
                    this.props.data.question != null &&
                    this.props.data.question.main;
                if (this.props.options && !this.props.options.isOther) {
                    this.replyToData["image"] = this.props.data.options[0].link;
                } else {
                    this.replyToData["image"] = qMark;
                }
                this.replyToData["type"] = "TEXT_IMG";
                break;
            case "TEXT_MOODBOARD":
                render = (
                    <Col xs={12}>
                        <h5>Furniture Style</h5>
                        <Row className="justify-content-md-center">
                            {this.props.data.options.map((option) => (
                                <Col key={option} xs={6}>
                                    <p className="option-text">{option}</p>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                );
                this.replyToData["text"] = "Furniture Style Inspirations";
                this.replyToData["type"] = "TEXT";
                break;
            case "VISUAL_MOODBOARD":
                render = (
                    <Col xs={12}>
                        <Image src={this.props.data}></Image>
                    </Col>
                );
                this.replyToData["image"] = this.props.data;
                this.replyToData["type"] = "IMAGE";
                break;

            case "UPLOAD":
                render = (
                    <Col xs={12}>
                        <Image
                            src={this.props.data.link}
                            className="img"
                        ></Image>
                    </Col>
                );
                this.replyToData["image"] = this.props.data.link;
                this.replyToData["type"] = "IMAGE";
                break;
            case "SEARCH":
                render = (
                    <Col xs={12}>
                        <Image src={this.props.data} className="img"></Image>
                    </Col>
                );
                this.replyToData["image"] = this.props.data;
                this.replyToData["type"] = "IMAGE";
                break;
            case "SKETCH":
                render = (
                    <Col
                        xs={12}
                        ref={(divElement) => (this.divElement = divElement)}
                        style={{ minHeight: "100%" }}
                    >
                        {this.props.data.sketchBg && (
                            <img
                                src={this.props.data.sketchBg}
                                ref={this.imgContainer}
                                alt=""
                                onLoad={this.handleImageLoaded}
                                style={{ maxWidth: "100%" }}
                            ></img>
                        )}
                        <CanvasDraw
                            className="canvas-sketch"
                            disabled
                            canvasWidth={200}
                            canvasHeight={200}
                            backgroundColor={"transparent"}
                            ref={(canvasDraw) =>
                                (this.loadableCanvas = canvasDraw)
                            }
                            saveData={this.props.data.sketchData}
                            imgSrc={this.props.data.sketchBg}
                        />
                    </Col>
                );
                this.replyToData["type"] = "SKETCH";
                this.replyToData["image"] = this.props.data.sketchData;
                break;
            case "DIMENSIONS":
                render = (
                    <Col xs={12}>
                        <h5>Dimensions</h5>
                        <p
                            className="col-12"
                            style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                marginBottom: "0rem",
                            }}
                        >
                            Length
                        </p>
                        <p className="col-12" style={{ textAlign: "center" }}>
                            {this.props.data.length / 100}{" "}
                            {this.props.data.units}
                        </p>
                        <p
                            className="col-12"
                            style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                marginBottom: "0rem",
                            }}
                        >
                            Width
                        </p>
                        <p className="col-12" style={{ textAlign: "center" }}>
                            {this.props.data.width / 100}{" "}
                            {this.props.data.units}
                        </p>
                        <p
                            className="col-12"
                            style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                marginBottom: "0rem",
                            }}
                        >
                            Height
                        </p>
                        <p className="col-12" style={{ textAlign: "center" }}>
                            {this.props.data.height / 100}{" "}
                            {this.props.data.units}
                        </p>
                    </Col>
                );
                this.replyToData["text"] = "Dimensions";
                this.replyToData["type"] = "TEXT";
                break;
            default:
                break;
        }
        return render;
    };

    handleImageLoaded = (e) => {
        let height =
            (this.imgContainer.current.clientHeight *
                this.divElement.clientWidth) /
            this.imgContainer.current.clientWidth;
        this.setState({
            imgLoaded: true,
            clientWidth: this.divElement.clientWidth,
            clientHeight: height,
        });
    };

    handleReplyTo = (e) => {
        e.preventDefault();
        this.props.replyToFunction({
            ...this.replyToData,
            refId: this.props.refId,
        });
    };

    handleAnnotate = (e) => {
        e.preventDefault();
        if (this.replyToData.image) {
            this.props.createAnnotation({
                annotationSrc: this.replyToData.image,
            });
        }
        this.props.history.push("/annotate");
    };
    render() {
        console.log(this.props.contentType);
        return (
            <Col xs={12} md={6} lg={4} xl={3} className={this.state.className}>
                <Col xs={12} className="canvas-tile">
                    <Row>{this.renderContent()}</Row>
                </Col>
                <Col xs={12} className="tile-controls">
                    <Row className="justify-content-end">
                        <Col xs={4} lg={4}>
                            <a href="" onClick={this.handleReplyTo}>
                                reply
                            </a>
                        </Col>
                        {this.replyToData.type == "IMAGE" ? (
                            <Col xs={4} lg={4}>
                                <a href="" onClick={this.handleAnnotate}>
                                    annotate
                                </a>
                            </Col>
                        ) : null}
                    </Row>
                </Col>
            </Col>
        );
    }

    componentDidUpdate() {
        if (this.loadableCanvas && this.props.data.sketchData) {
            // console.log(this.props.data)
            this.loadableCanvas.loadSaveData(this.props.data.sketchData);
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createAnnotation: (data) => dispatch(createAnnotation(data)),
    };
};

export default connect(null, mapDispatchToProps)(withRouter(CanvasTile));
