import React, { Component } from 'react'
import {Container, Row, Image, Col, Button} from 'react-bootstrap'

class Progress extends Component{

    render()
    {
        return (
                <Col xs={8} lg={6}>
                    <Col xs={12}>
                    <p className='app-nav'>Steps {this.props.step} out of {this.props.step_max}</p>
                    </Col>
                </Col>
        )
    }
}

export default Progress