const initCanvas = {
    id: "",
    quizAnswers: [],
    moodboards: [],
    dimensions: {},
    uploads: [],
    sketch: {},
    version:''
};

let DEBUG = false;

const canvasReducer = (state = initCanvas, action) => {
    let newState;

    if (DEBUG) console.log("CANVAS REDUCER:");
    if (DEBUG) console.log("ACTION:");
    if (DEBUG) console.log(action);

    if (DEBUG) console.log("CURRENT STATE:");
    if (DEBUG) console.log(state);

    switch (action.type) {
        case "CREATE_CANVAS":
            localStorage.setItem("nayaCanvasID", action.id);
            newState = {
                ...state,
                ...action.canvas,
                id: action.id,
                sketch: {},
            };
            break;
        case "SET_CANVAS":
            newState = {
                ...state,
                [action.id]: {
                    ...action.data,
                    id: action.data._id,
                },
            };
            break;
        default:
            newState = state;
    }

    if (DEBUG) console.log("NEW STATE:");
    if (DEBUG) console.log(newState);

    return newState;
};

export default canvasReducer;
