import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import {InputGroup, FormControl, Alert, Form} from 'react-bootstrap';
import cancel from '../../images/close.png';
import chat_bubble from '../../images/chat-bubble.svg';
import { motion } from "framer-motion";
import uuid from 'react-uuid';
import {updateEstimation} from '../../actions/estimationActions';
import { css } from "@emotion/react";
import SyncLoader from "react-spinners/SyncLoader";
import attach from '../../images/attach.svg';
import {UPLOAD_ENDPOINT} from '../../secrets';
import axios from 'axios';
import video from '../../images/video.svg';
import audio from '../../images/audio.svg';
import document from '../../images/document.svg';
import _ from 'lodash';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
`;

class Estimate extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      add_details: false,
      pricing: 0,
      add_cost: 0,
      timeline: '',
      notes: '',
      additional_costs_obj:[],
      estimation_id: this.props.estimation.id,
      formError: false,
      formErrorMsg: '',
      uploads:[],
      uploading:false,
      type: '',
      estimatedTimeDetails: {},
      canYouStartNow: false,
      whenCanYouStart: '',
      receivedAt: ''
    }
  }

  componentDidMount(){
    this.populateData();
  }

  populateData() {
    this.setState({
      pricing: this.props.estimation.estimatedCost ? this.props.estimation.estimatedCost/100 : 0,
      timeline: this.props.estimation.estimatedTime ? this.props.estimation.estimatedTime : '',
      notes: this.props.estimation.notes,
      additional_costs_obj: this.props.estimation.estimatedCostDetails.length > 0 ? this.setCostDetails(this.props.estimation.estimatedCostDetails) : [
        {label:"materials", value:0},
        {label:"labor", value:0},
        {label:"overhead", value:0},
      ],
      add_details: this.props.estimation.estimatedCostDetails.length > 0 ? true : false,
      uploads: this.props.estimation.uploads ? this.props.estimation.uploads : [],
      type: this.props.estimation.type,
      estimatedTimeDetails: this.props.estimation.estimatedTimeDetails,
      canYouStartNow: this.props.estimation.designStart.canYouStartNow,
      whenCanYouStart: this.props.estimation.designStart.whenCanYouStart ? new Date(this.props.estimation.designStart.whenCanYouStart) : new Date(),
      receivedAt: this.props.estimation.receivedAt
    })
  }

  setCostDetails = (dataToSet) => {
    let dataToChange = dataToSet;
    for(let i = 0; i < dataToChange.length; i++){
      dataToChange[i].value = dataToChange[i].value/100;
    }
    return dataToChange;
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.estimation.id!==prevState.estimation_id){
        return { estimation_id: nextProps.estimation.id};
   }
   return null;
  }

  componentDidUpdate(prevProps, prevState) {
      // console.log(this.state.whenCanYouStart)
      // console.log(this.props.estimation.designStart.whenCanYouStart)
      if(prevState.estimation_id!==this.props.estimation.id){
          this.populateData();
      }
  }

  // Checking if the device is a mobile phone or not to render data accordingly
  isMobile = () => {
    if(window.innerWidth <500){
        return true 
    }
    else{
        return false
    }
  }

  toggleAddDetails = () => {
    if(this.state.add_details === true){
      this.setState({additional_costs_obj:[
        {label:"materials", value:0},
        {label:"labor", value:0},
        {label:"overhead", value:0},
      ]})
    }
    this.setState({add_details: !this.state.add_details});
  }

  toggleAdditionalCost = () => {
    let data = {
      id: uuid(),
      label: 'Cost' + this.state.additional_costs_obj.length.toString(),
      value: 0
    }
    let tempObj = this.state.additional_costs_obj;
    tempObj.push(data);
    this.setState({additional_costs_obj: tempObj})
  }

  onChangeAdditionalCost = (e) => {
    let dataToChange = this.state.additional_costs_obj;
    for(let i = 0; i < dataToChange.length; i++){
      if(dataToChange[i].label === e.target.name){
        dataToChange[i].value = e.target.value;
        this.setState({additional_costs_obj: dataToChange})

      }
    }
  }

  onChangeAdditionalCostLabel = (e) => {
    let dataToChange = this.state.additional_costs_obj;
    for(let i = 0; i < dataToChange.length; i++){
      if(dataToChange[i].label === e.target.name){
        dataToChange[i].label = e.target.value;
        this.setState({additional_costs_obj: dataToChange})

      }
    }
  }

  onChange = (e) => {
    this.setState({formErrorMsg:'', formError: false})
    if(e.target.name === 'pricing') {
      this.setState({[e.target.name]: Math.floor(parseInt(e.target.value))})
    } else if (e.target.name === 'estimatedTimeDetails') {
      this.setState({
        [e.target.name]: {
          ...this.state[e.target.name],
          [e.target.dataset.type]: e.target.value
        }
      })
    }
    else {
      this.setState({[e.target.name]: e.target.value})
    }
  }

  getCostDetails = () => {
    let dataToChange = this.state.additional_costs_obj;
    for(let i = 0; i < dataToChange.length; i++){
      dataToChange[i].value = dataToChange[i].value*100;
    }
    return dataToChange;
  }

  submitEstimate = () => {
    if(this.state.pricing <= 0 || (this.state.type === 'Design' && Object.values(this.state.estimatedTimeDetails).includes(0))) {
      this.setState({formError: true, formErrorMsg:"Please fill all the required fields"})
    }
    else {
      const { notes, estimatedTimeDetails, canYouStartNow, whenCanYouStart, type } = this.state

      const author = this.props.user.user_type === 'ADMIN' ? undefined : this.props.user.id

      let data = {
        estimation_id: this.props.estimation.id,
        estimation: {
          estimatedTime: this.state.timeline,
          estimatedCost:this.state.pricing*100,
          notes,
          type,
          estimatedCostDetails: this.getCostDetails(this.state.additional_costs_obj),
          estimatedTimeDetails,
          designStart: {
            canYouStartNow: !!canYouStartNow,
            whenCanYouStart:  !!canYouStartNow ? '' :whenCanYouStart.toISOString()
          },
          receivedAt: this.state.receivedAt || new Date(),
          lastUpdate: new Date(),
          author: author,
          uploads: this.state.uploads.map((upload) => upload.id)
        }
      }
      this.props.updateEstimation(data);
    }
  }

  handleUpload = (fileData) => {
    const formData = new FormData();
    formData.append("file", fileData, fileData.name);
    this.setState({uploading:true})
    const UPLOAD_FILE_DATA_URI = UPLOAD_ENDPOINT
    axios
      .post(UPLOAD_FILE_DATA_URI, formData, { crossDomain: true })
      .then((response) => {
        this.setState({uploading:false});
        this.setState({uploads:[...this.state.uploads, response.data]})
      })
      .catch((e) => {
          console.log(e);
          this.setState({uploading:false})
      });
  };

  // handling files upload
  handleFilesAdd = (e) => {
    for(let i=0;i<e.target.files.length;i++){
      this.handleUpload(e.target.files[i]);
    }
  };

  removeImage = (link) => {
    let images = this.state.uploads.filter(x=>x!==link);
    this.setState({uploads:images});

  }

  getAttachmentType= (ext) => {
    if(["mp3","wav","wma","aac","m4a","flac",].includes(ext)){
      return "audio";
    }
    else if(["webm","mpg","mp2","mpeg","mpe","mpv","ogg","mp4","m4p","m4v","avi","wmv",].includes(ext)){
      return "video";
    }
    else if(["jpg","jpeg","gif","png","svg","raw","webp","tiff","psd","bmp","heif",].includes(ext)){
      return "image";
    }
    else{
      return "document";
    }
  }

  getAttachmentLink = (upload) => {
    if(!upload){
      return document;
    }
    switch(this.getAttachmentType(upload.ext)){
      case 'audio':
        return audio;
      case 'video':
        return video;
      case 'image':
        return upload.link;
      case 'document':
        return document;
      default:
        break;
    }
  }

  render(){
    const {add_details, additional_costs_obj, type, canYouStartNow, whenCanYouStart } = this.state;
    return(
      <div id="estimate">
        {
          this.isMobile() && (
            <>
            <p className="add-details" onClick={this.props.toggleEstimate} style={{marginBottom:'2rem', textAlign:'left', fontFamily:'rand medium'}}>{"< Back to Details"}</p>
            </>
          )
        }
        {
          this.props.estimation.success && (
            <Alert variant={'success'}>
              {this.props.estimation.successMsg}
            </Alert>
          )
        }
        {
          this.props.estimation.error && (
            <Alert variant={'danger'}>
              {this.props.estimation.errorMsg}
            </Alert>
          )
        }
        {
          this.state.formError && (
            <Alert variant={'danger'}>
              {this.state.formErrorMsg}
            </Alert>
          )
        }
        <h6>quote</h6>
        <h3 style={{marginBottom:'3rem', fontFamily:'rand medium'}}>My Estimate</h3>
        <div id="action-buttons" style={{ justifyContent: this.isMobile() && 'flex-end' }}>
          <div >
            {
              !this.isMobile() && (
                <div className="question" onClick={this.props.toggleComments} style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={chat_bubble} alt="chat-bubble" style={{ width: '2.5rem', marginRight: '0.5rem' }} fluid />
                  <h6 style={{ textTransform: "none", marginBottom: 0, fontFamily: 'rand medium' }}>Have a question?</h6>
                </div>
              )
            }
          </div>
        </div>
        { type === 'Fabrication' ?
          (
            <> 
              {/* Fabrication Pricing */}
              <label>pricing<span style={{ color: 'red' }}>  *</span></label>
              <InputGroup className="mb-3" style={{ marginBottom: "0.5rem!important" }}>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl placeholder="Enter Dollar Amount" type="number" value={this.state.pricing} name="pricing" onChange={this.onChange} min={0} />
              </InputGroup>
              <p className="add-details" onClick={this.toggleAddDetails}>{add_details ? "Remove Details" : "Add Details"}</p>
              {
                add_details && (
                <motion.div 
                  initial={{ opacity: 0, scale: 0.75 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0 }}
                  transition = {{ease: 'easeInOut'}}
                  style={{paddingLeft:'2rem'}}
                >
                    {
                      additional_costs_obj.map((obj, index)=> ( 
                        <motion.div 
                          initial={{ opacity: 0, scale: 0.75 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0 }}
                          transition = {{ease: 'easeInOut'}}
                          key={index}
                        >
                        <input defaultValue={obj.label} placeholder="Cost name" name={obj.label} className="editable-labels"  onChange={this.onChangeAdditionalCostLabel}></input>
                        <InputGroup className="mb-3" style={{marginBottom:"0.5rem!important"}}>
                          <InputGroup.Prepend>
                            <InputGroup.Text>$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl placeholder="Enter Dollar Amount" type="number"  defaultValue={obj.value} name={obj.label} onChange={this.onChangeAdditionalCost} onBlur={this.onChangeAdditionalCost} min={0}/>
                        </InputGroup>
                        </motion.div>
                      ))
                    }
                    <p className="add-details" onClick={this.toggleAdditionalCost}>{"+ Add Additional Cost"}</p>
                </motion.div>
              )
            }
            </>
          ) : (
            <>
              {/* Design Pricing and Estimated Hours*/}
              <label>pricing<span style={{ color: 'red' }}>  *</span></label>
              <InputGroup className="mb-3" style={{ marginBottom: "0.5rem!important" }}>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl placeholder="Enter Dollar Amount" type="number" value={this.state.pricing} name="pricing" onChange={this.onChange} min={0} />
              </InputGroup>
              <label>Estimated Hours</label>
              <motion.div
                initial={{ opacity: 0, scale: 0.75 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
                transition={{ ease: 'easeInOut' }}
                style={{ paddingLeft: '2rem' }}
              >
                {['Sketching', 'Modeling', 'Rendering', 'Drawing'].map((type, index) => (
                    <React.Fragment key={index}>
                    <label>
                      {/* Ternary below required because Backend Schema takes in Drawing and not Drawings */}
                      {type === 'Drawing' ? 'Drawings' : type}
                      <span style={{ color: 'red' }}>  *</span>
                    </label>
                      <InputGroup className="mb-3" style={{ marginBottom: "0.5rem!important" }}>
                        <FormControl placeholder="Enter your estimated hours" type="number" value={this.state.estimatedTimeDetails[type.toLowerCase()]} name="estimatedTimeDetails" data-type={type.toLowerCase()} onChange={this.onChange} min={0} />
                        <InputGroup.Append>
                          <InputGroup.Text> Hours </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </React.Fragment> 
                  ))
                }
              </motion.div>
            </>
          )
        }
        <label className="form-label">Add any relevant files</label>
        <label htmlFor="fileinput" style={{display:'flex', margin:0, cursor:'pointer'}} className="input-group-btn">
          <motion.div className="select-file-container"
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 0.9 }}>
                {
                  this.state.uploading ? <SyncLoader loading={true} css={override} size={10} /> : (
                    <>
                    <img src={attach} alt="attach" style={{width:'1.1rem', marginRight:'0.5rem'}}
                      whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.2 },
                      }}
                      whileTap={{ scale: 0.9 }}></img>
                      <span>SELECT FILES</span>
                      </>
                  )
                }
            
          </motion.div>
          <input  
            id = "fileinput" 
            multiple
            name='relevant'
            className   = "file-input" 
            style={{display:'none'}}
            onChange={this.handleFilesAdd}
            type = "file">
          </input>
        </label>

        <div className="images-container">
          {
            this.state.uploads.map((upload, index)=> {
              if (upload?.link) {
                return (<div className="img-close-icon-container" key={index}>
                    <a href={upload?.link}  target="_blank" rel="noopener noreferrer">
                      <img src={this.getAttachmentLink(upload)} alt="placeholder" className="image" fluid></img>
                    </a>
                    <motion.img src={cancel} alt="cancel" className="cancel" onClick={()=>{this.removeImage(upload)}}
                      whileHover={{
                        scale: 1.1,
                        transition: { duration: 0.2 },
                      }}
                      whileTap={{ scale: 0.9 }}/>
                  </div>
                )
              }
          })
          }
        </div>
        { type === 'Fabrication' ?
          (
            <>
              {/* Fabrication Timeline*/}
              <label className="form-label">timeline</label>
              <InputGroup className="mb-3">
                <FormControl placeholder="Enter approximate time to make" value={this.state.timeline} name="timeline" onChange={this.onChange} />
              </InputGroup>
            </>
          ) : (
            <>
              {/* Design Timeline*/}
              <label className="form-label">Can you start now?</label>
              <Form.Check label='Yes, I can start immediately' type='radio' id='design-start-true' name='canYouStartNow' value={true} checked={!!canYouStartNow} onChange={this.onChange} />
              <Form.Check label="No, I can't start quite yet" type='radio' id='design-start-false' name='canYouStartNow' value={null} checked={!!!canYouStartNow} onChange={this.onChange} />
            </>
          )
        }
        {!canYouStartNow && type === 'Design' && (
          <motion.div
            initial={{ opacity: 0, scale: 0.75 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.25 }}
            transition={{ ease: 'easeInOut' }}
          >
            <label className="form-label">When Can You Start?</label>
            <InputGroup className="mb-3">
              <FormControl placeholder={(whenCanYouStart && new Date(whenCanYouStart).toDateString()) || new Date().toDateString()}  name="whenCanYouStart"/>
            </InputGroup>
            <Calendar 
              value={whenCanYouStart}
              calendarType='US'
              minDate={new Date()}
              tileClassName='calendar-tile'
              onClickDay={value => {
                this.setState({ whenCanYouStart: value })}}
            />
          </motion.div>
        )}
        <label className="form-label">notes</label>
        <InputGroup className="mb-3">
          <FormControl placeholder="Additional info..." as="textarea" rows="5" value={this.state.notes} name="notes" onChange={this.onChange} />
        </InputGroup>
        <div id="action-buttons" style={{}}>
          <motion.button
            whileHover={{
              scale: 1.05,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.9 }} onClick={this.submitEstimate}>
              {this.props.estimation.loading ? <SyncLoader loading={true} css={override} size={10} /> : 'Submit'}
            </motion.button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      user: state.user, 
      estimation: state.estimation
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEstimation: (data) => dispatch(updateEstimation(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Estimate));
