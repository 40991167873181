import React, { Component } from 'react'
import {Container, Row, Image, Col, Button} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

class CancelButton extends Component{

    closeApp = (e) => {
        this.props.history.push("/")
    }
    
    render()
    {

        return (
                <Col xs={3}>
                    <Col xs={12} className="cancel">
                        <p className='app-nav' onClick={this.closeApp}>Exit</p>
                    </Col>
                </Col>
        )
    }
}

export default withRouter(CancelButton)