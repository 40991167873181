import React, { Component } from "react";

import { Container, Row, Col } from "react-bootstrap";

import "../../stylesheets/tag.css";

const TextTag = (props) => {
    if (props.active) {
        return (
            <span
                className="tag active"
                onClick={(e) => props.toggleTag(props.text, props.active)}
            >
                #{props.text}
            </span>
        );
    } else {
        return (
            <span
                className="tag inactive"
                onClick={(e) => props.toggleTag(props.text, props.active)}
            >
                #{props.text}
            </span>
        );
    }
};

class TagsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
        };
    }

    toggleTag = (text, active) => {
        var tags = this.state.tags;
        if (!active) {
            if (!tags.includes(text)) tags.push(text);
        } else {
            if (tags.includes(text)) {
                var newtags = [];
                tags.forEach((tag) => {
                    if (tag !== text) newtags.push(tag);
                });
                tags = newtags;
            }
        }
        this.setState({
            tags: tags,
        });
        this.props.handleSetTags(this.props.name, tags);
    };

    renderSampleTags = () => {
        var render = [];
        this.props.sampleTags.forEach((tag) => {
            render.push(
                <TextTag text={tag} toggleTag={this.toggleTag}></TextTag>
            );
        });
        return render;
    };

    renderTags = () => {
        var render = [];
        this.state.tags.forEach((tag) => {
            render.push(
                <TextTag text={tag} toggleTag={this.toggleTag} active></TextTag>
            );
        });
        return render;
    };

    handleTagInputChange = (e) => {
        const value = e.target.value;
        if (value.indexOf(",") >= 0) {
            var tags = this.state.tags;
            if (!tags) {
                tags = [];
            }
            var inputtags = value.split(",");
            inputtags = inputtags.slice(0, inputtags.length - 1);
            var newtags = [];
            inputtags.forEach((tag) => {
                if (!this.state.tags.includes(tag)) newtags.push(tag);
            });
            tags = tags.concat(newtags);
            e.target.value = "";
            this.setState({
                tags: tags,
            });
            console.log(this.props.name + " - " + tags);
            this.props.handleSetTags(this.props.name, tags);
        }
    };

    render() {
        const samples = this.renderSampleTags();
        const tags = this.renderTags();
        return (
            <Container>
                <Row className="justify-content-md-center">
                    <h3>Some ideas to start with (click to add)</h3>
                </Row>
                <Row className="justify-content-md-center">{samples}</Row>
                {this.state.tags.length > 0 && (
                    <Row
                        className="justify-content-md-center"
                        style={{ marginTop: "30px" }}
                    >
                        <h3>Your choices - </h3>
                    </Row>
                )}
                <Row className="justify-content-md-center">{tags}</Row>
                <Row className="justify-content-md-center">
                    <h3>Enter some tags (seperated by commas)</h3>
                </Row>
                <Row className="justify-content-md-center">
                    <input
                        onChange={this.handleTagInputChange}
                        placeholder="Start typing"
                    />
                </Row>
            </Container>
        );
    }
}

export default TagsList;
