import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios'
import {Modal, Image, Col, Row, Form} from 'react-bootstrap';
import closeIcon from '../../images/icons/cancel_icon.png';
import addIcon from '../../images/icons/add_circle.png';
import ReactDOM from 'react-dom';
import Slider from '@material-ui/core/Slider';
import {addAnswer} from '../../actions/accountActions';
import Options from './Options';
import { API_ENDPOINT } from "../../secrets";

import { config, getEmailAndId, getConfigEmail } from '../../services/cookieHelpers'

const credentials = getEmailAndId()
const configEmail = getConfigEmail()

class QuestionModal extends React.Component{
  state = {
    currentQuestionIndex:this.props.questionIndex,
    questionData:this.props.questionData,
  }
  
  // handles continuing to the next question
  handleContinue = () => {
    if(this.state.currentQuestionIndex+1 === this.state.questionData.length){
      this.props.toggleQuestionModal();
    }
    else{
      this.setState({currentQuestionIndex:this.state.currentQuestionIndex+1})
    }
  }

  // handles going back to the previous question
  handleBack = () => {
    if(this.state.currentQuestionIndex-1 < 0 ){
      this.props.toggleQuestionModal();
    }
    else{
      this.setState({currentQuestionIndex:this.state.currentQuestionIndex-1})
    }
  }

  addAnswer = (data) => 
  {
    
          const ADD_ANSWER_URI = `${API_ENDPOINT}/users/answer`

          axios.post(ADD_ANSWER_URI, { ...data, ...credentials }, config)
          .then(response => response.data).then(response => {
              console.log(response)
              if(response.status)
              {
                  console.log("RESPONSE:", response.user)
                  this.props.refreshUser(this.props.user._id)
                  // dispatch({type: "REFRESH_USER", id: response.user._id, user: response.user})
              }
              else
              {
                  //Handle Error
                  console.log("Error")              
                }
          }).catch(e => {
              //Handle Error
              console.log(e)
          })

  }

  render(){
    return(
      <Options isVisible={this.props.isVisible} 
              questionData={this.state.questionData[this.state.currentQuestionIndex]} 
              handleContinue={this.handleContinue} 
              handleBack={this.handleBack} 
              toggleQuestionModal={this.props.toggleQuestionModal}
              addAnswer={this.addAnswer}
              updateUser={this.props.updateUser}
              user={this.props.user}
              questions={this.props.questions}/>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      // user: state.user,
      // questions:state.user.questions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // addAnswer: (data) => dispatch(addAnswer(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(QuestionModal));