import React, { useEffect, useState, useRef } from 'react'
import './ProviderAgreement.scss'

const ProviderAgreement = ({
    tos,
    onChangeTOS,
    bottom,
    setBottom = () => false
  }) => {
  
  const containerRef = useRef(null)
  const timeout = useRef(null)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      clearTimeout(timeout.current)
    }
  }, [])

  const handleScroll = (e) => {
    if (bottom)
      return

    if (timeout.current !== null)
      clearTimeout(timeout.current)
    

    //checks if user has scrolled to bottom of div
    //the '+ 200' is used to buffer the extra white space at the bottom
    timeout.current = setTimeout(() => { 
      const bottomOfDiv = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 200

      if (bottomOfDiv)
        setBottom(true)
    //timeout of 200ms is used for performance
    }, 200)
    
  }

  return (
    <div class="c17 c38 provider-agreement" ref={containerRef} onScroll={handleScroll}>
      <p class="c21 c42"><span class="c9 c7 c11 c6">Provider Agreement</span></p>
      <p class="c8 c27"><span class="c9 c7 c11 c6"></span></p>
      <p class="c24"><span class="c2">Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The date in which you have
      clicked through to accept the terms of this Agreement</span></p>
      <p class="c27"><span class="c5">Between:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a
        id="id.30j0zll"></a><a id="id.gjdgxs"></a><span class="c5">Naya Studio, Inc. (</span><span
          class="c9">&ldquo;Naya&rdquo;</span><span class="c2">) </span></p>
      <p class="c27"><span
        class="c2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </p>
      <p class="c27"><span
        class="c2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;and</span>
      </p>
      <p class="c27"><span
        class="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;you
      (</span><span class="c9">&ldquo;Provider&rdquo; or &ldquo;You&rdquo;)</span></p>
      <p class="c27"><span
        class="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </p>
      <p class="c10"><span class="c5 c6">The terms and conditions of this Provider Agreement (the &ldquo;</span><span
        class="c9 c6">Agreement</span><span class="c5 c6">&rdquo;), together with Naya&rsquo;s Terms of Service located at
      [</span><a class="c5 " target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vR0FBgEXprCXloWii0Y3q7zvj27kHpxe9y-y8XriEXE5YCaAyk-EdBTn_ARLVXQww/pub">LINK</a><span class="c5 c6">] shall govern your use of Naya&rsquo;s online
      platform (the &ldquo;</span><span class="c9 c6">Platform</span><span class="c5 c6">&rdquo;) and all the services
        to be provided by Provider to Naya, whether for the further benefit of clients and customers of Naya or otherwise,
      and Naya to Provider. The Platform shall consist of its home page located at </span><span
          class="c9 c6">&ldquo;https://www.naya.studio,&rdquo;</span><span class="c2">&nbsp;as well as any URL that is
      linked or navigable to from the home page. </span></p>
      <p class="c10 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0 start" start="1">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Purpose of Naya</span></li>
      </ol>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c5 c6">Naya has created an online platform for matching you with individuals or businesses
      (&ldquo;</span><span class="c9 c6">Client</span><span class="c5 c6">&rdquo;)</span><span
          class="c9 c6">&nbsp;</span><span class="c5 c6">with design, consulting or fabricating expertise to bring your
      product design concepts and ideas to life (each, a &ldquo;</span><span class="c9 c6">Provider</span><span
          class="c5 c6">&rdquo; and collectively, the &ldquo;</span><span class="c9 c6">Providers</span><span
            class="c5 c6">&rdquo;). &nbsp;By using the Platform, you agree and acknowledge that Naya may connect you with
          Clients for purposes of fulfilling the requests of such Clients. &nbsp;Providers and Clients shall collectively be
      referred to herein as &ldquo;</span><span class="c9 c6">Users</span><span class="c5 c6">&rdquo;. &nbsp;On and
      through the Platform, Client may post request(s) for custom product design services (&ldquo;</span><span
          class="c9 c6">Project Requests</span><span class="c5 c6">&rdquo; and such deliverables provided in support
      thereof, &ldquo;</span><span class="c6 c9">Projects</span><span class="c5 c6">&rdquo;) and/or product
      manufacturing services (&ldquo;</span><span class="c9 c6">Maker Project Requests</span><span class="c5 c6">&rdquo;
      and such deliverables provided in support thereof, &ldquo;</span><span class="c9 c6">Maker Projects</span><span
          class="c2">&rdquo;). </span></p>
      <p class="c10 c8"><span class="c5 c13 c11 c6"></span></p>
      <p class="c10"><span class="c5 c13 c11 c6">Please check field applicable to your service provided on the
      Platform:</span></p>
      <p class="c10 c8"><span class="c5 c13 c11 c6"></span></p>
      <p class="c10"><span class="c9 c13 c11 c6">Phase 1: Project Requests: Design &amp; Consulting</span></p>
      <p class="c10 c8"><span class="c9 c11 c6 c13"></span></p>
      <p class="c10"><span class="c2"></span></p>
      <p class="c10 c8"><span class="c9 c13 c11 c6"></span></p>
      <p class="c0" id="h.1fob9te"><span class="c5 c6">Naya may match individuals with specific expertise, including,
      without limitation, professionals with design or other related consulting experience (&ldquo;</span><span
          class="c9 c6">Designers</span><span class="c5 c6">&rdquo; and &ldquo;</span><span
            class="c9 c6">Consultants</span><span class="c5 c6">,&rdquo; as applicable) with a Client to assist with such
      Client&rsquo;s particular Project Request and Project (&ldquo;</span><span class="c9 c6">Project
      Assignment</span><span class="c5 c6">&rdquo;). &nbsp;Project Assignments may include design and consulting
        projects requiring expertise in product design, 3D modeling, renderings, engineering, technical design,
        sustainability consulting, sub trades and the like. Designers and Consultants, as applicable, will be notified of
        Project Assignments via a prompt on the Platform that will allow Designer or Consultant to either accept or reject
        the Project Assignment. If Designer or Consultant accepts the Project Assignment, Designer or Consultant will be
        required under this Agreement to provide guidance to the Client for purposes of consulting support in respect of
      the Project (a &ldquo;</span><span class="c9 c6">Consultation</span><span class="c5 c6">&rdquo;). Upon notifying a
        Designer or Consultant of a Project Assignment, Naya shall provide a price estimate based on scope of work and
      historical pricing (the &ldquo;</span><span class="c9 c6">Naya Estimate</span><span class="c5 c6">&rdquo;).
        &nbsp;To accept the Project Assignment, Designer or Consultant, as applicable, shall sign this Agreement and
        either accept the Naya Estimate or alternatively provide a comprehensive price quote for delivery of such
      services, as may be determined by Naya in its sole discretion (such quote, the &ldquo;</span><span
          class="c9 c6">Project Quote</span><span class="c2">&rdquo;). &nbsp;Upon the Client&rsquo;s acceptance of such
          Project Quote, any and all obligations of Designer or Consultant hereunder, as applicable, shall be binding on
          such Provider. &nbsp;The Consultation and Project Quote shall together constitute the Project and shall be
          delivered to Naya for further distribution to Client for review and payment. In consideration of delivering these
          services to Client, Client shall have agreed to make payments to Naya for further distribution to Consultant or
          Designer, as applicable, in accordance with the terms of this Agreement. &nbsp;Notwithstanding the foregoing, upon
          the approval of Naya and Client, Provider may have the opportunity to adjust the pricing set forth in their
          Project Quote based on additional design or consulting requests by Client; provided, that any such additional
          payments must be completed via the Platform. &nbsp;Any price adjustments shall be based on Naya&rsquo;s pricing
          schedule for such additional requests, which shall be distributed by Naya to Client and Provider for payment in
      connection therewith.</span></p>
      <p class="c10 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">For detail regarding payments due to Consultant and Designer for services rendered, see
      &ldquo;Section 7 &ndash; Payment and Fees.&rdquo;</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c10"><span class="c9 c13 c11 c6">Phase 2: Maker Project Requests: Fabrication &nbsp;</span></p>
      <p class="c10 c8"><span class="c2"></span></p>
      <p class="c10"><span class="c2"></span></p>
      <p class="c10 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c5 c6">Naya may match Makers with a Project, to assist with creating and assembling the
      Project (&ldquo;</span><span class="c9 c6">Maker</span><span class="c5 c6">&nbsp;</span><span
          class="c9 c6">Project Assignment</span><span class="c5 c6">&rdquo;). Maker will be notified of Maker Project
          Assignments via a prompt on the Platform that will allow Maker to either accept or reject the Maker Project
          Assignment. If Maker accepts the Maker Project Assignment, Maker will be required under this Agreement to provide
          guidance to the Client, Designer and Consultant, as applicable, to ensure that the product can be constructed by
          Maker. This guidance may include, without limitation, insight and expertise with respect to materials, colors,
          finishes, design changes, structural changes, questions for Client and Designer or Consultant, as applicable, and
      any other information required for manufacturing the product (together, these services are &ldquo;</span><span
          class="c9 c6">Maker Consultation</span><span class="c5 c6">&rdquo;). To accept the Maker Project Assignment, Maker
          shall sign this Agreement and either accept the Naya Estimate or alternatively provide a comprehensive price quote
      for delivery of such fabrication services (such quote, the &ldquo;</span><span class="c9 c6">Manufacturing
      Quote</span><span class="c2">&rdquo;). &nbsp;Upon receiving and confirming the Manufacturing Quote, Naya shall
        provide Client with a total project cost and give Client the right to review and approve or reject Maker&rsquo;s
        bid to fabricate the Maker Project. &nbsp;If Client accepts the project specifications and total project cost
        delivered by Naya and Naya awards Maker the Maker Project Assignment, Maker will be obligated under this Agreement
        to construct and deliver the finalized Maker Project to the Client. Only Naya in its discretion may release Maker
        from this obligation. In consideration of delivering these services to Client, Client shall have agreed to make
        payments to Naya for further distribution to Maker in accordance with the terms of this Agreement.
        &nbsp;Notwithstanding the foregoing, upon the approval of Naya and Client, Maker may have the opportunity to
        adjust the pricing set forth in their Manufacturing Quote based on final construction details or revisions or
        amendments to the Maker Project Assignment; provided, that such additional payments must be completed via the
        Platform. &nbsp;Any price adjustments shall be based on Naya&rsquo;s pricing schedule for such additional
      requests, which shall be distributed by Naya to Client and Provider for payment in connection therewith.</span>
      </p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Additionally, Naya may ask Maker to manufacture products associated with Projects that
      another User provided to Naya in connection with separate Maker Consultations and Manufacturing Quotes. In this
      situation, Maker will have the option to accept or reject the Maker Project Assignment via a prompt on the
      Platform. Maker will be legally obligated under this Agreement to create and deliver the finalized Maker Project
      to the Client only if Maker accepts. In consideration for creating and delivering the Maker Project, Naya will pay
      Maker an amount equivalent to the Manufacturing Quote in accordance with the foregoing payment procedures and as
      further set forth in Section 7 hereof. &nbsp;</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">For detail regarding payments due to Maker for services rendered, see &ldquo;Section 7
      &ndash; Payment and Fees.&rdquo;</span></p>
      <p class="c10 c8"><span class="c2"></span></p><a id="id.3znysh7"></a>
      <ol class="c22 lst-kix_list_2-0" start="2">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Eligibility</span><span class="c5 c6">&nbsp;</span><span
          class="c9 c6">to use the Platform; Insurance</span></li>
      </ol>
      <p class="c18 c8 c17"><span class="c2"></span></p>
      <p class="c0"><span class="c5 c6">The Platform is available only to legal entities or persons who are at least
      eighteen (18) years old and are otherwise capable of forming legally binding contracts under applicable law. If
      you are a person who agrees to this Agreement on behalf of a company or other legal entity, the terms
      &ldquo;Provider,&rdquo; &ldquo;you,&rdquo; and &ldquo;your&rdquo; refer both to you personally and the entity on
      whose behalf you agree. Furthermore, you personally represent and warrant that you have the authority to bind that
      entity to this Agreement. &nbsp;In addition, each Maker shall </span><span class="c5">at its own cost, procure,
        maintain, and keep in full force and effect insurance to protect Maker and Naya in accordance with good industry
        practice from all claims that arise out of or result from Maker&rsquo;s performance under this Agreement. On
        request from Naya, the Maker shall provide evidence, satisfactory to the Company, of the existence or, as the case
        may be, the renewal of the insurance. &nbsp;You hereby agree and acknowledge that you shall comply in all respects
        with Naya&rsquo;s guidelines on Client contact, Project and/or Maker Project deliverables and use and continued
      use of the Platform, in accordance with the terms set forth at [<a href="https://docs.google.com/document/d/e/2PACX-1vR0FBgEXprCXloWii0Y3q7zvj27kHpxe9y-y8XriEXE5YCaAyk-EdBTn_ARLVXQww/pub" target="_blank">LINK</a>].</span></p>
      <p class="c8 c10"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="3">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Role</span></li>
      </ol>
      <p class="c10 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">You agree that when you accept a Project Assignment or Maker Project Assignment on the
      Platform, you are agreeing and intending to be legally obligated to Naya to provide a finalized Project and/or
      Maker Project consistent with the Project Request and/or finalized Maker Project consistent with the Maker Project
      Assignment, as applicable.</span></p>
      <p class="c8 c17 c18"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Provider acknowledges, agrees and understands that: (1) no User, including you, is an
      employee, agent, or representative of Naya; (2) Naya is not an employee, agent, or representative of any User,
      including you; (3) Naya does not, in any way, supervise, direct, or control any User&rsquo;s work; provided, that
      Naya reserves the right to monitor progress and quality of any Project Assignment or Maker Project Assignment; (4)
      Naya shall not have any liability or obligations for any acts or omissions by any User; (5) Naya has no control
      over Users; and (6) Naya makes no representations as to (a) the reliability, capability, honesty, qualifications,
      or other characteristics of any User or any User&rsquo;s statements or conduct; or (b) the quality, safety, or
      legality of any Project or Maker Project.</span></p>
      <p class="c10 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="4">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Restrictions on Use</span></li>
      </ol>
      <p class="c10 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">You may not:</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_1-0 start" start="1">
        <li class="c16 li-bullet-0"><span class="c2">post any material on the Platform in violation of another&rsquo;s
        intellectual property rights; or</span></li>
      </ol>
      <p class="c18 c8 c28"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_1-0" start="2">
        <li class="c16 li-bullet-0"><span class="c2">fail to comply with all applicable laws that relate to your use of or
        activities on or in relation to the Platform including but not limited to United States export
        restrictions.</span></li>
      </ol>
      <p class="c10 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="5">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Communications with Users</span></li>
      </ol>
      <p class="c10 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">All written communications between Provider and other Users relating to Projects and/or
      Maker Projects must be conducted through the Platform or communication devices designated by Naya in writing. All
      such communications may not, without Naya&rsquo;s prior written approval, contain any information regarding
      User&rsquo;s contact information, pricing of Project Assignments, Maker Project Assignments or related services,
      or requests to circumvent the Platform or the communication devices designated by Naya in writing.
      &nbsp;Notwithstanding the foregoing, Naya reserves the right to remove any comments from the Provider in its sole
      discretion.</span></p>
      <p class="c10 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Provider agrees to respond within 2 business days to any communications or requests
      made by other Users through the Platform regarding any Projects and/or Maker Projects which the Provider has
      agreed to provide (e.g., if a User contacts you on Friday, you must respond by the next Tuesday).</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">All communications hereunder shall be subject to Section 18 and the penalties expressly
      described therein. &nbsp;For the avoidance of doubt, no Provider or other User shall communicate on the Platform
      or otherwise to indirectly or directly disintermediate Naya or otherwise poach or solicit such Provider or User,
      as applicable, to perform or purchase services outside of this Agreement. &nbsp; &nbsp;</span></p>
      <p class="c10 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="6">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Accepting Project Assignments and Maker Project
        Assignments</span></li>
      </ol>
      <p class="c0 c8"><span class="c9 c7 c6 c11"></span></p>
      <p class="c12"><span class="c2">By accepting a Project Assignment or Maker Project Assignment, as applicable, you are
      warranting in good faith that you are and will be capable of accurately providing the services in a good
      workmanlike manner as outlined in the Project Request or Maker Project Request, as applicable.</span></p>
      <p class="c10 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="7">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Payment</span></li>
      </ol>
      <p class="c10 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c5 c6">Provider agrees that all payments due to you from Naya are to be received and
      accepted only through a payment mechanism provided or approved by Naya (an &ldquo;</span><span
          class="c9 c6">Approved Payment Mechanism</span><span class="c2">&rdquo;). &nbsp;</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Your payment shall be paid to you upon our receipt from the Client and your completion
      of the Project or Maker Project, as applicable, or on such other terms agreed to by you and Naya. &nbsp;Your
      payment shall be in an amount equal to the Project Quote or Manufacturing Quote, as applicable. &nbsp;All fees
      payable to each Provider shall be determined in respect of each Project and/or Maker Project and shall not be
      changed, modified or amended without the express written consent of Naya.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Unless otherwise approved in advance by Naya, all payments due to Designers or
      Consultants shall be due and paid to such Provider upon the completion of the Project. &nbsp;Provider agrees,
      acknowledges and understands that Naya charges Clients an amount in addition to the Project Quote or Manufacturing
      Quote, as applicable, payable to Naya for Platform processing and service fees.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Notwithstanding the foregoing, any additional revisions or amendments to any Project
      Quote or Manufacturing Quote shall be due and payable to Provider upon the completion of such additional services
      provided to Client or on terms otherwise agreed to by Naya. &nbsp;</span></p>
      <p class="c0 c8"><span class="c9 c13 c11 c6"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="8">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Release</span></li>
      </ol>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c5 c6">Provider agrees that by entering into this Agreement, Provider, for Provider and
      Provider&rsquo;s predecessors, successors, and assigns (together, the &ldquo;</span><span class="c9 c6">Provider
      Releasors</span><span class="c5 c6">&rdquo;), releases and forever discharges Naya and its predecessors,
        successors, assigns, agents, officers, directors, employees, subsidiaries, parents, affiliates, attorneys,
      contractors, and suppliers (together, the &ldquo;</span><span class="c9 c6">Naya Releasees</span><span
          class="c2">&rdquo;) from and against all actions, causes of action, claims, suits, debts, damages, judgments,
          liabilities, rights, contracts, obligations, and demands, whether now known or unknown, liquidated or
          unliquidated, that any of the Provider Releasors has, may have, has asserted, or could have asserted, of any
          nature and relating to any subject, excluding the right of Provider to enforce this Agreement according to its
      terms.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">In addition, without limiting the generality of the foregoing, Provider, for the
      Provider Releasors, specifically releases and forever discharges the Naya Releasees from and against all actions,
      causes of action, claims, suits, debts, damages, judgments, liabilities, rights, contracts, obligations, and
      demands, whether now known or unknown, liquidated or unliquidated, that any of the Provider Releasors has, may
      have, has asserted, or could have asserted, of any nature arising out of or in any way connected with any disputes
      Provider may have with any other User of the Platform.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="9">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Provider Conduct and Content</span></li>
      </ol>
      <p class="c18 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c5 c6">Provider agrees to be solely responsible for Provider&rsquo;s conduct and activities
      on and regarding the Platform and any and all data, text, information, usernames, graphics, images, photographs,
      designs, renderings, fabrications, profiles, audio, video, items, and links that Provider submits, posts, or
      displays on or in connection with the Platform (together, &ldquo;</span><span class="c9 c6">Content</span><span
          class="c2">&rdquo;). </span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Provider will not infringe upon or misappropriate any person&rsquo;s or entity&rsquo;s
      copyright, patent, trademark, trade secret or other proprietary or intellectual property rights or rights of
      publicity or privacy.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="10">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Intellectual Property Ownership</span></li>
      </ol>
      <p class="c10 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c5 c17">You hereby agree, acknowledge, represent, warrant and grant to Client all ownership
      rights, </span><span class="c5 c6">copyright, trademark, invention rights, other intellectual property, publicity,
        database rights, and any other legal rights of any kind that Provider has in the Content to Client upon completing
        your particular project assignment; provided, that Client shall have in certain cases explicitly granted to Naya a
        fully paid-up, irrevocable, worldwide, royalty-free, exclusive right and license to use such intellectual property
      and related rights for a period of ten (10) years.</span><span class="c2 c17">&nbsp;You represent and warrant that
      your deliverables do not infringe the intellectual property or proprietary rights of any third party.</span></p>
      <p class="c0 c8"><span class="c5 c7 c11 c17 c36"></span></p>
      <p class="c0" id="h.2et92p0"><span class="c2">Naya hereby grants and shall cause Client to grant to Provider a
      limited, non-exclusive license to use such Content otherwise assigned, conveyed and transferred to Client only for
      the purposes of (i) creating and delivering a Project and/or Maker Project, and (ii) displaying and showcasing
      such Project and/or Maker Project in such Provider&rsquo;s portfolio; provided that the completion and delivery of
      such work is attributed to Naya. Once the Project and/or Maker Project or any revision thereto is delivered, any
      such license of use will terminate.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="11">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Warranty Disclaimer</span></li>
      </ol>
      <p class="c18 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Naya makes no express or implied representations or warranties with regard to
      Naya&rsquo;s services, work products, Naya resources, the Platform, any activities or items related to this
      Agreement, or business conducted, or purchases or sales made with the assistance of Naya, all of which are
      provided &ldquo;as is&rdquo; and made at each User&rsquo;s own risk. To the maximum extent permitted by law, Naya
      disclaims all express or implied conditions, representations and warranties including, but not limited to, the
      warranties of merchantability, fitness for a particular purpose, and non-infringement. In addition, Provider
      agrees that no advice or information (oral or written) obtained by Users from Naya shall create any warranty of
      any kind not expressly made herein.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Naya makes no representations or warranties of any kind concerning Project Requests or
      Maker Project Requests, including but not limited to representations or warranties concerning the safety of
      Projects and/or Maker Projects, or Users&rsquo; capabilities, honesty, reliability, trustworthiness, or abilities
      to pay. </span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Without limiting the generality of the foregoing, Naya does not warrant or represent
      that your use of the Platform or interactions with other Users, or Project Requests and/or Maker Project Requests
      will be safe. You understand and agree that Naya does not assume, and expressly disclaims, any responsibility or
      liability for any damages to, or viruses or other malware that may infect, your equipment or other property on
      account of your access to or use of the Platform. You further understand and agree that Naya does not assume and
      expressly disclaims, any responsibility or liability for any damage or harm that any of the Projects Requests or
      Maker Project Requests causes to any property or person.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Notwithstanding the foregoing, Clients shall have the right to request Product
      warranties from Makers, which shall be determined on a case-by-case basis, and only upon the advance written
      request of Client.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="12">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Limitation of Liability</span></li>
      </ol>
      <p class="c18 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">In no event shall Naya, or its subsidiaries, parents, officers, directors, employees,
      agents, consultants, contractors, or suppliers be liable for any claims made by Client with respect to
      Provider&rsquo;s services or products provided hereunder or any damages whatsoever, whether direct, indirect,
      general, special, compensatory, consequential, or incidental, arising out of or relating to Naya&#39;s services,
      your use of Naya&rsquo;s services or the Platform, the conduct of any User (whether tortious or otherwise) in
      connection with the use of the Platform by you or any other User, or this Agreement, including without limitation,
      lost profits, bodily injury, or emotional distress.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Notwithstanding the foregoing, to the extent Naya is ever determined to be liable to
      you, you agree that Naya&#39;s liability, and (as applicable) the liability of Naya&rsquo;s subsidiaries,
      officers, directors, employees, agents, consultants, contractors, or suppliers, shall be limited to the total fees
      Naya actually paid to you as a Provider as a result of any Projects or Maker Projects in the twelve (12) months
      prior to the action giving rise to liability.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">These limitations shall apply to any liability arising from any cause of action
      whatsoever, whether in contract, tort (including negligence), strict liability, or otherwise, even if Naya is
      advised of the possibility of such costs or damages, and even if the limited remedies provided herein fail of
      their essential purpose. In no event shall Naya be liable to you, and you waive any right to seek from Naya,
      consequential, punitive, exemplary or special damages, or damages for loss of use, interruption of business, or
      loss of data or profits.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="13">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Indemnity</span></li>
      </ol>
      <p class="c18 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Provider agrees to indemnify and hold Naya and Naya&rsquo;s parents, subsidiaries,
      affiliates, officers, directors, consultants, suppliers, contractors, agents and employees harmless from any loss,
      expense, and damage, including but not limited to reasonable attorneys&rsquo; fees, arising out of or relating in
      any way to any claim or demand made, asserted, or threatened by any other person or entity and that arises out of
      or relates to Provider&rsquo;s conduct or failure to act, Provider&rsquo;s use of Naya&rsquo;s services,
      Provider&rsquo;s use of the Platform, Client&rsquo;s failure to perform under its certain Client Agreement with
      Naya or failure to make payments in accordance with the terms therein, including but not limited to claims or
      demands relating to Provider&rsquo;s breach of this Agreement, failure to provide a Project or applicable revision
      to Naya in accordance with an accepted Project Assignment, failure to provide Maker Project to a Client in
      accordance with a Maker Project Assignment, violation or alleged violation of Naya&rsquo;s or others&rsquo;
      intellectual property rights, violation or alleged violation of any other rights of another, and violation or
      alleged violation of any law or regulation.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="14">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">No Guarantee</span></li>
      </ol>
      <p class="c18 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Naya may, in its sole discretion, remove, cease operating, change the functionality of,
      or otherwise modify its services and the Platform at any time.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Naya does not guarantee continuous, uninterrupted, ongoing access to the Platform or
      any of the Platform&rsquo;s features, and operation of the Platform may be interfered with or eliminated entirely
      by numerous factors outside Naya&rsquo;s control or at Naya&rsquo;s sole discretion.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="15">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Legal Compliance</span></li>
      </ol>
      <p class="c18 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Provider agrees to comply with all applicable laws when using and in relation to
      Naya&rsquo;s service or the Platform.</span></p><a id="id.tyjcwt"></a>
      <p class="c10 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="16">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">No Agency</span></li>
      </ol>
      <p class="c10 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Provider understands and agrees that Provider and Naya are independent entities, and
      that no agency, partnership, joint venture, employee-employer, or franchiser-franchisee relationship is intended
      or created by this Agreement, or by the operation of the Naya service or the Platform.</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c2">Provider further understands and agrees that Naya is independent from all other Users,
      and that no agency, partnership, joint venture, employee-employer, or franchiser-franchisee relationship is
      intended or created between Naya and any other User by virtue of any other User&rsquo;s Agreements with Naya, or
      by the operation of the Naya service or the Platform.</span></p>
      <p class="c10 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="17">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Term and Termination</span><span class="c5 c6">&nbsp;</span></li>
      </ol>
      <p class="c25 c8"><span class="c4 c7"></span></p>
      <p class="c25"><span class="c30 c5">Termination.</span><span class="c5">&nbsp;Naya may terminate this Agreement at any
      time, in its sole discretion, by providing you notice of termination or by ceasing to operate the Platform.
      Furthermore, Naya may terminate any in-progress Project or Maker Project or revision thereto which you have agreed
      to provide under this Agreement for any negligent behavior, poor quality work, untimely communications with Users,
      unprofessional behavior, or any other reason in the full discretion of Naya, so long as Naya provides notice via
      the Platform before you have delivered the Project or Maker Project to Naya. If terminated, you will not be
      entitled to any payment for work already completed pursuant to that Project or Maker Project or revision
      thereto.</span></p>
      <p class="c25 c8"><span class="c2"></span></p>
      <p class="c25" id="h.3dy6vkm"><span class="c5 c30">Surviving Provisions.</span><span class="c2">&nbsp;In the event
      that this Agreement is terminated, the following Sections shall survive any such termination and remain in effect:
      Section 5 (&ldquo;Communications with Users&rdquo;), Section 7 (&ldquo;Payment&rdquo;), Section 8
      (&ldquo;Release&rdquo;), Section 9 (&ldquo;Provider Conduct and Content&rdquo;), Section 10 (&ldquo;Intellectual
      Property Ownership&rdquo;), Section 11 (&ldquo;Warranty Disclaimer&rdquo;), Section 12 (&ldquo;Limitation of
      Liability&rdquo;); Section 13 (&ldquo;Indemnity&rdquo;); Section 15 (&ldquo;Legal Compliance&rdquo;), Section 16
      (&ldquo;No Agency&rdquo;), Section 17 (&ldquo;Term and Termination&rdquo;), Section 18 (&ldquo;Exclusivity; No
      Disintermediation&rdquo;), Section 20 (&ldquo;Dispute Resolution&quot;) and Section 21 (&quot;Other
      Information&quot;). </span></p>
      <p class="c10 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="18">
        <li class="c0 c19 li-bullet-0"><span class="c9">Exclusivity; No Disintermediation</span></li>
      </ol>
      <p class="c0 c8"><span class="c2"></span></p>
      <p class="c0"><span class="c5">During the term of this Agreement and for a period of ten (10) years after its
      expiration or termination (the &ldquo;</span><span class="c9">Exclusivity Period</span><span class="c5">&rdquo;),
        Provider shall (i) provide all services relating to, or in any way connected with, any project requests received
        via the Platform in accordance with the terms of this Agreement and (ii) not directly or indirectly solicit,
        contact or engage any Clients for purposes of performing services similar to those provided hereunder. &nbsp;Any
        action during the Exclusivity Period by Provider that encourages, facilitates or solicits complete or partial
        payment outside of this Agreement or otherwise solicits for service engagement or any other similar business
        relationship with a Client for services similar to those provided hereunder shall constitute a material violation
      of this Agreement (a &ldquo;</span><span class="c9">Disintermediation Violation</span><span class="c5">&rdquo;).
        &nbsp;The Provider hereby agrees to pay to Naya liquidated damages for any Disintermediation Violation, in an
      amount equal to 50% of all fees paid to Provider in connection therewith.&nbsp; The parties hereto </span><span
          class="c5 c6 c17">further acknowledge that (a) the amount of loss or damages likely to be incurred by Naya for any
          Disintermediation Violation by Provider is impossible or difficult to precisely estimate, (b) the amounts
          specified herein bear a reasonable proportion and are not plainly or grossly disproportionate to the probable loss
          likely to be incurred by Naya, and (c) the parties are sophisticated business parties and have been represented by
          sophisticated legal and financial counsel and negotiated this Agreement at arm&#39;s length.&nbsp; Liquidated
          damages shall be paid by Provider within ten (10) business days of Provider&rsquo;s breach of this Section of the
      Agreement.</span><span class="c2">&nbsp;&nbsp;&nbsp;</span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="19">
        <li class="c0 c19 li-bullet-0"><span class="c9">License to Use Marks</span></li>
      </ol>
      <p class="c0 c8"><span class="c9 c7 c11 c6"></span></p>
      <p class="c0"><span class="c5">Each party hereto </span><span class="c5 c6 c17">grants the other party a limited
      non-exclusive license to use the other party&rsquo;s name, trademarks, service marks, logos and corporate names in
      connection with the performance of each party&rsquo;s obligations under this Agreement, including the right to use
      such party&rsquo;s name over the telephone, the internet, social media and in written materials in connection with
      the performance of services and business relationship described herein. </span></p>
      <p class="c0 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="20">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Dispute</span><span class="c5 c6">&nbsp;</span><span
          class="c9 c6">Resolution</span></li>
      </ol>
      <p class="c25 c8"><span class="c2"></span></p>
      <p class="c34"><span class="c2">This Agreement shall be governed by the laws of the Commonwealth of Massachusetts. If
      any dispute relating to the performance of this Agreement arises, Provider agrees to negotiate in good faith with
      Naya to resolve such dispute prior to pursuit of any legal process. If the parties are unable to so resolve the
      dispute, such dispute shall be resolved by binding arbitration in accordance with the following terms.</span></p>
      <p class="c34 c8"><span class="c2"></span></p>
      <p class="c34"><span class="c5 c6">We are not responsible for any disputes or disagreements between you and any third
      party you interact with using the Service. You assume all risk associated with dealing with third parties. You
      agree to resolve disputes directly with the other party, and Naya, at its option, may use commercially reasonable
      efforts to assist in resolving any such disputes. You release Naya of all claims, demands, and damages in disputes
      among Users of the Platform.</span></p>
      <p class="c3 c8"><span class="c2"></span></p>
      <p class="c3"><span class="c2">ALL DISPUTES CONCERNING OR ARISING OUT OF THIS AGREEMENT, INCLUDING WHETHER A DISPUTE
      IS SUBJECT TO ARBITRATION, SHALL BE RESOLVED BY BINDING ARBITRATION BEFORE A SINGLE ARBITRATOR AND ADMINISTERED BY
      THE AMERICAN ARBITRATION ASSOCIATION (&ldquo;AAA&rdquo;), USING THE THEN-CURRENT APPLICABLE AAA RULES. THE
      LOCATION OF THE ARBITRATION SHALL BE THE AAA OFFICE IN BOSTON, MASSACHUSETTS, OR ANOTHER LOCATION IN BOSTON,
      MASSACHUSETTS CHOSEN BY THE AAA OR THE ARBITRATOR.</span></p>
      <p class="c3"><span class="c2">THE FEDERAL ARBITRATION ACT, AS IN EFFECT AT THE TIME OF ANY ARBITRATION DEMAND, SHALL
      APPLY TO ANY ARBITRATION PURSUANT TO THIS AGREEMENT.</span></p>
      <p class="c3"><span class="c2">THE PARTIES SHALL EACH BEAR THEIR OWN EXPENSES RELATED TO ANY ARBITRATION. THE COSTS OF
      THE ARBITRATION TRIBUNAL, INCLUDING BUT NOT LIMITED THE ARBITRATOR&rsquo;S AND THE AAA&rsquo;S FEES, SHALL BE
      SHARED EQUALLY BETWEEN THE PARTIES, REGARDLESS OF WHICH PARTY PREVAILS. EACH PARTY TO THE ARBITRATION SHALL BEAR
      ITS OWN LEGAL FEES, REGARDLESS OF WHICH PARTY PREVAILS. THE ARBITRATOR SHALL NOT AWARD ANY MULTIPLE OR PUNITIVE
      DAMAGES, REGARDLESS OF WHICH PARTY PREVAILS. NOTWITHSTANDING ANYTHING TO THE CONTRARY PERMITTED BY THE APPLICABLE
      RULES OF THE AAA, ANY ARBITRATION PURSUANT TO THIS AGREEMENT SHALL INVOLVE MAKER AND NAYA ONLY IN THEIR INDIVIDUAL
      CAPACITIES, SHALL NOT BE CONSOLIDATED WITH ANY OTHER ARBITRATIONS, AND SHALL NOT BE ARBITRATED AS A CLASS OR OTHER
      FORM OF REPRESENTATIVE ACTION. MAKER AGREES NOT TO PARTICIPATE IN ANY ARBITRATION RELATED TO OR ARISING FROM THIS
      AGREEMENT AS A CLAIMANT OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION.</span></p>
      <p class="c3"><span class="c2">ANY ACTION TO CONFIRM AN ARBITRATION AWARD PURSUANT TO THIS AGREEMENT SHALL BE BROUGHT
      ONLY IN A COURT OF COMPETENT JURISDICTION WITHIN SUFFOLK COUNTY, MASSACHUSETTS. NAYA AND MAKER AGREE TO SUBMIT TO
      THE JURISDICTION OF ANY SUCH COURT, WAIVING ANY OBJECTION BASED ON PERSONAL JURISDICTION OR VENUE.</span></p>
      <p class="c3"><span class="c2">AT LEAST FORTY-FIVE (45) DAYS PRIOR TO EITHER PARTY INITIATING ANY ARBITRATION PURSUANT
      TO THIS AGREEMENT, THAT PARTY SHALL SEND TO THE OTHER PARTY A WRITTEN DEMAND DESCRIBING THE NATURE OF THE DISPUTE
      AND SETTING FORTH A PROPOSED RESOLUTION. THE PARTIES SHALL THEN ATTEMPT TO NEGOTIATE IN GOOD FAITH TO RESOLVE THE
      DISPUTE.</span></p>
      <p class="c34"><span class="c2">Notwithstanding the foregoing, in the case of a project issue, Naya recommends that
      Providers work directly with Clients to resolve any issues. &nbsp;Naya is under no obligation to provide dispute
      resolution with respect to any such claim and provides this process as a benefit to Providers and Clients. </span>
      </p>
      <p class="c25 c8"><span class="c2"></span></p>
      <ol class="c22 lst-kix_list_2-0" start="21">
        <li class="c0 c19 li-bullet-0"><span class="c9 c6">Other Information</span></li>
      </ol>
      <p class="c8 c23"><span class="c4 c7"></span></p>
      <p class="c33 c40"><span class="c4">Assignment</span><span class="c2">. Neither party may assign this Agreement to any
      third party except upon the other party&rsquo;s prior written consent. Furthermore, Provider may not contract with
      any other party for the completion of any services which Provider has agreed to provide to Naya under this
      Agreement, unless Naya consents to such contract; provided, for the avoidance of doubt, that nothing herein shall
      prohibit Provider from retaining services and procuring goods from subtrade specialists, provided, further, that
      Provider shall in all cases be liable for performance and completion of services hereunder.</span></p>
      <p class="c33 c40 c8"><span class="c4 c7"></span></p>
      <p class="c33 c40"><span class="c4">Entire Agreement</span><span class="c2">. This Agreement, together with
      Naya&rsquo;s Terms of Services and any additional project orders that may be issued to Provider by Naya represents
      the entire understanding and Agreement between Provider and Naya with respect to the subject matter hereof. This
      Agreement supersedes all prior oral and written and all contemporaneous oral negotiations, commitments and
      understandings between Provider and Naya. However, this Agreement can be amended or modified by mutual Agreement
      signed by Provider and Naya. </span></p>
      <p class="c33 c40 c8"><span class="c2"></span></p>
      <p class="c33 c40"><span class="c4">Notices</span><span class="c2">. All notices under this Agreement shall be in
      writing and delivered via mail or email services to the address set forth above. </span></p>
      <p class="c33 c8 c40"><span class="c2"></span></p>
      <p class="c18 c41"><span class="c2">By signing this Agreement, Provider confirms that Provider has carefully read the
      terms and conditions of this Agreement.</span></p>
      <p class="c10 c8"><span class="c2"></span></p><a id="t.44e0be3daa20a6b274524847a639aaf73a4e0edd"></a><a id="t.0"></a>
      <table class="c37">
        <tbody>
          <tr class="c26">
            <td class="c14" colspan="1" rowspan="1">
              <p class="c1"><span class="c9 c7 c11 c6"></span></p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c9 c7 c11 c6"></span></p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c9 c7 c11 c6"></span></p>
            </td>
          </tr>
          <tr class="c26">
            <td class="c14" colspan="1" rowspan="1">
              <p class="c1"><span class="c9 c7 c11 c6"></span></p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c9 c7 c11 c6"></span></p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c9 c7 c11 c6"></span></p>
            </td>
          </tr>
          <tr class="c26">
            <td class="c14" colspan="1" rowspan="1">
              <p class="c1"><span class="c2"></span></p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c2"></span></p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c2"></span></p>
            </td>
          </tr>
          <tr class="c32">
            <td class="c14" colspan="1" rowspan="1">
              <p class="c1"><span class="c2"></span></p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c2 c31"></span></p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c2"></span></p>
            </td>
          </tr>
          <tr class="c32">
            <td class="c14" colspan="1" rowspan="1">
              <p class="c1"><span class="c2 c31"></span></p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c9 c7 c11 c31 c6"></span></p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c2"></span></p>
            </td>
          </tr>
          <tr class="c32">
            <td class="c14" colspan="1" rowspan="1">
              <p class="c1"><span class="c2 c31"></span></p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c9 c7 c11 c31 c6"></span></p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c2"></span></p>
            </td>
          </tr>
          <tr class="c29">
            <td class="c14" colspan="1" rowspan="1">
              <p class="c1"><span class="c2 c31"></span></p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c2"></span></p>
            </td>
            <td class="c20" colspan="1" rowspan="1">
              <p class="c1"><span class="c2"></span></p>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="c8 c33"><span class="c9 c7 c11 c6"></span></p>
      <div>
        <p class="c8 c21"><span class="c7 c11 c6 c39"></span></p>
        <p class="c21 c8"><span class="c7 c11 c6 c35"></span></p>
      </div>
    </div>
  )
}

export default ProviderAgreement