import React, { useEffect, useState } from 'react'
// import logoSrc from '../../images/naya_logo_symbol.png'
import logoSrc from '../../images/pride_logo.svg'
import ProviderAgreement from './ProviderAgreement/ProviderAgreement'
import './SplashAuth.scss'
import { Provider } from 'react-alert'

const AgreementModal = ({
    tos,
    onChangeTOS,
    submit,
    hide
  }) => {
  const [ scrolledToBottom, setScrolledToBottom ] = useState(false)

  return(
    <div id='splash-modal_container' onClick={hide}>
      <div  
        className='splash-modal_body'
        onClick={e => e.stopPropagation()}
      >
        <div name='title'>
          <div className='logo_container'>
            <img src={logoSrc} type='logo' />
            <h2>Naya</h2>
          </div>
          <p>
            Please <b>read through</b>, <b>click the checkboxes,</b> and <b>accept</b> our Provider agreement to continue.
          </p>
        </div>
        <div name='body'>
          <ProviderAgreement
            onChangeTOS={onChangeTOS}
            submit={submit}
            tos={tos}
            bottom={scrolledToBottom}
            setBottom={setScrolledToBottom}
          />
        </div>
        <div name='footer'>
          <div className='checkbox_container'>
            <input type='checkbox' id='designer-checkbox' name='designer' checked={tos.designer} onChange={onChangeTOS} /> <label htmlFor='designer-checkbox'>I agree to the designer terms and conditions</label>
            <br />
            <input type='checkbox' id='maker-checkbox' name='maker' checked={tos.maker} onChange={onChangeTOS} /> <label htmlFor='maker-checkbox'>I agree to the maker terms and conditions</label>
          </div>
          <div className='main-btn_container' >
            <button className='main-btn' type='accept' disabled={!scrolledToBottom || !(tos.designer || tos.maker)} onClick={submit}>
              I accept
            </button>
            <span type='not-accept' onClick={hide}>
              I do not accept
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgreementModal