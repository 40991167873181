import { API_ENDPOINT } from "../secrets";
import axios from "axios";
import path from "path";
import { config, getEmailAndId } from '../services/cookieHelpers'


export const getCanvas = (data) => {
    return (dispatch) => {
        //Create Answer
        const GET_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas`;
        console.log("Data for getCanvas");
        console.log(data);
        if (data === undefined && localStorage.getItem("nayaCanvasID")) {
            data = { id: localStorage.getItem("nayaCanvasID") };
        }

        axios
            .get(GET_CANVAS_DATA_URI, { params: data }, config)
            .then((response) => {
                console.log(response);
                dispatch({
                    type: "SET_CANVAS",
                    data: response.data.canvas,
                    id: data.id,
                });
                dispatch({
                    type: "SET_COMMENTS",
                    comments: response.data.canvas.comments,
                    id: data.id,
                });
                dispatch({
                    type: "SET_APPROVAL_COMMENTS",
                    approval_comments: response.data.canvas.approval_comments,
                    id: data.id,
                });
            })
            .catch((e) => {
                console.log("Error getting canvas");
                console.log(e);
            });

        // dispatch({ type: 'SET_CANVAS', data: {
        //     dimensions: {
        //         length: 300,
        //         width: 300,
        //         height: 300
        //     }
        // }});
    };
};

export const updateCanvas = (data) => {

    return (dispatch) => {
        //Create Answer
        const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas`;

        axios
            .put(UPDATE_CANVAS_DATA_URI, data, config)
            .then((response) => {
                console.log(response);
                dispatch({ type: "ADD_PATHWAY", data });
            })
            .catch((e) => {
                console.log("Error");
            });
    };
};
