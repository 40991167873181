import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { API_ENDPOINT } from "../../secrets";

import "../../stylesheets/completeform.css";
import loading from "../../images/rectLoading.svg";
import done from "../../images/check.svg";

class CompleteForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
        };
    }

    componentDidMount() {
        const data = {
            user_id: this.props.user.id,
            builder_type: this.props.user.user_type,
            builder_data: this.props.form,
            lastAsked: new Date().toISOString(),
            email: true
        };
        if (this.props.mode === "SET")
            axios
                .post(`${API_ENDPOINT}/api/builderprefs`, data)
                .then((response) => {
                    console.log(response.data.builder_prefs);
                    this.setState({
                        isSubmitted: true,
                    });
                    window.setTimeout(() => {
                        this.props.history.push("/account");
                    }, 500);
                })
                .catch((err) => {
                    console.log(err);
                });
        else if (this.props.mode === "UPDATE")
            axios
                .put(
                    `${API_ENDPOINT}/api/builderprefs/${this.props.user.id}`,
                    data,
                    { crossDomain: true }
                )
                .then((response) => {
                    console.log(response.data.builder_prefs);
                    this.setState({
                        isSubmitted: true,
                    });
                    window.setTimeout(() => {
                        this.props.history.push("/account");
                    }, 500);
                })
                .catch((err) => {
                    console.log(err);
                });
    }

    renderProgress = () => {
        return (
            <section>
                Crunching some numbers...
                <img src={loading} alt="" />
            </section>
        );
    };

    renderSubmitted = () => {
        return (
            <section>
                Done!
                <img src={done} alt="" />
            </section>
        );
    };

    render() {
        if (this.state.isSubmitted) {
            return this.renderSubmitted();
        } else {
            return this.renderProgress();
        }
    }
}

/**
 *
 * @param {*} state
 * @param {*} ownProps
 * Maps the Redux state to component props
 */
const mapStateToProps = (state, ownProps) => {
    return {
        form: state.UI.form,
        mode: state.UI.mode,
        user: state.user,
    };
};

/**
 *
 * @param {*} dispatch
 * Maps redux actions to component props
 */
const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CompleteForm));
