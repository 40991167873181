const initAnnotations = {
}

let DEBUG = false
const annotationsReducer = (state = initAnnotations, action) => {
    let newState

    if(DEBUG) console.log("COMMENTS REDUCER:")
    if(DEBUG) console.log("ACTION:")
    if(DEBUG) console.log(action)

    if(DEBUG) console.log("CURRENT STATE:")
    if(DEBUG) console.log(state)

    switch(action.type)
    {
        case "EDIT_ANNOTATION_SUCCESS":
        case "CREATE_ANNOTATION_SUCCESS":
        case "GET_ANNOTATION_SUCCESS":
            localStorage.setItem("currentAnnotation", action.payload._id)
            newState = {...state, [action.payload._id]: action.payload, current: action.payload}
            break
        case "DELETE_ANNOTATION_SUCCESS":
        default:
            newState = state
    }

    if(DEBUG) console.log("NEW STATE:")
    if(DEBUG) console.log(newState)

    return newState
}

export default annotationsReducer