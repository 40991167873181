import axios from 'axios'
import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { isLoggedIn } from '../../../actions/authActions'
import { API_ENDPOINT } from '../../../secrets'
import { config, getEmailAndId, getConfigEmail } from '../../../services/cookieHelpers'

/**
 * Fetch estimation data and filter based on canvas IDs
 * @returns {[ 
 *  data: {
 *    canvasID: string[],
 *    canvas: object[],
 *    estimates: object[]
 *  },
 *  loading: boolean
 * ]} returns [data, loading] where data = { canvas: [], estimations: []}
 */
export const usePopulate = () => {
  const [ data, setData ] = useState({
    canvasID: [],
    canvas: [],
    estimates: []
  })

  /*
   * SET UP CODE
   */

  const [ completed, setCompleted ] = useState(false)
  const userID = useSelector(state => state.user.id)
  const [ credentials, setCredentials ] = useState(null)
  const [ configEmail, setConfigEmail ] = useState(null)

  const GET_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas`
  const GET_ESTIMATIONS_DATA_URI = `${API_ENDPOINT}/api/estimations/?author=${userID}`

  //fetch Estimations and filter by author
  const fetchEstimations = useCallback(async () => {

    let canvasIDs

    try {
      const response = await axios.get(GET_ESTIMATIONS_DATA_URI, configEmail)
      const estimations = response.data

      canvasIDs = estimations.map((estimate) => estimate.canvas)

      setData(prev => ({
        ...prev,
        canvasID: [...canvasIDs],
        estimates: [...estimations]
      }))

    } catch (e) {
      console.error(e)
    } 
  }, [GET_ESTIMATIONS_DATA_URI, configEmail])


  //populate Canvas IDs to display name of canvas ID
  const fetchCanvasName = useCallback(async (canvasIDs) => {

    try {
      const axiosDataArray = canvasIDs.map((id, index) => {
        return axios.get(GET_CANVAS_DATA_URI, { params: { id: id, ...credentials } }, config)
      })

      const fetchedArray = await Promise.all(axiosDataArray)
      const populatedArray = fetchedArray.map(res => res.data.canvas)

      setData((prev) => ({ ...prev, canvas: [...populatedArray] }))

    } catch (e) {
      console.error(e)
    } finally {
      //set complete to true after everything is done loading
      setCompleted(true)
    }
  }, [GET_CANVAS_DATA_URI, credentials])

  /*
   * RUN CODE 
   */

  //this useEffect ensures our credentials are not stale
  useEffect(() => {
    if (isLoggedIn && userID) {
      setCredentials(() => getEmailAndId())
      setConfigEmail(() => getConfigEmail())
    }
  }, [userID])

  //populate Estimations
  useEffect(() => {
    if (userID && (credentials && configEmail)){
      fetchEstimations()
    }

  }, [fetchEstimations, userID, credentials, configEmail])

  //populate Canvases
  useEffect(() => {
    if (userID && (credentials && configEmail)){
      fetchCanvasName(data.canvasID)
    }
  }, [data.canvasID, userID, fetchCanvasName, credentials, configEmail])

  return [data, completed]
}