import React, { Component } from "react";
import { Row, Image, Col } from "react-bootstrap";
import "../../stylesheets/option.css";
import checkmark from "../../images/icons/selected.svg";
import bg1 from "../../images/icons/bg_1.svg";
import bg2 from "../../images/icons/bg_2.svg";
import bg3 from "../../images/icons/bg_3.svg";

// import text_bg from "../../images/text_bg_1.svg";

class Option extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mouseOver: false,
            isSelected: this.props.selected,
            name: this.props.name,
        };
    }
    handleSelect = (e) => {
        e.preventDefault();
        this.setState({
            isSelected: !this.state.isSelected,
        });
        this.props.onClick(
            this.props.name,
            this.props.value,
            !this.state.isSelected
        );
    };

    render() {
        let style = {};
        let bgimg = bg1;
        if (this.state.isSelected) {
            style = { filter: "invert(0)" };
            bgimg = [bg1, bg2, bg3][this.props.text.length % 3];
        }
        if (this.props.link) {
            return (
                <Col
                    xs={{ span: 6, offset: 0 }}
                    sm={{ span: 4, offset: 0 }}
                    md={{ span: 3, offset: 0 }}
                    xl={{ span: 2, offset: 0 }}
                    onClick={this.handleSelect}
                    onMouseOver={() => this.setState({ mouseOver: true })}
                    onMouseLeave={() => this.setState({ mouseOver: false })}
                >
                    <Col xs={12} className="option-wrapper">
                        {this.state.isSelected && (
                            <img
                                src={checkmark}
                                style={{
                                    height: "20px",
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                }}
                                alt={this.props.text}
                            ></img>
                        )}
                        <Row className="justify-content-md-center">
                            <Col xs={12}>
                                <img
                                    alt=""
                                    src={bg1}
                                    style={{ display: "none" }}
                                />
                                <img
                                    alt=""
                                    src={bg2}
                                    style={{ display: "none" }}
                                />
                                <img
                                    alt=""
                                    src={bg3}
                                    style={{ display: "none" }}
                                />
                                {this.state.mouseOver && (
                                    <img
                                        src={bgimg}
                                        style={{
                                            position: "absolute",
                                            top: "-1rem",
                                            left: "0",
                                            opacity: "0.3",
                                            width: "100%",
                                        }}
                                        alt={this.props.text}
                                        fluid
                                    ></img>
                                )}
                                {this.state.isSelected && (
                                    <img
                                        src={bgimg}
                                        style={{
                                            position: "absolute",
                                            top: "-1rem",
                                            left: "0",
                                            width: "100%",
                                        }}
                                        alt={this.props.text}
                                        fluid
                                    ></img>
                                )}
                                <Image
                                    className="option-img"
                                    src={this.props.link}
                                    style={style}
                                    fluid
                                ></Image>
                            </Col>
                            <p className="col-12">{this.props.text}</p>
                        </Row>
                    </Col>
                </Col>
            );
        } else {
            return (
                <Col
                    xs={{ span: 6, offset: 0 }}
                    sm={{ span: 4, offset: 0 }}
                    md={{ span: 3, offset: 0 }}
                    xl={{ span: 2, offset: 0 }}
                    onClick={this.handleSelect}
                    onMouseOver={() => this.setState({ mouseOver: true })}
                    onMouseLeave={() => this.setState({ mouseOver: false })}
                    style={{ cursor: "pointer" }}
                >
                    <Col xs={12} className="option-wrapper">
                        <Row>
                            <Col style={{ padding: "5px 10px" }}>
                                {this.state.mouseOver && (
                                    <img
                                        src={checkmark}
                                        style={{
                                            position: "absolute",
                                            right: "0%",
                                            opacity: "0.3",
                                            height: "20px",
                                            width: "20px",
                                        }}
                                        alt={this.props.text}
                                        fluid
                                    ></img>
                                )}
                                {this.state.isSelected && (
                                    <img
                                        src={checkmark}
                                        style={{
                                            position: "absolute",
                                            right: "0%",
                                            height: "20px",
                                            width: "20px",
                                        }}
                                        alt={this.props.text}
                                        fluid
                                    ></img>
                                )}
                                <p className="col-12">{this.props.text}</p>
                            </Col>
                        </Row>
                    </Col>
                </Col>
            );
        }
    }
}

export default Option;
