import { API_ENDPOINT } from "../secrets";
import axios from "axios";
import { DEBUG } from "../secrets";
import { config, getEmailAndId, getConfigEmail } from '../services/cookieHelpers'

export const createComment = (data) => {
    console.log("in create comment:", data)
    const credentials = getEmailAndId()

    return (dispatch) => {
        //Create Answer
        console.log("in create comment dispatch:", data)
        const CREATE_COMMENT_DATA_URI = `${API_ENDPOINT}/api/comments`;

        axios
            .post(CREATE_COMMENT_DATA_URI, { ...data, ...credentials }, config)
            .then((response) => {
                if (DEBUG) console.log(response);
                console.log(response);
                if (response.data.status) {
                    dispatch({
                        type: "SET_COMMENTS",
                        id: response.data.canvas._id,
                        comments: response.data.canvas.comments,
                    });
                } else {
                    dispatch({
                        type: "ERROR_POST_COMMENT",
                        message: response.data.message,
                    });
                }
            })
            .catch((e) => {
                console.log("dispatch error:", e)
                if (DEBUG) console.log(e);
                dispatch({
                    type: "ERROR_POST_COMMENT",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const getComments = (data) => {
    const configEmail = getConfigEmail()

    return (dispatch) => {
        //Create Answer
        var id = data.id;
        if (!id) {
            id = localStorage.getItem("nayaCanvasID");
        }

        const CREATE_COMMENT_DATA_URI = `${API_ENDPOINT}/api/comments?id=${id}`;

        axios
            .get(CREATE_COMMENT_DATA_URI, configEmail)
            .then((response) => {
                if (DEBUG) console.log(response);
                if (response.data.status) {
                    dispatch({
                        type: "SET_COMMENTS",
                        id: response.data.canvas._id,
                        comments: response.data.comments,
                    });
                } else {
                    dispatch({
                        type: "ERROR_GET_COMMENT",
                        message: response.data.message,
                    });
                }
            })
            .catch((e) => {
                if (DEBUG) console.log(e);
                dispatch({
                    type: "ERROR_GET_COMMENT",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};
