import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import userIcon from '../../images/icons/profile_pic.png';
import add from '../../images/icons/add.png';
import {Image, Modal, Row, Spinner} from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import axios from 'axios';
import {API_ENDPOINT} from '../../secrets';
import {updateUser} from '../../actions/accountActions';

import 'react-image-crop/dist/ReactCrop.css';

class ProfilePic extends React.Component{
  state = {
    src: null,
    crop: {
      unit: '%',
      width: 30,
      aspect: 1 / 1,
    },
    showModal:false,
    croppedImageUrl:null,
    showProfilePic:false,
    uploading:false
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result, showModal:true })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {

    const [croppedImageBlob, croppedImageUrl] =  await this.getCroppedImg(this.imageRef,crop,'newFile.jpeg');
    this.setState({ croppedImageUrl, croppedImageBlob });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
          if (!blob) {
              console.error('Canvas is empty');
              return;
          }
          
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve([blob, this.fileUrl]);
      }, 'image/jpeg');
    });
  }

  handleUpload = (e) => {
    this.setState({loading:true})
    e.preventDefault()
    const formData = new FormData();
    formData.append("file", this.state.croppedImageBlob)
    const UPLOAD_FILE_DATA_URI = `${API_ENDPOINT}/api/upload`
    axios.post(UPLOAD_FILE_DATA_URI, formData, {crossDomain: true})
      .then(response => {
        let data = {
            id: this.props.user.id,
            updateType: "PROFILE_PIC",
            profile_pic: response.data.link
        }
        this.props.updateUser(data)
        this.setState({showModal: false, loading:false})

      }).catch(e => {
        console.log(e)
        this.setState({showModal: false, loading:true})

      }) 
  }

  cropModal = () => {
    const { crop, src } = this.state;
    return(
      <Modal
      size="md"
      show={this.state.showModal}
      onHide={() => this.setState({showModal: false})}
      aria-labelledby="example-modal-sizes-title-sm"
      id="instructions-modal"
    >
      <Modal.Header closeButton style={{borderBottom:'0'}}>
      </Modal.Header>
      <Modal.Body>
      {src && (
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        )}
      </Modal.Body>
      <Modal.Footer style={{borderTop:0, display:'flex', justifyContent:'center'}}>
        <button className="crop-btn" onClick={this.handleUpload}>
          {
            this.state.loading ? (
              <div>
                <Spinner animation="grow" style={{color:'#fff', width:'1rem', height:'1rem'}} />
                <Spinner animation="grow" style={{color:'#fff', width:'1rem', height:'1rem'}} />
                <Spinner animation="grow" style={{color:'#fff', width:'1rem', height:'1rem'}} />
              </div>
            ) : 'Done'
          }
        </button>
      </Modal.Footer>
    </Modal>
    )
  }

  displayProfilePic = () => {
    return(
      <Modal
      size="md"
      show={this.state.showProfilePic}
      onHide={() => this.setState({showProfilePic: false})}
      aria-labelledby="example-modal-sizes-title-sm"
      id="instructions-modal"
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
            <Image src={this.props.user.profile_pic} fluid style={{maxWidth:'100%', width:'auto'}}></Image>
        </Row>
      </Modal.Body>
    </Modal>
    )
  }

  render(){
    return(
      <div style={{flexDirection:'column', display:'flex', width:105}}>
        <div style={{border:'1px solid #a3a3a3', borderRadius:'50%',cursor:'pointer', width:'max-content'}}>
          <Image src={this.props.user.profile_pic ? this.props.user.profile_pic : userIcon} fluid style={{borderRadius:'50%'}} onClick={()=>{this.setState({showProfilePic:true})}}/>
        </div>
        <div className="image-upload">
          <label htmlFor="file-input" style={{display:'flex', justifyContent:'flex-end', marginTop:-30}}>
            <Image src={add} fluid style={{marginLeft:-25, width:35, height:35, marginTop:'auto', cursor:'pointer'}}/>
          </label>
          <input id="file-input" type="file" onChange = {this.onSelectFile} ref= {this.fileInput}/>
        </div>
        {this.displayProfilePic()}
        {this.cropModal()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(updateUser(data))
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePic));