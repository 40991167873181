// Navbar for splash screen
import React, { Component } from "react";
import { Navbar, Nav, Col, Row } from "react-bootstrap";
import '../../../stylesheets/navbar.css';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {getNotifications, readNotification} from '../../../actions/notificationsActions';
import Prompt from '../../prompts/Prompt';
// import logo from '../../../images/naya_logo_symbol.png'
import logo from '../../../images/pride_logo.svg'
import { ECOSYSTEM_APP_ENDPOINT, USER_APP_ENDPOINT } from "../../../secrets";
import { logoutFirebase } from '../../../actions/authActions'

class Navlinks extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.closeNav = this.closeNav.bind(this);
        this.setNavExpanded = this.setNavExpanded.bind(this);
        this.state = {
            className: "flex-row-reverse navbar-collapse collapse",
            scrollPos: 0,
            navExpanded: false,
            notifications:[],
            currentNotification: undefined,
            showNotification: false,
        };
    }

    toggleNotification = () => {
        this.setState({showNotification:!this.state.showNotification})
    }

    handleClick(event) {
        this.setState({
            className: "flex-row-reverse navbar-collapse collapse",
        });
        event.preventDefault();
    }

    componentDidMount() {
        this.props.getNotifications({user_id:this.props.user.id})
        // fetching the notifications after every 10 seconds
        setInterval(() => this.props.getNotifications({user_id:this.props.user.id}), 10000)
    }

    static getDerivedStateFromProps(nextProps, prevState){
       if(nextProps.notifications.notifications!==prevState.notifications){
           return {notifications:nextProps.notifications.notifications.reverse()}
       }
       return null;
     }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.notifications.length!==this.props.notifications.notifications.length){
           this.priorityNotification()
        }
    }

    // function to check the priority of the notifications 
    // if the notification is equal to or above 100, it is displayed immediately on the screen
    priorityNotification = () => {
        let notifs = [];
        this.state.notifications.map((notif)=>{
            if(notif.priority>=100 && !notif.readStatus){
                notifs.push(notif)
            }
        })
        notifs=notifs.slice().sort((a, b) => b.priority - a.priority);
        if(notifs.length!==0){
            this.props.readNotification(notifs[0]._id,this.state.userId);
            this.passDataToPrompt(notifs[0]);
        }
        
    }

    // passing data to the notification prompt
    passDataToPrompt = (data) => {
        this.setState({currentNotification:data},this.toggleNotification())
    }


    setNavExpanded(expanded) {
        this.setState({ navExpanded: expanded });
    }

    closeNav() {
        console.log("Closing nav");
        this.setState({ navExpanded: false });
    }

    signout = async (e) => {
        e.preventDefault()
        await logoutFirebase()
        this.props.history.push('/')
    }

    renderNavList = () => {
        return (
            <Nav>
                <li>
                    <Link to="/profile" className="nav-link"
                        style={{color: window.location.href.includes("/profile") ? '#000000' :'#a3a3a3', borderBottom:window.location.href.includes("/profile") && !this.state.navExpanded && '1px solid #000000', paddingBottom:5}}>
                        profile
                    </Link>
                </li>
                <li>
                    <Link to="/my-projects" className="nav-link"
                        style={{color: window.location.href.includes("/my-projects") ? '#000000' :'#a3a3a3', borderBottom:window.location.href.includes("/my-projects") && !this.state.navExpanded && '1px solid #000000', paddingBottom:5}}>
                        my projects
                    </Link>
                </li>
                <li>
                    <Link to="/my-estimates" className="nav-link"
                        style={{ color: window.location.href.includes("/my-estimates") ? '#000000' : '#a3a3a3', borderBottom: window.location.href.includes("/my-estimates") && !this.state.navExpanded && '1px solid #000000', paddingBottom: 5 }}>
                        my estimates
                    </Link>
                </li>
                <li>
                    <Link to="/notifications" className="nav-link"
                        style={{color: window.location.href.includes("/notifications") ? '#000000' :'#a3a3a3', borderBottom:window.location.href.includes("/notifications") && !this.state.navExpanded && '1px solid #000000', paddingBottom:5}}>
                        notifications
                    </Link>
                </li>
                <li>
                    <Link to="/payment-information" className="nav-link"
                        style={{color: window.location.href.includes("/payment-information") ? '#000000' :'#a3a3a3', borderBottom:window.location.href.includes("/payment-information") && !this.state.navExpanded && '1px solid #000000', paddingBottom:5}}>
                        payments
                    </Link>
                </li>
                <li>
                    <Link to="/learn-more" className="nav-link"
                        style={{color: window.location.href.includes("/learn-more") ? '#000000' :'#a3a3a3', borderBottom:window.location.href.includes("/learn-more") && !this.state.navExpanded && '1px solid #000000', paddingBottom:5}}>
                        learn more
                    </Link>
                </li>
                <li>
                    <a 
                        href={USER_APP_ENDPOINT} 
                        className="nav-link"
                        style={{color: '#a3a3a3'}}
                    >
                        naya app
                    </a>
                </li>
                <li>
                    <a href={ECOSYSTEM_APP_ENDPOINT} className="nav-link"
                        onClick = {this.signout}
                        style={{color: window.location.href.includes("/signout") ? '#000000' :'#a3a3a3', borderBottom:window.location.href.includes("/sign-out") && !this.state.navExpanded && '1px solid #000000', paddingBottom:5}}>
                        sign out
                    </a>
                </li>
            </Nav>
        );
    };

    render() {
        let navbar;
            navbar = (
                <>
                <Navbar
                    // fixed="top"
                    expand="lg"
                    className="navbar-on-scroll col-12"
                    id="navbar-light"
                    onToggle={this.setNavExpanded}
                    expanded={this.state.navExpanded}
                >
                    <Col xs={12} style={{ paddingLeft: "0" }}>
                        <Row style={{ justifyContent: "space-between" }}>
                            <Navbar.Brand
                                href="/"
                                style={{ paddingLeft: "1.5rem", color:'#000000' }}
                            >
                                <img src={logo} alt="naya-logo" style={{width: 'auto', height: '1rem'}}></img>
                            </Navbar.Brand>
                            <Navbar.Toggle
                                aria-controls="collapsible_navbar-light"
                                onSelect={() => null}
                            />
                            <Navbar.Collapse
                                id="collapsible_navbar-light"
                                className={this.state.className}
                            >
                                {this.renderNavList(false)}
                            </Navbar.Collapse>
                        </Row>
                    </Col>
                </Navbar>
                {
                    this.state.showNotification && (<Prompt data={this.state.currentNotification} toggleNotification={this.toggleNotification}/>)
                }
                </>
            );

        return navbar;
    }
}

const mapStateToProps = (state) => {
    return { 
        user: state.user,
        notifications: state.notifications
    }
}

const mapDispatchToProps = (dispatch) => {

return { 
    getNotifications: (data) => dispatch(getNotifications(data)),
    readNotification: (notifId,userId) => dispatch(readNotification(notifId,userId)),
    signOut: () => dispatch({ type: "SIGN_OUT" }),
}
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navlinks));

