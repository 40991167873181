import React from 'react';
// For pdfs
import { Document, Page, pdfjs } from "react-pdf";
import prev from '../../images/prev-page.png';
import next from '../../images/next-page.png';
import maximize from '../../images/maximize.svg';
import {Modal} from 'react-bootstrap';
import { motion } from "framer-motion";
import download from '../../images/download.svg';

class DisplayPDF extends React.Component{
  constructor(props){
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }
  state = {
    numPages: null,
    pageNumber: 1,
    fileObject:
      this.props.src !== undefined
      ? {
          url:
              "https://cors-anywhere.herokuapp.com/" +
              this.props.src,
      }
      : {},
      showModal: false, height:0
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPreviousPage = () => {
    if (
      this.state.pageNumber -
          1 !==
      0
    ) {
        this.setState({
            pageNumber:
                this.state
                    .pageNumber - 1,
        });
    } else {
        this.setState({
            pageNumber: this.state
                .numPages,
        });
    }
  }

  goToNextPage = () => {
    if (
      this.state.pageNumber + 1 >
      this.state.numPages
    ) {
        this.setState({
            pageNumber: 1,
        });
    } else {
        this.setState({
            pageNumber:
                this.state
                    .pageNumber + 1,
        });
    }
  }

  toggleDisplayModal = () => {
    this.setState({showModal: !this.state.showModal})
  }

  getPageScrollers = () => {
    return(
      <>
      <motion.img src={prev} alt="previous-page" style={{width:'1.5rem', marginLeft:'0.2rem', height:'1.5rem', cursor:'pointer'}} onClick={this.goToPreviousPage} fluid
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.2 },
          }}
          whileTap={{ scale: 0.9 }}></motion.img>
      <motion.img src={next} alt="next-page" style={{width:'1.5rem', marginRight:'0.2rem', height:'1.5rem', cursor:'pointer'}} onClick={this.goToNextPage} fluid
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.2 },
          }}
          whileTap={{ scale: 0.9 }}></motion.img>
      </>
    )
  }

  render(){
    return(
      <div style={{display:'flex', alignItems:'flex-end'}} id="pdf-container">
      <div className="pdf-view">
        <Document file={this.state.fileObject} onLoadSuccess={this.onDocumentLoadSuccess}
          onLoadError={(error) =>alert("Error while loading document! " +error.message)}
          onSourceError={(error) =>alert("Error while retrieving document source! " +error.message)}>
            <Page pageNumber={this.state.pageNumber}/> 
            <Modal size='xl' show={this.state.showModal} onHide={this.toggleDisplayModal} className="pdf-modal" centered>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body style={{ overflowY: "hidden",overflowX: "hidden", display:'flex', alignItems:'center', flexDirection:'column'}}>
                <Page pageNumber={this.state.pageNumber}/>
                <div className="page-handlers" style={{position:'absolute', width:'100%', height:'100%', padding:'inherit', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                  {this.getPageScrollers()}
                </div>
              </Modal.Body>
            </Modal>
        </Document>
        <div className="page-handlers">
          {this.getPageScrollers()}
        </div>
      </div>
        <div className="pdf-maximize">
          <a href={this.props.src} download>
            <motion.img src={download} alt="downoad" className="image-actions" style={{width:'1.5rem', margin:'1rem', cursor:'pointer'}}
              whileHover={{
                scale: 1.1,
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 0.9 }}/>
          </a>
          <motion.img src={maximize} alt="maximize" style={{width:'1.5rem', margin:'1rem 0', cursor:'pointer'}} fluid onClick={this.toggleDisplayModal}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.9 }}></motion.img>
        </div>
      </div>
    )
  }
}

export default DisplayPDF;