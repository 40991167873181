import React from "react";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    root: {
        width: 500,
    },
    input: {
        width: 100,
        color: "white",
        borderColor: "white",
    },
});

export default function InputSlider(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState((props.max + props.min) / 2);

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
        props.handleSlider(props.name, newValue, props.index);
    };

    const handleInputChange = (event) => {
        setValue(event.target.value === "" ? "" : Number(event.target.value));
    };

    const handleBlur = () => {
        if (value < props.min) {
            setValue(props.min);
        } else if (value > props.max) {
            setValue(props.max);
        }
    };

    return (
        <div className={classes.root}>
            {props.unit && 
            <Typography
                id="input-slider"
                style={{marginBottom: "50px" }}
                gutterBottom
            >
                Specify{" "}
                {props.dimension && ["Length", "Width", "Height"][props.index]}{" "}
                in {props.unit}
            </Typography>}
            <Grid container spacing={5} alignItems="center">
                <Grid item xs>
                    <Slider
                        value={typeof value === "number" ? value : 0}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        min={props.min}
                        max={props.max}
                        style={{"color":"black", "marginBottom":"-20px"}}
                        step={props.step}
                        valueLabelDisplay="on"
                    />
                </Grid>
                <Grid item>
                    <Input
                        className={classes.input}
                        value={value}
                        margin="dense"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        style={{"color":"black"}}
                        inputProps={{
                            step: props.step,
                            min: props.min,
                            max: props.max,
                            type: "number",
                            "aria-labelledby": "input-slider",
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
}
