import React from 'react';
import {Row, Col, Image, Modal} from 'react-bootstrap';
import maximize from '../../images/icons/maximize.svg'
import download from '../../images/icons/download.svg'
import rotateIcon from '../../images/icons/rotateIcon.svg';

class ImageBox extends React.Component{
    imgBox = React.createRef();
    state = {
        rotation:0, 
        imageBoxWidth:'',
        imageBoxHeight:'auto',
        upload:this.props.upload,
    }

    rotateImage = () => {
        let newRotation = this.state.rotation + 90;
        if(newRotation == 360){
            newRotation = 0;
        }
        this.setState({
            rotation: newRotation, imageBoxHeight:'auto', imageBoxWidth:''
        })
    }

    lightBoxImageDisplay = () => {
        if(this.state.rotation===90 || this.state.rotation === 270)
        this.setState({imageBoxWidth:`${this.imgBox.current.clientWidth}px`, imageBoxHeight:`${this.imgBox.current.clientHeight}px`})
    }

    getCols = () => {
        return this.props.getCols()
    }

    render(){
        const {rotation, imageBoxWidth, imageBoxHeight, upload} = this.state;
        return(
            <Col xs={12} md={this.getCols() < 2? 12/this.getCols(): 6} 
                    lg={this.getCols() < 4? 12/this.getCols(): 4} 
                    xl={12/this.getCols()} 
                    style={{"minHeight": "300px", "padding":"0"}} className="img-wrapper">
            <Image src={upload.link} style={{transform: `rotate(${rotation}deg)`}}></Image>
            <Image src={maximize} className="maximizeButton" style={{"width":"2rem", "height":"2rem", "bottom":"1rem",  "right":"1rem", "top":"unset", "left":"unset", "cursor":"pointer"}} onClick={() => this.setState({showLightbox: true, lightboxImage: upload.link})}></Image>
            <a href={upload.link}><Image src={download} className="maximizeButton" style={{"width":"2rem", "height":"2rem", "bottom":"1rem",  "right":"3.5rem", "top":"unset", "left":"unset", "cursor":"pointer"}}></Image></a>
            <Image 
                src={rotateIcon} className="maximizeButton" 
                style={{"width":"2rem", "height":"2rem", "bottom":"1rem",  "right":"6rem", "top":"unset", "left":"unset", "cursor":"pointer"}} 
                onClick={this.rotateImage}
            ></Image>

            <Modal
                size="xl"
                show={this.state.showLightbox && this.state.lightboxImage}
                onHide={() => this.setState({showLightbox: false, lightboxImage: null})}
                aria-labelledby="example-modal-sizes-title-xl"
                id="instructions-modal"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body 
                    style={{overflowY:'auto'}}
                >
                    <Row 
                        className="justify-content-center" 
                        style={{height:imageBoxWidth}}
                        >
                        <img 
                            ref={this.imgBox}
                            src={this.state.lightboxImage} 
                            onLoad={this.lightBoxImageDisplay}
                            style={{"maxWidth":"100%", "height":imageBoxHeight, transform: `rotate(${rotation}deg)`}} alt="lightbox"></img>
                    </Row>
                </Modal.Body>
            </Modal>
        </Col>
        )
    }
}

export default ImageBox;