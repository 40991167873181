import React from 'react';
import chat_bubble from '../../images/chat-bubble.svg';
import { motion } from "framer-motion";

/**
 * Used exclusively for mobile view
 */
class ActionsButtons extends React.Component{

  render(){
    return(
      <div id="action-buttons">
        <div className="question" onClick={this.props.toggleComments} style={{display:'flex', alignItems:'center'}}>
          <img src={chat_bubble} alt="chat-bubble" style={{width:'2.5rem', marginRight:'0.5rem'}} fluid/>
          <h6 style={{marginBottom:0, fontFamily:'rand medium'}} >Have a question?</h6>
        </div>
        {
          !this.props.showEstimate && (
            <motion.button onClick={this.props.toggleEstimate} 
            whileHover={{
              scale: 1.2,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.9 }}>Enter Estimates</motion.button>
          )
        }
        
      </div>
    )
  }
}

export default ActionsButtons;