import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Form, Spinner } from 'react-bootstrap';
import Navbar from '../utilities/navbar/Navbar';
import secure from '../../images/icons/Lock.svg';
import {addPaymentDetails} from '../../actions/paymentActions';
import '../../stylesheets/payment-information.css';
import _ from 'lodash';

class PaymentInformation extends React.Component{
  state = {
    account_holder_name:'',
    email:'',
    billing_address:'',
    bank_name:'',
    bank_address:'',
    account_number:'',
    routing_number:'', 
    error:'',
    showForm:true,
    paymentDetails: null,
    loading:false,
    is_routing_number:true,
    bic_code:''
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.paymentDetails!==prevState.paymentDetails){
        return { paymentDetails: nextProps.paymentDetails};
   }
   return null;
  }

  componentDidUpdate(prevProps, prevState) {
      if(prevState.paymentDetails!==this.props.paymentDetails){
          this.setFormStatus();
      }
  }

  componentDidMount(){
    this.setFormStatus();
  }

  setFormStatus = () => {
    if(!_.isUndefined(this.props.paymentDetails) && !_.isNull(this.props.paymentDetails)){
      this.setState({showForm:false})
    }
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value, error:''})
  }

  onSubmit = (e) => {
    e.preventDefault()
    const {account_holder_name, email, billing_address, bank_name, bank_address, account_number, routing_number, bic_code, is_routing_number} = this.state;
    if(
      account_holder_name.trim().length === 0 ||
      account_number.trim().length === 0 ||
      email.trim().length === 0 ||
      billing_address.trim().length === 0 ||
      bank_name.trim().length === 0 ||
      bank_address.trim().length === 0
    ){
      this.setState({error:'Please complete required fields.'})
    }
    // TODO: add email validation
    // else if (.test(email)){
    //   this.setState({error:'Please enter valid email.'})
    // }
    // else if(account_number.trim().length < 4 || account_number.trim().length >17){
    //   this.setState({error:'Please enter valid account number.'})
    // }
    else if(is_routing_number && routing_number.trim().length !== 9){
      this.setState({error:'Please enter valid routing number.'})
    }
    else if(!is_routing_number && (bic_code.replace(/\s/g, "").trim().length < 8 || bic_code.replace(/\s/g, "").trim().length >11)){
      this.setState({error:'Please enter valid BIC code.'})
    }
    else{
      let raw_account_no = account_number.replace(/\s/g, "");
      const data = {
        account_holder_name,
        email,
        billing_address,
        bank_name,
        bank_address, 
        account_number:raw_account_no,
        user_id: this.props.user.id
      }
      if(is_routing_number){
        data['routing_number'] = routing_number
      }
      else{
        data['bic_code'] = bic_code.replace(/\s/g, "");
      }
      this.setState({loading:true})
      this.props.addPaymentDetails(data)
    }
  }

  changeBankAccount = () => {
    this.setState({showForm: true})
  }

  renderForm = () => {
    const {account_holder_name, email, billing_address, bank_name, bank_address, account_number, routing_number, bic_code, is_routing_number, error} = this.state;
    return(
      <>
      <h6>Which account would you like your payment to be deposited to?</h6>
      <Form>
        <div className="form-sections-container">
          <div className="section-1">
            <p>Your Details</p>

            {/* Account Holder's Name */}
            <Form.Group>
              <Form.Label>Account Holder's Name <span className="asterisk">*</span></Form.Label>
              <Form.Control type="text" placeholder="" name="account_holder_name" defaultValue={account_holder_name} onChange={this.onChange}/>
            </Form.Group>

            {/* Email */}
            <Form.Group>
              <Form.Label>Email <span className="asterisk">*</span></Form.Label>
              <Form.Control type="email" placeholder="" name="email" defaultValue={email} onChange={this.onChange}/>
            </Form.Group>

            {/* Billing Address */}
            <Form.Group>
              <Form.Label>Billing Address <span className="asterisk">*</span></Form.Label>
              <Form.Control type="text" placeholder="" name="billing_address" defaultValue={billing_address} onChange={this.onChange}/>
            </Form.Group>
          </div>

          <div className="section-2">
            <p>Bank Details</p>

            {/* Bank Name */}
            <Form.Group>
              <Form.Label>Bank Name <span className="asterisk">*</span></Form.Label>
              <Form.Control type="text" placeholder="" name="bank_name" defaultValue={bank_name} onChange={this.onChange}/>
            </Form.Group>

            {/* Bank Address */}
            <Form.Group>
              <Form.Label>Bank Address <span className="asterisk">*</span></Form.Label>
              <Form.Control type="text" placeholder="" name="bank_address" defaultValue={bank_address} onChange={this.onChange} />
            </Form.Group>

            {/* Account Number */}
            <Form.Group>
              <Form.Label>Account Number <span className="asterisk">*</span></Form.Label>
              <Form.Control type="text" placeholder="" name="account_number" defaultValue={account_number} onChange={this.onChange}/>
            </Form.Group>

            <Form.Group className="code-options-container">
              <div className="code-options">
                <div className={`radio-btn-outer-${is_routing_number ? 'active' : 'inactive'}`} onClick={()=>{this.setState({is_routing_number:true})}}>
                  <div className={`radio-btn-inner-${is_routing_number ? 'active' : 'inactive'}`}>

                  </div>
                </div>
                <p>Routing Number</p>
              </div>
              <p>OR</p>
              <div className="code-options">
                <div className={`radio-btn-outer-${!is_routing_number ? 'active' : 'inactive'}`} onClick={()=>{this.setState({is_routing_number:false})}}>
                  <div className={`radio-btn-inner-${!is_routing_number ? 'active' : 'inactive'}`} style={{marginLeft:"-1px"}}>

                  </div>
                </div>
                <p>BIC Code</p>
              </div>
            </Form.Group>

          {/* Routing Number/ BIC Code */}
          {
            is_routing_number ? (
              <Form.Group>
                <Form.Label>Routing Number(9 Digits) <span className="asterisk">*</span></Form.Label>
                <Form.Control type="number" placeholder="" name="routing_number" defaultValue={routing_number} onChange={this.onChange}/>
              </Form.Group>
            ) : (
              <Form.Group>
                <Form.Label>BIC Code(8-11 Digits) <span className="asterisk">*</span></Form.Label>
                <Form.Control type="text" placeholder="" name="bic_code" defaultValue={bic_code} onChange={this.onChange}/>
              </Form.Group>
            )
          }
    
          <Form.Group style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
            <img src={secure} alt="secure" style={{width:'14px', height:'14px', marginRight:'0.2rem'}}/>
            <p style={{margin:0}}>Secure Form</p>
          </Form.Group>
          </div>
        </div>
        {
          error && <p>{error}</p>
        }
        <button className="submit-button" onClick={this.onSubmit}>
          {
            this.state.loading ? this.renderSpinners() : 'Submit'
          }
        </button>
      </Form>
      </>
    )
  }

  getStatus = () => {
    const {createdAt, updatedAt} = this.props.paymentDetails;
    const d1 = new Date(createdAt);
    const d2 = new Date(updatedAt);
    let status = "Processing";
    if(d1.getTime() === d2.getTime()){
      status = "Processing";
    }
    else{
      if(this.props.paymentDetails.confirmation_status){
        status = 'Verified';
      }
      else {
        status = "Unable to Verify - Please Resubmit"
      }
    }
    return status;
  }

  getFormattedDate = (raw_date) => {
    const date = new Date(raw_date);
    const months = [ "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December" ];
    const currentMonth = date.getMonth();
    const currentDate = date.getDate();
    return `${currentDate} ${months[currentMonth]}, ${date.getFullYear()}`;
  }

  // Loadinding indicator for upload
  renderSpinners = () => (
    <div>
      <Spinner animation="grow" size="sm" />
      Saving...
    </div>
  )

  renderStatus = () => {
    return (
      <>
      <div className="status-container">
        <div className="account-details">
          <h6>Account Details</h6>

          <p style={{color:'#000', marginBottom:0}}>Account Information Submitted:</p>
          <p>{this.getFormattedDate(this.props.paymentDetails.createdAt)}</p>

          <p style={{color:'#000', marginBottom:0}}>Confirmation Status:</p>
          <p>{this.getStatus()}</p>
        </div>
        <div className="billing-process">
          <h6>Payment and Billing Process</h6>
          <p style={{color:'#000'}}>Payments will automatically be transferred to the provided account after you complete your role in the design and fabrication process.</p>
        </div>
      </div>
        <button className="submit-button" onClick={this.changeBankAccount} style={{marginTop:'3.5rem'}}>
          Change Bank Account
        </button>
        </>
    )
  }
  
  render(){
    const {showForm} = this.state;
    return(
      <div id="payment-information">
        <Navbar></Navbar>
        <Container fluid className='form-container'>
          <h3>Payment Information</h3>
          {
            showForm ? this.renderForm() : this.renderStatus()
          }
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
      user: state.user,
      paymentDetails: state.user.paymentDetails
  }
}

const mapDispatchToProps = (dispatch) => {

  return { 
    addPaymentDetails: (data) => dispatch(addPaymentDetails(data)),
  }
}
export default  connect(mapStateToProps, mapDispatchToProps)(withRouter(PaymentInformation));