import axios from 'axios'

import { API_ENDPOINT } from  '../secrets'
import { config, getEmailAndId } from '../services/cookieHelpers'

// const config = {
//   headers: {
//       "Access-Control-Allow-Origin": "*"    }
// }
//   axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
//   axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const setCanvas = (data) => 
{
    return (dispatch) => {
        
        dispatch({ type: 'SET_CANVAS_ID', data: data});
    }
}

export const updateUser = (data) => 
{
    const credentials = getEmailAndId()

    return (dispatch) => {
        const UPDATE_USER_DATA_URI = `${API_ENDPOINT}/users/update`

        axios.put(UPDATE_USER_DATA_URI, {...data, ...credentials}, config)
        .then(response => response.data).then(response => {
            if(response.status)
            {
                dispatch({type: "REFRESH_USER", id: response.user._id, user: response.user})
            }
            else
            {
                dispatch({type: "ERROR_UPDATE_USER", message:response.message})
            }
        }).catch(e => {
            console.log(e)
            dispatch({type: "ERROR_UPDATE_USER", message:"Our systems are experiencing issues. Please try again after some time"})
        })

        dispatch({ type: 'UPDATE_USER_INIT', data: data});
    }
}

export const addAnswer = (data) => 
{
    const credentials = getEmailAndId()
  
    return (dispatch) => {
        const ADD_ANSWER_URI = `${API_ENDPOINT}/users/answer`

        axios.post(ADD_ANSWER_URI, { ...data, ...credentials }, config)
        .then(response => response.data).then(response => {
            if(response.status)
            {
                dispatch({type: "REFRESH_USER", id: response.user._id, user: response.user})
            }
            else
            {
                dispatch({type: "ERROR_UPDATE_USER", message:response.message})
            }
        }).catch(e => {
            console.log(e)
            dispatch({type: "ERROR_UPDATE_USER", message:"Our systems are experiencing issues. Please try again after some time"})
        })

        dispatch({ type: 'UPDATE_USER_INIT', data: data});
    }
}

export const addUploadsToUser = (data) => 
{
    const credentials = getEmailAndId()
  
    return (dispatch) => {
        const ADD_ANSWER_URI = `${API_ENDPOINT}/users/uploads`

        axios.post(ADD_ANSWER_URI, { ...data, ...credentials }, config)
        .then(response => response.data).then(response => {
            console.log(response)
            if(response.status)
            {
                console.log("RESPONSE:", response.user)
                dispatch({type: "REFRESH_USER", id: response.user._id, user: response.user})
            }
            else
            {
                dispatch({type: "ERROR_UPDATE_USER", message:response.message})
            }
        }).catch(e => {
            console.log(e)
            dispatch({type: "ERROR_UPDATE_USER", message:"Our systems are experiencing issues. Please try again after some time"})
        })

        dispatch({ type: 'UPDATE_USER_INIT', data: data});
    }
}