import cookie from 'cookie'
import Firebase from './firebase'
import axios from 'axios'

//axios universal defaults
axios.defaults.withCredentials = true

const parsedCookie = cookie.parse(document.cookie)
const { csst = null } = parsedCookie

export const config = {
  headers: {
    "authorization": `Bearer ${csst}`,
  },
  withCredentials: true
};

/**
 * Returns an object with 'email' and '_id' credentials
 * @returns {object} user object
 * @returns {object.email} user email if exists
 * @returns {object._id} user's firebase UID if exists
 */
export const getEmailAndId = () => {
  const user = Firebase.auth().currentUser
  if (!user) {
    // console.log('No user in firebase, please login and refresh')
    return null
  }
  const { email, uid } = user

  return { authEmail: email, uid }
}

/**
 * Used for Get Requests
 */
export const getConfigEmail = () => {
  //backend expects credentials
  const credentials = getEmailAndId()

  const configObj = {
    ...config,
    params: {
      ...credentials
    }
  }
  
  // console.log(configObj)

  return configObj
}