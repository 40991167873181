import React, { Component } from "react";

/**
 * Components
 */
import Option from "./Option";
import ProgressBar from "../utilities/ProgressBar";
import BackButton from "../utilities/BackButton";
import Progress from "../utilities/Progress";
import CancelButton from "../utilities/CancelButton";
import ContinueButton from "../utilities/ContinueButton";
import InputSlider from "../utilities/InputSlider";
import RangeSlider from "../utilities/RangeSlider";
import GoogleMap from "../utilities/GoogleMap";
import MediaDrop from "../utilities/MediaDrop";
import TagsList from "../utilities/TagsList";

/**
 * Redux Actions
 */
import { getQuestions, fillForm } from "../../actions/surveyActions";
import { signOut } from "../../actions/userActions";

/**
 * Higher Level Components
 */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { API_ENDPOINT } from "../../secrets";
/**
 * Style
 */
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import "../../stylesheets/style.css";

/**
 * Images and Media
 */
import infoIcon from "../../images/icons/infoIcon.svg";
import OtherOption from "./OtherOption";

/**
 * An Updated question class to render survey questions
 */
class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: this.props.selectedOptions,
            mode: this.props.mode,
            answerId: this.props.answerId,
            otherOptionSelected: this.props.isOther,
            otherOptionText: this.props.other,
            form: {
                name: null,
                value: null,
            },
            showInvalidFormAlert: false,
            showNotEnoughFilesAlert: false,
            addedFiles: [],
            filesUploaded: false,
        };
        console.log("Index - " + this.props.index);
    }

    componentDidMount() {
        this.setState({
            form: {
                name: null,
                value: null,
            },
        });
    }

    handleRedirect = (e) => {
        e.preventDefault();
        let redirectPage = e.target.value;
        this.props.history.push(redirectPage);
    };

    /**
     * Handles form submit - Continue button
     */
    handleSubmit = (e) => {
        e.preventDefault();

        const field = {
            name: this.state.form.name,
            value: this.state.form.value,
        };

        if (this.props.currentQuestion.answer_type === "UPLOAD") {
            const minFiles = this.props.currentQuestion.question_number_range
                .low;
            if (
                !this.state.form.value ||
                this.state.form.value.length < minFiles
            ) {
                return this.setState({
                    showNotEnoughFilesAlert: true,
                });
            }
        }

        if (!field.name || !field.value) {
            return this.setState({
                showInvalidFormAlert: true,
            });
        }

        console.log("Filling form");
        console.log(field);
        this.props.fillForm(field);
        const nextPage = this.props.step + 1;
        this.props.history.push(`q${nextPage}`);
    };

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            ...this.state,
            form: {
                name: name,
                value: value,
            },
        });
    };

    handleSliderChange = (name, value) => {
        this.setState({
            ...this.state,
            form: {
                name: name,
                value: value,
            },
        });
    };

    handleDimensionSliderChange = (name, value, index) => {
        var newValues = this.state.form.value;
        if (!Array.isArray(newValues) || newValues.length !== 3) {
            newValues = [0, 0, 0];
        }
        newValues[index] = value;
        this.setState({
            ...this.state,
            form: {
                name: name,
                value: newValues,
            },
        });
    };

    /**
     * Select/Deselect an option
     */
    toggleOption = (name, value, isSelected) => {
        var options = this.state.form.options;
        if (!options) options = [];
        if (isSelected) {
            if (!options.includes(value)) options.push(value);
        } else {
            if (options.includes(value)) {
                var newOptions = [];
                options = options.forEach((element) => {
                    if (element !== value) {
                        newOptions.push(element);
                    }
                });
                options = newOptions;
            }
        }
        console.log(options);
        this.setState({
            ...this.state,
            form: {
                options: options,
                name: name,
                value: options,
            },
        });
    };

    renderTopNavigation = () => {};

    toggleOtherOption = () => {
        this.setState({ otherOptionSelected: !this.state.otherOptionSelected });
    };

    handleOtherOption = (text) => {
        this.setState({ otherOptionText: text });
    };

    handleLocationUpdate = (name, latLng) => {
        console.log(latLng);
        this.setState({
            ...this.state,
            form: {
                name: name,
                value: { geo_type: "POINT", coordinates: latLng },
            },
        });
        console.log(this.state.form.value);
    };

    handleAddFile = (name, file) => {
        console.log("Value  -  " + this.state.form.value);
        var files = this.state.form.value;
        if (!files || !Array.isArray(files)) {
            files = [];
        }
        files.push(file);
        console.log(files);
        this.setState({
            form: {
                name: name,
                value: files,
            },
        });
    };

    handleSetTags = (name, tags) => {
        this.setState({
            form: {
                name: name,
                value: tags,
            },
        });
    };

    renderOptions = (question) => {
        let optionsRender = question.question_options.map((option, index) => (
            <Option
                key={index}
                selected={false}
                name={question.mapping_field}
                value={option.value}
                alt={option.alt}
                onClick={this.toggleOption}
                text={option.title}
                link={option.image}
            />
        ));
        let otherOption = (
            <OtherOption
                selected={this.state.otherOptionSelected}
                onClick={this.toggleOtherOption}
                onValue={this.handleOtherOption}
                text={this.state.otherOptionText}
            ></OtherOption>
        );
        return optionsRender;
    };

    renderSelectOptions = (question) => {
        var options = [];
        options.push(<option value="">---Select One---</option>);
        question.question_select.forEach((option) => {
            options.push(<option value={option.value}>{option.title}</option>);
        });
        return (
            <select
                name={question.mapping_field}
                onChange={this.handleInputChange}
                required
            >
                {options}
            </select>
        );
    };

    renderChoice = (question) => {
        let choice;
        switch (question.answer_type) {
            case "TEXT":
                choice = (
                    <input
                        type="TEXT"
                        name={question.mapping_field}
                        onChange={this.handleInputChange}
                    />
                );
                break;
            case "SELECT":
                choice = this.renderSelectOptions(question);
                break;
            case "OPTIONS":
                choice = this.renderOptions(question);
                break;
            case "NUMBER":
                choice = (
                    <InputSlider
                        min={question.question_number_range.low}
                        step={question.question_number_range.step}
                        max={question.question_number_range.high}
                        name={question.mapping_field}
                        handleSlider={this.handleSliderChange}
                        unit={question.question_number_range.unit}
                    />
                );
                break;
            case "DIMENSIONS":
                choice = (
                    <div>
                        <InputSlider
                            min={question.question_number_range.low}
                            step={question.question_number_range.step}
                            max={question.question_number_range.high}
                            name={question.mapping_field}
                            handleSlider={this.handleDimensionSliderChange}
                            unit={question.question_number_range.unit}
                            index={0}
                            dimension
                        />
                        <InputSlider
                            min={question.question_number_range.low}
                            step={question.question_number_range.step}
                            max={question.question_number_range.high}
                            name={question.mapping_field}
                            handleSlider={this.handleDimensionSliderChange}
                            unit={question.question_number_range.unit}
                            index={1}
                            dimension
                        />
                        <InputSlider
                            min={question.question_number_range.low}
                            step={question.question_number_range.step}
                            max={question.question_number_range.high}
                            name={question.mapping_field}
                            handleSlider={this.handleDimensionSliderChange}
                            unit={question.question_number_range.unit}
                            index={2}
                            dimension
                        />
                    </div>
                );
                break;
            case "RANGE":
                choice = (
                    <RangeSlider
                        min={question.question_number_range.low}
                        step={question.question_number_range.step}
                        max={question.question_number_range.high}
                        name={question.mapping_field}
                        handleSlider={this.handleSliderChange}
                        unit={question.question_number_range.unit}
                    />
                );
                break;
            case "LOCATION":
                choice = (
                    <GoogleMap
                        updateLocation={this.handleLocationUpdate}
                        name={question.mapping_field}
                    />
                );
                break;
            case "UPLOAD":
                choice = (
                    <MediaDrop
                        addFile={this.handleAddFile}
                        name={question.mapping_field}
                    />
                );
                break;
            case "TAGS":
                choice = (
                    <TagsList
                        sampleTags={question.sample_tags}
                        handleSetTags={this.handleSetTags}
                        name={question.mapping_field}
                    />
                );
                break;
            default:
                choice = null;
        }
        return choice;
    };

    renderQuestion = () => {
        const index = this.props.index;
        const question = this.props.currentQuestion;

        const renderChoice = this.renderChoice(question);
        const title = question.title;
        const desc = question.desc;

        return (
            <Container>
                <Row className="justify-content-md-center">
                    <h2 className="col-12 question-h2">{title}</h2>
                    <h4
                        className="col-12 question-h4"
                        style={{ textAlign: "center", "marginTop":"1rem", "fontWeight":"400", "fontSize":"1.2rem" }}
                    >
                        {desc}
                    </h4>
                    {renderChoice}
                    <Col xs={12}>
                        <Row className="justify-content-md-center">
                            <Button variant="dark" onClick={this.handleSubmit}>
                                Continue
                            </Button>
                        </Row>
                        <Row className="justify-content-md-center">
                            <p
                                className="skip-button"
                                onClick={() => {this.props.history.push("/account")}}
                                style={{ cursor: "pointer" }}
                            >
                                Skip to Account
                            </p>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    };

    render() {
        const renderQuestion = this.renderQuestion();
        var minFiles = -1;
        if (this.props.currentQuestion.answer_type === "UPLOAD") {
            minFiles = this.props.currentQuestion.question_number_range.low;
        }
        return (
            <Container fluid={true}>
                <ProgressBar
                    width={(this.props.step * 100) / this.props.stepMax}
                ></ProgressBar>
                {this.state.showInvalidFormAlert && (
                    <Alert variant={"warning"}>Please fill in the fields</Alert>
                )}
                {this.state.showNotEnoughFilesAlert && (
                    <Alert variant={"warning"}>
                        Please upload at least {minFiles} files
                    </Alert>
                )}
                <Row>
                    <BackButton></BackButton>
                    <Progress
                        step={this.props.step}
                        step_max={this.props.stepMax}
                    ></Progress>
                    {window.innerHeight < document.body.clientHeight && (
                        <ContinueButton
                            onSubmit={this.handleSubmit}
                        ></ContinueButton>
                    )}

                    {window.innerHeight >= document.body.clientHeight && (
                        <CancelButton></CancelButton>
                    )}

                    {renderQuestion}
                </Row>
            </Container>
        );
    }
}

/**
 *
 * @param {*} state
 * @param {*} ownProps
 * Maps the Redux state to component props
 */
const mapStateToProps = (state, ownProps) => {
    return {
        index: ownProps.index,
        questions: state.UI.questions,
        step: ownProps.index + 1,
        stepMax: state.UI.no_of_questions,
        currentQuestion: state.UI.questions[ownProps.index],
        answer_type: state.UI.questions[ownProps.index].answer_type,
    };
};

/**
 *
 * @param {*} dispatch
 * Maps redux actions to component props
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getQuestions: () => dispatch(getQuestions()),
        fillForm: (field) => dispatch(fillForm(field)),
        signOut: () => dispatch(signOut()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Question));
