const initLoading = {
    
}
const DEBUG = false
const loadingReducer = (state = initLoading, action) => {
    let newState

    if(DEBUG) console.debug("LOADING REDUCER:")
    if(DEBUG) console.debug("ACTION:")
    if(DEBUG) console.debug(action)
    if(DEBUG) console.debug("CURRENT STATE:")
    if(DEBUG) console.debug(state)

    switch(action.type)
    {
        case "LOGIN_INIT":
            newState ={...state, loginLoading: action.data}
            break
        default:
            newState = state
    }

    if(DEBUG) console.debug("NEW STATE:")
    if(DEBUG) console.debug(newState)

    return newState
}

export default loadingReducer