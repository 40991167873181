import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spinner, Form } from 'react-bootstrap'
import { updateUser } from '../../actions/accountActions'
// import logoSrc from '../../images/naya_logo_symbol.png'
import logoSrc from '../../images/pride_logo.svg'
import AgreementModal from './AgreementModal.js'
import './SplashAuth.scss'

const SplashAuth = ({ 
    checkingAuthStatus = true, 
    authenticated = false,
    setState
  }) => {

  const acceptedTermsOfService = useSelector(state => state.user.acceptedTermsOfService)
  const _id = useSelector(state => state.user.id)
  const dispatch = useDispatch()
  const [ acceptedTOS, setAcceptedTOS ] = useState(acceptedTermsOfService)
  const [ modalVisible, setModalVisible ] = useState(false)

  useEffect(() => {
    setAcceptedTOS(acceptedTermsOfService)
  }, [acceptedTermsOfService])

  const agreeToTOS = async () => {
    //find timestamps
    const { timestamps: oldTimestamps, designer: oldDesigner, maker: oldMaker } = acceptedTermsOfService
    const { designer, maker } = acceptedTOS
  
    let timestamps = {}

    if (oldTimestamps) {
      timestamps = oldTimestamps
    }

    //designer timestamps, only fires when going from false -> true and no timestamp previously
    if (designer && oldDesigner !== designer && !timestamps.designer) {
      timestamps.designer = new Date()
    }

    //maker timestamps, only fires when going from false -> true and no timestamp previously
    if (maker && oldMaker !== maker && !timestamps.maker) {
      timestamps.maker = new Date()
    }

    //update TOS
    await dispatch(updateUser({ 
      updateType: 'terms-of-service',
      _id,
      acceptedTermsOfService: {
        ...acceptedTOS,
        timestamps
      }
    }))

    //find user_type
    let user_type
    switch (true) {
      case acceptedTOS.designer && acceptedTOS.maker:
        user_type = 'DESIGNER_MAKER'
        break;
      case acceptedTOS.designer:
        user_type = 'DESIGNER'
        break;
      case acceptedTOS.maker:
        user_type = 'MAKER'
        break;
      default:
        user_type = 'USER'
        break;
    }

    //update user_type based on TOS
    await dispatch(updateUser({
      updateType: 'PROFILE_TYPE',
      id: _id,
      user_type
    }))

    //close modals
    setState({
      checkingAuthStatus: true,
      authenticated: true
    }, () => setModalVisible(false))
  }

  const onChangeTOS = (e) => {
    const { name } = e.target
    setAcceptedTOS(prev => ({
      ...prev,
      [name]: !prev[name]
    }))
  }


  return (
    <>
      <div id='splash-auth'>
        {
          modalVisible && <AgreementModal
            show={modalVisible}
            hide={() => setModalVisible(false)}
            tos={acceptedTOS}
            onChangeTOS={onChangeTOS}
            submit={agreeToTOS}
          />
        }
        { checkingAuthStatus && (
          <div className='spinner-container'>
            <Spinner animation='border' variant='dark' size='lg' role='status' />
            <p> We are currently checking your log in status</p>
          </div>
        )}
        {/* firebase check is done and user has not signed their respectful terms of service, for designer or maker*/}
        { (!checkingAuthStatus && !authenticated) && (
          <div className='tos-container'>
            <img src={logoSrc} alt='logo' style={{marginBottom: '2rem'}}/>
            <h2> Welcome! </h2>
            <p>
              Thank you for choosing to work with us at Naya. We are excited to have you on board. 
            </p>
            <p style={{marginTop: '1rem'}}>
              Before we can begin, we need you to agree to the terms of service. Please read through and agree to our Provider agreement and terms of service to continue.
            </p>
            <p style={{marginTop: '1rem'}}>
              After accepting, you will still be able to access the Provider agreement and terms of service in the 'learn more' section.
            </p>
            <button className='main-btn' style={{marginTop: '2rem', marginBottom: '2rem', padding: '1rem 2rem'}}  onClick={() => setModalVisible(true)}>
              View the Provider Agreement
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default SplashAuth