import React from 'react';
import {Container, Row, Button} from 'react-bootstrap';
import Navbar from '../utilities/navbar/Navbar';
import { USER_APP_ENDPOINT } from '../../secrets'
import '../../stylesheets/learnMore.css';

class LearnMore extends React.Component{
  state={
    faqs:[
      {
        question: 'Where can I find a copy of the Terms of Service or Provider Agreement?',
        answer: (
          <>
            <a href={`https://docs.google.com/document/d/e/2PACX-1vR0FBgEXprCXloWii0Y3q7zvj27kHpxe9y-y8XriEXE5YCaAyk-EdBTn_ARLVXQww/pub`} target="_blank" rel="noopenner noreferrrer">Terms of Service </a> and <a href="https://docs.google.com/document/d/e/2PACX-1vQbZ7UdvdV3fVoBwqYO1rt9twTI_TusT8XDSQ5Wa3X3t9VFKb2tqPNCTnTWyOZ5og/pub" target="_blank" rel="noopenner noreferrer"> Provider Agreement</a>
          </>
        )
      },
      {
        question:"How much does it cost to join Naya?",
        answer:"Absolutely nothing! The Naya platform is free for makers and designers."
      },
      {
        question:"What are my responsibilities as a designer?",
        answer:"You will collaborate with the client to visualize and realize their design concept. This will include some writing, sketching, CAD modeling and rendering. Once you and the client have agreed on a design direction, you’ll generate some simple shop drawings and hand the project off to the fabricator."
      },
      {
        question:"As a Designer, what do I get paid per project? When do I get paid?",
        answer:"The payment is released as soon as both Naya and the client have approved the final design. The design payment (starting at $100) depends on the scope of the project."
      },
      {
        question:"What are my responsibilities as a maker?",
        answer:"When you receive a match, you will generate a quote. You will then consult with the designer. To make any design adjustments necessary for fabrication. You will then build the piece, send progress photos, and a video of the finished project. Then deliver it to it’s final destination!"
      },
      {
        question:"As a Maker, what do I get paid per project? When do I get paid?",
        answer:"If our client accepts your quote for the project, you will take home 100% of that bid. We don’t take anything off the top. You will get paid in 2 deposits: 50% at the beginning of the project, and 50% after final delivery."
      },
      {
        question:"What if I’m both a designer and a maker?",
        answer:"Great! You can sign up for both roles or choose whether you’d like to sell your services as a designer or a maker! We have created a distinction between the design phase and the maker phase to allow creators to choose which part of the job they want to take on."
      },
      {
        question:"How much time do I need to commit to Naya?",
        answer:"As much as you want! You can take on a new project every week or just take on one a year. Totally up to you. We just ask that you only accept projects that you will realistically be able to deliver on time and on budget. 	"
      },
      {
        question:"Do I need a lot of experience to join Naya?",
        answer:"Yes and no. We are looking for high quality, professional level work, but we seek to provide equal access to both seasoned veterans and up-and-comers alike."
      },
    ]
  }
  render(){
    return(
      <>
        <Navbar/>
        <Container id="learn-more">
          <h3>Check out our extensive guidelines for:</h3>
          <Row style={{width:'max-content'}}>
            <Button
              style={{marginRight:'1rem'}}
              onClick={()=>{window.location.href="https://docs.google.com/presentation/d/e/2PACX-1vRejhJlrp7bYaszNV6tvCAeNTaF0Jd3lQZKfjMYsvXqOljIBWURIp-j22-NJHkL6f6KrbDRxrdFwsLO/pub?start=false&loop=false&delayms=3000"}}
            >
              Designer
            </Button>
            <Button
              onClick={()=>{window.location.href="https://docs.google.com/presentation/d/e/2PACX-1vQR_vAAlBS6cEEL645xC-Mo9GCi8yd3dqKjD2gdP9izia90oFMvV5-lrKkDr4sY7-jW7ua6ZUk8mTXv/pub?start=false&loop=false&delayms=3000"}}
            >
              Fabricator
            </Button>
          </Row>
          <Row style={{alignItems:'center', marginTop:'1.5rem', marginBottom:'1rem'}}>
            <div style={{height:1, backgroundColor:'#a3a3a3', minWidth:120, maxWidth:300, marginRight:'1rem'}}/>
            <h6>or</h6>
            <div style={{height:1, backgroundColor:'#a3a3a3', minWidth:120, maxWidth:300, marginLeft:'1rem'}}/>
          </Row>
          <h1>FAQ</h1>
          {
            this.state.faqs.map((faq, index)=>(
              <div key={index} style={{maxWidth:800, marginBottom:'1.5rem'}}>
              <h5>{faq.question}</h5>
              <p>{faq.answer}</p>
              </div>
            ))
          }
        </Container>
      </>
    )
  }
}

export default LearnMore;
