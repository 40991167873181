import { API_ENDPOINT, USER_APP_ENDPOINT } from "../secrets";
import axios from "axios";
import { DEBUG } from "../secrets";
import { getConfigEmail, config, getEmailAndId } from '../services/cookieHelpers'

const emailAndId = getEmailAndId()

// const config = {
//     headers: {
//         "Access-Control-Allow-Origin": "*",
//     },
// };

// axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
// axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export const loginLocal = (data) => {
    return (dispatch) => {
        const AUTH_LOGIN_LOCAL_URI = `${API_ENDPOINT}/auth/login`;
        axios
            .post(AUTH_LOGIN_LOCAL_URI, data, config)
            .then((response) => {
                if (response.data.status) {
                    console.log(response.data);
                    dispatch({ type: "LOGIN_INIT", data: false });
                    dispatch({ type: "LOGIN_LOCAL", data: response.data });
                    dispatch(
                        getSecondaryUserInformation({
                            user_id: response.data.user._id,
                        })
                    );
                } else {
                    dispatch({ type: "LOGIN_INIT", data: false });
                    dispatch({
                        type: "LOGIN_ERROR",
                        data: response.data.message,
                    });
                }
            })
            .catch((e) => {
                dispatch({ type: "LOGIN_INIT", data: false });
                dispatch({
                    type: "LOGIN_ERROR",
                    data:
                        "Our systems are encountering problems. Please try again in some time",
                });
            });

        dispatch({ type: "LOGIN_INIT", data: true });
    };
};

export const loginToken = () => {
    console.log("in login token")
    return (dispatch) => {
        const AUTH_TOKEN_LOCAL_URI = `${API_ENDPOINT}/auth/jwt`;
        let data = localStorage.getItem("nayaJwtToken");
        if (data) {
            axios
                .get(AUTH_TOKEN_LOCAL_URI, {
                    headers: { Authorization: `Bearer ${data}` },
                })
                .then((response) => {
                    if (DEBUG) console.log(response);
                    if (response.data.status) {
                        dispatch({
                            type: "LOGIN_TOKEN",
                            data: response.data.user,
                        });
                        dispatch(
                            getSecondaryUserInformation({
                                user_id: response.data.user._id,
                            })
                        );
                    } else {
                        dispatch({
                            type: "LOGIN_ERROR",
                            data: response.data.message,
                        });
                        localStorage.removeItem("nayaJwtToken");
                    }
                    dispatch({ type: "LOGIN_INIT", data: false });
                })
                .catch((e) => {
                    dispatch({ type: "LOGIN_INIT", data: false });
                    dispatch({
                        type: "LOGIN_ERROR",
                        data:
                            "Our systems are encountering problems. Please try again in some time",
                    });
                    localStorage.getItem("nayaJwtToken")
                });

            dispatch({ type: "LOGIN_INIT", data: true });
        } else {
            dispatch({ type: "LOGIN_INIT", data: false });
        }
    };
};

/**
 * Sends a get request to naya api for user information and save to redux store
 */
export const loginCookie = () => {
    const configEmail = getConfigEmail()

    return (dispatch) => {
        const AUTH_COOKIE_USER_URI = `${API_ENDPOINT}/users/id`
        axios.get(AUTH_COOKIE_USER_URI, configEmail)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: "LOGIN_COOKIE",
                        data: response.data.user,
                    });
                    dispatch(
                        getSecondaryUserInformation({
                            user_id: response.data.user._id,
                        })
                    );
                } else {
                    dispatch({
                        type: "LOGIN_ERROR",
                        data: response.data.message,
                    });
                }
                dispatch({ type: "LOGIN_INIT", data: false });
            })
            .catch((e) => {
                console.log(e)
                dispatch({ type: "LOGIN_INIT", data: false });
                dispatch({
                    type: "LOGIN_ERROR",
                    data:
                        "Our systems are encountering problems. Please try again in some time",
                });
            });

            dispatch({ type: "LOGIN_INIT", data: true });
    }
}

export const signOut = () => (dispatch) => {
        dispatch({ type: "SIGN_OUT" });
};

export const changeProfilePic = (data) => {
    return (dispatch) => {
        const CHANGE_PROFILE_PIC_URL = `${API_ENDPOINT}/api/userprefs`;
        if (DEBUG) console.log(data);
        axios
            .put(CHANGE_PROFILE_PIC_URL, data, { crossDomain: true })
            .then((response) => {
                if (DEBUG) console.log(response);
                if (response.data.status) {
                    dispatch({ type: "CREATE_PROFILE_PIC" });
                    dispatch(
                        getSecondaryUserInformation({ user_id: data.user_id })
                    );
                } else {
                    dispatch({
                        type: "ERROR_CREATE_PROFILE_PIC",
                        message: response.data.message,
                    });
                }
            })
            .catch((e) => {
                if (DEBUG) console.log(e);
                dispatch({
                    type: "ERROR_CREATE_PROFILE_PIC",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const getProfilePic = (data) => {
    return (dispatch) => {
        if (DEBUG) console.log("Getting profile name");
        if (DEBUG) console.log(data);
        const GET_PROFILE_PIC_URL = `${API_ENDPOINT}/api/userprefs`;
        axios
            .get(GET_PROFILE_PIC_URL, { params: data }, { crossDomain: true })
            .then((response) => {
                if (response.data.status) {
                    dispatch({
                        type: "SET_PROFILE_PIC",
                        link: response.data.data.options[0].value,
                    });
                } else {
                    dispatch({
                        type: "ERROR_GET_PROFILE_PIC",
                        message: response.data.message,
                    });
                }
            })
            .catch((e) => {
                dispatch({
                    type: "ERROR_GET_PROFILE_PIC",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const changeProfileName = (data) => {
    return (dispatch) => {
        const CHANGE_PROFILE_NAME_URL = `${API_ENDPOINT}/api/userprefs`;
        if (DEBUG) console.log(data);
        axios
            .put(CHANGE_PROFILE_NAME_URL, data, { crossDomain: true })
            .then((response) => {
                if (DEBUG) console.log(response);
                if (response.data.status) {
                    dispatch({ type: "CREATE_PROFILE_NAME" });
                    dispatch(
                        getSecondaryUserInformation({ user_id: data.user_id })
                    );
                } else {
                    dispatch({
                        type: "ERROR_CREATE_PROFILE_NAME",
                        message: response.data.message,
                    });
                }
            })
            .catch((e) => {
                if (DEBUG) console.log(e);
                dispatch({
                    type: "ERROR_CREATE_PROFILE_NAME",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const getProfileName = (data) => {
    return (dispatch) => {
        if (DEBUG) console.log("Getting profile pic");
        if (DEBUG) console.log(data);
        const GET_PROFILE_NAME_URL = `${API_ENDPOINT}/api/userprefs`;
        axios
            .get(GET_PROFILE_NAME_URL, { params: data }, { crossDomain: true })
            .then((response) => {
                if (response.data.status) {
                    dispatch({
                        type: "SET_PROFILE_NAME",
                        name: response.data.data.options[0].value,
                    });
                } else {
                    dispatch({
                        type: "ERROR_GET_PROFILE_NAME",
                        message: response.data.message,
                    });
                }
            })
            .catch((e) => {
                dispatch({
                    type: "ERROR_GET_PROFILE_NAME",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const getSecondaryUserInformation = (data) => {
    return (dispatch) => {
        let pic_data = {
            ...data,
            key: "PROFILE_PIC",
        };
        dispatch(getProfilePic(pic_data));

        let name_data = {
            ...data,
            key: "PROFILE_NAME",
        };
        dispatch(getProfileName(name_data));
    };
};

// redirect to passport
export const signUpInGoogle = (user_type) => {
    window.location.href = `${API_ENDPOINT}/auth/google?callbackURL=${window.location.protocol}//${window.location.host}&user_type=${user_type}`
}

export const signUpInFacebook = (user_type) => {
    window.location.href = `${API_ENDPOINT}/auth/facebook?callbackURL=${window.location.protocol}//${window.location.host}&user_type=${user_type}`
}