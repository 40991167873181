import React, { Component } from "react";

import { Switch, Route, withRouter } from "react-router-dom";
import { loginCookie, loginToken, signOut } from "./actions/userActions";
import { getQuestions } from "./actions/surveyActions";
import { connect } from "react-redux";

import Auth from "./components/authComponents/Auth";

import Canvas from "./components/canvas/Canvas";
import Question from "./components/quiz/Question";

import "./App.css";
import Annotate from "./components/sketch/Annotate";
import CompleteForm from "./components/quiz/CompleteForm";
import UserProfile from "./components/admin/UserProfile";

import SplashAuth from "./components/authComponents/SplashAuth";
import EcosystemSplash from "./components/splash-page/SplashPage";

import BasicInfo from "./components/basicInfo/BasicInfo";
import Profile from "./components/profile/Profile";
import Projects from "./components/projects/Projects";
import LearnMore from "./components/learnMore/LearnMore";
import Notifications from "./components/notifications/Notifications";
import EstimateRequest from "./components/forms/EstimateRequest";
import ManualEstimation from "./components/manual-estimation/ManualEstimation";
import Estimates from "./components/estimate/Estimates";
import PaymentInformation from "./components/payments/PaymentInformation";

import { getLogoutUrl } from "./helpers/urls";
import { ECOSYSTEM_LOGIN_PAGE, HOME_PAGE_ENDPOINT } from "./secrets";

import { AdminAuth } from './components/admin/adminAuth'
import { Admin } from './components/admin/admin'

//for cookies auth
import cookie from 'cookie'
import Firebase from './services/firebase'
import { checkStatus, getAuthUser, logoutFirebase } from './actions/authActions'
import { getRedirectUri } from './services/qsRedirect'
import { getRedirectLocation } from "./components/authComponents/qsHelper";


function CanvasRouter({ match }) {
    localStorage.setItem("nayaCanvasID", match.params.canvas_id);
    return <Canvas id={match.params.canvas_id}></Canvas>;
}
function EstimationRouter({ match }) {
    return <ManualEstimation id={match.params.estimation_id}></ManualEstimation>;
}


class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            //true if user is logged in, has user type match up with their terms of service agreement.
            authenticated: false,
            //checkingAuthStatus happens when the system is checking firebase and mongo if a user is logged in or not. false when the check is done.
            checkingAuthStatus: true
        }
    }

    componentDidMount() {
        const parsedCookie = cookie.parse(document.cookie);
        const { csst = null } = parsedCookie;
        if (!csst)
            logoutFirebase()

        const redirect = getRedirectUri()

        Firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                //verification
                const verified = await getAuthUser()

                if (!verified) {
                    console.log('User does not exist in our database. Please contact us at info@naya.studio')
                    this.setState({
                        checkingAuthStatus: false
                    })
                    return window.location.href = redirect
                }

                //loads user data
                await this.props.loginCookie()

                // const { pathname } = this.props.location
                const callback = localStorage.getItem("nayaEcosystemRedirect");

                if (callback !== null)
                    return this.props.history.push(`/${callback}`)
                
            } else {
                this.setState({ authenticated: false })
                //await is important here and not sure why the linter is throwing a fit, 
                const status = await checkStatus()

                //if not logged in, redirect to homepage
                if (!status) {
                    window.location.href = redirect
                }
            }
        })
    }

    //checks for user_type and if they have accepted our terms of service for their user_type
    //i.e. if DESIGNER then they need to have accepted the designer terms of service.
    componentDidUpdate(prevProps) {
        const { 
            user: { 
                acceptedTermsOfService: {
                    designer: prevDesigner, //boolean
                    maker: prevMaker //boolean
                },
                user_type: prevUserType, //[USER, ADMIN, DESIGNER, MAKER, DESIGNER_MAKER]
                id: prevId //previous _id
            }
        } = prevProps

        const { 
            checkingAuthStatus, 
            user: {
                acceptedTermsOfService: {
                    designer, //boolean
                    maker, //boolean
                },
                user_type, //[USER, ADMIN, DESIGNER, MAKER, DESIGNER_MAKER]
                id, //_id
            } 
        } = this.props

        const redirect_uri = getRedirectLocation()
        
        //When user redux store loads from endpoint and after the user has changed terms of service
        if (
            prevId !== id
            || prevDesigner !== designer
            || prevMaker !== maker
            || prevUserType !== user_type
            || checkingAuthStatus
        ) {

            //if user is admin, allowed to site
            if (user_type === 'ADMIN') {
                return this.setState({
                    checkingAuthStatus: false,
                    authenticated: true
                })
            }

            //if user is a designer and maker and have agreed to both designer and maker Terms of Services, allowed to site, if redirected here, send them back
            if (user_type === 'DESIGNER_MAKER' && (designer && maker)) {
                if (redirect_uri)
                    window.location.href = redirect_uri

                return this.setState({
                    checkingAuthStatus: false,
                    authenticated: true
                })
            }

            //if user is designer and have agreed to the designer Terms of Service, allowed to site, if redirected here, send them back
            if (user_type === 'DESIGNER' && designer) {
                if (redirect_uri)
                    window.location.href = redirect_uri

                return this.setState({
                    checkingAuthStatus: false,
                    authenticated: true
                })
            }

            //if user is fabricator/maker and have agreed to the maker Terms of Service, allowed to site, if redirected here, send them back
            if (user_type === 'MAKER' && maker) {
                if (redirect_uri)
                    window.location.href = redirect_uri

                return this.setState({
                    checkingAuthStatus: false,
                    authenticated: true
                })            
            }

            //sent to click checkbox for agree to Terms of Service page (SplashAuth.js)
            this.setState({
                checkingAuthStatus: false,
                authenticated: false
            })
        }
    }

    requireAuth(nextState, replace, next) {
        if (this.props.user.id === "") {
            replace({
                pathname: "/login",
                state: { nextpathname: nextState.location.pathname },
            });
        }
        next();
    }

    questionRouter = ({ match }) => {
        if (!this.props.no_of_questions || this.props.no_of_questions === 0)
            window.location = "account";
        if (match.params.q_id > this.props.no_of_questions) {
            return <CompleteForm></CompleteForm>;
        }
        let QuestionRender = (
            <Question
                key={match.params.q_id}
                index={match.params.q_id - 1}
            ></Question>
        );
        return QuestionRender;
    };

    userRouter = ({ match }) => {
        
        let UserRender = (
            <UserProfile
                id={match.params.user_id}
            ></UserProfile>
        );
        return UserRender;
    };

    render() {        
        if (!this.state.authenticated){
            return <SplashAuth checkingAuthStatus={this.state.checkingAuthStatus} authenticated={this.state.authenticated} setState={(newState) => this.setState(newState)}/>
        }

        //authenticated is allowed to use the webiste.
        return (
            <Switch>
                <Route
                    path={`/payment-information`}
                    component={PaymentInformation}
                ></Route>
                <Route
                    path={`/estimation/:estimation_id`}
                    component={EstimationRouter}
                ></Route>
                <Route path='/user/:user_id'
                        component={this.userRouter}>
                </Route>
                <Route path='/canvas'>
                    <Canvas></Canvas>
                </Route>

                <Route path='/annotate'>
                    <Annotate></Annotate>
                </Route>

                <Route
                    path={`/q:q_id`}
                    component={this.questionRouter}
                    // onEnter={this.requireAuth}
                ></Route>

                <Route path='/canvas/:canvas_id' component={CanvasRouter} />

                <Route path='/splash'>
                    <EcosystemSplash></EcosystemSplash>
                </Route>

                <Route path='/learn-more' component={LearnMore}></Route>

                <Route
                    path='/basic-information'
                    component={BasicInfo}
                ></Route>

                <Route exact path={['/', '/profile', '/account']} >
                    <Profile setAuth={auth => this.setState(auth)} />
                </Route>

                <Route path='/my-projects' component={Projects}></Route>

                <Route path='/my-estimates' > 
                    <Estimates></Estimates>
                </Route>

                <Route
                    path='/notifications'
                    component={Notifications}
                ></Route>

                <Route
                    path='/estimates/request/:id'
                    component={EstimateRequest}
                />

                <Route path='/admin'>
                    <AdminAuth />
                </Route>

                <Route path='/admin-dashboard' >
                    <Admin />
                </Route>
            </Switch>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        no_of_questions: state.UI.no_of_questions,
        authCallback: state.authCallback,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loginCookie: () => dispatch(loginCookie()),
        loginToken: () => dispatch(loginToken()),
        getQuestions: () => dispatch(getQuestions()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
