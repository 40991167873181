import { API_ENDPOINT } from "../secrets";
import axios from "axios";
import { config, getEmailAndId } from '../services/cookieHelpers'

const credentials = getEmailAndId()

export const addPaymentDetails = (data) => {
    return (dispatch) => {
        const NEW_PAYMENT_DETAILS = `${API_ENDPOINT}/api/upload-payment-details`;
        axios
            .post(NEW_PAYMENT_DETAILS, { ...data, ...credentials }, config)
            .then((response) => {
                dispatch({ type: "SET_PAYMENT_DETAILS", paymentDetails: response.data.paymentDetails });
            })
            .catch((e) => {
                console.log(e);
            });
    };
};