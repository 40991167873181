import React from 'react';
import photo_placeholder from '../../images/photo-placeholder.png';
import CommentMedia from './CommentMedia';

class CommentTile extends React.Component{

  timeDifference(current, previous) {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + ' seconds ago';
    }

    else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + ' mins ago';
    }

    else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + ' h ago';
    }

    else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + ' days ago';
    }

    else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + ' months ago';
    }

    else {
        return Math.round(elapsed / msPerYear) + ' y ago';
    }
  }

  renderCommentHeader = () => {
    return(
      <div className="comment-header">
        <img src={photo_placeholder} alt="placeholder" className="profile-pic" fluid></img>
        <div className="comment-details">
          <h6>{this.props.comment_data.user?.name}</h6>
          <p>posted {this.timeDifference(new Date(), new Date(this.props.comment_data.updatedAt))}</p>
        </div>
      </div>
    )
  }

  renderComment = () => {
    return(
      <div className={this.props.type==="self" ? "self-comment" : "other-comment"}>
        <p dangerouslySetInnerHTML={{__html:this.props.comment_data.comment_text}}></p>
        {
          this.renderCommentMedia()
        }
      </div>
    )
  }

  renderCommentMedia = () => {
    return(
      <div className="comment-media-wrapper">
        {
          this.props.comment_data.comment_image_uploads.map((image, index) => (
            <CommentMedia src={image.link} ext={image.ext} key={index}></CommentMedia>
          ))
        }
      </div>
    )
  }

  render(){
    return(
      <div id="estimate-comment-tile">
        <div className={this.props.type==="self" ? "self-comment-container" : "other-comment-container"}>
        {this.renderCommentHeader()}
        {this.renderComment()}
        </div>
      </div>
    )
  }
}

export default CommentTile;