const initState = {
    questions: [],
    no_of_questions: 0,
    form: {},
    mode: "SET",
    authCallback: "profile",
};

let DEBUG = false;

const UIReducer = (state = initState, action) => {
    let newState;

    if (DEBUG) console.log("Action - " + action.type);

    switch (action.type) {
        case "ADD_QUESTIONS":
            const count = action.value.length;
            newState = {
                ...state,
                questions: action.value,
                no_of_questions: count,
            };
            break;
        case "FILL_FORM":
            const name = action.name;
            const value = action.value;
            console.log(`Filling data - ${name} with value ${value}`);
            newState = {
                ...state,
                form: {
                    ...state.form,
                    [name]: value,
                },
            };
            console.log("FORM FILLED - ");
            console.log(newState.form);
            break;
        case "SET_MODE":
            const mode = action.mode;
            newState = {
                ...state,
                mode: mode,
            };
            break;
        
        case "AUTH_CALLBACK":
            newState = { ...state, authCallback: action.data };
            break;
        default:
            newState = state;
    }

    if (DEBUG) console.log("NEW STATE - ");
    if (DEBUG) console.log(newState);
    return newState;
};

export default UIReducer;
