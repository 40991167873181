import React, { Component } from 'react'
import {Col, Button} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

class BackButton extends Component{

    goBack = (e) => 
    {
        this.props.history.goBack()
    }
    render()
    {
        return (
                <Col xs={3} className="navbar-continue">
                    <Button variant="dark" style={{"marginTop":"4rem", "marginBottom":"4rem"}} onClick={this.props.onSubmit}>Continue</Button>
                </Col>
        )
    }
}

export default withRouter(BackButton)