import axios from "axios";
import { PROJECT_BACKEND_ENDPOINT } from "../secrets";
import { getEmailAndId } from '../services/cookieHelpers'

const postTrigger = (trigger, callback) => {
    const credentials = getEmailAndId()
    const url = `${PROJECT_BACKEND_ENDPOINT}/trigger`;
    console.log(`sending trigger to ${url}`);
    axios
        .post(url, { trigger: trigger, ...credentials }, { crossDomain: true })
        .then((response) => {
            callback(response);
        })
        .catch((e) => {
            console.log("Error sending trigger");
            console.log(e);
        });
};

export { postTrigger };