import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Col, Row} from 'react-bootstrap';
import makerQuestions from './Maker.json';
import designerQuestions from './Designer.json';
import designerMakerQuestions from './M&D.json';
import QuestionModal from './QuestionModal';
import {updateUser} from '../../actions/accountActions';
import '../../stylesheets/questionTiles.css';

class Questions extends React.Component{
  state={
    showAll:true,
    questions:[],
    questionModal:false,
    questionIndex:0,
    disabled: !["DESIGNER_MAKER", "DESIGNER", "MAKER"].includes(this.props.user.user_type),
    type:this.props.user.user_type, 
  }

  toggleQuestionModal = (index) => {
    this.setState({
      questionIndex:index, questionModal:!this.state.questionModal
    })
  }

  componentDidMount(){
    this.getQuestions();
  }

  getQuestions = () => {
    if(this.props.user.user_type === 'DESIGNER_MAKER'){
      this.setState({questions:designerMakerQuestions},()=>{
        this.state.questions.slice(5, this.state.questions.length).map((question, index)=>{
          if(this.checkForAnswer(question)){
            this.setState({showAll:true})
          }
        })
      })
    }
    else if(this.props.user.user_type === 'DESIGNER'){
      this.setState({questions:designerQuestions},()=>{
        this.state.questions.slice(5, this.state.questions.length).map((question, index)=>{
          if(this.checkForAnswer(question)){
            this.setState({showAll:true})
          }
        })
      })
    }
    else{
      this.setState({questions:makerQuestions},()=>{
        this.state.questions.slice(5, this.state.questions.length).map((question, index)=>{
          if(this.checkForAnswer(question)){
            this.setState({showAll:true})
          }
        })
      })
    }
  }

  // function to display the question data answered by the user
  getAnswerData = (question) => {
    let answer = null;
    answer=this.props.questions.filter(x=>x.id===question.id);
    // checking if the answer to a particular question exists in the database or not
    if(answer===null || answer.length===0){
      return(
        <div className="add-question" style={{color:this.state.disabled ? "#a3a3a3" : "#000000"}}>
          +
        </div>
      )
    }
    else{
      switch(question.type){
        case "mcq":
          return(
            <>
            {
              answer[0].selected_options.length===0 ? (
                <div className="add-question" style={{color:this.state.disabled ? "#a3a3a3" : "#000000"}}>
                  +
                </div>
              ) : (
                <Row className="options-container">
                  <Row style={{justifyContent:'center', width:'100%', height:'min-content'}}>
                  {
                    !answer[0].additional_question && answer[0].selected_options.map((option, index)=>(
                      <div 
                        key={index} 
                        style={{cursor:'pointer'}}
                        className={'unselected-option'}
                        dangerouslySetInnerHTML={{__html:option}}>
                      </div>
                    ))
                  }
                  {
                    answer[0].additional_question && (
                      <h4 style={{fontWeight:400,  fontSize:14, color:'#666666'}}>{answer[0].additional_question}</h4>
                    )
                  }
                  </Row>
                </Row>
              )
            }
            </>
          )
          break;
        case "production-capacity":
          return(
            <Row className="options-container">
              <Row style={{justifyContent:'center', width:'100%', marginTop:'3rem'}}>
                <h4 style={{width:'40%', marginLeft:'1rem', marginRight:'1rem', fontSize:20, height:30, paddingBottom:'0.5rem', borderBottom:'1px solid #666666', marginBottom:5, fontWeight:400, }}>{answer[0].production_capacity}</h4>
                <h6 style={{fontSize:14, color:'#666666', width:'100%'}}>units per order</h6>
              </Row>
            </Row>
          )
          break;
        case "dimensions":
          return(
            <Row className="options-container">
              <div style={{color:'#a3a3a3', width:'100%'}}>units: <span style={{color:'#000000'}}>feet</span></div>
              <div style={{margin:'0.2rem'}}>
                <div style={{paddingBottom:'0.3rem', borderBottom:'1px solid #666666', fontWeight:400,  fontSize:18}}>{answer[0].dimensions[0]}</div>
                <div style={{ textAlign:'center', color:'#666666', fontWeight:400, fontSize:14}}>length</div>
              </div>
              <div style={{ textAlign:'center', color:'#666666', fontWeight:100, fontSize:35, marginTop:'-0.5rem'}}>&#xd7;</div>
              <div style={{margin:'0.2rem'}}>
                <div style={{paddingBottom:'0.3rem', borderBottom:'1px solid #666666', fontWeight:400,  fontSize:18}}>{answer[0].dimensions[1]}</div>
                  <div style={{ textAlign:'center', color:'#666666', fontWeight:400, fontSize:14}}>width</div>
              </div>
              <div style={{ textAlign:'center', color:'#666666', fontWeight:100, fontSize:35, marginTop:'-0.5rem'}}>&#xd7;</div>
              <div style={{margin:'0.2rem'}}>
                  <div style={{paddingBottom:'0.3rem', borderBottom:'1px solid #666666', fontWeight:400,  fontSize:18}}>{answer[0].dimensions[2]}</div>
                  <div style={{ textAlign:'center', color:'#666666', fontWeight:400, fontSize:14}}>height</div>
              </div>
            </Row>
          )
          break;
        case "price-range":
          return(
            <Row className="options-container" style={{paddingTop:'1rem', paddingLeft:'1rem', paddingRight:'1rem'}}>
              <Row style={{paddingTop:"2rem", display:'flex', justifyContent:'center'}}>
                <div style={{ textAlign:'center', color:'#999999', fontWeight:100, fontSize:22, marginRight:5}}>$</div>
                <div style={{paddingBottom:'0.3rem', borderBottom:'1px solid #666666', height:30, fontWeight:400,  fontSize:18}}>
                  {answer[0].price_range[0]}
                </div>
                <div style={{ textAlign:'center', color:'#999999', fontWeight:100, fontSize:14, marginLeft:10, marginRight:10, height:30, display:'flex', alignItems:'center'}}>to</div>
                <div style={{ textAlign:'center', color:'#999999', fontWeight:100, fontSize:22, marginRight:5}}>$</div>
                <div style={{paddingBottom:'0.3rem', borderBottom:'1px solid #666666', height:30, fontWeight:400,  fontSize:18}}>
                  {answer[0].price_range[1]}
                </div>
              </Row>
            </Row>
          )
          break;
        case "work-experience":
          return(
            <Row className="options-container">
              <Row style={{justifyContent:'center', width:'100%', marginTop:'3rem'}}>
                <h4 style={{width:'40%', marginLeft:'1rem', marginRight:'1rem', fontSize:20, height:30, paddingBottom:'0.5rem', borderBottom:'1px solid #666666', marginBottom:5, fontWeight:400, }}>{answer[0].work_experience}</h4>
                <h6 style={{fontSize:14, color:'#666666', width:'100%'}}>years</h6>
              </Row>
            </Row>
          )
          break;
        case "production-scale":
          return(
            <Row className="options-container" style={{paddingTop:'1rem', paddingLeft:'1rem', paddingRight:'1rem'}}>
              <Row style={{paddingTop:"2rem", display:'flex', justifyContent:'center'}}>
                <div>
                  <div style={{color:'#666666',  fontWeight:400, fontSize:12}}>Artisanal</div>
                  <div style={{paddingBottom:'0.3rem', borderBottom:'1px solid #666666', fontWeight:400,  fontSize:18}}>
                    {answer[0].production_scale[0]}
                  </div>
                  <div style={{color:'#666666',  fontWeight:400, fontSize:10}}>units per order</div>
                </div>
                <div style={{ textAlign:'center', color:'#999999', fontWeight:100, fontSize:14, marginLeft:10, marginRight:10}}>to</div>
                <div>
                  <div style={{color:'#666666',  fontWeight:400, fontSize:12}}>Production</div>
                  <div style={{paddingBottom:'0.3rem', borderBottom:'1px solid #666666', fontWeight:400,  fontSize:18}}>
                    {answer[0].production_scale[1]}
                  </div>
                  <div style={{color:'#666666',  fontWeight:400, fontSize:10}}>units per order</div>
                </div>
              </Row>
            </Row>
          )
          break;
        case "additional-info":
          return(
            <>
            {
              answer[0].additional_info.length===0 ? (
                <div className="add-question" style={{color:this.state.disabled ? "#a3a3a3" : "#000000"}}>
                  +
                </div>
              ) : (
                <Row className="options-container" style={{paddingTop:'1rem', paddingLeft:'1rem', paddingRight:'1rem'}}>
                  <div style={{color:'#666666',  fontWeight:400, fontSize:12, wordBreak:'break-all'}}>{answer[0].additional_info}</div>
                </Row>
              )
            }
            </>
          )
          break;
        default:
          return(
            <div className="add-question" style={{color:this.state.disabled ? "#a3a3a3" : "#000000", fontSize:12}}>
              Answer Found
            </div>
          )
          break;
      }
    }
  }

  // function to check if an answer exists
  checkForAnswer = (question) => {
    let answer = null;
    answer=this.props.questions.filter(x=>x.id===question.id);
    if(answer===null || answer.length===0){
      return false;
    }
    else{
      if(answer[0].type==='mcq'){
        if(answer[0].selected_options.length===0){
          return false;
        }
        else{
          return true;
        }
      }
      else if(answer[0].type==='additional-info'){
        if(answer[0].additional_info.length===0){
          return false;
        }
        else{
          return true;
        }
      }
      return true;
    }
  }

  render(){
    return(
      <Col id="questions">
        {/* MODAL FOR EACH QUESTION */}
        {
          this.state.questionModal && <QuestionModal isVisible={this.state.questionModal} questionData={this.state.questions} toggleQuestionModal={this.toggleQuestionModal} questionIndex={this.state.questionIndex}/>
        }
        <Row>
          <h6 style={{width:'100%', textAlign:'center',  fontWeight:'bold', marginBottom:20, marginTop:20, fontSize:20, color:this.state.disabled ? "#a3a3a3" : "#000000"}}>Some important questions</h6>
        </Row>
        {/* DISPLAYING THE QUESTION TILES AS PER - 
        1. USER TYPE(DESIGNER OR MAKER OR BOTH) 
        2. WHETHER ALL QUESTIONS SHOWULD BE DISPLAYED OR SOME OF THEM SHOULD BE HIDDEN */}
        <div class="questions-container">
        {
            this.state.questions.slice(0,this.state.showAll ? this.state.questions.length : 5).map((question, index)=>(
              <div key={index} className="question-tile-container" style={{paddingTop:'1.5rem'}}>
                <div 
                  className="question-tile" 
                  // DISABLED IF NEITHER DESIGNER NOR MAKER IS SELECTED
                  onClick={()=>{!this.state.disabled && this.toggleQuestionModal(index)}}>
                  <h4 className="question-title" style={{color:this.state.disabled ? "#a3a3a3" : "#000000"}}>{question.question}</h4>
                  {
                    this.getAnswerData(question)
                  }
                </div>
                {/* CHECKING IF THE ANSWER EXISTS FOR A QUESTION. iF ANSWER EXISTS, DISPLAYING AN EDIT OPTION AT THE BOTTOM OF THE TILE */}
                {
                  this.checkForAnswer(question) ?(
                    <div className="edit-question" onClick={()=>this.toggleQuestionModal(index)} edit={true}>edit</div>
                  ) : <div style={{height:24}}></div>
                }
              </div>
            ))
          }
        </div>
        {/* SHOW ALL OPTION */}
        {
          !this.state.showAll && (
            <>
            <Row>
              <h6 style={{width:'100%', textAlign:'center',  fontWeight:'bold', marginBottom:20, marginTop:20, fontSize:20, color:this.state.disabled ? "#a3a3a3" : "#000000"}}>Some other important questions</h6>
            </Row>
            <Row>
              <div className="add-question" style={{color:this.state.disabled ? "#a3a3a3" : "#000000"}} onClick={()=>{
                if(!this.state.disabled){
                  this.setState({showAll:true})}
                }
              }>+</div>
            </Row>
            </>
          )
        }
      </Col>
    )
  }

  checkForUpdate = () => {
    if(this.state.disabled!==!["DESIGNER_MAKER", "DESIGNER", "MAKER"].includes(this.props.user.user_type)){
      this.setState({disabled:!this.state.disabled})
    }
    if(this.state.type!==this.props.user.user_type){
      this.setState({type:this.props.user.user_type},this.getQuestions());
    }
  }

  componentDidUpdate()
  {
      this.checkForUpdate()
  }

}

const mapStateToProps = (state) => {
  return {
      user: state.user,
      questions:state.user.questions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(updateUser(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Questions));