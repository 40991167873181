const initNotifications = {
    notifications:[]
}

let DEBUG = false

const notificationsReducer = (state = initNotifications, action) => {
    let newState

    if(DEBUG) console.log("NOTIFICATIONS REDUCER:")
    if(DEBUG) console.log("ACTION:")
    if(DEBUG) console.log(action)

    if(DEBUG) console.log("CURRENT STATE:")
    if(DEBUG) console.log(state)

    switch(action.type)
    {
        case 'GET_NOTIFICATIONS':
            newState = {
                ...state, 
                notifications:action.notifications, 
            }
            break;
        default:
            newState = state
    }

    if(DEBUG) console.log("NEW STATE:")
    if(DEBUG) console.log(newState)

    return newState
}

export default notificationsReducer