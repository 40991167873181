import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import '../../stylesheets/profile.css'
import { checkProfileStatus, calculateColorInGradient, findMissingQuestions, statusQuestions, ecosystemTypes } from './helpers/profileStatus'

const statusColors = {
  'Complete': '#29CF00',
  'Basic': '#d6b60a',
  'Incomplete': 'red'
}

const findBackgroundColor = (
    length = 0,
    active = 0,
    position
  ) => {

  if ((position + 1) > active) {
    //grey
    return '#d3d3d3'
  }

  const ratio = (position + 1) / length
  const color = calculateColorInGradient(position === 0 ? 0 : ratio)

  return `var(--theme-color-2)`
}

const ProgressBar = ({
    user
  }) => {
  const [ hover, setHover ] = useState(false)
  const profileStatusRx = useSelector(state => checkProfileStatus(state.user))
  const missingQuestionsRx = useSelector(state => findMissingQuestions(state.user)?.filter(q => q.type === 'mcq'))
  const missingProjectsRx = useSelector(state => findMissingQuestions(state.user)?.find(q => q.type === 'projects'))
  const questionIdsRx = useSelector(state => {
    if (!ecosystemTypes.includes(state.user.user_type))
      return []

    const questions = statusQuestions[state.user.user_type]()
    return [...questions, 'projects']
  })

  let profileStatus
  let missingQuestions
  let missingProjects
  let questionIds
  
  if (user) {
    profileStatus = checkProfileStatus(user)
    missingQuestions = findMissingQuestions(user)?.filter(q => q.type === 'mcq')
    missingProjects = findMissingQuestions(user)?.find(q => q.type === 'projects')
    questionIds = []
    if (ecosystemTypes.includes(user.user_type)) {
      const questions = statusQuestions[user.user_type]()
      questionIds = [...questions, 'projects']
    }
  } else {
    profileStatus = profileStatusRx
    missingQuestions = missingQuestionsRx
    missingProjects = missingProjectsRx
    questionIds = questionIdsRx
  }

  const handleMouseEnter = (e, entering) => {
    e.preventDefault()
    setHover(entering)
  }

  return (
    <div id='progress-bar' onMouseOver={(e) => handleMouseEnter(e, true)} onMouseLeave={(e) => handleMouseEnter(e, false)}>
      <span style={{fontSize: '18px'}} className='header'>Profile Status: <span style={{color: statusColors[profileStatus]}}> {profileStatus} </span> 
        </span>
      <div className='progress-stepper-container'>
        {questionIds.map((q, i) => (
          <span
            key={`progress-bar-${i}`}
            style={{
              backgroundColor: findBackgroundColor(questionIds.length, questionIds.length - (missingQuestions.length + (missingProjects ? 1 : 0)), i)
            }}
          />
        ))}
      </div>
      {(missingQuestions.length > 0 || missingProjects) && hover && (
        <div className='missing-progress-container'>
          You can improve your visibility by doing the following:
          <ul className='details'>
            {missingProjects && (
              <li>
                {missingProjects.message}
              </li>
            )}
            {missingQuestions.length > 0 && (
              <li>
                Answer the following questions:
                <ul className='question-ul'>
                  {missingQuestions.length > 0 && missingQuestions.map((q, i) => (
                    <li key={`progress-bar-question-li-${i}`}>
                      {q.message}
                    </li>
                  ))}
                </ul>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ProgressBar