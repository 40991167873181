import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form, Dropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import {
    createAnnotation,
    editAnnotation,
    getAnnotation,
    deleteAnnotation,
} from "../../actions/annotationActions";
import { postComment } from "../../actions/approvalCommentsActions";
import CanvasDraw from "react-canvas-draw";
import "../../stylesheets/style.css";
import "../../stylesheets/annotate.css";
import axios from "axios";
import { CirclePicker } from "react-color";
import NavBar from "../utilities/NavBar";
import { DEBUG } from "../../secrets";

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <p
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </p>
));

class AnnotateApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 100,
            height: 100,
            imgLoaded: false,
            annotationText: "",
            brushColor: "#aa0000",
            brushRadius: 5,
        };

        this.imgContainer = React.createRef();
    }

    componentDidMount() {
        // this.props.getAnnotation({id: this.props.annotationId})
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.editAnnotation({
            id: this.props.annotation._id,
            annotationSrc: this.props.annotation.annotationSrc,
            annotationData: this.saveableCanvas.getSaveData(),
        });
        this.props.postComment({
            canvas_id: this.props.canvasID,
            user: this.props.user.id,
            annotation_linked: this.props.annotation._id,
            comment_text: this.state.annotationText,
        });
        this.props.history.push("canvas");
    };

    handleCancel = (e) => {
        e.preventDefault();
        this.props.deleteAnnotation({ id: this.props.annotation._id });
        this.props.history.push("canvas");
    };

    handleColor = (color, e) => {
        if (DEBUG) console.log(color.hex);
        if (DEBUG) console.log(e);
        this.setState({ brushColor: color.hex });
    };

    getMeta = (url, callBack) => {
        if (DEBUG) console.log(url);

        var img = new Image();
        img.onload = function () {
            if (DEBUG) console.log("Image loaded");
            callBack(url, this.width, this.height);
        };
        img.src = url;
    };

    handleChange = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    };

    renderBrushRadii = (n) => {
        let items = [];
        for (var i = 1; i < n; i++) {
            items.push(
                <Dropdown.Item
                    key={i}
                    name={i}
                    onClick={(e) => {
                        this.setState({ brushRadius: e.target.name });
                    }}
                >
                    {i}px
                </Dropdown.Item>
            );
        }
        return items;
    };

    render() {
        let colors = [
            "#eeeeee",
            "#000000",
            "#f44336",
            "#e91e63",
            "#9c27b0",
            "#673ab7",
            "#3f51b5",
            "#2196f3",
            "#03a9f4",
            "#00bcd4",
            "#009688",
            "#4caf50",
            "#8bc34a",
            "#cddc39",
            "#ffeb3b",
            "#ffc107",
            "#ff9800",
            "#ff5722",
            "#795548",
            "#607d8b",
        ];
        return (
            <Container fluid={true} id="annotate-app">
                <NavBar></NavBar>
                {this.props.annotation && this.props.annotation.annotationSrc && (
                    <Col
                        xs={{ span: 10, offset: 1 }}
                        lg={{ span: 6, offset: 3 }}
                    >
                        <h2 className="col-12">Markup and Post</h2>

                        <Row>
                            <Col xs={12} style={{ height: "max-content" }}>
                                <img
                                    alt=""
                                    src={this.props.annotation.annotationSrc}
                                    ref={this.imgContainer}
                                    style={{ maxWidth: "100%" }}
                                ></img>
                                {this.state.imgLoaded && (
                                    <CanvasDraw
                                        // imgSrc={this.props.annotation.annotationSrc}
                                        ref={(canvasDraw) =>
                                            (this.saveableCanvas = canvasDraw)
                                        }
                                        brushColor={this.state.brushColor}
                                        canvasHeight={this.state.height}
                                        backgroundColor={"transparent"}
                                        canvasWidth={this.state.width}
                                        brushRadius={this.state.brushRadius}
                                        lazyRadius={0}
                                        style={{
                                            position: "absolute",
                                            top: "0",
                                        }}
                                    ></CanvasDraw>
                                )}

                                <Col xs={12} id="annotate-tools">
                                    <Row>
                                        <Col xs={12} lg={2}>
                                            <Row>
                                                <Dropdown className={"col-4"}>
                                                    <Dropdown.Toggle
                                                        as={CustomToggle}
                                                        id="dropdown-custom-components"
                                                    >
                                                        <div
                                                            className="color-dropdown-toggle"
                                                            style={{
                                                                width: "2rem",
                                                                height: "2rem",
                                                                borderRadius:
                                                                    "2rem",
                                                                backgroundColor: this
                                                                    .state
                                                                    .brushColor,
                                                            }}
                                                        ></div>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item eventKey="1">
                                                            <CirclePicker
                                                                colors={colors}
                                                                onChange={
                                                                    this
                                                                        .handleColor
                                                                }
                                                            />
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <Dropdown className={"col-6"}>
                                                    <Dropdown.Toggle
                                                        as={CustomToggle}
                                                        id="dropdown-custom-components"
                                                    >
                                                        <Form.Control
                                                            value={
                                                                this.state
                                                                    .brushRadius
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            name="brushRadius"
                                                        ></Form.Control>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu
                                                        style={{
                                                            maxHeight: "300px",
                                                            textAlign: "center",
                                                            overflowY: "scroll",
                                                        }}
                                                    >
                                                        {this.renderBrushRadii(
                                                            20
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Row>
                                        </Col>

                                        <Col
                                            xs={4}
                                            lg={1}
                                            className="tools-links"
                                        >
                                            <p
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    this.saveableCanvas.undo();
                                                }}
                                            >
                                                Undo
                                            </p>
                                        </Col>

                                        <Col
                                            xs={4}
                                            lg={1}
                                            className="tools-links"
                                        >
                                            <p
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    this.saveableCanvas.clear();
                                                }}
                                            >
                                                Clear
                                            </p>
                                        </Col>

                                        <Col
                                            xs={4}
                                            lg={1}
                                            className="tools-links"
                                        >
                                            <p
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    this.setState({
                                                        brushColor: "#ffffff",
                                                    });
                                                }}
                                            >
                                                Eraser
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Form.Control
                                    as="textarea"
                                    rows="3"
                                    value={this.state.annotationText}
                                    name={"annotationText"}
                                    onChange={this.handleChange}
                                    className="annotate-textarea"
                                ></Form.Control>
                            </Col>

                            <Col xs={12}>
                                <Row className="justify-content-md-center">
                                    <Button
                                        variant="dark"
                                        onClick={this.handleSubmit}
                                    >
                                        POST
                                    </Button>
                                </Row>
                                <Row className="justify-content-md-center">
                                    <p
                                        className="skip-button"
                                        onClick={this.handleCancel}
                                    >
                                        CANCEL
                                    </p>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Container>
        );
    }

    componentDidUpdate() {
        if (
            !this.state.imgLoaded &&
            this.props.annotation &&
            this.imgContainer
        ) {
            this.getMeta(
                this.props.annotation.annotationSrc,
                (url, width, height) => {
                    let wrapperWidth = this.imgContainer.current.clientWidth;
                    if (DEBUG) console.log("callback reached");
                    if (DEBUG) console.log(wrapperWidth);
                    this.setState({
                        height: (height * wrapperWidth) / width,
                        width: wrapperWidth,
                        imgLoaded: true,
                    });
                }
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        canvasID: localStorage.getItem("nayaCanvasId"),
        user: state.user,
        annotationId: localStorage.getItem("currentAnnotation"),
        annotation: state.annotations.current,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createAnnotation: (data) => dispatch(createAnnotation(data)),
        editAnnotation: (data) => dispatch(editAnnotation(data)),
        getAnnotation: (data) => dispatch(getAnnotation(data)),
        deleteAnnotation: (data) => dispatch(deleteAnnotation(data)),
        postComment: (data) => dispatch(postComment(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AnnotateApp));
