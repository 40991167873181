import { API_ENDPOINT } from "../secrets";
import axios from "axios";
import { config, getEmailAndId, getConfigEmail } from '../services/cookieHelpers'

const configEmail = getConfigEmail()

/**
 * Get questions for Onborading
 */
const getQuestions = (callBack, tag, emptyResult) => {
    const configEmail = getConfigEmail()

    return (dispatch) => {
        console.log("Getting questions with " + tag);
        // Maker Questions
        var INIT_QUESTIONS_URI;
        if (tag) INIT_QUESTIONS_URI = `${API_ENDPOINT}/api/survey?tag=${tag}`;
        else INIT_QUESTIONS_URI = `${API_ENDPOINT}/api/survey`;
        axios
            .get(INIT_QUESTIONS_URI, configEmail)
            .then((response) => {
                dispatch({
                    type: "ADD_QUESTIONS",
                    key: "makerQuestions",
                    value: response.data.question,
                });
                console.log(response.data.question);
                if (response.data.question.length === 0) emptyResult();
                else callBack();
            })
            .catch((e) => {
                dispatch({ type: "ERROR_MAKER_QUESTIONS", data: {} });
            });
    };
};

const getQuestionsWithFields = (callBack, tag, field, emptyResult) => {
    const configEmail = getConfigEmail()

    return (dispatch) => {
        console.log("Getting questions with " + tag + " fields " + field);
        // Maker Questions
        var INIT_QUESTIONS_URI;
        if (tag)
            INIT_QUESTIONS_URI = `${API_ENDPOINT}/api/survey?tag=${tag}&field=${field}`;
        else INIT_QUESTIONS_URI = `${API_ENDPOINT}/api/survey`;
        axios
            .get(INIT_QUESTIONS_URI, configEmail)
            .then((response) => {
                dispatch({
                    type: "ADD_QUESTIONS",
                    key: "makerQuestions",
                    value: response.data.question,
                });
                console.log(response.data.question);
                if (response.data.question.length === 0) emptyResult();
                else callBack();
            })
            .catch((e) => {
                console.log(e);
                dispatch({ type: "ERROR_MAKER_QUESTIONS", data: {} });
            });
    };
};

const fillForm = (field) => {
    return (dispatch) => {
        console.log("Adding field to form...");
        dispatch({ type: "FILL_FORM", name: field.name, value: field.value });
    };
};

export { getQuestions, fillForm, getQuestionsWithFields };
