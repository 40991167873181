import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Container, Row, Col} from 'react-bootstrap';
import CanvasManager from '../accountComponents/CanvasManager';
import Navbar from '../utilities/navbar/Navbar';
import '../../stylesheets/account.css';

class Projects extends React.Component{

  renderDesignCanvases = () => {
    if (
        this.props.user.user_type === "DESIGNER" ||
        this.props.user.user_type === "DESIGNER_MAKER"
    ) {
        return (
            <CanvasManager
                projects={this.props.designCanvases}
                type={"Design"}
            ></CanvasManager>
        );
    } else {
        return <p></p>;
    }
  };

  renderFabricationCanvases = () => {
      if (
          this.props.user.user_type === "MAKER" ||
          this.props.user.user_type === "DESIGNER_MAKER"
      ) {
          return (
              <CanvasManager
                  projects={this.props.fabricationCanvases}
                  type={"Fabrication"}
              ></CanvasManager>
          );
      } else {
          return <p></p>;
      }
  };

  render(){
    return(
      <>
      {/* <Navbar/> */}
      {/* <Container>
        <Row>
          <Container className="account-details">
            <Col xs={12}>
                <Row>
                    {this.renderDesignCanvases()}
                    {this.renderFabricationConsultingCanvases()}
                    {this.renderFabricationCanvases()}
                </Row>
            </Col>
          </Container>
        </Row>
      </Container> */}
      <div>
      <Navbar/>
        <Container
            id="account"
            fluid
        >
            <Container>
                <Row>
                    <Container className="account-details">
                        <Col xs={12}>
                            <Row>
                                {this.renderDesignCanvases()}
                                {this.renderFabricationCanvases()}
                            </Row>
                        </Col>
                        
                    </Container>
                </Row>
            </Container>
        </Container>
    </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      user: state.user,
      designCanvases: state.user.design_canvas,
      fabricationCanvases: state.user.fabrication_canvas,
      consultingCanvases: state.user.consulting_canvas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateUser: (data) => dispatch(updateUser(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Projects));