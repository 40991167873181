import React, { Component } from "react";
import {
    Container,
    Row,
    Col,
    Alert,
    Form,
    Button,
    Image,
} from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
    AUTH_REGISTER_API_URL,
    AUTH_USER_PREFS_API_URL,
    USER_APP_ENDPOINT,
} from "../../secrets.js";
import axios from "axios";
import "../../stylesheets/style.css";
import logo from "../../images/naya-logo-white.png";
import { DEBUG } from "../../secrets";
import { getQuestions } from "../../actions/surveyActions";
import { signUpInFacebook, signUpInGoogle } from "../../actions/userActions"
import {ReactComponent as GoogleIcon } from '../../images/g-logo.svg'
import {ReactComponent as FacebookIcon } from '../../images/f-logo.svg'

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";


class Auth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            confirmPassword: "",
            isDesigner: false,
            isMaker: false,
            showPasswordError: false,
            showFunctionError: false,
            showRegistrationError: false,
            registrationMessage: "",
            isRegistered: false,
            designProjects: "I don't know yet",
            fabricationProjects: "I don't know yet",
            userDetails: {},
            showEmailForm: false
        };
    }

    componentDidMount() {
        // document.body.style.backgroundColor = "#000";
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
            showFunctionError: false,
            showPasswordError: false,
            showRegistrationError: false,
        });
    };

    handleCheck = (event) => {
        const name = event.target.getAttribute('name')

        this.setState({
            [name]: !this.state[name],
            showFunctionError: false,
            showPasswordError: false,
        })
    }

    confirmPassword = () => {
        if (this.state.password === this.state.confirmPassword) {
            return true;
        } else {
            return false;
        }
    };

    getUserType(){
        let userType = "DESIGNER_MAKER";
        // if (this.state.isDesigner) {
        //     if (this.state.isMaker) {
        //         userType = "DESIGNER_MAKER";
        //     } else {
        //         userType = "DESIGNER";
        //     }
        // } else if (this.state.isMaker) {
        //     userType = "MAKER";
        // }
        return userType
    }

    createUserPrefs = (user_id) => {
        let data = {};
        let options = [];

        if (this.state.isDesigner) {
            options.push({
                key: "designProjects",
                value: this.state.designProjects,
            });
        }
        if (this.state.isMaker) {
            options.push({
                key: "fabricationProjects",
                value: this.state.fabricationProjects,
            });
        }

        data.user_id = user_id;
        data.options = options;

        axios
            .post(AUTH_USER_PREFS_API_URL, data, { crossdomain: true })
            .then((response) => {
                if (DEBUG) console.log(response);
                if (!response.data.status) {
                    this.setState({
                        showRegistrationError: true,
                        registrationMessage:
                            "Something went wrong. Please contact support info@naya.studio",
                    });
                }
            });
    };

    sendRegistrationRequest = (data) => {
        if (DEBUG) console.log("Sending register request with data:");
        if (DEBUG) console.log(data);
        if (DEBUG) console.log(AUTH_REGISTER_API_URL);
        axios
            .post(AUTH_REGISTER_API_URL, data, { crossdomain: true })
            .then((response) => {
                if (DEBUG) console.log(response);
                if (!response.data.status) {
                    this.setState({
                        showRegistrationError: true,
                        registrationMessage: response.data.message,
                    });
                } else {
                    this.createUserPrefs(response.data.user._id);
                    this.setState({
                        isRegistered: true,
                        userDetails: response.data.user,
                    });
                }
            });
    };

    handleSignupGoogle = (e) => {
        e.preventDefault()

        if(this.state.agreeToTM)
        {
            this.props.signUpInGoogle(this.getUserType())
        }
        else
        {
            this.setState({"showRegistrationError": true, registrationMessage:"Please agree to the terms and conditions before proceeding"})
        }
    }

    handleSignupFacebook = (e) => {
        e.preventDefault()
        if(this.state.agreeToTM)
        {
            this.props.signUpInFacebook(this.getUserType())
        }
        else
        {
            this.setState({"showRegistrationError": true, registrationMessage:"Please agree to the terms and conditions before proceeding"})
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.confirmPassword()) {
            let userType = this.getUserType();

            // if (typeof userType == "undefined") {
            //     this.setState({ showFunctionError: true });
            // } 
            // else {
                const data = {
                    email: this.state.email,
                    password: this.state.password,
                    user_type: userType,
                };
                this.sendRegistrationRequest(data);
            // }
        } else {
            this.setState({ showPasswordError: true });
        }
    };


    isRegistered = () => {
        if (this.state.isRegistered) {
            this.props.history.push("/login");
        } else {
            return (<><Row className="justify-content-md-center">
                <Col xs={{ span: 8, offset: 2 }} lg={6}>
                    <Row>
                        <Col xs={4} md={{ span: 4 }} className="img-wrapper">
                            <Image src={logo} fluid style={{ filter: "invert()" }} />
                        </Col>
                        <Col xs={8} md={8}>
                            <h1>naya</h1>
                        </Col>
                    </Row>
                </Col>
            </Row>
                <h3 style={{ textAlign: "center", marginBottom: "3rem", "fontSize": "2rem" }}>Sign Up</h3>
                {
                // this.state.showEmailForm ?
                    this.renderEmailForm()
                    // : 
                    // this.renderRoleForm()
                    }
            </>)
        }
    };

    renderRoleForm() {
        return (<Form style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <Form.Group className="vertical">

                {this.state.showFunctionError && (
                    <Alert variant="danger">
                        Error: You need to choose at least one function
                    </Alert>
                )}
                <h3>Choose a Role</h3>
                <Row className="vertical">
                    <div className={`box ${this.state.isDesigner ? "checked" : ""}`}
                        name="isDesigner"
                        style={{ "fontWeight": "400" }}
                        onClick={this.handleCheck}>
                        Designer

                    </div>
                    <div className={`box ${this.state.isMaker ? "checked" : ""}`}
                        name="isMaker"
                        style={{ "fontWeight": "400" }}
                        onClick={this.handleCheck}>
                        Maker
                    </div>
                </Row>

            </Form.Group>
            <div className="info">
                {this.state.isDesigner &&
                    <p><a rel="noopener noreferrer" target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vR_GeIO6TMGWSYLcV1fqJg8JdLKvcS06xmsizuYQiORgIZ8CfRsY8S8O3JnyKk_8doMRsCetThtFzqq/pub">Designer Guidelines and Tips</a></p>}
                {this.state.isMaker &&
                    <p><a rel="noopener noreferrer" target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vTI0DYTnyf7xkyVPFcV7UpmNY44USpNL5VjbwjrBpLpjjseABvv7fknz0iD0NvFuG5wO0hxgaNrRogA/pub">Maker Guidelines and Tips</a></p>}
                {(this.state.isMaker || this.state.isDesigner) &&
                    <p>Please review and bookmark the above document(s) before registering.</p>}
            </div>

            <div style={{ "marginTop": "2rem" }}>
                <Button variant="dark" onClick={() => {
                    if (!this.state.isDesigner && !this.state.isMaker) {
                        this.setState({ showFunctionError: true });
                    } else {
                        this.setState({ showEmailForm: true })
                    }
                }
                }>Next</Button>


            </div>
        </Form>)
    }

    renderEmailForm() {
        return (<Form onSubmit={this.handleSubmit}>
            {/* <p
                    style={{ textAlign: "center", color: "#007bff", cursor: "pointer" }}
                    onClick={() => { this.setState({ showEmailForm: false }) }}>
                    Back
                </p> */}
            {this.state.showRegistrationError && (
                <Alert variant="danger">
                    {this.state.registrationMessage ||
                        "Error: Please contact info@naya.studio to resolve this error"}
                </Alert>
            )}
            <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                />
            </Form.Group>

            <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>

                {this.state.showPasswordError && (
                    <Alert variant="danger">
                        Error: Passwords do not match
                    </Alert>
                )}
                <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleInputChange}
                />
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={this.state.confirmPassword}
                    onChange={this.handleInputChange}
                    placeholder="Re-type Password"
                />
            </Form.Group>

            <Form.Check
            // type="checkbox"
            // name="isMaker"
            // checked={this.state.isMaker}
            // onChange={this.handleInputChange}
            // label="I have read the terms and conditions"
            >
                <Form.Check.Input type="checkbox" name="agreeToTM" checked={this.state.agreeToTM} onChange={this.handleInputChange} isValid />
                <Form.Check.Label style={{ "color": "black" }}>I have read the previous documents and agree to the <a target="_blank" rel="noopener noreferrer" href="https://app.naya.studio/tos/designer">designer</a> and <a target="_blank" rel="noopener noreferrer" href="https://app.naya.studio/tos/maker">maker</a> terms and conditions</Form.Check.Label>
            </Form.Check>
            <Col xs={{ span: 8, offset: 2 }} style={{ "marginTop": "2rem" }}>
                <Button variant="dark" type="submit" style={{ "textTransform": "none", "width": "100%", "fontSize": "1.2rem", "fontWeight": "700", "borderRadius": "5px", "padding": "1rem" }} disabled={!this.state.agreeToTM}>
                    Register
                            </Button>
                <p style={{"borderBottom":"1px solid black", "textAlign":"center", "lineHeight":"0.1em", "marginTop":"1rem"}}>
                    <span style={{"backgroundColor":"white", "padding":"0 1rem"}}>or</span>
                </p>
                <Button onClick={this.handleSignupGoogle} className='col-12 col-lg-10 order-1 order-lg-2' style={{"color":"black", "textTransform":"none", "fontSize":"1.1rem"}} id="google-btn"> 
                    <GoogleIcon  style={{height: "18px", width: "18px", marginRight: "15px"}}/>
                    Sign in with Google
                </Button>
                <Button onClick={this.handleSignupFacebook} className='col-12 col-lg-10 order-1 order-lg-2' style={{"textTransform":"none", "fontSize":"1.1rem"}} id="facebook-btn"> 
                    <FacebookIcon  style={{height: "18px", width: "18px", marginRight: "15px"}}/>
                    Sign in with Facebook
                </Button>
                <p style={{ textAlign: "center", marginTop: "1rem" }}>
                    Already signed up?{" "}
                    <a
                        href={`${USER_APP_ENDPOINT}/login-ecosystem`}
                        style={{ fontWeight: "700" }}
                    >
                        Login here
                    </a>
                </p>
            </Col>


        </Form>)
    }

    render() {
        let content = this.isRegistered();
        return (
            <Container className="wrapper" id="register-wrapper">
                <Row>
                    <Col
                        xs={{ span: 12, offset: 0 }}
                        lg={{ span: 6, offset: 3 }}
                    >
                        {content}
                    </Col>

                </Row>
            </Container>
        );
    }

    componentDidUpdate(){
        if(this.isLoggedIn()){
            let redirect = localStorage.getItem("nayaEcosystemRedirect");
            if(redirect){
                this.props.history.push(`/${redirect}`)
            }
            else{
                this.props.history.push('/profile')
            }
        }
    }

    isLoggedIn = () => {
        if(this.props.user && this.props.user.id){
            return true;
        }
        else{
            return false;
        }
    }
}

/** Maps redux state to component props */
const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        history: ownProps["history"],
        flow: state.UI.flow,
        questions: state.UI.questions,
    };
};

/** Maps redux ations to component props */
const mapDispatchToProps = (dispatch) => {
    return {
        getInitData: () => dispatch(getQuestions()),
        setPathway: (data) => dispatch({ type: "SET_PATHWAY", tag: data }),
        signUpInGoogle      : (user_type) => signUpInGoogle(user_type), 
        signUpInFacebook    : (user_type) => signUpInFacebook(user_type),
        dispatchLoginError  : () => dispatch({
                                        type: "LOGIN_ERROR",
                                        data: "Please agree to the terms and conditions before proceeding",
                                    })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Auth));
