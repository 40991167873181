import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Container, Row, Col, Image, Form, Button, Modal} from 'react-bootstrap';
import Navbar from '../utilities/navbar/Navbar';
import userIcon from '../../images/icons/profile_pic.png';
import location from '../../images/icons/location.png';
import add from '../../images/icons/add.png';
import { isLoggedIn } from '../../actions/authActions';
import '../../stylesheets/profile.css';
import Projects from './Projects';
import Questions from './Questions';
import Switch from "react-switch";
import ReactCrop from 'react-image-crop';
import ProgressBar from '../profile/ProgressBar'
import axios from 'axios';
import {API_ENDPOINT} from '../../secrets';
import { config, getEmailAndId, getConfigEmail } from '../../services/cookieHelpers'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const credentials = getEmailAndId()
const configEmail = getConfigEmail()

const ADDRESS_KEYS = {
  'city': 'administrative_area_level_2',
  'country': 'country',
  'state': 'administrative_area_level_1',
  'country_code': 'country',
  'lat': 0,
  'lng': 0
}

class UserProfile extends React.Component{
  constructor(props){
    super(props);
    this.fileInput = React.createRef();
  }
  state = {
    user                  : {
                              id: "",
                              email: "",
                              user_type: "NOT_SET",
                              user_canvas: [],
                              design_canvas: [],
                              fabrication_canvas: [],
                              profile_pic: userIcon,
                              profile_name: "".split("@")[0],
                              // Newly added
                              name:"",
                              last_name:"",
                              studio_name:"",
                              description:"",
                              personal_website:"",
                              questions:[],
                          },
    designer              : this.props.user.user_type==="DESIGNER" || this.props.user.user_type==="DESIGNER_MAKER" ? true : false,
    maker                 : this.props.user.user_type==="MAKER" || this.props.user.user_type==="DESIGNER_MAKER" ? true : false,
    editName              : false,
    editCompany           : false,
    editLocation          : false,
    editDescription       : false,
    name                  : this.props.user.name,
    last_name             : this.props.user.last_name,
    profile_pic           : this.props.user.profile_pic,
    studio_name           : this.props.user.studio_name,
    location              : this.props.user.location,
    description           : this.props.user.description,
    user_type             : this.props.user.user_type,
    showImageCropModal    : false,
    isImageUploading      : false,
    uploadedImageLink     : null,
    crop                  : {
                              unit: '%',
                              width: 30,
                              aspect: 1 / 1,
                            },
    croppedImageUrl       : null,
    showProfilePic        : false,
    nameError             : false,
    lastNameError         : false,
    address: '',
    detailed_location: {},
  }

  componentDidMount(){

    if (this.props.id !== "")
    {

      //TODO: Get user details
      this.getUserData(this.props.id)
    }
    else
    {
      //TODO: Return user to home page / or show error
    }

  }


  getUserData = async (user_id) => {
    console.log("in get")
    const GET_USER_DATA_URI = `${API_ENDPOINT}/users/user_id`
    try {
      let response = await axios.get(GET_USER_DATA_URI, {params: {id: user_id, admin_password: "testadmin"}}, {crossDomain: true})
      // console.log(response.data)
      let {data} = response
      if(data)
      {
        let {user} = data
        user = user[0]
        // this.setState({user: user})
        // console.log(user)

        this.setState(
        {
          user                  : user,
          designer              : user.user_type==="DESIGNER" || user.user_type==="DESIGNER_MAKER" ? true : false,
          maker                 : user.user_type==="MAKER" || user.user_type==="DESIGNER_MAKER" ? true : false,
          name                  : user.name,
          last_name             : user.last_name,
          profile_pic           : user.profile_pic,
          studio_name           : user.studio_name,
          detailed_location     : user.detailed_location,
          description           : user.description,
          user_type             : user.user_type,
        })
      }
    } catch (error) {
      // TODO: 
      console.log(error)
    }
  }

  // toggles designer switch
  handleChangeDesigner = () => {
    if(this.state.user.user_type !== "ADMIN"){
      this.setState({ designer: !this.state.designer },()=>{
        let type="";
        if(this.state.designer && this.state.maker){
          type="DESIGNER_MAKER";
        }
        else if(this.state.designer){
          type="DESIGNER";
        }
        else if(this.state.maker){
          type="MAKER";
        }
        else{
          type="USER";
        }
        let data = {
          id:this.state.user._id,
          user_type:type,
          updateType:'PROFILE_TYPE'
        }
        this.updateUser(data);
      });
    }
  };

  updateUser = (data) => 
  {
    
          const UPDATE_USER_DATA_URI = `${API_ENDPOINT}/users/update`

          axios.put(UPDATE_USER_DATA_URI, { ...data, ...credentials }, config)
          .then(response => response.data).then(response => {
              if(response.status)
              {
                  this.getUserData(response.user._id)
              }
              else{
                //TODO: Manage error
                console.log("Error")
              }
          }).catch(e => {


                //TODO: Manage error
                console.log(e)          
            })
  }

  // toggles maker switch
  handleChangeMaker = () => {
    this.setState({ maker: !this.state.maker },()=>{
      let type="";
      if(this.state.designer && this.state.maker){
        type="DESIGNER_MAKER";
      }
      else if(this.state.designer){
        type="DESIGNER";
      }
      else if(this.state.maker){
        type="MAKER";
      }
      else{
        type="USER";
      }
      let data = {
        id:this.state.user._id,
        user_type:type,
        updateType:'PROFILE_TYPE'
      }
      this.updateUser(data);
    });
  };

  handleChange = (e) =>{
    this.setState({[e.target.name]:e.target.value})
  }

  // edit profile data
  editData = (e, type) => {
    switch(type){
      case "PROFILE_LOCATION":
        let locationData = {
          id:this.state.user._id,
          updateType:type,
          detailed_location:this.state.detailed_location
        }
        this.updateUser(locationData);
        this.setState({editLocation:false})
        break;
      case "PROFILE_NAME":
        if(this.state.name.trim().length===0 && this.state.last_name.trim().length===0){
          this.setState({nameError:true, lastNameError:true})
        }
        else if(this.state.name.trim().length===0){
          this.setState({nameError:true})
        }
        else if(this.state.last_name.trim().length===0){
          this.setState({lastNameError:true})
        }
        else{
          let nameData = {
            id:this.state.user._id,
            updateType:type,
            name:this.state.name,
            last_name:this.state.last_name,
          }
          this.updateUser(nameData);
          this.setState({editName:false, nameError:false, lastNameError:false})
        }
        break;
      case "PROFILE_STUDIO":
        let studioData = {
          id:this.state.user._id,
          updateType:type,
          studio_name:this.state.studio_name,
        }
        this.updateUser(studioData);
        this.setState({editCompany:false})
        break;
      case "PROFILE_DESCRIPTION":
        let descriptionData = {
          id:this.state.user._id,
          updateType:type,
          description:this.state.description,
        }
        this.updateUser(descriptionData);
        this.setState({editDescription:false})
        break;
      default:
        break;
    } 
  }

  onSelectFile = () => {
    if (this.fileInput.current.files.length >0 && this.fileInput.current.files[0]) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({
          uploadedImageLink   : reader.result, 
          showImageCropModal  : true, 
          isImageUploading    : false})
        );
      reader.readAsDataURL(this.fileInput.current.files[0]);
      this.setState({isImageUploading: true})
    }
  }

  handleUpload = (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append("file", this.state.croppedImageBlob)
    const UPLOAD_FILE_DATA_URI = `${API_ENDPOINT}/api/upload`
    axios.post(UPLOAD_FILE_DATA_URI, formData, {crossDomain: true})
      .then(response => {
        let data = {
            id: this.state.user._id,
            updateType: "PROFILE_PIC",
            profile_pic: response.data.link
        }
        this.updateUser(data)
        this.setState({profile_pic: response.data.link, showImageCropModal: false})

      }).catch(e => {
        console.log(e)
        this.setState({showImageCropModal: false})

      }) 
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  copyEmail = (copiableText) => {
    const tempElement = document.createElement('textarea');
    tempElement.value = copiableText;
    tempElement.setAttribute('readonly', '');
    tempElement.style.position = 'absolute';
    tempElement.style.left = '-9999px';
    document.body.appendChild(tempElement);
    // console.log(tempElement)
    try {
      tempElement.select();
      document.execCommand('copy');
    } catch {
      tempElement.setSelectionRange(0, copiableText.length - 1)
      document.execCommand('copy')
    }
    document.body.removeChild(tempElement);
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
    const [croppedImageBlob, croppedImageUrl] =  await this.getCroppedImg(this.imageRef,crop,'newFile.jpeg');
    this.setState({ croppedImageUrl, croppedImageBlob });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
          if (!blob) {
              console.error('Canvas is empty');
              return;
          }
          
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve([blob, this.fileUrl]);
      }, 'image/jpeg');
    });
  }

  handleSelect = async (address) => {
    this.setState({ address })
    geocodeByAddress(address)
      .then(async (results) => {
        const coords = await getLatLng(results[0]);
        const detailed_address = {
          "city": this.extractAddress(results[0].address_components, 'city'),
          "state": this.extractAddress(results[0].address_components, 'state'),
          "country": this.extractAddress(results[0].address_components, 'country'),
          "country_code": this.extractAddress(results[0].address_components, 'country_code'),
          'lat': coords.lat,
          'lng': coords.lng,
          "display_address": this.state.address
        }
        this.setState({ detailed_location: detailed_address });
      })
      .catch(error => console.error('Error', error));
  }

  handleLocationChange = address => {
    this.setState({ address });
  }

  extractAddress = (address, type) => {
    let data = "";
    for (let i = 0; i < address.length; i++) {
      if (address[i].types.includes(ADDRESS_KEYS[type])) {
        if (type === 'country_code') {
          data = address[i].short_name;
        }
        else {
          data = address[i].long_name;
        }
      }
    }
    return data;
  }

  renderAutoComplete = () => (
    <PlacesAutocomplete
      value={this.state.address}
      onChange={this.handleLocationChange}
      onSelect={this.handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="auto-complete">
          <input
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'location-search-input',
            })}
          />
          <div className={(loading || suggestions.length > 0) && "autocomplete-dropdown-container"}>
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              const className = 'suggestion-item';
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )

  renderLocationModal = () => {
    return (
      <Modal show={this.state.editLocation} onHide={() => { this.setState({ editLocation: false }) }} className="search-location-modal">
        <Modal.Header closeButton style={{ borderBottom: 0 }}>
          <Modal.Title style={{ fontSize: '1.2rem' }}>Search location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderAutoComplete()}
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 0, display: 'flex', justifyContent: 'center' }}>
          <button className="main-btn" onClick={(e) => { this.editData(e, "PROFILE_LOCATION") }}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    )
  }

  render(){
    if (this.props.user.user_type !== 'ADMIN') {
      this.props.history.push('/')
    }

    return(
      <div>
        <Navbar />
        <Container id="profile">
          {this.renderLocationModal()}
          <Row className="user-details-container">
            <div style={{ flexDirection: 'column', display: 'flex', width: 105 }}>
              <div style={{ border: '1px solid #a3a3a3', borderRadius: '50%', cursor: 'pointer', width: 'max-content' }}>
                <Image src={(this.state.user && this.state.user.profile_pic) ? this.state.user.profile_pic : userIcon} fluid style={{ borderRadius: '50%' }} onClick={() => { this.setState({ showProfilePic: true }) }} />
              </div>
              <div class="image-upload">
                <label htmlFor="file-input" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -30 }}>
                  <Image src={add} fluid style={{ marginLeft: -25, width: 35, height: 35, marginTop: 'auto', cursor: 'pointer' }} />
                </label>
                <input id="file-input" type="file" onChange={this.onSelectFile} ref={this.fileInput} />
              </div>
            </div>
            {/* USER DETAILS */}
            <div style={{
              flex: 1,
              //  minWidth:100
            }} className="name-container">
              {/* NAME */}
              <Col className="name" style={{ paddingight: 0 }}>
                <Row style={{ flexFlow: 'row', width: 'inherit' }} className="name-wrapper">
                  {
                    this.state.editName ? (
                      <>
                        <div style={{ marginTop: '1rem', marginRight: 5 }}>
                          <Form.Control
                            plaintext={!this.state.editName}
                            readOnly={!this.state.editName}
                            defaultValue={this.state.user.name}
                            name="name"
                            placeholder="First Name"
                            onChange={this.handleChange}
                            style={{ padding: 5, margin: 0,  borderRadius: 5 }} />
                          {
                            this.state.nameError && (<p style={{ color: 'red', fontSize: 10, margin: 0 }}>*first name cannot be empty</p>)
                          }
                        </div>
                        <div style={{ marginTop: '1rem' }}>
                          <Form.Control
                            plaintext={!this.state.editName}
                            readOnly={!this.state.editName}
                            defaultValue={this.state.user.last_name}
                            name="last_name"
                            placeholder="Last Name"
                            onChange={this.handleChange}
                            style={{ padding: 5, margin: 0,  borderRadius: 5 }} />
                          {
                            this.state.lastNameError && (<p style={{ color: 'red', fontSize: 10, margin: 0 }}>*last name cannot be empty</p>)
                          }

                        </div>
                      </>
                    ) : (
                        <h3 style={{}} onClick={() => { this.setState({ editName: true }) }}>{this.state.user && (this.state.user.name + " " + this.state.user.last_name)}</h3>
                      )
                  }
                  {
                    !this.state.editName && (
                      <Row className="edit-name" style={{ marginLeft: '1rem', alignItems: 'center' }}>
                        <div style={{ cursor: 'pointer',  fontWeight: 400 }} onClick={() => { this.setState({ editName: true }) }}>edit</div>
                      </Row>
                    )
                  }
                </Row>
                {
                  this.state.editName && (
                    <Row>
                      <div style={{ cursor: 'pointer', marginRight: 10,  fontWeight: 400 }} onClick={(e) => { this.editData(e, "PROFILE_NAME") }}>submit</div>
                      <div style={{ cursor: 'pointer', color: '#a3a3a3',  fontWeight: 400 }} onClick={() => { this.setState({ editName: false }) }}>cancel</div>
                    </Row>
                  )
                }
              </Col>
              {/* EMAIL */}
              <Col>
                <Row>
                  <span className='user-email-link' onClick={(e) => {
                    e.stopPropagation()
                    this.copyEmail(this.state.user.email)}}
                  >
                      {this.state.user.email}
                    </span>
                </Row>
              </Col>
              {/* COMPANY */}
              <Col className="company" style={{ marginBottom: 5, paddingight: 0 }}>
                <Row style={{ width: 'inherit' }} className="name-wrapper">
                  {
                    this.state.editCompany ? (
                      <>
                        <Form.Control
                          plaintext={!this.state.editCompany}
                          readOnly={!this.state.editCompany}
                          defaultValue={this.state.user.studio_name}
                          name="studio_name"
                          placeholder="Studio/Company name"
                          onChange={this.handleChange}
                          style={{ padding: 5, margin: 0,  fontWeight: 400, color: '#666666', borderRadius: 5, marginBottom: 0 }} />
                      </>
                    ) : (
                        <h4 onClick={() => { this.setState({ editCompany: true }) }} style={{ padding: !this.state.editCompany && 0,  fontWeight: 400, color: '#a3a3a3', borderRadius: 5, marginTop: 0 }} >{this.state.user.studio_name ? this.state.user.studio_name : "Your studio/company name"}</h4>
                      )
                  }
                  {
                    !this.state.editCompany && (
                      <Row className="edit-company" style={{ marginLeft: '1rem', alignItems: 'center' }}>
                        <div style={{ cursor: 'pointer',  fontWeight: 400 }} onClick={() => { this.setState({ editCompany: true }) }}>edit</div>
                      </Row>
                    )
                  }
                </Row>
                {
                  this.state.editCompany && (
                    <Row>
                      <div style={{ cursor: 'pointer', marginRight: 10,  fontWeight: 400 }} onClick={(e) => { this.editData(e, "PROFILE_STUDIO") }}>submit</div>
                      <div style={{ cursor: 'pointer', color: '#a3a3a3',  fontWeight: 400 }} onClick={() => { this.setState({ editCompany: false }) }}>cancel</div>
                    </Row>
                  )
                }
              </Col>
              {/* location */}
              <Col className="location" style={{ paddingLeft: 0, paddingight: 0 }}>
                <div style={{ display: 'flex', marginBottom: !this.state.editLocation && '0.75rem', alignItems: 'center' }} className="name-wrapper">
                  <Image src={location} style={{ width: 20, height: 20, marginRight: 5 }} />
                  <h5 onClick={() => { this.setState({ editLocation: true }) }} style={{ padding: !this.state.editLocation && 0, margin: 0, color: '#a3a3a3', borderRadius: 5, }}>{(this.state.detailed_location && this.state.detailed_location.display_address) ? this.state.detailed_location.display_address : "Enter your location"}</h5>
                  {
                    !this.state.editLocation && (
                      <Row className="edit-location" style={{ margin: 0, alignItems: 'center' }}>
                        <div style={{ cursor: 'pointer', fontWeight: 400 }} onClick={() => { this.setState({ editLocation: true }) }}>edit</div>
                      </Row>
                    )
                  }
                </div>
              </Col>
            </div>
            {/* USER TYPE-> MAKER/DESIGNER */}
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} className="switch-container">
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <Switch
                  checked={this.state.designer}
                  onChange={this.handleChangeDesigner}
                  onColor="#FFFFFF"
                  onHandleColor="#000000"
                  handleDiameter={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={35}
                  className="react-switch"
                  id="material-switch"
                />
                <button onClick={this.handleChangeDesigner} className="switch-button" style={{ backgroundColor: this.state.designer ? '#000000' : '#666666' }}>Designer</button>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <Switch
                  checked={this.state.maker}
                  onChange={this.handleChangeMaker}
                  onColor="#FFFFFF"
                  onHandleColor="#000000"
                  handleDiameter={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={35}
                  className="react-switch"
                  id="material-switch"
                />
                <button onClick={this.handleChangeMaker} style={{ margin: 0, backgroundColor: this.state.maker ? '#000000' : '#666666', color: '#FFFFFF', padding: '0.5rem', fontSize: 12, textTransform: 'capitalize', border: 'none', width: 100, borderRadius: 5, marginLeft: 10 }}>Fabricator</button>
              </div>
            </div>
          </Row>
          {/* DESCRIPTION */}
          <Form.Group as={Row} className="description-container">
            <div style={{ color: '#666666', fontWeight: 400,  fontSize: 18, width: 108, alignSelf: this.state.editDescription && 'flex-start' }}>
              Description:
            </div>
            <div style={{ padding: 0, flex: 1, minWidth: 300 }} className="description">
              {
                !this.state.user.description || this.state.editDescription ? (
                  <>
                    <Form.Control
                      as="textarea"
                      maxLength={150}
                      name="description"
                      onChange={this.handleChange}
                      onClick={() => { this.setState({ editDescription: true }) }}
                      defaultValue={this.state.user.description}
                      readOnly={!this.state.editDescription}
                      placeholder="Share a bit about the kind of work you do, the experience you have, or any expertise you want to highlight." />
                    <p style={{ margin: 0, fontSize: 11, textAlign: 'right', color: '#a3a3a3' }}>150 characters</p>
                    {
                      !this.state.editDescription && (
                        // <Row className="edit-description" style={{marginLeft:'1rem'}}>
                        <div className="edit-description" style={{ cursor: 'pointer',  fontWeight: 400, width: '100%' }} onClick={() => { this.setState({ editDescription: true }) }}>edit</div>
                        // {/* </Row> */}
                      )
                    }
                  </>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <h6 style={{ wordBreak: 'break-all' }} onClick={() => { this.setState({ editDescription: true }) }}>{this.state.user.description}</h6>
                      {
                        !this.state.editDescription && (
                          <Row className="edit-description" style={{ marginLeft: '1rem' }}>
                            <div style={{ cursor: 'pointer',  fontWeight: 400, width: '100%' }} onClick={() => { this.setState({ editDescription: true }) }}>edit</div>
                          </Row>
                        )
                      }
                    </div>
                  )
              }
              {
                this.state.editDescription && (
                  <Row style={{ width: '100%', textAlign: 'right', margin: 0, justifyContent: 'flex-end' }}>
                    <div style={{ cursor: 'pointer', marginRight: 10,  fontWeight: 400 }} onClick={(e) => { this.editData(e, "PROFILE_DESCRIPTION") }}>submit</div>
                    <div style={{ cursor: 'pointer', color: '#a3a3a3',  fontWeight: 400 }} onClick={() => { this.setState({ editDescription: false }) }}>cancel</div>
                  </Row>
                )
              }
            </div>
          </Form.Group>
          {/* PROGRESS BAR */}
          <ProgressBar user={this.state.user} />
          {/* UPLOAD */}
          {
            // If both (maker and designer) is selected
            // (this.state.designer || this.state.maker) ? (
              <>
                <Projects disabled={(this.state.designer || this.state.maker) ? false : true} 
                          user={this.state.user} 
                          questions={this.state.user && this.state.user.questions? this.state.user.questions: []}
                          updateUser={this.updateUser}
                          refreshUser={this.getUserData}
                />
              </>
            // ) :
            //   // No option is selected
            //   (
            //     <Projects disabled={true}/>
            //   )
          }
          {/* QUESTIONS */}
          <Questions user={this.state.user} 
                    questions={this.state.user && this.state.user.questions? this.state.user.questions: []} 
                      updateUser={this.updateUser}
                      refreshUser={this.getUserData}
                      />
        </Container>

        {/* IMAGE CROP MODAL */}
        <Modal
          size="sm"
          show={this.state.showImageCropModal}
          onHide={() => this.setState({ showImageCropModal: false })}
          aria-labelledby="example-modal-sizes-title-sm"
          id="instructions-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Crop Profile Image
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center">
              <ReactCrop
                src={this.state.uploadedImageLink}
                crop={this.state.crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col xs="12">
              <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <Row className="justify-content-center">
                  <Button variant="dark" onClick={this.handleUpload}>Done</Button>
                </Row>
              </Col>
            </Col>
          </Modal.Footer>
        </Modal>
        {/* Show profile picture modal */}
        <Modal
          size="md"
          show={this.state.showProfilePic}
          onHide={() => this.setState({ showProfilePic: false })}
          aria-labelledby="example-modal-sizes-title-sm"
          id="instructions-modal"
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center">
              <Image src={this.state.user.profile_pic} fluid></Image>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateUser: (data) => dispatch(updateUser(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserProfile));