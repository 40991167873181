import { API_ENDPOINT } from "../secrets";
import axios from "axios";
import { DEBUG } from "../secrets";
import { config, getEmailAndId } from '../services/cookieHelpers'


export const getNotifications = (data) => 
{
    const credentials = getEmailAndId()
    return (dispatch) => {
        const AUTH_GET_NOTIFICATION_URI = `${API_ENDPOINT}/api/pushnotification`
        axios.get(AUTH_GET_NOTIFICATION_URI, {params: { ...data, ...credentials }} , config)
        .then(response => {
            // console.log("Notifications:", response.data)
            if(response.data.status)
            {
                dispatch({type: "GET_NOTIFICATIONS", notifications: response.data.notifications})
            }
        }).catch(e => {
            console.log(e)
        })
    }
}

export const readNotification = (notification_id,userId) => 
{
    const credentials = getEmailAndId()
    return (dispatch) => {
        const AUTH_PUT_NOTIFICATION_URI = `${API_ENDPOINT}/api/pushnotification/${notification_id}`
        axios.put(AUTH_PUT_NOTIFICATION_URI, { "readStatus":true, ...credentials } , config)
        .then(response => {
            if(response.data.status)
            {
                dispatch(getNotifications({user_id:userId}))
            }
        }).catch(e => {
            console.log(e)
        })
    }
}