import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCanvas } from "../../actions/canvasActions";
import { postTrigger } from "../../actions/triggerActions";
import { getTransactionsForCanvas } from "../../actions/transactionActions";
import CanvasTile from "./CanvasTile";
import { Container, Row, Col, Button } from "react-bootstrap";
import SyncLoader from "react-spinners/SyncLoader";
import CommentTileInput from "./CommentTileInput";
import CommentTile from "./CommentTile";
import NavBar from "../utilities/NavBar";

class Canvas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            replyTo: undefined,
            quickActionLoading: false,
            quickActionCompleted: false,
        };
        this.commentTileRef = React.createRef();
    }
    componentDidMount() {
        require("../../stylesheets/canvas.css");
        // document.body.style.backgroundColor = "#fff";
        if (
            localStorage.getItem("nayaCanvasID") !== undefined &&
            localStorage.getItem("nayaCanvasID") !== ""
        ) {
            const id = localStorage.getItem("nayaCanvasID");
            this.props.getCanvas({ id: id });
            this.props.getTransactionsForCanvas(id);
        }
        // document.body.style.backgroundColor = "#222";
    }

    readyForTender = () => {
        console.log("Ready for Tender!");
        const canvas_id = this.props.canvas._id;
        this.setState({ quickActionLoading: true });
        postTrigger(
            {
                triggerType: "READY_FOR_TENDER",
                payload: canvas_id,
                data: {
                    canvas_id: canvas_id,
                    user_id: this.props.canvas.client,
                },
            },
            (response) => {
                console.log("Posted trigger successfully");
                this.setState({
                    quickActionLoading: false,
                    quickActionCompleted: true,
                });
                this.props.getCanvas({ id: canvas_id });
                this.props.getTransactionsForCanvas(canvas_id);
            }
        );
    };

    startFabrication = () => {
        console.log("Start fabrication");
        const canvas_id = this.props.canvas._id;
        this.setState({ quickActionLoading: true });
        postTrigger(
            {
                triggerType: "FABRICATION",
                payload: canvas_id,
                data: {
                    canvas_id: canvas_id,
                    user_id: this.props.canvas.client,
                },
            },
            (response) => {
                console.log("Posted trigger successfully");
                this.setState({
                    quickActionLoading: false,
                    quickActionCompleted: true,
                });
                this.props.getCanvas({ id: canvas_id });
                this.props.getTransactionsForCanvas(canvas_id);
            }
        );
    };

    qualityControl = () => {
        console.log("Ready for Quality Control!");
        const canvas_id = this.props.canvas._id;
        this.setState({ quickActionLoading: true });
        postTrigger(
            {
                triggerType: "QUALITY_CONTROL",
                payload: canvas_id,
                data: {
                    canvas_id: canvas_id,
                    user_id: this.props.canvas.client,
                },
            },
            (response) => {
                console.log("Posted trigger successfully");
                this.setState({
                    quickActionLoading: false,
                    quickActionCompleted: true,
                });
                this.props.getCanvas({ id: canvas_id });
                this.props.getTransactionsForCanvas(canvas_id);
            }
        );
    };

    renderTransactionSection = () => {
        let render;

        let DesignerFee = [];
        let FabricationFee = [];
        let DesignerExtraFee = [];

        if (this.props.transactions) {
            DesignerFee = this.props.transactions.filter(
                (transaction) => transaction.description === "DESIGNER_FEE"
            );
            FabricationFee = this.props.transactions.filter(
                (transaction) => transaction.description === "FABRICATION_FEE"
            );
            DesignerExtraFee = this.props.transactions.filter(
                (transaction) =>
                    transaction.description === "DESIGNER_EXTRA_FEE"
            );
        }

        if (
            this.props.canvas.fabricationCost &&
            this.props.canvas.fabricationCost !== 0
        ) {
            return (
                <Col xs={12}>
                    <h6>Start Fabrication</h6>
                    <p>
                        To start fabrication, please proceed to pay the deposit
                        for fabrication by clicking below
                    </p>
                    <Button
                        variant='dark'
                        onClick={this.handleRedirect}
                        value='pay-fabrication-fee'
                        style={{
                            borderRadius: "0px",
                            marginTop: "0px",
                            marginLeft: "0px",
                        }}
                    >
                        Pay Fabrication Deposit{" "}
                        {this.props.canvas.fabricationCost}
                    </Button>
                    <p>
                        If you would like to continue designing, you can pay for
                        additional design work be clicking below
                    </p>
                    <Button
                        variant='dark'
                        onClick={this.handleRedirect}
                        value='pay-addl-design-fee'
                        style={{
                            borderRadius: "0px",
                            marginTop: "0px",
                            marginLeft: "0px",
                        }}
                    >
                        Additional Design Round $49
                    </Button>
                </Col>
            );
        } else {
            if (DesignerFee.length === 0) {
                return (
                    <Col xs={12}>
                        <h6>Co:Design with a designer</h6>
                        <p>
                            To collaborate with a designer and maker, please pay
                            the designer fee by clicking on the link below
                        </p>
                        <Button
                            variant='dark'
                            onClick={this.handleRedirect}
                            value='pay-design-fee'
                            style={{
                                borderRadius: "0px",
                                marginTop: "0px",
                                marginLeft: "0px",
                            }}
                        >
                            Pay Designer Fee $49
                        </Button>
                    </Col>
                );
            } else {
                return (
                    <Col xs={12}>
                        <h6>Continue Co:Design</h6>
                        <p>
                            If you would like to continue designing, you can pay
                            for additional design work by clicking below
                        </p>
                        <Button
                            variant='dark'
                            onClick={this.handleRedirect}
                            value='pay-addl-design-fee'
                            style={{
                                borderRadius: "0px",
                                marginTop: "0px",
                                marginLeft: "0px",
                            }}
                        >
                            Additional Design Round $49
                        </Button>
                    </Col>
                );
            }
        }
    };

    renderQuickActionButton = () => {
        let { quickActionLoading, quickActionCompleted } = this.state;
        let render;

        let DesignerFee = [];
        let FabricationFee = [];
        let DesignerExtraFee = [];

        if (this.props.transactions) {
            DesignerFee = this.props.transactions.filter(
                (transaction) => transaction.description === "DESIGNER_FEE"
            );
            FabricationFee = this.props.transactions.filter(
                (transaction) => transaction.description === "FABRICATION_FEE"
            );
            DesignerExtraFee = this.props.transactions.filter(
                (transaction) =>
                    transaction.description === "DESIGNER_EXTRA_FEE"
            );
        }

        const stage_history = (this.props.canvas.stage_history || []).map(
            (o) => o["stage"]
        );
        const stage =
            stage_history.length != 0
                ? stage_history[stage_history.length - 1]
                : this.props.canvas.stage;
        const user_type = this.props.user.user_type;
        var assigned_role = null;
        if (this.props.canvas.fabricator == this.props.user.id) {
            assigned_role = "fabricator";
        } else if (this.props.canvas.designer == this.props.user.id) {
            assigned_role = "designer";
        } else if (this.props.canvas.consultant == this.props.user.id) {
            assigned_role = "consultant";
        }

        if (quickActionLoading) {
            return (
                <Button variant='dark'>
                    <SyncLoader
                        color={"#ffffff"}
                        sizeUnit={"px"}
                        size={"10"}
                    ></SyncLoader>
                </Button>
            );
        }

        if (quickActionCompleted) {
            return (
                <Button variant='dark' disabled>
                    Action Completed!
                </Button>
            );
        }

        // enter quality control button
        if (assigned_role == "fabricator" && stage == "FABRICATION") {
            return (
                <Button
                    variant='dark'
                    onClick={this.qualityControl}
                    value='ready-for-qc'
                    style={{
                        borderRadius: "0px",
                        marginTop: "0px",
                        marginLeft: "0px",
                        width: "100%",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                    }}
                >
                    Ready For Quality Control
                </Button>
            );
        }

        // ready for tender button
        if (
            assigned_role == "designer" &&
            stage_history &&
            (stage_history.includes("DESIGN_STAGE_BYPASS_REQUEST") ||
                stage_history.includes("MCF"))
        ) {
            return (
                <Button
                    variant='dark'
                    onClick={this.readyForTender}
                    value='ready-for-tender'
                    style={{
                        borderRadius: "0px",
                        marginTop: "0px",
                        marginLeft: "0px",
                        width: "100%",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                    }}
                >
                    Ready For Tender
                </Button>
            );
        }

        // Start Fabrication button
        if (
            assigned_role == "fabricator" &&
            !this.props.canvas.consultant &&
            stage_history &&
            stage_history.includes("FABRICATION_CONSULTING") &&
            !stage_history.includes("FABRICATION")
        ) {
            return (
                <Button
                    variant='dark'
                    onClick={this.startFabrication}
                    value='start-fabrication'
                    style={{
                        borderRadius: "0px",
                        marginTop: "0px",
                        marginLeft: "0px",
                        width: "100%",
                        fontWeight: "200",
                        fontSize: "0.9rem",
                    }}
                >
                    Start Fabrication
                </Button>
            );
        }
    };

    renderDesignFeeButton = () => {
        let render = <div></div>;
        try {
            if (this.props.transactions) {
                let DesignerFee = this.props.transactions.filter(
                    (transaction) => transaction.description === "DESIGNER_FEE"
                );
                if (DesignerFee.length > 0) {
                    render = (
                        <p>
                            You have already paid the design fee for two
                            iterations
                        </p>
                    );
                } else {
                    console.log("Pos4");
                    render = (
                        <Button
                            variant='dark'
                            onClick={this.handleRedirect}
                            value='/pay-design-fee'
                            style={{ borderRadius: "0px" }}
                        >
                            Pay Designer Fee
                        </Button>
                    );
                }
            } else {
                console.log("Pos4");
                render = (
                    <Button
                        variant='dark'
                        onClick={this.handleRedirect}
                        value='pay-design-fee'
                        style={{
                            borderRadius: "0px",
                            marginTop: "0px",
                            marginLeft: "0px",
                        }}
                    >
                        Pay Designer Fee
                    </Button>
                );
            }
        } catch (error) {
            return (
                <p>
                    Error Retreiving Information: Please check your internet
                    connection.
                </p>
            );
        }

        return render;
    };

    renderExtraDesignFeeButton = () => {
        let render = <div></div>;
        try {
            if (this.props.transactions) {
                let DesignerFee = this.props.transactions.filter(
                    (transaction) => transaction.description === "DESIGNER_FEE"
                );
                let DesignerExtraFee = this.props.transactions.filter(
                    (transaction) =>
                        transaction.description === "DESIGNER_EXTRA_FEE"
                );
                if (DesignerFee.length > 0) {
                    if (DesignerExtraFee.length > 0) {
                        render = (
                            <p>
                                You have already paid the design fee for{" "}
                                {DesignerFee.length} additional iterations
                            </p>
                        );
                    } else {
                        console.log("Pos4");
                        render = (
                            <Button
                                variant='dark'
                                onClick={this.handleRedirect}
                                value='pay-addl-design-fee'
                                style={{
                                    borderRadius: "0px",
                                    marginTop: "0px",
                                    marginLeft: "0px",
                                }}
                            >
                                Pay for additional design work
                            </Button>
                        );
                    }
                }
            }
        } finally {
            return render;
        }
    };

    renderFabricationFeeButton = () => {
        let render = <div></div>;
        try {
            if (this.props.transactions) {
                let DesignerFee = this.props.transactions.filter(
                    (transaction) => transaction.description === "DESIGNER_FEE"
                );
                let FabricationFee = this.props.transactions.filter(
                    (transaction) =>
                        transaction.description === "FABRICATION_FEE"
                );
                let DesignerExtraFee = this.props.transactions.filter(
                    (transaction) =>
                        transaction.description === "DESIGNER_EXTRA_FEE"
                );
                if (FabricationFee.length > 0) {
                    render = (
                        <Col xs={12}>
                            Your piece is being fabricated. We will be in touch
                            when it is finished
                        </Col>
                    );
                } else if (
                    DesignerFee.length > 0 &&
                    this.props.canvas.fabricationCost &&
                    this.props.canvas.fabricationCost !== 0
                ) {
                    render = (
                        <Button
                            variant='dark'
                            onClick={this.handleRedirect}
                            value='pay-fabrication-fee'
                            style={{
                                borderRadius: "0px",
                                marginTop: "0px",
                                marginLeft: "0px",
                            }}
                        >
                            Start Fabrication
                        </Button>
                    );
                }
            }
        } finally {
            return render;
        }
    };

    renderNextSteps = () => {
        let render = <div></div>;

        return render;
    };

    handleRedirect = (e) => {
        e.preventDefault();
        let redirectPage = e.target.value;
        this.props.history.push("/" + redirectPage);
    };
    renderSketch = () => {
        let render = <div></div>;
        try {
            let sketch = this.props.canvas.sketch;
            if (sketch && sketch.sketchData) {
                render = (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        refId={sketch._id}
                        contentType={"SKETCH"}
                        data={sketch}
                    ></CanvasTile>
                );
            }
            return render;
        } catch (error) {
            console.log(error);
            return render;
        }
    };

    renderSearches = () => {
        let render = <div></div>;
        try {
            let searches = this.props.canvas.searches;

            if (searches !== undefined) {
                let keywords = Object.keys(searches);
                let all_images = [];
                // console.log(keywords)
                keywords.forEach(
                    (key) => (all_images = [...all_images, ...searches[key]])
                );
                // console.log(all_images)
                render = all_images.map((img) => (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        key={img}
                        refId={img.slice(0, 10)}
                        contentType={"SEARCH"}
                        data={img}
                    ></CanvasTile>
                ));
            }
            return render;
        } catch (error) {
            // console.log(error)
            return render;
        }
    };

    renderUploads = () => {
        let render = <div></div>;
        try {
            let uploads = this.props.canvas.uploads;

            if (uploads !== undefined && uploads.length > 0) {
                render = uploads.map((upload) => (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        key={upload._id}
                        refId={upload._id}
                        contentType={"UPLOAD"}
                        data={upload}
                    ></CanvasTile>
                ));
            }
            return render;
        } catch (error) {
            console.log(error);
            return render;
        }
    };
    renderTextMoodboard = () => {
        let render = <div></div>;
        try {
            let textMoodboard = this.props.canvas.moodboards.filter(
                (moodboard) => moodboard.isText
            )[0];
            if (textMoodboard !== undefined) {
                textMoodboard = (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        refId={textMoodboard._id}
                        contentType={"TEXT_MOODBOARD"}
                        data={textMoodboard}
                    ></CanvasTile>
                );
            }

            return textMoodboard;
        } catch (error) {
            console.log(error);
            return render;
        }
    };
    renderVisualMoodboard = () => {
        let render = <div></div>;
        try {
            let visualMoodboard = this.props.canvas.moodboards.filter(
                (moodboard) => !moodboard.isText
            )[0];

            if (visualMoodboard !== undefined) {
                visualMoodboard = visualMoodboard.options.map((option) => (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        refId={option}
                        key={option}
                        contentType={"VISUAL_MOODBOARD"}
                        data={option}
                    ></CanvasTile>
                ));
            }
            return visualMoodboard;
        } catch (error) {
            console.log(error);
            return render;
        }
    };
    renderQuizAnswers = () => {
        let render;
        try {
            if (
                this.props.canvas.quizAnswers !== undefined &&
                this.props.canvas.quizAnswers.length > 0
            ) {
                render = this.props.canvas.quizAnswers.map((answer) => (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        refId={answer._id}
                        key={answer._id}
                        contentType={"ANSWER"}
                        data={answer}
                    ></CanvasTile>
                ));
            }
        } catch (error) {
            console.log(error);
            render = undefined;
        }
        return render;
    };

    renderDimensions = () => {
        let render;

        try {
            let dimensions = this.props.canvas.dimensions;
            if (
                dimensions &&
                dimensions.length &&
                dimensions.height &&
                dimensions.width
            ) {
                let dimension = this.props.canvas.dimensions;
                render = (
                    <CanvasTile
                        replyToFunction={this.replyTo}
                        refId={dimension._id}
                        key={dimension._id}
                        contentType={"DIMENSIONS"}
                        data={dimension}
                    ></CanvasTile>
                );
            } else {
                render = undefined;
            }
        } catch (error) {
            console.log(error);
            render = undefined;
        }
        return render;
    };

    renderComments = () => {
        let render;
        console.log("rendering comments");
        try {
            let comments = this.props.comments[
                localStorage.getItem("nayaCanvasID")
            ];
            if (comments !== undefined && comments.length > 0) {
                render = [];
                for (let index = 0; index < comments.length; index++) {
                    const commentData = comments[index];
                    if (
                        commentData.comment_text !== "" ||
                        commentData.comment_image_uploads.length > 0 ||
                        commentData.comment_model_uploads.length > 0
                    ) {
                        // console.log(commentData)
                        render.push(
                            <CommentTile
                                data={commentData}
                                key={commentData._id}
                                refId={commentData._id}
                                key={commentData._id}
                            ></CommentTile>
                        );
                    }
                }
            }
        } catch (error) {
            // console.log(error)
            render = <div></div>;
        }
        // console.log(render)
        return render;
    };

    renderApprovalComments = () => {
        let render;
        console.log("rendering comments");
        try {
            let comments = this.props.approval_comments[
                localStorage.getItem("nayaCanvasID")
            ];
            if (comments !== undefined && comments.length > 0) {
                render = [];
                for (let index = 0; index < comments.length; index++) {
                    const commentData = comments[index];
                    if (
                        commentData.comment_text !== "" ||
                        commentData.comment_image_uploads.length > 0 ||
                        commentData.comment_model_uploads.length > 0
                    ) {
                        // console.log(commentData)
                        render.push(
                            <CommentTile
                                data={commentData}
                                key={commentData._id}
                                refId={commentData._id}
                                key={commentData._id}
                                type='APPROVAL_COMMENT'
                            ></CommentTile>
                        );
                    }
                }
            }
        } catch (error) {
            // console.log(error)
            render = <div></div>;
        }
        // console.log(render)
        return render;
    };

    renderAddContentBoard = () => {
        let render = <div></div>;

        // console.log(this.state.replyTo)

        try {
            if (this.props.user !== undefined && this.props.user.id !== "") {
                render = (
                    <CommentTileInput
                        replyTo={this.state.replyTo}
                        clearReplyTo={() =>
                            this.setState({ replyTo: undefined })
                        }
                        getRef={(ref) => (this.commentTileRef = ref)}
                    ></CommentTileInput>
                );
            }
            return render;
        } catch (error) {
            return render;
        }
    };

    replyTo = (comment) => {
        // console.log(comment)
        // e.preventDefault()
        this.setState({ replyTo: comment });
        this.commentTileRef.scrollIntoView();
    };

    renderCanvasTiles = () => {
        let QuizAnswersRender = this.renderQuizAnswers();
        let TextMoodboardRender = this.renderTextMoodboard();
        let VisualMoodboardRender = this.renderVisualMoodboard();
        let UploadsRender = this.renderUploads();
        let SketchRender = this.renderSketch();
        let SearchRender = this.renderSearches();
        let DimensionsRender = this.renderDimensions();
        return (
            <Row>
                {QuizAnswersRender}
                {TextMoodboardRender}
                {DimensionsRender}
                {VisualMoodboardRender}
                {SearchRender}
                {UploadsRender}
                {SketchRender}
            </Row>
        );
    };

    render() {
        if (this.props.canvas) {
            return (
                <Container fluid>
                    <NavBar></NavBar>
                    <Container id='canvas'>
                        <Row>
                            <Col xs={12}>
                                <Row>
                                    <Col xs={12} md={6} lg={8}>
                                        {this.props.canvas.name &&
                                            this.props.canvas.name !== "" && (
                                                <h1>
                                                    {" "}
                                                    {this.props.canvas.name}
                                                </h1>
                                            )}
                                        {(!this.props.canvas.name ||
                                            this.props.canvas.name === "") && (
                                            <h1> Canvas</h1>
                                        )}
                                    </Col>
                                    {this.props.pathway !== "COMPETITION" && (
                                        <Col xs={12} md={6} lg={4}>
                                            {this.renderQuickActionButton()}
                                        </Col>
                                    )}
                                </Row>

                                {this.props.canvas.story &&
                                    this.props.canvas.story !== "" && (
                                        <Col xs={12}>
                                            <Row>
                                                <hr
                                                    style={{
                                                        width: "100%",
                                                        height: "2px",
                                                        backgroundColor:
                                                            "black",
                                                    }}
                                                ></hr>
                                                <p
                                                    style={{
                                                        fontWeight: "200",
                                                        fontSize: "1.2rem",
                                                    }}
                                                >
                                                    {this.props.canvas.story}
                                                </p>{" "}
                                            </Row>
                                        </Col>
                                    )}

                                <hr
                                    style={{
                                        width: "100%",
                                        height: "2px",
                                        backgroundColor: "black",
                                    }}
                                ></hr>

                                <Col xs={12} className='next-steps'>
                                    <Row>
                                        <Col xs={12} lg={12}>
                                            {/* <Col xs={12}>
                                    <h6>
                                        Collaborate with a designer
                                    </h6>
                                    <p>
                                        To collaborate with a designer and maker, please pay the designer fee by clicking on the link below
                                    </p>
                                    {this.renderDesignFeeButton()}
                                    {this.renderExtraDesignFeeButton()}
                                    {this.renderFabricationFeeButton()}
                                </Col> */}
                                            {/* {this.renderTransactionSection()} */}
                                        </Col>

                                        {this.renderNextSteps()}
                                    </Row>
                                </Col>
                            </Col>
                            {/* <hr style={{"width": "100%", "height": "2px", "backgroundColor": "black"}}></hr> */}

                            <Col xs={12}>{this.renderCanvasTiles()}</Col>
                            <hr
                                style={{
                                    width: "100%",
                                    height: "2px",
                                    backgroundColor: "black",
                                }}
                            ></hr>
                            <Col xs={12}>
                                <Row>
                                    {this.props.user.user_type !== "USER" &&
                                        this.renderApprovalComments()}
                                    {this.renderComments()}
                                    {this.renderAddContentBoard()}
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            );
        } else {
            return <p>Loading...</p>;
        }
    }
}

const mapStateToProps = (state) => {
    console.log("approval_comments:", state.approval_comments);
    return {
        user: state.user,
        canvas: state.canvas[localStorage.getItem("nayaCanvasID")],
        comments: state.comments,
        approval_comments: state.approval_comments,
        transactions: state.transactions[state.canvas._id],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCanvas: (data) => dispatch(getCanvas(data)),
        getTransactionsForCanvas: (id) =>
            dispatch(getTransactionsForCanvas(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Canvas));
